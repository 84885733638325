import React, { Component, Fragment } from "react";
import { connectAutoComplete } from "react-instantsearch-dom";
import AutoSuggest from "react-autosuggest";
import PageHit from "./hit/page-hit";
import DisplayingHitResultsCount from "./hit/displaying-hit-results-count";
import SuggestionCategoryTitle from "./suggestion-category-title";
import DisplayTotalHitCount from "./hit/displaying-total-hit-count";
import FaqHit from "./hit/faq-hit";
//import {bindActionCreators} from 'redux'
import { doSiteSearch } from "../actions";
//import {connect} from 'react-redux'
import getIn from "lodash-es/get";
import cx from "classnames";
//import {addLocaleUrl} from '../../../modules/utils'
import { addLocaleUrl } from "../../../utils/utils";

const renderInputComponent = (
  inputProps,
  showDeleteButton,
  onClear,
  onSubmit
) => (
  <Fragment>
    <input
      {...inputProps}
      className="form-control nav-search-field"
      type="search"
      aria-label="Site Search Box Input"
      onKeyDown={(e) => {
        const { target: input = {} } = e;
        const activeDescendent =
          input.getAttribute && input.getAttribute("aria-activedescendant");
        if (e.keyCode === 13 && !activeDescendent) {
          onSubmit(e);
        } else {
          inputProps.onKeyDown(e);
        }
      }}
    />
    <div>
      <button className="primary-button btn btn-secondary" onClick={onSubmit}>
        <div className="inner">
          <i className="icon-search" />
        </div>
      </button>
      {showDeleteButton && (
        <i onClick={onClear} className="search-delete icon-close-grey" />
      )}
    </div>
  </Fragment>
);

function renderSuggestionsContainer({ containerProps, children }, onClick) {
  return (
    <div {...containerProps} aria-label="Search Box Suggestions">
      {children}
      <DisplayTotalHitCount onClick={onClick} />
    </div>
  );
}

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.currentRefinement || "",
      isMobile: window.innerWidth < 768,
    };
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    var self = this;
    window.addEventListener("resize", this.onResize);
    [...this.node.querySelectorAll('[role="combobox"]')].forEach((element) => {
      element.removeAttribute("role");
      element.setAttribute("aria-label", "Search Combo Box");
    });
    [...this.node.querySelectorAll('[role="listbox"]')].forEach((element) => {
      element.removeAttribute("role");
      element.setAttribute("aria-label", "Search List Box");
    });
    [...this.node.querySelectorAll('[role="option"]')].forEach((element) => {
      element.removeAttribute("role");
    });
    // wait till after the slide animation finishes
    setTimeout(function () {
      if (self.node) {
        self.node.querySelectorAll(".nav-search-field")[0].focus();
      }
    }, 500);
  }

  componentDidUpdate() {
    [...this.node.querySelectorAll('[role="combobox"]')].forEach((element) => {
      element.removeAttribute("role");
      element.setAttribute("aria-label", "Search Combo Box");
    });
    [...this.node.querySelectorAll('[role="listbox"]')].forEach((element) => {
      element.removeAttribute("role");
      element.setAttribute("aria-label", "Search List Box");
    });
    [...this.node.querySelectorAll('[role="option"]')].forEach((element) => {
      element.removeAttribute("role");
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onChange = (event, { newValue }) => {
    if (newValue) {
      this.setState({ value: newValue });
    } else {
      this.setState({ value: "" });
    }
  };

  onResize() {
    const { isMobile: isMobileState } = this.state;
    const isMobileWidth = window.innerWidth < 768;
    if (
      (isMobileWidth && !isMobileState) ||
      (!isMobileWidth && isMobileState)
    ) {
      this.setState({ isMobile: isMobileWidth });
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.refine(value);
  };

  onSuggestionsClearRequested = () => {};

  onClearValue = () => {
    this.setState({ value: "" });
    this.props.refine();
  };

  getSuggestionValue(hit, locale) {
    return hit.type === "page"
      ? hit[`displayTitle_${locale}`]
      : hit[`title_${locale}`];
  }

  shouldRenderSuggestions(value) {
    return value.trim().length >= 0;
  }

  renderSuggestion(hit = {}, locale) {
    switch (hit.type) {
      case "page":
        return <PageHit hit={hit} locale={locale} />;
      case "faq":
        return <FaqHit hit={hit} locale={locale} />;
      default:
        return null;
    }
  }
  renderSectionTitle(section) {
    return section.hits.length > 0 ? (
      <div className="suggestion-category-header">
        <SuggestionCategoryTitle section={section} />
        <DisplayingHitResultsCount section={section} />
      </div>
    ) : (
      ""
    );
  }

  getSectionSuggestions(section) {
    return section.hits;
  }
  getHitsWithType(hits) {
    const [pages = {}, faqs = {}] = hits;
    const { hits: pageHits = [] } = pages;
    const { hits: faqHits = [] } = faqs;
    return [
      {
        ...pages,
        hits: pageHits
          .map((hit) => ({ ...hit, type: "page" }))
          .slice(0, 6 - Math.min(3, faqHits.length)),
      },
      {
        ...faqs,
        hits: faqHits
          .map((hit) => ({ ...hit, type: "faq" }))
          .slice(0, 6 - Math.min(3, pageHits.length)),
      },
    ];
  }
  onSuggestionSelected(e, value = {}) {
    const { toggleSearch, locale, lang } = this.props;
    const { suggestion: hit = {} } = value || {};

    e.stopPropagation();
    e.preventDefault();

    if (hit.type === "page") {
      window.location.href = addLocaleUrl(lang, hit.url);
    } else if (hit.type === "faq") {
      doSiteSearch(hit[`title_${locale}`], true, lang);
    }

    toggleSearch();
    this.onClearValue();
  }
  render() {
    const { locale, placeholder, hits, lang, id } = this.props;

    const { value } = this.state;

    const showDeleteButton = value.length > 0;

    const noPageHits = getIn(hits, ["0", "hits"], []).length === 0;

    const inputProps = {
      placeholder,
      onChange: this.onChange,
      value,
    };

    const onSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (value.length > 0) {
        doSiteSearch(value, true, lang);
        this.props.toggleSearch();
        this.onClearValue();
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    return (
      <form
        ref={(node) => (this.node = node)}
        className="form-inline main-nav-search"
        onSubmit={onSubmit}
      >
        <AutoSuggest
          theme={{
            container: "input-group input-with-button",
            suggestionsContainer: cx("suggestions", { noPageHits }),
            sectionContainer: "suggestions-category",
          }}
          id={id}
          suggestions={this.getHitsWithType(hits)}
          multiSection={true}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
          onSuggestionSelected={(e, hit) => this.onSuggestionSelected(e, hit)}
          getSuggestionValue={(hit) => this.getSuggestionValue(hit, locale)}
          renderSuggestion={(hit) => this.renderSuggestion(hit, locale)}
          inputProps={inputProps}
          renderSectionTitle={this.renderSectionTitle}
          getSectionSuggestions={this.getSectionSuggestions}
          focusInputOnSuggestionClick={!this.state.isMobile}
          alwaysRenderSuggestions={this.state.isMobile}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderInputComponent={(inputProps) =>
            renderInputComponent(
              inputProps,
              showDeleteButton,
              () => this.onClearValue(),
              onSubmit
            )
          }
          renderSuggestionsContainer={(props) =>
            renderSuggestionsContainer(props, onSubmit)
          }
        />
      </form>
    );
  }
}

//const mapDispatchToProps = dispatch => bindActionCreators({doSiteSearch}, dispatch)
//export default connectAutoComplete(connect(null, mapDispatchToProps)(AutoComplete))

export default connectAutoComplete(AutoComplete);
