import footerProjection from "./footer-projection";
import headerProjection from "./header-projection";
import metadataProjection from "./items/metadata-projection";
import searchPageProjection from "./pages/search-page-projection";

// const coBrand = 'mammoth';

const siteWideComponentProjection = (coBrand) => `{
  'headerNav': *[_type == 'headerNav'] ${headerProjection(coBrand)},
  'footerNav': *[_type == 'footerNav'] ${footerProjection()},
  'searchSettings': *[_type == "searchPage"] ${searchPageProjection},
  'metadata': *[_type == "metadata"] ${metadataProjection},
}`;

export default siteWideComponentProjection;
