import headingOverrideProjection from "../../modules/heading-override-projection";
import regionProjection from "../../modules/region-projection";

const destinationLocalPassProjection = `{
  _id,
  fullName,
  headlineOverride ${headingOverrideProjection},
  hasLocalPasses,
  orderRank,
  'country': region->${regionProjection}.country,
  url
}`;

export default destinationLocalPassProjection;
