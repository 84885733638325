import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

// use this as a simpler import than all the above
const Markdown = ({ children, className, components }) => {
  return (
    <ReactMarkdown
      className={className}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[gfm]}
      components={components}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
