import { useCallback, useEffect } from "react";
import {
  CURRENCY,
  LOCALE,
  useAppContext,
} from "../../utils/context/AppContext";
import { getQueryParamByName, localeCookie } from "../../utils/utils";

const CheckLocale = () => {
  const [
    { currency, locale },
    { changeCurrency, changeLocale, setHiddenNavigation },
  ] = useAppContext((store) => ({
    currency: store[CURRENCY],
    locale: store[LOCALE],
  }));

  const checkNav = useCallback(() => {
    const hiddenNavigationParam = getQueryParamByName("hide-nav");
    const hiddenNavigationParamExists = hiddenNavigationParam !== null;

    if (hiddenNavigationParamExists) {
      const hiddenNavigation = hiddenNavigationParam !== "false";
      localStorage.setItem("hide-nav", hiddenNavigation);
      setHiddenNavigation(hiddenNavigation);
    } else {
      const localStoredHiddenNavigation =
        localStorage.getItem("hide-nav") === "true";
      setHiddenNavigation(localStoredHiddenNavigation);
    }
  }, [setHiddenNavigation]);

  const checkCurrency = () => {
    const currencyParam = getQueryParamByName("currency") || null;

    if (!currencyParam) return;

    if (currencyParam.toUpperCase() !== currency) {
      const changeTo = currencyParam.toUpperCase();
      // get new ampData then reload
      Promise.all([changeCurrency(changeTo)]).then(() => {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("currency");
        window.location.searchParams = newUrl.searchParams;
        window.location.href = newUrl;
      });
    }
  };

  useEffect((e) => {
    // check cookie
    const currLocale = localeCookie();
    if (currLocale !== locale) {
      changeLocale(currLocale);
      window.location.reload();
    }

    if (currency) {
      document.body.classList.add(`with-${currency}`);
    }

    // check for hidden nav
    checkNav();
    checkCurrency();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default CheckLocale;
