import { getLocale } from "../../utils";

const locale = getLocale();

const socialFeedProjection = `socialFeed->{
  _type,
  _id,
  externalWebsiteUrl,
  facebookHandle,
  instagramHandle,
  pixleeAlbumId,
  socialHashTag,
  'socialText': socialText.${locale},
  'socialTitle': socialTitle.${locale},
  twitterHandle
}
`;

export default socialFeedProjection;
