import classNames from "classnames";
import React, { Component } from "react";
import environments from "../../../../modules/environments";
import blueAccountIcon from "../../../../static/images/svg/account.svg";
import { setQueryParamByName } from "../../../../utils/utils";
import CurrencyLanguageSelector from "../../../currency-language-selector";
// import CurrencyLangSelector from "components/currency-lang-selector";
import NavigationPanel from "./panel";
import NavigationPrimaryCTA from "./primary-cta";
import NavigationPromoArea from "./promo-area";
import NavigationSecondaryCTAs from "./secondary-ctas";
import NavigationTertiaryCTAs from "./tertiary-ctas";
import Stickers from "./stickers";

// inline styles
import "./styles.scss";

export default class Navigation extends Component {
  state = {
    openedSecondaryNavID: -1,
  };

  toggleSecondaryCollapse(index) {
    this.setState((state) => ({
      openedSecondaryNavID: state.openedSecondaryNavID === index ? -1 : index,
    }));
  }

  componentDidUpdate(prevProps) {
    const { collapsed } = this.props;

    if (collapsed === true && prevProps.collapsed === false) {
      this.toggleSecondaryCollapse(-1);
    }
  }

  renderSideNav(link, index) {
    return (
      <li
        className="side-nav__navigation-panel-container"
        key={`${index}-${link.entryTitle}`}
      >
        <NavigationPanel
          onClick={() => this.toggleSecondaryCollapse(index)}
          isOpen={this.state.openedSecondaryNavID === index}
          title={link.title}
        />
      </li>
    );
  }

  renderContentNav(panel, index) {
    const secondaryClassNames = classNames("side-nav-content", {
      open: this.state.openedSecondaryNavID === index,
    });

    const {
      title,
      primaryCta,
      secondaryCta,
      tertiaryCta,
      promoArea,
      promoAreaUrl,
    } = panel;

    const locale = this.props.locale;

    return (
      <div className={secondaryClassNames} key={index}>
        <div
          className="side-nav-content__title"
          onClick={() => this.toggleSecondaryCollapse(-1)}
        >
          <i className="icon-chevron-left" />
          <span className="inner">{title}</span>
        </div>
        <div className="side-nav-content__wrapper side-nav-content__wrapper--ctas">
          <NavigationSecondaryCTAs links={secondaryCta} locale={locale} />
          <NavigationPrimaryCTA link={primaryCta} locale={locale} />
          <NavigationTertiaryCTAs links={tertiaryCta} locale={locale} />
        </div>
        <div className="side-nav-content__wrapper">
          <NavigationPromoArea content={promoArea} link={promoAreaUrl} />
          <Stickers />
        </div>
      </div>
    );
  }

  getLoginURL() {
    // redirect from AMP login back to page on website after login
    let loginUrl =
      environments.amp.cartUrl + "/" + this.props.locale + "/login";
    loginUrl = setQueryParamByName(
      loginUrl,
      "redirect_uri",
      "https://ikonpass.com" + window.location.pathname
    );

    return loginUrl;
  }

  renderAccountPanel() {
    const { headerNavContent, userData } = this.props;
    const { logInPrefixText, logInLinkText, logInSuffixText } =
      headerNavContent;
    return (
      !userData && (
        <li className="side-nav__account-icon-wrapper">
          <div className="side-nav__account-icon">
            <img
              src={blueAccountIcon}
              alt="Account Icon"
              className="account-icon"
            />
          </div>
          <div className="side-nav__account-text">
            <React.Fragment>
              <span>{logInPrefixText}</span>
              <br />
            </React.Fragment>
            <span>
              <a href={this.getLoginURL()}>{logInLinkText}</a>{" "}
              <React.Fragment>{logInSuffixText}</React.Fragment>
            </span>
          </div>
        </li>
      )
    );
  }

  render() {
    const { collapsed, headerNavContent, userData, currencyModalContent } =
      this.props;

    const menuClasses = classNames("side-nav", { open: !collapsed });
    const { navLinks } = headerNavContent;
    const renderableLinks = navLinks.filter(
      (link) => !link.loggedInOnly || userData !== null
    );

    return (
      <div>
        <nav className={menuClasses}>
          <ul>
            {renderableLinks.length > 0 &&
              renderableLinks.map((link, index) => {
                return this.renderSideNav(link, index);
              })}
            {this.renderAccountPanel()}
          </ul>

          {
            <ul>
              <li>
                <CurrencyLanguageSelector
                  content={currencyModalContent}
                  type="side-nav"
                />
              </li>
            </ul>
          }
        </nav>
        {renderableLinks.length > 0 &&
          navLinks.map((link, index) => this.renderContentNav(link, index))}
      </div>
    );
  }
}
