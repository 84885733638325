import { writeCookie, deleteCookie, getCookieValue } from "../utils/utils";

const getEnv = () => {
  return process.env.REACT_APP_ENVIRONMENT;
};

const isLocalhost = () => {
  if (window.location.host === "localhost:8000") {
    return true;
  }

  return false;
};

export const isDevelop = () => {
  const env = getEnv();
  if (env === "development") {
    return true;
  }

  return false;
};

export const isStaging = () => {
  const env = getEnv();
  if (env === "staging") {
    return true;
  }

  return false;
};

export const isProduction = () => {
  const env = getEnv();
  if (env === "production") {
    return true;
  }

  return false;
};

const isContentPreview = () => {
  console.log(
    `%cENV: ${getEnv()}`,
    "color: orange; font-family: monospace; font-size: 16px"
  );
  if (isProduction()) {
    console.log("preview mode disabled");
    deleteCookie("preview");
    deleteCookie("preview-amp");
  } else {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.preview) {
      console.log("preview mode active");
      writeCookie("preview", "true", 30, null, null, null);
    }
    if (params["preview-amp"]) {
      console.log("amp preview active");
      writeCookie("preview-amp", "true", 30, null, null, null);
    }
  }
};

const affirmSettings = () => {
  let affirmApiKey = {
    public_api_key: "W6CLXI2Y0SKW7KRX", //"UGUR88Q6JXCJAEUC"
  };
  return affirmApiKey;
};

const trineoSettings = () => {
  let accessDetailsApi =
    getEnv() === "staging" && getCookieValue("preview-amp")
      ? "https://account.uat.ikonpass.com/api/v2/products/ikonpass2324,ikonbaseplus2324,ikonbase2324,ikon42324,ikonpass2425,ikonbaseplus2425,ikonbase2425,ikon42425/access-details"
      : "https://account.ikonpass.com/api/v2/products/ikonpass2324,ikonbaseplus2324,ikonbase2324,ikon42324,ikonpass2425,ikonbaseplus2425,ikonbase2425,ikon42425/access-details";
  let cartApi = "https://account.ikonpass.com/api/v2/cart";
  let userApi = "https://account.ikonpass.com/api/v2/me";
  let userRedemptionsApi = "https://account.ikonpass.com/api/v2/my-redemptions";
  let userProductsApi = "https://account.ikonpass.com/api/v2/my-products";
  let userResortFavoritesApi =
    "https://account.ikonpass.com/api/v2/resort-favorites";
  let userMountainChecklistApi =
    "https://account.ikonpass.com/api/v2/mountain-checklist";
  let sessionCookie = "session";
  let sessionTokenCookie = "_itw_iaa_prod_session";
  let catalogApi =
    getEnv() === "staging" && getCookieValue("preview-amp")
      ? "https://account.uat.ikonpass.com/api/v2/product-catalog"
      : "https://account.ikonpass.com/api/v2/product-catalog";
  let cartUrl = "https://account.ikonpass.com";
  let ampLocale = "locale=en";
  let xsrfCookie = "XSRF_TOKEN_PLACEHOLDER"; // This is replaced during build time
  let apiKey = "Z3Vlc3Q6b29tdldqcFdzTkRvTWRVY3lLWjhERnVzwe";
  let logoutUrl = "https://account.ikonpass.com/logout";
  let pingUrl = "https://account.ikonpass.com/api/v2/ping";
  let softLoginApi = "https://account.ikonpass.com/api/v2/guest-summary";

  if (isDevelop() || isLocalhost()) {
    accessDetailsApi =
      "https://account.uat.ikonpass.com/api/v2/products/ikonpass2324,ikonbaseplus2324,ikonbase2324,ikon42324,ikonpass2425,ikonbaseplus2425,ikonbase2425,ikon42425/access-details";
    cartApi = "https://account.uat.ikonpass.com/api/v2/cart";
    userApi = "https://account.uat.ikonpass.com/api/v2/me";
    catalogApi = "https://account.uat.ikonpass.com/api/v2/product-catalog";
    cartUrl = "https://account.uat.ikonpass.com";
    sessionCookie = "uat_session";
    sessionTokenCookie = "_amp_session";
    logoutUrl = "https://account.uat.ikonpass.com/logout";
    pingUrl = "https://account.uat.ikonpass.com/api/v2/ping";
    userRedemptionsApi =
      "https://account.uat.ikonpass.com/api/v2/my-redemptions";
    userProductsApi = "https://account.uat.ikonpass.com/api/v2/my-products";
    userResortFavoritesApi =
      "https://account.uat.ikonpass.com/api/v2/resort-favorites";
    userMountainChecklistApi =
      "https://account.uat.ikonpass.com/api/v2/mountain-checklist";
    softLoginApi = "https://account.uat.ikonpass.com/api/v2/guest-summary";
  }

  if (window.location.pathname.indexOf("/fr") >= 0) {
    ampLocale = "locale=fr"; // yikes!
  }

  // This is specifically for running localhost, as the netlify build is not available to us.
  // Would need to be updated if it's ever changed in the Netlify UI.
  if (isLocalhost() || isDevelop()) {
    xsrfCookie = "UAT-XSRF-TOKEN";
  }

  // add language parameter
  catalogApi = `${catalogApi}?${ampLocale}`;

  return {
    accessDetailsApi,
    cartApi,
    userApi,
    userRedemptionsApi,
    userProductsApi,
    userResortFavoritesApi,
    userMountainChecklistApi,
    sessionCookie,
    sessionTokenCookie,
    catalogApi,
    cartUrl,
    xsrfCookie,
    apiKey,
    logoutUrl,
    pingUrl,
    softLoginApi,
  };
};

const cachedAccessDetailsUrl = () => {
  let domain = "";
  switch (getEnv()) {
    case "production":
      domain = "https://www.ikonpass.com";
      break;
    case "staging":
      domain = "https://staging.ikonpass.com";
      break;
    case "development":
      domain = "https://dev.ikonpass.com";
      break;
    default:
      domain = "";
      break;
  }
  return domain + "/static/json/access-details.json";
};

const config = {
  isLocalhost: isLocalhost(getEnv()),
  isDevelop: isDevelop(getEnv()),
  isStaging: isStaging(getEnv()),
  isProduction: isProduction(getEnv()),
  isContentPreview: isContentPreview(),
  amp: trineoSettings(),
  affirmApiKey: affirmSettings(),
  cachedAccessDetailsUrl: cachedAccessDetailsUrl(),
};

export default config;
