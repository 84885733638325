import classNames from "classnames";

// inline styles
import "./styles.scss";

const MenuIcon = ({ collapsed, onClick }) => {
  const menuIconClassNames = classNames(
    "button",
    "unstyled",
    "navigation-menu-icon-button",
    {
      "open-menu": !collapsed,
    }
  );

  return (
    <button onClick={onClick} className={menuIconClassNames} title="Main menu">
      <div className="navigation-menu-icon">
        <span className="hidden-text">Menu</span>
        <div className="nav-menu-bar bar-1" />
        <div className="nav-menu-bar bar-2" />
        <div className="nav-menu-bar bar-3" />
      </div>
    </button>
  );
};

export default MenuIcon;
