import iconProjection from "../modules/icon-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const statProjection = `{
  _id,
  _type,
  countUpIcon,
  icon->${iconProjection},
  'label': label.${locale},
  'value': value.${locale},
  'valueSymbol': valueSymbol.${locale}
}`;

export default statProjection;
