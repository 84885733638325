import { useEffect, useRef } from "react";

const Flyout = ({
  isOpen,
  onCloseButtonClick,
  onBackButtonClick,
  children,
}) => {
  const flyoutRef = useRef();

  useEffect(() => {
    const parentDropdown = document.querySelector(".account-dropdown");
    parentDropdown.addEventListener("scroll", () => {
      flyoutRef.current.style.top = `${parentDropdown.scrollTop}px`;
    });
  }, []);

  useEffect(() => {
    // Prevents Account Dropdown from scrolling at the end of Flyout
    var ele = document.querySelector(".account-dropdown.open");
    if (isOpen) {
      ele.style.overflowY = "hidden";
    } else {
      ele.style.overflowY = "auto";
    }
  }, [isOpen]);

  return (
    <div
      ref={flyoutRef}
      className={`account-dropdown-flyout ${isOpen ? "open" : ""}`}
    >
      <div className="header-buttons">
        <button onClick={onBackButtonClick} className="button unstyled back">
          <i className="icon-chevron-left" />
        </button>

        <button onClick={onCloseButtonClick} className="button unstyled close">
          <i className="icon-close-grey" />
        </button>
      </div>
      {children}
    </div>
  );
};

export default Flyout;
