import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const adventureIntroProjection = `{
  _id,
  entryTitle,
  'heading': heading.${locale},
  headerImage ${imageProjection},
  'introContent': introContent.${locale},
  adventureItineraryDayCount,
  adventureItineraryItems[] ->,
  adventureDestinationCount,
  adventureDestinations[]
}`;

export default adventureIntroProjection;
