import { getLocale } from "../../utils";

const locale = getLocale();

export const followUsProjection = `{
  _id,
  _type,
  'backgroundColor': backgroundColor.title,
  entryTitle,
  'title': title.${locale},
  'intro': intro.${locale},
  accounts[] -> {
    _id,
    _type,
    url,
    accountType
  }
}`;
