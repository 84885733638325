import { getVisitedDestinationPages } from "../../../../utils/utils";
import Sticker from "./partials/sticker";
import { useRef } from "react";

const Stickers = () => {
  // This will return an array of destination codes from
  // local storage
  const container = useRef();

  const visitedDestinations = getVisitedDestinationPages();

  // const testDestinations = [
  //   {
  //     "resortCode": "MAM",
  //     "url": "https://dev.ikonpass.com/en/destinations/mammoth-mountain",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "+3"
  //     }
  //   },
  //   {
  //     "resortCode": "SB",
  //     "url": "https://dev.ikonpass.com/en/destinations/steamboat",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "+33"
  //     }
  //   },
  //   {
  //     "resortCode": "WP",
  //     "url": "https://dev.ikonpass.com/en/destinations/winter-park-resort",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-32"
  //     }
  //   },
  //   {
  //     "resortCode": "SB3",
  //     "url": "https://dev.ikonpass.com/en/destinations/skibig3",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "+26"
  //     }
  //   },
  //   {
  //     "resortCode": "TAO",
  //     "url": "https://dev.ikonpass.com/en/destinations/taos-ski-valley",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-22"
  //     }
  //   },
  //   {
  //     "resortCode": "BBM",
  //     "url": "https://dev.ikonpass.com/en/destinations/big-bear-mountain-resort",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-27"
  //     }
  //   },
  //   {
  //     "resortCode": "DVR",
  //     "url": "https://dev.ikonpass.com/en/destinations/deer-valley-resort",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-25"
  //     }
  //   },
  //   {
  //     "resortCode": "TBO",
  //     "url": "https://dev.ikonpass.com/en/destinations/thredbo",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-21"
  //     }
  //   },
  //   {
  //     "resortCode": "RED",
  //     "url": "https://dev.ikonpass.com/en/destinations/red-mountain",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "+17"
  //     }
  //   },
  //   {
  //     "resortCode": "BRI",
  //     "url": "https://dev.ikonpass.com/en/destinations/brighton",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-33"
  //     }
  //   },
  //   {
  //     "resortCode": "SRV",
  //     "url": "https://dev.ikonpass.com/en/destinations/sunday-river",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-0"
  //     }
  //   },
  //   {
  //     "resortCode": "ELD",
  //     "url": "https://dev.ikonpass.com/en/destinations/eldora-mountain-resort",
  //     "position": {
  //       "x": null,
  //       "y": null,
  //       "rotation": "-28"
  //     }
  //   }
  // ]

  // window.localStorage.setItem('ikonpassVisitedDestinations', JSON.stringify(testDestinations))

  return (
    <div className="nav-stickers" ref={container}>
      {visitedDestinations.length > 0 &&
        visitedDestinations.map((destination, index) => {
          return (
            <Sticker
              key={index}
              container={container}
              index={index}
              image={destination.resortCode}
              name={destination.name}
              url={destination.url}
            />
          );
        })}
    </div>
  );
};

export default Stickers;
