import buttonProjection from "../modules/button-projection";
import genericHeroProjection from "../modules/generic-hero-projection";
import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";
import seoProjection from "../seo-projection";

const locale = getLocale();

const tripsLandingPageProjection = `{
  _id,
  _type,
  'adventures': *[_type == "adventurePage"] {
    _id,
    _type,
    adventureId,
    'adventureBodyText': adventureBodyText.${locale},
    'adventureRegion': adventureRegion.${locale},
    'bookingLinkText': bookingLinkText.${locale},
    bookingLinkUrl,
    'tripTypeLabel': tripTypeLabel -> {
      'tagText': tagText.${locale}
    },
    listingDisplayImage ${imageProjection},
    'title': title.${locale},
    'ctaText': detailsText.${locale},
    'url': url.current,
  }, 
  cta ${buttonProjection},
  entryTitle,
  hero -> ${genericHeroProjection},
  'leadText': leadText.${locale},
  seo ${seoProjection}
}`;

export default tripsLandingPageProjection;
