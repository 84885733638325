import {
  getProductFromCatalogById,
  getPriceByAgeCategory,
  setPrerenderReady,
} from "../../utils/utils";
// import { productData, currency } from '../../../containers/app/selectors'
// import {createRichText} from '../../../modules/rich-text'
import Markdown from "../markdown";
import React from "react";
import SpanTooltip from "../span-tooltip";
// import {setPrerenderReady} from '../../../modules/utils'
import { PRODUCT_DATA, useAmp } from "../../utils/context/AmpContext";
import { CURRENCY, useAppContext } from "../../utils/context/AppContext";
import { useEffect } from "react";

// inline styles
import "./_styles.scss";

const AffirmCallout = ({
  content,
  embeddableContent,
  idPrefix = "",
  hideLeadingTrailingText = false,
  priceOverride = 0,
}) => {
  const [{ currency }] = useAppContext((store) => ({
    currency: store[CURRENCY],
  }));
  const [{ productData }, { fetchProductData }] = useAmp((store) => ({
    productData: store[PRODUCT_DATA],
  }));

  useEffect(() => {
    if (!productData || !productData.length) {
      fetchProductData();
    }
  }, [productData, fetchProductData]);

  if (!content.affirmCallout) return null;
  let entry = content.affirmCallout;

  if (embeddableContent) {
    entry = embeddableContent;
  }

  // Variables
  let affirmClassName = entry.affirmClass;
  let dataPageType = entry.affirmPageType;
  let dataLearnmoreShow = entry.showAffirmModal;
  let dataAffirmType = entry.affirmType;
  let dataAffirmColor = entry.affirmColor;
  let affirmModalButtonText = entry.affirmModalButtonText;
  let affirmLeadText = entry.affirmLeadText;
  let affirmTrailingText = entry.affirmTrailingText;
  let productVariant = entry.productVariant;

  // Get productId from contentful and productData to find price
  let productId = content.affirmCallout.productCodeForAmount;
  let pass = null;
  let productPrice = 99900;

  if (currency === "CAD" || currency?.code === "CAD") {
    return null;
  }

  // if product variant field is left blank in contentful, default is 'adult'
  if (!productVariant) {
    productVariant = "adult";
  }

  if (productId && productId.length && Array.isArray(productData)) {
    pass = getProductFromCatalogById(productData, productId);
    productPrice = getPriceByAgeCategory(pass, productVariant) * 100; // This converts format of price to match Affirm currency format requirements
    if (!pass || !productPrice) {
      return null;
    }
  }

  if (affirmClassName === "mini-cart" && !priceOverride) {
    return null;
  } else if (affirmClassName === "mini-cart" && priceOverride) {
    productPrice = parseFloat(priceOverride.toString().replace(",", "")) * 100;
  }

  // Triggers the calls to Affirm's API
  setPrerenderReady();

  // If no logo color is chosen in Contentful
  let logoAttribute = dataAffirmType ? `${dataAffirmType}` : "";
  let colorAttribute = dataAffirmColor ? `${dataAffirmColor}` : "";

  // Educational Modal i.e. no price models
  if (affirmClassName === "affirm-site-modal") {
    return (
      <a
        href="javascript:void(0)"
        name="affirmCallout"
        className={`${affirmClassName} amp-button primary`}
        data-page-type={`${dataPageType}`}
        data-learnmore-show={`${dataLearnmoreShow}`}
        data-affirm-type={`${logoAttribute}`}
        data-affirm-color={`${colorAttribute}`}
      >
        <span className="inner">{affirmModalButtonText}</span>
      </a>
    );

    // Produce Modal i.e shows monthly prices based on product id
  } else if (affirmClassName === "affirm-product-modal") {
    return (
      <div id={content._id}>
        <a
          name="affirmCallout"
          className={`${affirmClassName}`}
          data-amount={`${productPrice}`}
          data-page-type={`${dataPageType}`}
          data-learnmore-show={`${dataLearnmoreShow}`}
          data-affirm-type={`${logoAttribute}`}
          data-affirm-color={`${colorAttribute}`}
        ></a>
      </div>
    );

    // Affirm-as-low-as Modal, shows monthly prices based on product id
  } else if (affirmClassName === "affirm-as-low-as") {
    return (
      <div id={content._id}>
        <div
          className={`${affirmClassName}`}
          data-amount={`${productPrice}`}
          data-page-type={`${dataPageType}`}
          data-learnmore-show={`${dataLearnmoreShow}`}
          data-affirm-type={`${logoAttribute}`}
          data-affirm-color={`${colorAttribute}`}
        />
        {content.affirmCallout.tooltipContent && (
          <SpanTooltip
            id={`tooltip-${idPrefix}`}
            theme="light"
            className="information-tooltip"
            content={content.affirmCallout.tooltipContent}
            addWhiteSpace
          />
        )}
      </div>
    );
  } else if (affirmClassName === "affirm-price-only") {
    return (
      <div className="affirm-callout" id={content._id}>
        {entry.affirmCalloutCTA && (
          <div className="single-cta-callout">
            <div className="single-cta-callout--text">
              {entry.affirmCalloutCTA}
            </div>
          </div>
        )}
        {!hideLeadingTrailingText && (
          <span>
            <Markdown>{affirmLeadText}</Markdown>
          </span>
        )}
        {currency === "USD" && (
          // <div className="affirm-price-with-currency"> //@TODO: determine if this break anything anywhere elese
          <div className="affirm-column-wrap">
            &nbsp;
            <span
              className={`${affirmClassName}`}
              data-amount={`${productPrice}`}
              data-page-type={`${dataPageType}`}
              data-learnmore-show={`${dataLearnmoreShow}`}
              data-affirm-type={`${logoAttribute}`}
              data-affirm-color={`${colorAttribute}`}
            />
            <span className="per-month">
              <font className="affirm-currency">USD</font> <strong>/ MO</strong>
            </span>
          </div>
          // </div>
        )}
        {!hideLeadingTrailingText && <Markdown>{affirmTrailingText}</Markdown>}
        {currency === "CAD" && <h5>N/A</h5>}
        {content.affirmCallout.tooltipContent && currency === "USD" && (
          <SpanTooltip
            id={`tooltip-${idPrefix}`}
            theme="light"
            className="information-tooltip affirm-tooltip"
            content={content.affirmCallout.tooltipContent}
            addWhiteSpace
          />
        )}
      </div>
    );
  } else if (affirmClassName === "copy-only") {
    return (
      <div className="affirm-callout affirm-copy-only" id={content._id}>
        <Markdown>{affirmLeadText}</Markdown>
        <Markdown>{affirmTrailingText}</Markdown>
        {content.affirmCallout.tooltipContent && (
          <SpanTooltip
            id={`tooltip-${idPrefix}`}
            theme="light"
            className="information-tooltip affirm-tooltip"
            content={content.affirmCallout.tooltipContent}
            addWhiteSpace
          />
        )}
      </div>
    );
  } else if (affirmClassName === "logo-only") {
    return (
      <div className="affirm-callout affirm-logo-only" id={content._id}>
        <span className="logo-only-lead-text">
          <Markdown>{affirmLeadText}</Markdown>
        </span>
        <span className="__affirm-logo __affirm-logo-blue affirm-logo">
          Affirm
        </span>
        <span className="logo-only-trail-text">
          <Markdown>{affirmTrailingText}</Markdown>
        </span>
      </div>
    );
  } else if (affirmClassName === "mini-cart") {
    return (
      <small className="affirm-callout">
        {!hideLeadingTrailingText && (
          // <span dangerouslySetInnerHTML={affirmLeadText}>
          <Markdown>{affirmLeadText}</Markdown>
          // </span>
        )}
        &nbsp;
        <span
          className="affirm-price-only"
          data-amount={`${productPrice}`}
          data-page-type={`${dataPageType}`}
          data-learnmore-show={`${dataLearnmoreShow}`}
          data-affirm-type={`${logoAttribute}`}
          data-affirm-color={`${colorAttribute}`}
        />
        {!hideLeadingTrailingText && (
          <span>
            <Markdown>{affirmTrailingText}</Markdown>
          </span>
        )}
        <span className="__affirm-logo __affirm-logo-blue affirm-logo">
          Affirm
        </span>
        {content.affirmCallout.tooltipContent && (
          <SpanTooltip
            id={`tooltip-${idPrefix}`}
            theme="light"
            className="information-tooltip affirm-tooltip"
            content={content.affirmCallout.tooltipContent}
            addWhiteSpace
          />
        )}
      </small>
    );
  } else {
    return null;
  }
};

export default AffirmCallout;
