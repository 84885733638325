import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const passWithContentProjection = ` {
    _id,
    'mountainBackgroundColor': mountainBackgroundColor.title,
    'mountainRangeColor': mountainRangeColor.title,
    'headline': headline.${locale},
    'leadText': leadText.${locale},
    'passImage': passImage ${imageProjection}
  }
`;

export default passWithContentProjection;
