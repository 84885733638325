import blogTilesProjection from "../../modules/blog-tiles-projection";
import columnedContentProjection from "../../modules/columned-content-projection";
import contentBlocksProjection from "../../modules/content-blocks-projection";
import destinationOverviewProjection from "../../modules/destination-overview-projection";
import genericHeroProjection from "../../modules/generic-hero-projection";
import imageProjection from "../image-projection";
import seoProjection from "../../seo-projection";
import socialFeedProjection from "../../modules/social-feed-projection";
import statProjection from "../../modules/stat-projection";

import { formatSeasonWithSlash, getLocale } from "../../../utils";
import subdestinationProjection from "../subdestination-projection";
import buttonProjection from "../../modules/button-projection";
import { getActiveSeason } from "../../../users";

const locale = getLocale();
const season = formatSeasonWithSlash(getActiveSeason()) || null;

const destinationProjection = `{
  _id,
  _type,
  accessChanged,
  accessChangedBase,
  accessChangedSession,
  'blogTiles': ${blogTilesProjection},	
  'body': body->${columnedContentProjection},	
  'contentBlocks': ${contentBlocksProjection},
  'country': region->country->name.${locale},
  'description': description.${locale},
  'destinationOverview': ${destinationOverviewProjection},
  entryTitle,
  fullName,
  globeFileName,
  hasArMap,
  hero->${genericHeroProjection},
  'informationTooltipContent': informationTooltipContent.${locale},
  'informationTooltipContentBase': informationTooltipContentBase.${locale},
  'informationTooltipContentSession': informationTooltipContentSession.${locale},
  'informationTooltipContentPrevious': informationTooltipContentPrevious.${locale},
  'informationTooltipContentBasePrevious': informationTooltipContentBasePrevious.${locale},
  'informationTooltipContentSessionPrevious': informationTooltipContentSessionPrevious.${locale},
  latlng,
  'leadText': leadText.${locale},	
  name,
  new,
  orderRank,
  policiesReservationsUrl,
  'region': region->name.${locale},
  resortCode,	
  resortLink,	
  resortLogo->{	
    darkLogo ${imageProjection},	
    colorLogo ${imageProjection},	
    logo ${imageProjection},	
  },	
  'resortLinkText': resortLinkText.${locale},
  'secondBody': secondBody->${columnedContentProjection},	
  seo ${seoProjection},	
  ${socialFeedProjection},
  stats-> {
    stats[] -> ${statProjection}
  },
  subDestinations[]->${subdestinationProjection},
  'title': entryTitle,
  'url': url.current,
  'accessDetailsCurrentSeasonDescription': accessDetailsCurrentSeasonDescription.${locale},
  'accessDetailsProvisions': accessDetailsProvisions.${locale},
  'accessDetailsPreviousSeasonDescription': accessDetailsPreviousSeasonDescription.${locale},
  // 'ikonPassCurrentSeasonDetails': ikonPassCurrentSeasonDetails.${locale},
  // 'ikonPassPreviousSeasonDetails': ikonPassPreviousSeasonDetails.${locale},
  // 'ikonBasePassCurrentSeasonDetails': ikonBasePassCurrentSeasonDetails.${locale},
  // 'ikonBasePassPreviousSeasonDetails': ikonBasePassPreviousSeasonDetails.${locale},
  // 'ikonBasePassPlusCurrentSeasonDetails': ikonBasePassPlusCurrentSeasonDetails.${locale},
  // 'ikonBasePassPlusPreviousSeasonDetails': ikonBasePassPlusPreviousSeasonDetails.${locale},
  excludeFromListings,
  'passes': *[_type == 'accessDetails' && usePreviousSeasonDetails == false && year.en == '${season}'] {
    passes[]-> {
      'title': title.${locale},
      entryTitle,
      passId,
      urlSlug,
      showReservationsRequired,
      'blackoutDatesInformation': blackoutDatesInformation.${locale},
    }
  }[0].passes,
  'accessDetails': {
    'currentSeasonDescription':
      accessDetailsCurrentSeasonDescription.${locale},
    'primaryCTA': accessDetailsPrimaryCTA ${buttonProjection},
    'secondaryCTA': accessDetailsSecondaryCTA ${buttonProjection}
  }
}
`;

export default destinationProjection;
