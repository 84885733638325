import { useState } from "react";
import classNames from "classnames";

import NewsletterModal from "./partials/newsletter-modal";
import Button from "../button";
import Breadcrumbs from "../breadcrumbs";

import { addLocaleUrl } from "../../utils/utils";
import {
  FOOTER_CONTENT,
  useCMSContent,
} from "../../utils/context/CMSContentContext";
import {
  HIDDEN_NAVIGATION,
  LOCALE,
  SHOW_SEARCH,
  SHOW_STICKY_CART_BAR,
  useAppContext,
} from "../../utils/context/AppContext";

// inline styles
import "./styles.scss";
import "../breadcrumbs/styles.scss";
import Markdown from "../markdown";
// import { Link } from "react-router-dom";

const Footer = ({ children }) => {
  const [{ footerContent }] = useCMSContent((store) => ({
    footerContent: store[FOOTER_CONTENT],
  }));
  const [{ showStickyCartBar, hiddenNavigation, showSearch, locale }] =
    useAppContext((store) => ({
      showStickyCartBar: store[SHOW_STICKY_CART_BAR],
      hiddenNavigation: store[HIDDEN_NAVIGATION],
      showSearch: store[SHOW_SEARCH],
      locale: store[LOCALE],
    }));

  const [emailError, setEmailError] = useState(false);

  const setupFooterContent = (data) => {
    let footerItems = {
      mainLinks: [],
      subLinks: [],
      email: {
        title: data.emailHeader,
        paragraph: data.emailText,
        placeHolder: data.emailInputPlaceholder,
        button: data.emailButtonText,
        formHelp: data.emailFormHelpText,
        success: data.emailSuccessMessage,
        pageId: data.emailFormPageId,
        siteId: data.emailFormSiteId,
        parentPageId: data.emailFormParentPageId,
        postUrl: data.emailFormPostUrl.current,
      },
      emailModal: {
        title: data.emailModalHeader,
        paragraph: data.emailModalText,
        placeHolder: data.emailModalInputPlaceholder,
        button: data.emailModalButtonText,
        formHelp: data.emailModalFormHelpText,
        success: data.emailModalSuccessMessage,
        pageId: data.emailModalFormPageId,
        siteId: data.emailModalFormSiteId,
        parentPageId: data.emailModalFormParentPageId,
        postUrl: data.emailModalFormPostUrl.current,
      },
      disclaimerText: "",
      cookieSettingsSnippet: data.cookieSettingsSnippet,
    };

    if (data) {
      if (data.footerDisclaimerRichText) {
        footerItems.disclaimerText = data.footerDisclaimerRichText;
      } else if (!data.footerDisclaimerRichText && data.footerDisclaimerText) {
        footerItems.disclaimerText = data.footerDisclaimerText;
      } else {
        footerItems.disclaimerText = "";
      }
      const footerMainLinks = data.footerLinks;
      const footerSubLinks = data.footerSubLinks;

      footerMainLinks.forEach((link) => {
        if (link) {
          link.url = link.url && link.url.current ? link.url.current : link.url;
          link.title = link.title ? link.title : link.entryTitle;
          footerItems.mainLinks.push({
            title: link.title,
            url: addLocaleUrl(locale, link.url),
            openInNewContext: link.openInNewContext === true,
          });
        }
      });

      footerSubLinks.forEach((link) => {
        link.url = link.url && link.url.current ? link.url.current : link.url;
        link.title = link.title ? link.title : link.entryTitle;
        footerItems.subLinks.push({
          title: link.title,
          url: addLocaleUrl(locale, link.url),
          openInNewContext: link.openInNewContext === true,
        });
      });
    }

    return footerItems;
  };

  const getParameterByName = (name, url) => {
    if (!url) {
      url = window.location.href;
    }

    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const validateEmail = (e) => {
    let emailInput = document.getElementById("control_EMAIL");
    // $FlowFixMe
    let email = emailInput.value;

    if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError(false);
      return true;
    }

    setEmailError(true);
    return false;
  };

  const className = classNames("amp-global-footer", { showSearch });
  const footerClasses = classNames({ "sticky-cart-active": showStickyCartBar });
  const content = footerContent ? setupFooterContent(footerContent) : null;

  if (!content) {
    return null;
  }

  if (hiddenNavigation && content) {
    return null;
  }

  return (
    <div className={className} id="amp-global-footer">
      {!hiddenNavigation && (
        <footer id="footer" className={footerClasses}>
          {children}
          <div className="footer-bg-colors">
            <div className="container-fluid footer-content">
              <div className="row">
                <div className="col-12 col-md-6 footer-left">
                  <span className="h2">{content.email.title}</span>
                  <p className="mb-4">{content.email.paragraph}</p>
                  <form
                    id="footerSignupForm"
                    onSubmit={validateEmail}
                    action={content.email.postUrl}
                    className="form-inline"
                    method="post"
                    data-pageid={content.email.pageId}
                    data-siteid={content.email.siteId}
                    data-parentpageid={content.email.formParentPageId}
                  >
                    <div className="input-with-button form-group has-warning">
                      <input
                        aria-label="Email"
                        required
                        name="Email"
                        id="control_EMAIL"
                        type="email"
                        className="form-control"
                        placeholder={content.email.placeHolder}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                        title="Please provide a valid email address. Example: name@host.com"
                      />
                      {getParameterByName("signup") === "true" ? (
                        <div className="ml-2">{content.email.success}</div>
                      ) : (
                        <button
                          className="amp-button primary-button btn btn-secondary"
                          type="submit"
                          value="submit"
                        >
                          <div className="inner">{content.email.button}</div>
                        </button>
                      )}
                    </div>
                    {emailError && (
                      <p className="mb-0">
                        {locale?.toLowerCase() === "en"
                          ? "Please enter a valid email address!"
                          : "Veuillez entrer une adresse email valide"}
                      </p>
                    )}
                    <input
                      type="hidden"
                      name="pageId"
                      value={content.email.pageId}
                    />
                    <input
                      type="hidden"
                      name="siteId"
                      value={content.email.siteId}
                    />
                    <input
                      type="hidden"
                      name="parentPageId"
                      value={content.email.parentPageId}
                    />
                    <input
                      type="hidden"
                      name="formSourceName"
                      value="StandardForm"
                    />
                    <input type="hidden" name="sp_exp" value="yes" />
                  </form>
                  <p className="mt-2 footer-form-help">
                    {content.email.formHelp}
                  </p>
                </div>
                <div className="col-12 col-md-6 footer-right">
                  {/* <Breadcrumbs location={this.props.location} title={Helmet.peek().onChangeClientState} /> */}
                  <Breadcrumbs />
                  <div className="row footer-navigation">
                    <div className="flex-footer-links">
                      {content &&
                        content.mainLinks.map((link, idx) => {
                          const targetProps = link.openInNewContext
                            ? { target: "_blank" }
                            : {};

                          if (
                            link.title === "COOKIE_SETTINGS_PLACEHOLDER" &&
                            content.cookieSettingsSnippet
                          ) {
                            return (
                              <span
                                key={idx}
                                dangerouslySetInnerHTML={{
                                  __html: content.cookieSettingsSnippet,
                                }}
                              ></span>
                            );
                          } else if (link.url.includes("mailto:")) {
                            return (
                              <div key={idx}>
                                <a href={link.url} {...targetProps}>
                                  {link.title}
                                </a>
                              </div>
                            );
                          } else {
                            return (
                              <div key={idx}>
                                <Button
                                  type="unstyled"
                                  to={link.url}
                                  url={link.url}
                                  text={link.title}
                                  {...targetProps}
                                  allTranslations={link.allTranslations}
                                />
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                  <ul className="footer-legal list-inline mt-5">
                    <li className="list-inline-item">
                      <p>&copy; {new Date().getFullYear()} Ikon Pass, Inc.</p>
                    </li>

                    {content &&
                      content.subLinks.map((link, idx) => {
                        const targetProps = link.openInNewContext
                          ? { target: "_blank" }
                          : {};

                        if (
                          link.title === "COOKIE_SETTINGS_PLACEHOLDER" &&
                          content.cookieSettingsSnippet
                        ) {
                          return (
                            <li
                              key={idx}
                              className="list-inline-item"
                              dangerouslySetInnerHTML={{
                                __html: content.cookieSettingsSnippet,
                              }}
                            ></li>
                          );
                        } else {
                          return (
                            <li key={idx} className="list-inline-item">
                              <Button
                                type="unstyled"
                                to={link.url}
                                url={link.url}
                                text={link.title}
                                {...targetProps}
                                allTranslations={link.allTranslations}
                              />
                              {/* <Button {...targetProps} type='unstyled' to={link.url}>
                                                                <p>{link.title}</p>
                                                            </Button> */}
                            </li>
                          );
                        }
                      })}
                    {content.cookieSettingsSnippet && (
                      <li
                        key={content.subLinks.length + 1}
                        className="list-inline-item"
                        dangerouslySetInnerHTML={{
                          __html: content.cookieSettingsSnippet,
                        }}
                      ></li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
      {content && content.disclaimerText && !hiddenNavigation && (
        <div
          className="footer-disclaimer"
          id="footer-disclaimer"
          role="complementary"
        >
          <Markdown>{content.disclaimerText}</Markdown>
        </div>
      )}

      {content && content.emailModal && (
        <NewsletterModal content={content.emailModal} />
      )}
    </div>
  );
};

export default Footer;

// import React, { Component } from 'react'
// import classNames from 'classnames'
// import NewsletterModal from './partials/newsletter-modal'
// import Button from '../button'
// import Breadcrumbs from '../breadcrumbs'
// import { addLocaleUrl } from '../../utils/utils'
// import { AllContexts } from '../../utils/context/AllContexts'

// // inline styles
// import "./styles.scss";
// import "../breadcrumbs/styles.scss";

// /*
// type NavLink = {
//     title: string,
//     url: string
// }

// type LangMenu = {
//     langKey: string,
//     link: string,
//     selected?: boolean
// }

// type Email = {
//     title: string,
//     paragraph: string,
//     placeHolder: string,
//     success: string,
//     button: string,
//     formHelp: string
// }

// type Props = {
//     content: {
//         mainNav: { links: NavLink[] },
//         subNav: { links: NavLink[] },
//         currentUrl: string,
//         email: Email,
//         disclaimerText: "",
//         cookieSettingsSnippet: ""
//     },
//     langKey: string,
//     //langsMenu: LangMenu[]
// }

// type State = {
//     isSubmitted: boolean,
//     errorMessage: string,
//     lang: string,
//     formPageId: string,
//     formSiteId: string,
//     formParentPageId: string,
//     formPostUrl: string,
//     siteId?: string
// }
// */

// const getParameterByName = (name, url) => {
//     if (!url) {
//         url = window.location.href
//     }

//     name = name.replace(/[[\]]/g, '\\$&')
//     const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
//     const results = regex.exec(url)

//     if (!results) {
//         return null
//     }

//     if (!results[2]) {
//         return ''
//     }
//     return decodeURIComponent(results[2].replace(/\+/g, ' '))
// }

// /*
// const currentPageLink = locale => {
//     const currentPath = window.location.pathname
//     let newPath = currentPath.split('/')
//     newPath[1] = locale.replace(/\//gi, '')

//     return newPath.join('/')
// }

// const TempLanguageSwitcher = ({ langs }) => {
//     return (
//         <div className='language-picker'>
//             {langs.map((lang, idx) => {
//                 let langName = ''
//                 let link = currentPageLink(lang.link)
//                 let classes = classNames('language-chooser', {
//                     'selected-language disabled-link': lang.selected
//                 })

//                 if (lang.langKey === 'en') {
//                     langName = 'English'
//                 } else {
//                     langName = 'FRANÇAIS'
//                 }

//                 return (
//                     <div className={classes} key={idx}>
//                         <a href={link}>{langName} </a>
//                     </div>
//                 )
//             })}
//         </div>
//     )
// }
// */

// // MP 20220725 - setup footer content from Sanity data, did not want to rewrite everything so kept old data structure
// const setupFooterContent = (data, locale) => {

//     let footerItems = {
//         mainLinks: [],
//         subLinks: [],
//         email: {
//             title: data.emailHeader,
//             paragraph: data.emailText,
//             placeHolder: data.emailInputPlaceholder,
//             button: data.emailButtonText,
//             formHelp: data.emailFormHelpText,
//             success: data.emailSuccessMessage,
//             pageId: data.emailFormPageId,
//             siteId: data.emailFormSiteId,
//             parentPageId: data.emailFormParentPageId,
//             postUrl: data.emailFormPostUrl.current
//         },
//         emailModal: {
//             title: data.emailModalHeader,
//             paragraph: data.emailModalText,
//             placeHolder: data.emailModalInputPlaceholder,
//             button: data.emailModalButtonText,
//             formHelp: data.emailModalFormHelpText,
//             success: data.emailModalSuccessMessage,
//             pageId: data.emailModalFormPageId,
//             siteId: data.emailModalFormSiteId,
//             parentPageId: data.emailModalFormParentPageId,
//             postUrl: data.emailModalFormPostUrl.current
//         },
//         disclaimerText: (data.footerDisclaimerText) ? data.footerDisclaimerText : '',
//         cookieSettingsSnippet: data.cookieSettingsSnippet
//     }

//     if (data) {
//         const footerMainLinks = data.footerLinks
//         const footerSubLinks = data.footerSubLinks

//         footerMainLinks.map((link) => {
//             link.url = link.url && link.url.current ? link.url.current : link.url;
//             link.title = link.title ? link.title : link.entryTitle;
//             footerItems.mainLinks.push({
//                 title: link.title,
//                 url: addLocaleUrl(locale, link.url),
//                 openInNewContext: link.openInNewContext === true,
//             })
//         })

//         footerSubLinks.map((link) => {
//             link.url = link.url && link.url.current ? link.url.current : link.url;
//             link.title = link.title ? link.title : link.entryTitle;
//             footerItems.subLinks.push({
//                 title: link.title,
//                 url: addLocaleUrl(locale, link.url),
//                 openInNewContext: link.openInNewContext === true,
//             })
//         })
//     }

//     return footerItems
// }

// class Footer extends Component {

//     constructor (props, context) {
//         super(props)

//         const wasFormSubmitted = getParameterByName('signup') === 'true'

//         const { locale } = context

//         this.state = {
//             isSubmitted: wasFormSubmitted,
//             emailError: false,
//             langKey: locale,
//             locale: locale,
//             footerContent: null,
//             // TO DO! TO REVIEW state or props for items below????
//             // MP 20220725 - hardcoded values now temp, removed and changed to get from context when render
//             // newsletterModalContent: 'modal content for newsletter???!!!' - not used anywhere???
//             //showStickyCartBar: false, // MP 20220804 - removed from state as this was moved to App.js and context
//             //hiddenNavigation: false
//         }
//     }

//     componentDidMount() {

//         // const { locale, getFooter, getSiteWideComponentContent, sanityRequest } = this.context;

//         // getSiteWideComponentContent().then((data) => {
//         //     this.setState({
//         //       footerContent: setupFooterContent(data.footerNav, locale)
//         //     });
//         // });
//     }

//     // For older versions of Safari's benefit...
// validateEmail (e) {
//     let emailInput = document.getElementById('control_EMAIL')
//     // $FlowFixMe
//     let email = emailInput.value

//     if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
//         this.setState({
//             emailError: false
//         })
//         return true
//     }

//     this.setState({
//         emailError: true
//     })
//     return false

// }

//     render () {
//         //const { content, langsMenu, showStickyCartBar, hiddenNavigation, showSearch, metaData } = this.props
//         //const { content, showStickyCartBar, hiddenNavigation, showSearch, metaData } = this.props
//         const { showSearch } = this.state
//         const { showStickyCartBar, hiddenNavigation, footerContent } = this.context
//         const className = classNames('amp-global-footer', {showSearch})
//         const footerClasses = classNames({'sticky-cart-active': showStickyCartBar})
//         const content = footerContent ?
//             setupFooterContent(footerContent) :
//             null;

//         if (!content) {
//             return null
//         }

//         if (hiddenNavigation && content) {
//             return null
//         }

//         return (
//             <div className={className} id='amp-global-footer'>
//                 {!hiddenNavigation && (
//                     <footer id='footer' className={footerClasses}>
//                         <div className='footer-bg-colors'>
//                             <div className='container-fluid footer-content'>
//                                 <div className='row'>
//                                     <div className='col-12 col-md-6 footer-left'>
//                                         <h2>{content.email.title}</h2>
//                                         <p className='mb-4'>{content.email.paragraph}</p>
//                                         <form
//                                             id='footerSignupForm'
//                                             onSubmit={this.validateEmail}
//                                             action={content.email.postUrl}
//                                             className='form-inline'
//                                             method='post'
//                                             data-pageid={content.email.pageId}
//                                             data-siteid={content.email.siteId}
//                                             data-parentpageid={content.email.formParentPageId}
//                                         >
//                                             <div className='input-with-button form-group has-warning'>
//                                                 <input
//                                                     aria-label='Email'
//                                                     required
//                                                     name='Email'
//                                                     id='control_EMAIL'
//                                                     type='email'
//                                                     className='form-control'
//                                                     placeholder={content.email.placeHolder}
//                                                     pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}'
//                                                     title='Please provide a valid email address. Example: name@host.com'
//                                                 />
//                                                 {this.state.isSubmitted ? (
//                                                     <div className='ml-2'>{content.email.success}</div>
//                                                 ) : (
//                                                     <button
//                                                         className='amp-button primary-button btn btn-secondary'
//                                                         type='submit'
//                                                         value='submit'
//                                                     >
//                                                         <div className='inner'>{content.email.button}</div>
//                                                     </button>
//                                                 )}
//                                             </div>
//                                             {this.state.emailError && (
//                                                 <p className='mb-0'>{this.state.langKey === 'en'
//                                                     ? 'Please enter a valid email address!'
//                                                     : 'Veuillez entrer une adresse email valide'}
//                                                 </p>
//                                             )}
//                                             <input
//                                                 type='hidden'
//                                                 name='pageId'
//                                                 value={content.email.pageId}
//                                             />
//                                             <input
//                                                 type='hidden'
//                                                 name='siteId'
//                                                 value={content.email.siteId}
//                                             />
//                                             <input
//                                                 type='hidden'
//                                                 name='parentPageId'
//                                                 value={content.email.parentPageId}
//                                             />
//                                             <input
//                                                 type='hidden'
//                                                 name='formSourceName'
//                                                 value='StandardForm'
//                                             />
//                                             <input type='hidden' name='sp_exp' value='yes' />
//                                         </form>
//                                         <p className='mt-2 footer-form-help'>{content.email.formHelp}</p>
//                                     </div>
//                                     <div className='col-12 col-md-6 footer-right'>
//                                         { /* <Breadcrumbs location={this.props.location} title={Helmet.peek().onChangeClientState} /> */ }
//                                         <Breadcrumbs />
//                                         <div className='row footer-navigation'>
//                                             <div className='flex-footer-links'>
//                                                 {content &&
//                                                 content.mainLinks.map((link, idx) => {
//                                                     const targetProps = link.openInNewContext ? {target: '_blank'} : {}

//                                                     if(link.title === "COOKIE_SETTINGS_PLACEHOLDER" && content.cookieSettingsSnippet) {
//                                                         return (
//                                                             <span key={idx} dangerouslySetInnerHTML={{ __html: content.cookieSettingsSnippet }}></span>
//                                                         )
//                                                     } else if (link.url.includes('mailto:')) {
//                                                         return (
//                                                             <div
//                                                                 key={idx}
//                                                             >
//                                                                 <a href={link.url} {...targetProps}>
//                                                                     {link.title}
//                                                                 </a>
//                                                             </div>
//                                                         )
//                                                     } else {
//                                                         return (
//                                                             <div key={idx}>
//                                                                 <Button type='unstyled' to={link.url} url={link.url} text={link.title} {...targetProps} allTranslations={link.allTranslations} />
//                                                             </div>
//                                                         )
//                                                     }
//                                                 })}
//                                             </div>
//                                         </div>
//                                         <ul className='footer-legal list-inline mt-5'>
//                                             <li className='list-inline-item'>
//                                                 <p>&copy; {new Date().getFullYear()} Ikon Pass, Inc.</p>
//                                             </li>

//                                             {content &&
//                                             content.subLinks.map((link, idx) => {
//                                                 const targetProps = link.openInNewContext ? {target: '_blank'} : {}

//                                                 if(link.title === "COOKIE_SETTINGS_PLACEHOLDER" && content.cookieSettingsSnippet) {
//                                                     return (
//                                                         <li key={idx} className='list-inline-item' dangerouslySetInnerHTML={{ __html: content.cookieSettingsSnippet }}></li>
//                                                     )
//                                                 } else {
//                                                     return (
//                                                         <li key={idx} className='list-inline-item'>
//                                                             <Button type='unstyled' to={link.url} url={link.url} text={link.title} {...targetProps} allTranslations={link.allTranslations} />
//                                                             { /* <Button {...targetProps} type='unstyled' to={link.url}>
//                                                                 <p>{link.title}</p>
//                                                             </Button> */ }
//                                                         </li>
//                                                     )
//                                                 }
//                                             })}
//                                             {content.cookieSettingsSnippet && (
//                                                 <li key={content.subLinks.length + 1} className='list-inline-item' dangerouslySetInnerHTML={{ __html: content.cookieSettingsSnippet }}></li>
//                                             )}
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     </footer>
//                 )}
//                 {(content && content.disclaimerText && !hiddenNavigation) && (
//                     <div className="footer-disclaimer" id="footer-disclaimer" role="complementary">
//                         <small>{content.disclaimerText}</small>
//                     </div>
//                 )}

//                 {content && content.emailModal && (
//                     <NewsletterModal content={content.emailModal}/>
//                 )}

//             </div>
//         )
//     }
// }

// Footer.contextType = AllContexts

// //const mapStateToProps = createPropsSelector({showSearch})
// //export default connect(mapStateToProps)(Footer)
// export default Footer

// /*
//   // MP 20220720 - original Footer element embed from app.js with all different props
//   <Footer langsMenu={langsMenu}
//               langKey={langKey}
//               content={footerContent}
//               newsletterModalContent={newsletterModalContent}
//               showStickyCartBar={showStickyCartBar}
//               hiddenNavigation={hiddenNavigation}
//               location={this.props.location}
//             />

// */
