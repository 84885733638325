import React from "react";
import { connectStateResults, Index } from "react-instantsearch-dom";
//import {createPropsSelector} from 'reselect-immutable-helpers'
//import {sitePreferences} from '../../../app/selectors'
//import {connect} from 'react-redux'
import {
  SEARCH_SETTINGS,
  useCMSContent,
} from "../../../../utils/context/CMSContentContext";
// import { useEffect, useState } from "react";
// import { getCookieValue } from "../../../../utils/utils";

const UnconnectedPagesTotalHits = ({
  searchResults,
  faqTotalHits,
  onClick,
}) => {
  const [{ searchSettings }] = useCMSContent((store) => ({
    searchSettings: store[SEARCH_SETTINGS],
  }));

  const { nbHits, query = "" } = searchResults || {};
  const { searchDisplayingTotalText = "", searchQuickLinks = [] } =
    searchSettings || {};
  const totalHits = nbHits + faqTotalHits;
  const showQuickLinks = query.length === 0 && searchQuickLinks.length > 0;
  if (showQuickLinks) {
    return null;
  }
  return (
    <button type="submit" className="total-hits" onClick={onClick}>
      {searchDisplayingTotalText.replace("?", totalHits)}
    </button>
  );
};

//const mapStateToProps = createPropsSelector({sitePreferences})
//const PageTotalHits = connect(mapStateToProps)(connectStateResults(UnconnectedPagesTotalHits))
const PageTotalHits = connectStateResults(UnconnectedPagesTotalHits);

const UnconnectedFaqTotalHits = ({ searchResults, onClick }) => {
  const { nbHits } = searchResults || {};
  return (
    <Index indexName="sanityPages">
      <PageTotalHits faqTotalHits={nbHits} onClick={onClick} />
    </Index>
  );
};

const FaqTotalHits = connectStateResults(UnconnectedFaqTotalHits);

const DisplayTotalHitCount = ({ onClick }) => (
  <Index indexName="sanityFaqs">
    <FaqTotalHits onClick={onClick} />
  </Index>
);

export default DisplayTotalHitCount;
