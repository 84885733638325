const vectorMountainsProjection = `
{
  _id,
  _type,
  'backgroundColor': backgroundColor.value,
  topColor,
  middleColor,
  bottomColor,
  disableParallax
}
`;

export default vectorMountainsProjection;
