import { CURRENT_SEASON, LAST_SEASON } from "./current-season";

//TODO: Move variables to Netlify env variables
export const LAST_YEAR_DATE_RANGE = "2022-2023";
export const CURRENT_IKON_PASS_ID = `ikonpass${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_PASS_ID = `ikonpass${LAST_SEASON}`;
export const CURRENT_IKON_BASE_PASS_ID = `ikonbase${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_BASE_PASS_ID = `ikonbase${LAST_SEASON}`;
export const CURRENT_IKON_BASE_PLUS_PASS_ID = `ikonbaseplus${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_BASE_PLUS_PASS_ID = `ikonbaseplus${LAST_SEASON}`;
export const CURRENT_IKON_SESSION_FOUR_DAY_PASS_ID = `ikon4${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_SESSION_FOUR_DAY_PASS_ID = `ikon4${LAST_SEASON}`;
export const CURRENT_IKON_SESSION_THREE_DAY_PASS_ID = `ikon3${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_SESSION_THREE_DAY_PASS_ID = `ikon3${LAST_SEASON}`;
export const CURRENT_IKON_SESSION_TWO_DAY_PASS_ID = `ikon2${CURRENT_SEASON}`;
export const LAST_YEAR_IKON_SESSION_TWO_DAY_PASS_ID = `ikon2${LAST_SEASON}`;
