import { Highlight } from "react-instantsearch-dom";
import React from "react";
import CustomSnippet from "./custom-snippet";

const FaqHit = ({ hit, locale }) => {
  return (
    <div className="hit">
      <div className="hit-title">
        <Highlight
          className="title"
          attribute={`title_${locale}`}
          hit={hit}
          tagName="mark"
        />
      </div>
      <div className="hit-content">
        <CustomSnippet attribute={`rawContent_${locale}`} hit={hit} />
      </div>
    </div>
  );
};

export default FaqHit;
