import { sanityClient, sanityPreviewClient } from "../client.js";
// import { getCookieValue } from './utils.js'
import imageUrlBuilder from "@sanity/image-url";
import {
  isDevelop,
  isProduction,
  isStaging,
} from "../../src/modules/environments";

// Projections
import destinationProjection from "./groq/items/destinations/destination-projection";
import destinationLiteProjection from "./groq/items/destinations/destination-lite-projection";
import destinationPassListingPageProjection from "./groq/pages/destination-pass-listing-page-projection";
// import destinationLocalPassProjection from './groq/items/destinations/destination-local-pass-projection.js' //@TODO: what's this?
import ikonTileProjection from "./groq/items/ikon-tile-projection";
import pageProjection from "./groq/page-projection";

import headerProjection from "./groq/header-projection";
import footerProjection from "./groq/footer-projection";

// Templated page projections
import adventurePageProjection from "./groq/pages/adventure-page-projection.js";
import appPageProjection from "./groq/pages/app-page-projection.js";
import destinationsPageProjection from "./groq/pages/destinations-page-projection";
import homePageProjection from "./groq/pages/home-page-projection";
import passPageProjection from "./groq/pages/pass-page-projection";
import searchPageProjection from "./groq/pages/search-page-projection.js";
import faqPageProjection from "./groq/pages/faq-page-projection";
import tripsLandingPageProjection from "./groq/pages/trips-landing-page-projection.js";

import { getCookieValue, getLocale, stripLocaleUrl } from "./utils.js";

import localPassLandingPageProjection from "./groq/pages/local-pass-landing-page-projection";
import metadataProjection from "./groq/items/metadata-projection";
import passCartWidgetProjection from "./groq/modules/pass-cart-widget-projection.js";
import shopPassesPageProjection from "./groq/pages/shop-passes-page-projection.js";
import sitePreferencesProjection from "./groq/site-preferences-projection.js";
import localPassPageProjection from "./groq/pages/local-pass-page-projection.js";

import destinationFavoriteProjection from "./groq/items/destinations/destination-user-favorite-projection.js";
import destinationPassDetailsProjection from "./groq/items/destinations/destination-pass-details-projection";
import destinationAccessDataProjection from "./groq/items/destinations/destination-access-details-projection";

import siteWideComponentProjection from "./groq/site-wide-component-projection.js";
import allEventsPageProjection from "./groq/pages/all-events-page-projection.js";
import stokeEventsPageProjection from "./groq/pages/stoke-events-page-projection.js";

const locale = getLocale();
const isPreview = getCookieValue("preview") && !isProduction;

export const getSiteWideComponentData = (coBrand) => {
  return `${siteWideComponentProjection(coBrand)}`;
};

export const getSitePreferencesData = () => {
  return `*[_type == 'sitePreferences'] ${sitePreferencesProjection}`;
};

export const getHomepageData = () => {
  if (isPreview) {
    return `*[_type == 'homePage' && isCurrentPage == false] ${homePageProjection}`;
  } else {
    return `*[_type == 'homePage' && isCurrentPage == true] ${homePageProjection}`;
  }
};

// export const getHomepageNextYearData = () =>
//   `*[_type == 'homePage' && id == 'homePageNextYear'] ${homePageProjection}`;

export const getPassCartWidgetContent = (passID) => {
  return `*[_type == 'passCartWidget' && linkedPass->.passId == '${passID}'] ${passCartWidgetProjection}`;
};

// for the pass cart widgets for the time being.
export const getPassCartWidgetContentDirect = (
  passID,
  adobeTargetActivityID
) => {
  // We need to fetch the passCartWidget with adobe target ID
  return `*[_type == 'passCartWidget' && linkedPass->.passId == '${passID}' && adobeTargetActivityID in [null, "${adobeTargetActivityID}"] ]|order(_updatedAt desc) ${passCartWidgetProjection}`;
};

// for the pass cart widgets adobe target.
export const getPassCartWidgetContentAdobeTargetDirect = async (passID) => {
  if (isPreview) {
    const data = await sanityPreviewClient.fetch(
      `*[_type == 'passCartWidget' && linkedPass->.passId == '${passID}']|order(_updatedAt desc) ${passCartWidgetProjection}`
    );
    return data[0];
  } else {
    const data = await sanityClient.fetch(
      `*[_type == 'passCartWidget' && linkedPass->.passId == '${passID}']|order(_updatedAt desc)[0] ${passCartWidgetProjection}`
    );

    return data;
  }
};

export const getShopPassesLandingPageData = () =>
  `*[_type == 'shopPassesPage' && url.current == 'shop-passes'] ${shopPassesPageProjection}`;

export const getShopPassesLandingPagePreviousData = () =>
  `*[_type == 'shopPassesPage' && url.current == 'shop-passes-2023-2024'] ${shopPassesPageProjection}`;

export const getAppPageData = () =>
  `*[_type == 'appPage'] ${appPageProjection}`;

export const getAllEventsPageData = () =>
  `*[_type == 'allEventsPage'] ${allEventsPageProjection}`;
export const getStokeEventsPageData = () =>
  `*[_type == 'stokeEventsPage'] ${stokeEventsPageProjection}`;

export const getPassPageData = (slug) => {
  const strippedSlug = stripLocaleUrl(locale, slug);
  return `*[_type == "passDetailsPage" && url.current == '${strippedSlug}'] ${passPageProjection}`;
};

export const getPageDataBySlug = (slug) => {
  const strippedSlug = stripLocaleUrl(locale, slug);
  return `*[_type == "page" && url.current == '${strippedSlug}'] ${pageProjection}`;
};

export const getGlobalMetadata = () =>
  `*[_type == "metadata"] ${metadataProjection}`;

export const getCountriesData = () =>
  `*[_type == "country"]|order(orderRank asc)`;

export const getRegionsData = (locale) =>
  `*[_type == "region"]|order(orderRank asc) {
    _id,
    _type,
    country ->,
    'name': name.${locale},
    orderRank
  }`;

// export const getRegionsData = async () => {
//   const data = await sanityClient.fetch(
//     `*[_type == "region"]|order(orderRank asc) {
//       _id,
//       _type,
//       country ->,
//       'name': name.${locale},
//       orderRank
//     }`
//   )

//   return data
// }

export const getDestinationsData = () =>
  `*[_type == "destination"] |order(orderRank asc) ${destinationProjection}`;

export const getDestinationsLiteData = () =>
  `*[_type == "destination"] |order(orderRank asc) ${destinationLiteProjection}`;

export const getDestinationsAccessDetailsData = () =>
  `*[_type == "destination"] |order(orderRank asc) ${destinationAccessDataProjection}`;

export const getIkonPassesData = () =>
  `*[_type == "pass" && showOnLocalPassPage] ${ikonTileProjection}`;

// Templated page requests
export const getDestinationsPageData = () =>
  `*[_type == "destinationsLandingPage"] ${destinationsPageProjection}`;

export const getPassDetailsDestinationsData = () =>
  `*[_type == "destination"] |order(orderRank asc) ${destinationPassDetailsProjection}`;

export const getFavoriteDestinationsData = (destinationCodes) =>
  `*[_type == "destination" && resortCode in ${JSON.stringify(
    destinationCodes
  )}] ${destinationFavoriteProjection}`;

export const getAccessDetailsDestinationsData = () =>
  `*[_type == "destination"] |order(orderRank asc) ${destinationAccessDataProjection}`;

export const getLocalPassLandingPageData = () =>
  `*[_type == "localPassesLandingPage"] ${localPassLandingPageProjection}`;

export const getTripsLandingPageData = () =>
  `*[_type == "tripsLandingPage"] ${tripsLandingPageProjection}`;

export const getTripPageData = (destinationName) => {
  return `*[_type == "adventurePage" && url.current == 'adventures/${destinationName}'] ${adventurePageProjection}`;
};

export const getLocalPassPageData = (destination, pass) => {
  const query = `local-passes/${destination}/${pass}`;
  return `*[_type == "localPass" && urlSlug.current == "${query}"] ${localPassPageProjection}`;
};

export const getDestinationPassListingPageData = (slug) => {
  return `*[_type == "destinationPassListingPage" && url.current == 'local-passes/${slug}'] ${destinationPassListingPageProjection}`;
};

export const getDestinationPageData = (slug) => {
  const strippedSlug = stripLocaleUrl(locale, slug);
  return `*[_type == "destination" && url.current == 'destinations/${strippedSlug}'] ${destinationProjection}`;
};

export const getHeaderData = (coBrand) => {
  return `*[_type == "headerNav"] ${headerProjection(coBrand)}`;
};

export const getFooterData = () =>
  `*[_type == "footerNav"][0] ${footerProjection()}`;

export const getDestinationPageQuery = (slug) => {
  const strippedSlug = stripLocaleUrl(locale, slug);
  return `*[_type == "destination" && url.current == 'destinations/${strippedSlug}'] ${destinationProjection}`;
};

export const getFaqPageData = () =>
  `*[_type == 'faqPage' && url.current == 'faq'] ${faqPageProjection}`;

export const getSearchPageData = () =>
  `*[_type == "searchPage"] ${searchPageProjection}`;

export const getCurrencyCodesData = () => `*[_type == 'siteCurrency'][].code`;

// Get a pre-configured url-builder from your sanity client
// const builder = imageUrlBuilder(sanityClient)

// Then we like to make a simple function like this that gives the
// builder an image and returns the builder for you to specify additional
// parameters:

export const imageUrlFor = (source) => {
  return imageUrlBuilder(sanityClient)
    .image(source)
    .auto("format")
    .quality(source.quality || 75);
};

export const getThreeByFourImageUrl = (source, quality = 75) => {
  return imageUrlFor(source)
    .width(800)
    .height(1000)
    .fit("crop")
    .quality(quality)
    .url();
};

export const getSquareImageUrl = (source, quality = 75) => {
  return imageUrlFor(source)
    .width(1200)
    .height(1200)
    .fit("crop")
    .quality(quality)
    .url();
};

export const getSixteenByNineImageUrl = (source, quality = 75) => {
  return imageUrlFor(source)
    .width(1900)
    .height(1069)
    .fit("crop")
    .quality(quality)
    .url();
};

export const getPanoramaImageUrl = (source, quality = 75) => {
  return imageUrlFor(source)
    .width(1900)
    .height(475)
    .fit("crop")
    .quality(quality)
    .url();
};

export const getStylesForResponsiveHeroBg = (elementId, imageSource) => {
  const mobileBg = getThreeByFourImageUrl(imageSource);
  const desktopBg = getSixteenByNineImageUrl(imageSource);
  const styles = `#${elementId} {
        background-image: url(${mobileBg});
      }
      @media (min-width: 768px){
        #${elementId} {
          background-image: url(${desktopBg});
        }
      }
    }`;
  return styles;
};
