import { getLocale } from "../../utils";

const locale = getLocale();

const accordionGroupProjection = `{
  'title': title.${locale},
  titlePadding,
  'accordionIntro': accordionIntro.${locale},
  'backgroundColor': backgroundColor.title,
  variant,
  accordionList[] -> {
    _id,
    'title': title.${locale},
    'content': content.${locale}
  }
}`;

export default accordionGroupProjection;
