import { getLocale } from "../../../utils";

const locale = getLocale();

const destinationAccessDetailsProjection = `{
  _id,
  entryTitle,
  fullName,
  'url': url.current,
  resortCode,
  excludeFromListings,
  'accessDetailsProvisions': accessDetailsProvisions.${locale},
  'accessDetailsCurrentSeasonDescription':
    accessDetailsCurrentSeasonDescription.${locale},
  // accessDetailsShowCurrentSeasonSpecialCondition,
  // accessDetailsShowPreviousSeasonSpecialCondition,
  'accessDetailsPreviousSeasonDescription':
    accessDetailsPreviousSeasonDescription.${locale},
  'ikonPassCurrentSeasonDetails': ikonPassCurrentSeasonDetails.${locale},
  'ikonPassPreviousSeasonDetails':
    ikonPassPreviousSeasonDetails.${locale},
  'ikonBasePassCurrentSeasonDetails':
    ikonBasePassCurrentSeasonDetails.${locale},
  'ikonBasePassPreviousSeasonDetails':
    ikonBasePassPreviousSeasonDetails.${locale},
  'ikonBasePassPlusCurrentSeasonDetails':
    ikonBasePassPlusCurrentSeasonDetails.${locale},
  'ikonBasePassPlusPreviousSeasonDetails':
    ikonBasePassPlusPreviousSeasonDetails.${locale},
}`;

export default destinationAccessDetailsProjection;
