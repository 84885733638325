import imageProjection from "../items/image-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

const destinationOverviewProjection = `{
  _id,
  _type,
  entryTitle,
  destinationOverviewMapImage ${imageProjection},
  destinationOverviewPathImage ${imageProjection},
  destinationOverviewLegendPlacement,
  'destinationOverviewFirstLegendTitle': destinationOverviewFirstLegendTitle.${locale},
  'destinationOverviewFirstLegendDescription': destinationOverviewFirstLegendDescription.${locale},
  'destinationOverviewSecondLegendTitle': destinationOverviewSecondLegendTitle.${locale},
  'destinationOverviewSecondLegendDescription': destinationOverviewSecondLegendDescription.${locale},
  'destinationOverviewThirdLegendTitle': destinationOverviewThirdLegendTitle.${locale},
  'destinationOverviewThirdLegendDescription': destinationOverviewThirdLegendDescription.${locale},
  enableMapScroll
}
`;

export default destinationOverviewProjection;
