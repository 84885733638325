import classNames from "classnames";

// inline styles
import "./styles.scss";

const Prompt = ({ content, userData, className = "" }) => {
  if (!content) {
    return null;
  }

  const { loginPromptLeadText, loginPromptTrailText, loginPromptLinkText } =
    content;

  const showLoginPrompt =
    window.location.pathname === "/" ||
    window.location.pathname === "/fr" ||
    window.location.pathname.indexOf("shop-passes") > -1;

  // TODO: connect to env context
  const loginUrl = "/";
  // redirect from AMP login back to page on website after login
  // let loginUrl = env.amp.cartUrl + "/" + locale + "/login";
  // loginUrl = setQueryParamByName(
  //   loginUrl,
  //   "redirect_uri",
  //   "https://ikonpass.com" + window.location.pathname
  // );

  if (
    !(
      showLoginPrompt &&
      loginPromptLeadText &&
      loginPromptTrailText &&
      loginPromptLinkText &&
      !userData
    )
  ) {
    return null;
  }

  return (
    <div className={classNames("login-prompt", className)}>
      <span className="login-prompt-lead-text">{loginPromptLeadText}</span>
      <a className="login-prompt-link-text" href={loginUrl}>
        {loginPromptLinkText}
      </a>
      <span className="login-prompt-trail-text">{loginPromptTrailText}</span>
    </div>
  );
};

export default Prompt;
