import buttonProjection from "./button-projection";
import { getLocale } from "../../utils";
import iconProjection from "./icon-projection";

const locale = getLocale();

const passCartPromoProjection = `{
  _id,
  additionalCta ${buttonProjection},
  apiCode,
  availableCurrencies[] ->,
  discountedPassesToShow[],
  'title': title.${locale},
  icon -> ${iconProjection},
  'description': description.${locale},
}`;

export default passCartPromoProjection;
