import React from "react";
import classNames from "classnames";
import {
  getAgeRangeByAgeCategory,
  getNumberWithCommas,
  formatSeasonWithSlash,
  getLocale,
  renderDollarSign,
} from "../../../../../utils/utils";
import { AllContexts } from "../../../../../utils/context/AllContexts";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import AffirmCallout from "../../../../affirm-callout";
import environments from "../../../../../modules/environments";
import { toAmpCheckout } from "../../../../../modules/analytics-events";

// inline styles
import "./styles.scss";

class MiniCart extends React.Component {
  state = {
    catalogById: {},
  };

  constructor(props, context) {
    super(props, context);

    const { productData, fetchProductData } = context;
    if (!productData || !productData.length) {
      fetchProductData();
    }

    this.catalogById = this.catalogById.bind(this);
    this.sortedCartData = this.sortedCartData.bind(this);
    this.combineVariantPrice = this.combineVariantPrice.bind(this);
    this.combineVariantOriginalPrice =
      this.combineVariantOriginalPrice.bind(this);
    this.buildVariantLines = this.buildVariantLines.bind(this);
    // this.sendToAMPAnalytics = this.sendToAMPAnalytics.bind(this);
    this.handleViewCartButtonClick = this.handleViewCartButtonClick.bind(this);
  }

  handleViewCartButtonClick(cartData, productData) {
    toAmpCheckout(cartData, productData);
    window.location.assign(`${environments.amp.cartUrl}/${getLocale()}/cart`);
  }

  catalogById() {
    const { productData } = this.context;

    let catalogById = {};
    if (productData) {
      catalogById = productData.reduce((products, product) => {
        products[product.id] = product;

        const variantsById = products[product.id].variants.reduce(
          (variants, variant) => {
            variants[variant.ageCategory] = variant;
            return variants;
          },
          {}
        );

        products[product.id]["variantsById"] = variantsById;
        return products;
      }, {});
    }

    return catalogById;
  }

  sortedCartData(cartData) {
    const catalogById = this.catalogById();

    // Make cart object with product type as keys for array of those products -- { ikonPass: [products], ikonBase: [products] }
    const productsByType = cartData.profiles.reduce((products, product) => {
      // profiles dont always have items in them
      if (product.items.length) {
        let type = product.items[0].type,
          variant = product.items[0].variant;
        // create keys with type
        products[type] = products[type] || {};
        // create deeper keys with variant name
        products[type][variant] = products[type][variant] || [];
        // add products to each variant object, include age range and display name for easier use later
        products[type][variant].push({
          ageRange: getAgeRangeByAgeCategory(catalogById[type], variant),
          displayName: catalogById[type].variantsById[variant].displayName,
          ...product.items[0],
        });
      }
      return products;
    }, {});

    // Once grouped by type and variant, get back into array format
    let cartByTypeArray = [];
    for (let type in productsByType) {
      // Transform variant objects back into arrays
      let typeVariants = [];
      for (let variant in productsByType[type]) {
        typeVariants.push(productsByType[type][variant]);
      }

      // If this type is an Ikon product, and the season to the title
      let title = catalogById[type].title;
      if (
        title === "Ikon Pass" ||
        title === "Ikon Base Pass" ||
        title === "Ikon Base Plus Pass" ||
        title === "Ikon Session Pass 4-Day"
      ) {
        title = formatSeasonWithSlash(catalogById[type].season) + " " + title;
      }

      // Put types back into array, include the nice type title
      cartByTypeArray.push({
        title: title,
        type: type,
        products: typeVariants,
      });
    }

    return cartByTypeArray;
  }

  // Combine variant 'paid' prices and format nicely
  combineVariantPrice(variants) {
    const price = variants.reduce((total, variant) => {
      total += variant["paid_price"];
      return total;
    }, 0);

    const prettyPrice = renderDollarSign(
      getNumberWithCommas((price / 100).toFixed(2)),
      getLocale(),
      false
    );

    return prettyPrice;
  }

  // Combine variant original prices, compare with paid price, and format nicely
  combineVariantOriginalPrice(variants) {
    let paidPrice = 0;
    const price = variants.reduce((total, variant) => {
      paidPrice += variant["paid_price"];
      total += variant["original_price"];
      return total;
    }, 0);

    // If the original and paid are the same, don't show
    if (paidPrice === price) {
      return null;
    }

    // If showing this price, we dont want the .00's
    // const prettyPrice = `$${getNumberWithCommas(price / 100)}`;
    const prettyPrice = renderDollarSign(
      getNumberWithCommas(price / 100),
      getLocale,
      false
    );
    return prettyPrice;
  }

  buildVariantLines(variants, key) {
    // Need to allow for the possibility of a single variant having different prices

    // Create price buckets within each variant -- { 599: [product1], 799: [product2,product3] }
    let prodByPrice = {};
    variants.forEach((variant) => {
      prodByPrice[variant["paid_price"]] =
        prodByPrice[variant["paid_price"]] || [];
      prodByPrice[variant["paid_price"]].push(variant);
    });

    // Get back into array format for mapping -- [ [product1], [product2,product3] ]
    let prodByPriceArr = [];
    for (let price in prodByPrice) {
      prodByPriceArr.push(prodByPrice[price]);
    }

    // Build the HTML needed for each variant, 1 line or more if more than one price is found
    return prodByPriceArr.map((prod, x) => {
      return (
        <div key={x} className="mini-cart-variants">
          <div className="mini-cart-variant">
            <div className="mini-cart-variant-age">
              {prod.length > 1 && `${prod.length}x`} {prod[0].displayName}{" "}
              {prod[0].ageRange}
            </div>
            <div className="mini-cart-variant-price">
              {this.combineVariantOriginalPrice(prod) && (
                <del>{this.combineVariantOriginalPrice(prod)}</del>
              )}
              {this.combineVariantPrice(prod)}
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const { content } = this.props;
    const { cartData, productData, currency, locale } = this.context;

    if (
      !cartData ||
      !cartData.profiles ||
      !cartData.profiles.length ||
      !productData ||
      !currency ||
      !locale ||
      !content
    ) {
      return null;
    }

    const simpleCartData = this.sortedCartData(cartData);

    const cartSubtotal = (cartData.total / 100).toFixed(2);
    const subtotalChunks = cartSubtotal.toString().split(".");
    const cartTotalDisplay = getNumberWithCommas(subtotalChunks[0]);
    const cartCents = `.${subtotalChunks[1]}`;
    const affirmCallout = content.affirmCallout ? content : null;

    // some styling needs doing if this is open on a pass detail page on mobile
    let mobilePassSideBarStylesNeeded = false;
    if (window.innerWidth < 576) {
      let passSideBar = document.getElementsByClassName("sidebar-wrapper")[0];
      if (passSideBar && passSideBar.classList.contains("expanded")) {
        mobilePassSideBarStylesNeeded = true;
      }
    }

    const miniCartClasses = classNames("mini-cart", {
      "mini-cart-mobile-pass": mobilePassSideBarStylesNeeded,
    });

    return (
      <div className={miniCartClasses} id="mini-cart">
        <div className="mini-cart-types">
          {simpleCartData.map((type, i) => {
            return (
              <div className="mini-cart-type" key={i}>
                <h4 className="mini-cart-type-title">{type.title}</h4>
                {type.products.map((variants, j) => {
                  return this.buildVariantLines(variants, j);
                })}
              </div>
            );
          })}
        </div>
        <div className="mini-cart-subtotal">
          <div className="mini-cart-subtotal-label">{content.subtotalText}</div>
          <div className="mini-cart-subtotal-price">
            {getLocale().toLowerCase() === "en" && (
              <span className="mini-cart-subtotal-small">$</span>
            )}
            {cartTotalDisplay}
            <span className="mini-cart-subtotal-small">{cartCents}</span>
            {getLocale().toLowerCase() === "fr" && (
              <span className="mini-cart-subtotal-small">$</span>
            )}
            <span className="mini-cart-subtotal-currency">
              {cartData.payload_currency}
            </span>
          </div>
        </div>
        <div className="mini-cart-terms">
          {affirmCallout && (
            <AffirmCallout
              content={affirmCallout}
              productData={productData}
              currency={currency}
              priceOverride={cartTotalDisplay}
            />
          )}
          {content.disclaimerText && (
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
            >
              {content.disclaimerText}
            </ReactMarkdown>
          )}
        </div>
        <div className="mini-cart-actions">
          {content.cartButtonText && (
            <button
              onClick={() =>
                this.handleViewCartButtonClick(cartData, productData)
              }
              className="button primary"
            >
              <span className="inner">{content.cartButtonText}</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

MiniCart.contextType = AllContexts;

export default MiniCart;
