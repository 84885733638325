import buttonProjection from "./button-projection";
import { getLocale } from "../../utils";
import headingOverrideProjection from "./heading-override-projection";
import iconProjection from "./icon-projection";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const disruptionBannerProjection = ` {
    _id,
    _type,
    alignment,
    backgroundColorBehindBanner,
    backgroundImage ${imageProjection},
    cta ${buttonProjection},
    disableTopOverlap,
    'icon': icon->${iconProjection},
    'headline': headline.${locale},
    headlineOverride ${headingOverrideProjection},
    'leadParagraph': leadParagraph.${locale},
    showIkonLogo,
    slantedTop,
    textColor,
  }
`;

export default disruptionBannerProjection;
