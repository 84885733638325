import { getLocale } from "../../utils";

const locale = getLocale();

const tabsProjection = `{
  _type,
  _id,
  'backgroundColor': backgroundColor.value,
  tabs[] -> {
    _type,
    _id,
    'title': title.${locale},
    'content': content.${locale}
  }
}
`;

export default tabsProjection;
