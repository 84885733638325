import iconProjection from "./icon-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const cartPromotionBannerProjection = `{
  'description': description.${locale},
  entryTitle,
  icon->${iconProjection}, 
  'mobileDescription': mobileDescription.${locale},
  'mobileAccordionTitle': mobileAccordionTitle.${locale},
  'title': title.${locale},
  _id,
  _type
}
`;

export default cartPromotionBannerProjection;
