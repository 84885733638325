import basicLinkProjection from "./basic-link-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const faqGroupProjection = `{
  'entryTitle': entryTitle,
  'faqCategoryTitle': title.${locale},
  'title': title.${locale},
  displayType,
  'faqContent': faqContent.${locale},
  faqList[] -> {
    _id,
    'title': title.${locale},
    'content': content.${locale},
    removeFromSearch,
    searchRank
  },
  faqCta ${basicLinkProjection},
  hasBackground
}`;

export default faqGroupProjection;
