import React, { useState, useEffect } from "react";
// import Button from "components/button";
// import env from "../../../modules/environments";
// import {
//   buildEmailPreferencesLink,
//   getUserProductBlackoutDates,
//   getDaysUsedForActivePass,
// } from "../../../modules/user";
// import Notification from "components/notification";
// import {
//   formatSeasonWithSlash,
//   updateEnvSpecificAccountLink,
//   addLocaleUrl,
//   isLinkActive,
// } from "../../../modules/utils";
import classNames from "classnames";
import UserProfileImageCircle from "../../../../user-profile-img-circle";
// import LogoutIcon from "../../graphics/icons/logout.svg";
// import { createRichText } from "../../../modules/rich-text";
// import { createMarkup } from "../../../modules/markdown";

// import AccountBlogPost from "./account-blogpost";
// import AccountLinks from "../links";
// import AccountRedemptions from "./account-redemptions";

// import NavFaqGroup from "./nav-faq-group/nav-faq-group";
// import { destinationList } from "../../../containers/app/selectors";
import { Link } from "react-router-dom";
// import NotificationsFlyout from "./notifications-flyout";
// import { getUserBlackoutDates, showMountainChecklist } from "../utils/index";
import {
  getActiveSeason,
  getUserProductBlackoutDates,
  getUserBlackoutDates,
} from "../../../../../utils/users";

import Markdown from "../../../../markdown";

import covidCreditIcon from "../../../../../static/images/svg/covid-credit-fill-check.png";
import logoutIcon from "../../../../../static/images/svg/logout.svg";
import {
  addLocaleUrl,
  formatSeasonWithSlash,
} from "../../../../../utils/utils";
import MenuLinks from "../menu-links";
// import Favorites from "../favorites";
import Recommendations from "../recommendations";
import Statistics from "../stats";
import NavFaqGroup from "../faq-group";
import Notification from "../notification";
import Button from "../../../../button";
import environments from "../../../../../modules/environments";

const passesWithFriendsAndFamilyVouchers = ["Ikon Pass", "Ikon Base Pass"];

function Dropdown({
  content,
  locale,
  logoutUser,
  closeDropdown,
  sitePreferences,
  accountDropdownOpen,
  userData,
  numberOfNotifications,
  updateNumberOfNotifications,
}) {
  const [profileImageSize, setProfileImageSize] = useState("small");
  const [activePasses, setActivePasses] = useState([]);
  const [familyAccounts, setFamilyAccounts] = useState([]);
  const [currentSeason, setCurrentSeason] = useState(getActiveSeason());
  const [isNotificationFlyoutOpen, setIsNotificationFlyoutOpen] =
    useState(false);

  useEffect(() => {
    // adjust image size initially based on screen width
    if (window.innerWidth >= 925) {
      setProfileImageSize("large");
    }
    window.addEventListener("resize", handleScreenSize);

    getActivePasses();

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  const handleScreenSize = () => {
    if (window.innerWidth >= 925) {
      setProfileImageSize("large");
    } else {
      setProfileImageSize("small");
    }
  };

  const toggleUserNotificationsNav = () => {
    setIsNotificationFlyoutOpen(!isNotificationFlyoutOpen);
  };

  const getActivePasses = () => {
    // Finding out how many family passes are present. Subtracting the users' passes from all passes.
    if (userData && userData.products && userData.products.all) {
      const allPassesArray = [];
      const userPassesArray = [];

      for (let i = 0; i < userData.products.all.length; i++) {
        allPassesArray.push(userData.products.all[i]);
        if (allPassesArray[i].profileId === userData.me.profile.id) {
          userPassesArray.push(userData.products.all[i]);
        }
      }

      const activePasses = userPassesArray.filter((pass) => {
        const lastItemIndex = pass.season.split("-").length - 1;
        const lastEligibleYear = parseInt(
          pass.season.split("-")[lastItemIndex]
        );
        return lastEligibleYear === parseInt(currentSeason.split("-")[1]);
      });

      const allActivePasses = allPassesArray.filter((pass) => {
        const lastItemIndex = pass.season.split("-").length - 1;
        const lastEligibleYear = parseInt(
          pass.season.split("-")[lastItemIndex]
        );
        return lastEligibleYear === parseInt(currentSeason.split("-")[1]);
      });

      const familyAccts = Math.abs(
        allActivePasses.length - activePasses.length
      );

      setActivePasses(activePasses);
      setFamilyAccounts(familyAccts);
    }
  };

  if (!content || !accountDropdownOpen || !userData) {
    return null;
  }

  let blackouts = null;
  let userHasProducts = false;
  let showFriendsAndFam = false;
  let pass = null;
  // let className = classNames(parentClassName, {
  //   "child-flyout-open":
  //     !this.state.userNotificationsNavCollapsed ||
  //     !this.state.faqFlyoutCollapsed,
  // });
  // let shouldShowMountainChecklist = showMountainChecklist(userData);

  if (
    userData.products &&
    userData.products.primary &&
    userData.products.primary.access
  ) {
    userHasProducts = true;
    blackouts = getUserProductBlackoutDates(
      userData.products.all,
      userData.products.primary.season,
      content.upcomingBlackoutsDaysOfWarning
    );
    if (
      passesWithFriendsAndFamilyVouchers.includes(
        userData.products.primary.productName
      )
    ) {
      showFriendsAndFam = true;
    }
    pass = getUserBlackoutDates(userData);
  }

  let isSoftlyLoggedIn = false;
  if (
    userData &&
    userData.me &&
    userData.me.profile &&
    !userData.me.profile.email
  ) {
    isSoftlyLoggedIn = true;
  }

  // checking variant of user's passes, will only show if variant is not adult
  let passVariantAmount = []; // TODO: Variable Unused.
  if (userData && userData.products && userData.products.all) {
    userData.products.all.forEach((product, index) => {
      if (product.variant !== "adult") {
        passVariantAmount.push(product.variant);
      }
    });
  }

  // finding out user has products and if so are they eligible to renew
  let canRenew;
  let renewablePasses = []; // TODO: Variable Unused.
  if (userData && userData.products && userData.products.all) {
    userData.products.all.forEach((product, index) => {
      renewablePasses.push(product);
      if (product.isRenewable === true) {
        canRenew = true;
      }
    });
  } else {
    canRenew = false;
  }

  const { cloudinaryPhoto } = userData.me.profile;
  let userImgUrl = null;
  if (cloudinaryPhoto && cloudinaryPhoto.version && cloudinaryPhoto.publicId) {
    userImgUrl = `https://res.cloudinary.com/intrawest/image/upload/g_face,c_thumb,w_100,h_100/v${cloudinaryPhoto.version}/${cloudinaryPhoto.publicId}`;
  }

  // const resortFavoritesList = userData.resortFavorites;

  // // loop through all of the destination list and pull the resort code, url and url text to use in render method
  // var destinationUrls = {};
  // for (let i = 0; i < destinationList.length; i++) {
  //   var destinationItem = destinationList[i];
  //   if (destinationItem.data.resortCode) {
  //     destinationUrls[destinationItem.data.resortCode.toLowerCase()] = {
  //       link: destinationItem.data.resortLink,
  //       text: destinationItem.data.resortLinkText,
  //     };
  //   }
  // }

  return (
    <div
      className={classNames("account-dropdown", {
        open: accountDropdownOpen,
      })}
    >
      <div className="close-button" onClick={closeDropdown}>
        <div className="close-bar-1" onClick={closeDropdown}>
          <div className="close-bar-2" onClick={closeDropdown}></div>
        </div>
      </div>
      <div className={classNames("account-dropdown-user", "with-background")}>
        <UserProfileImageCircle
          profileImg={userImgUrl}
          variant={profileImageSize}
        />
        <div className="user-welcome">
          {userData.me.profile.firstName} {userData.me.profile.lastName}
        </div>

        {/* amount of passes & family accounts */}
        {!isSoftlyLoggedIn &&
        userData &&
        userData.products &&
        userData.products.all ? (
          <div className="user-pass-list">
            {activePasses.length >= 1 ? (
              <div>
                <span className="user-pass-amount">
                  {activePasses.length} &nbsp;
                  {activePasses.length === 1
                    ? content.userProductsSingular
                    : content.userProductsPlural}
                </span>
                •
                <span className="user-pass-variants">
                  {familyAccounts} &nbsp;
                  {familyAccounts === 1
                    ? content.familyProductsSingular
                    : content.familyProductsPlural}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : null}

        {!userHasProducts ? (
          <div className="user-without-pass">
            {!isSoftlyLoggedIn &&
              content.noPassMessage &&
              content.noPassMessage}
          </div>
        ) : null}
        {numberOfNotifications > 0 && (
          <Notification
            numberOfNotifications={numberOfNotifications}
            updateNumberOfNotifications={updateNumberOfNotifications}
            isOpen={isNotificationFlyoutOpen}
            onToggle={toggleUserNotificationsNav}
            content={content}
            sitePreferences={sitePreferences}
            userData={userData}
            userHasProducts={userHasProducts}
            blackouts={blackouts}
            pass={pass}
          />
        )}
        <div className="user-view-account">
          <Button
            url={`${environments.amp.cartUrl}/${locale}/myaccount`}
            text={
              content.viewMyAccountCta
                ? content.viewMyAccountCta
                : "View My Account"
            }
            type="blue-creek"
          />
        </div>
      </div>

      {userHasProducts ? (
        <div className="account-dropdown-user">
          <div
            className={
              userData.products.primary.productName ===
              "Ikon Session Pass 4-Day"
                ? "user-primary-pass-break"
                : "user-primary-pass"
            }
          >
            <span
              className={
                userData.products.primary.productName ===
                "Ikon Session Pass 4-Day"
                  ? "user-primary-pass-break-season"
                  : "user-primary-pass-season"
              }
            >
              {formatSeasonWithSlash(userData.products.primary.season)}
            </span>
            <span className="user-primary-pass-name">
              {userData.products.primary.productName}
            </span>
          </div>
        </div>
      ) : (
        <div className="no-passes-user-intro" />
      )}

      {/* Condition will always be false */}
      {/* {!userHasProducts && content.noPassCta && false && (
        <div className="user-without-pass-cta">
          <Button
            onClick={closeDropdown}
            className="primary straight"
            target={
              content.noPassCta.fields.openInNewContext ? "_blank" : null
            }
            to={addLocaleUrl(locale, content.noPassCta.fields.url)}
            title={content.noPassCta.fields.title}
          >
            {content.noPassCta.fields.title}
          </Button>
          {isSoftlyLoggedIn && (
            <div>
              <Button
                className="plain inline"
                style={{ textAlign: "left" }}
                type="unstyled"
                onClick={this.softLoginActionToggle}
              >
                Logged in only action
              </Button>
              {this.state.showSoftLoginMessage && (
                <small>{this.state.softLoginMessage}</small>
              )}
            </div>
          )}
        </div>
      )} */}

      {/* SEE account-redemption.jsx */}
      <Statistics
        userData={userData}
        content={content}
        canRenew={canRenew}
        showFriendsAndFam={showFriendsAndFam}
        userHasProducts={userHasProducts}
      />

      {/* view pass CTA goes here */}
      {userData && userHasProducts ? (
        <div className="account-dropdown-links">
          <Markdown
            className="view-pass-cta"
            components={{
              p: ({ node, ...props }) => (
                <p className="view-pass-text" {...props} />
              ),
            }}
          >
            {content.viewPassesLink}
          </Markdown>
        </div>
      ) : (
        ""
      )}

      {/* FAQ */}
      {content.faqGroup && (
        <NavFaqGroup locale={locale} content={content.faqGroup} />
      )}

      {/* SEE account-dropdown.jsx */}
      <Recommendations userData={userData} content={content} />

      {/* Favorite Destinations */}
      {/* SEE favorite-destinations.jsx */}
      {/*<Favorites
        userData={userData}
        content={content}
        // destinationList={destinationList}
      />*/}

      <hr className="separator" />

      {/* Account Links */}
      {/* SEE: account-links.js */}
      <MenuLinks
        canRenew={canRenew}
        content={content}
        closeDropdown={closeDropdown}
        userData={userData}
        locale={locale}
      />
      <div className="account-dropdown-links">
        {/* Shop passes text link */}
        {canRenew && content.shopPassesLink && (
          <span className="chevron-cta">
            {/* <a href={content.shopPassesTextLink.fields.url}> */}
            <Link to={addLocaleUrl(locale, content.shopPassesLink.url)}>
              {content.shopPassesLink.title}
            </Link>
          </span>
        )}

        {/* Covid Credit blurb and icon */}
        {canRenew && content.covidCreditDisclaimer ? (
          <div className="covid-container">
            <img className="covid-icon" src={covidCreditIcon} alt="" />
            <div className="covid-blurb">
              <Markdown className="covid-blurb-body">
                {content.covidCreditDisclaimer}
              </Markdown>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* logout CTA */}
        {content.logOutLinkText && (
          <div className="header-account-link logout">
            <button
              className="button unstyled"
              onClick={logoutUser}
              title={"Log Out"}
            >
              <span className="inner">
                {content.logOutLinkText}
                <img src={logoutIcon} alt={`Log Out Icon`} />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropdown;
