import React from "react";
import { Configure } from "react-instantsearch-dom";
import { CUSTOM_ELLIPSIS_TEXT } from "../../constants";

const ConfigurePages = ({ locale }) => {
  const lang = locale.slice(0, 2) === "en" ? "en" : "fr";

  return (
    <Configure
      hitsPerPage={6}
      attributesToRetrieve={[
        `siteSearchDisplayTitle_${lang}`,
        `siteSearchDisplayDescription_${lang}`,
        "url",
      ]}
      attributesToHighlight={[`siteSearchDisplayTitle_${lang}:100`]}
      attributesToSnippet={[`siteSearchDisplayDescription_${lang}:30`]}
      restrictSearchableAttributes={[
        `siteSearchDisplayTitle_${lang}`,
        `siteSearchDisplayDescription_${lang}`,
      ]}
      filters="NOT removeFromSearch:true"
      snippetEllipsisText={CUSTOM_ELLIPSIS_TEXT}
      queryLanguages={[lang]}
    />
  );
};

export default ConfigurePages;
