import imageProjection from "../items/image-projection";

const videoEmbedProjection = `{
  _type,
  _id,
  title,
  videoEmbedCode,
  imageOverlay ${imageProjection},
  playButtonColor,
  boxShadow
}`;

export default videoEmbedProjection;
