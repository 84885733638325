import contentBlockProjections from "./modules/content-blocks-projection";
import genericHeroProjection from "./modules/generic-hero-projection";
import seoProjection from "./seo-projection";

const pageProjection = `{
  _id,
  _type,
  hero->${genericHeroProjection},
  isHeaderOpaque,
  entryTitle,
  metaData,
  'contentBlocks': contentBlocks[] -> ${contentBlockProjections},
  seo ${seoProjection},
  slug
}`;

export default pageProjection;
