import buttonProjection from "../modules/button-projection.js";
import headingOverrideProjection from "./heading-override-projection.js";
import imageProjection from "../items/image-projection.js";
import { getLocale } from "../../utils";

const locale = getLocale();

const benefitTileProjection = `{
  _id,
  'benefitText': benefitText.${locale},
  headlineOverride ${headingOverrideProjection},
  image ${imageProjection},
  'tooltipText': tooltipText.${locale},
  cta ${buttonProjection}
}
`;

export default benefitTileProjection;
