import adventureRotatorProjection from "../modules/adventure-rotator-projection";
import buttonProjection from "../modules/button-projection";
import cardItemsProjection from "../lists/card-items-projection";
import countriesProjection from "../lists/countries-projection";
import destinationsLiteProjection from "../lists/destinations-lite-projection";
import cardGroupProjection from "../modules/card-group-projection";
import disruptionBannerProjection from "../modules/disruption-banner-projection";
import seoProjection from "../seo-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const destinationsPageProjection = `{
  'banner': {
    'headline': globe.headline.${locale},
    'leadText': globe.leadText.${locale},
    'buttonText': globe.buttonText.${locale}
  },
  _id,
  seo ${seoProjection},
  'globeContent': {
    'launchCtaText': globe.launchCtaText.${locale},
    'loadingText': globe.loadingText.${locale},
    'secondaryCTA': globe.secondaryCTA ${buttonProjection},
    'backButtonText': globe.backButtonText.${locale},
    'viewDetailsText': globe.viewDetailsText.${locale},
    'operationsPoliciesReservationsText': globe.operationsPoliciesReservationsText.${locale},
    'nearbyDestinationsText': globe.nearbyDestinationsText.${locale},
    'unlimitedText': globe.unlimitedText.${locale},
    'daysInTotalText': globe.daysInTotalText.${locale},
    'hintText': globe.hintText.${locale},
    'resetText': globe.resetText.${locale},
    'rotateHintText': globe.rotateHintText.${locale},
    'travelHintText': globe.travelHintText.${locale},
    'zoomHintText': globe.zoomHintText.${locale},
    'viewInARCta': globe.viewInARCta.${locale},
    'noAccessText': globe.noAccessText.${locale},
    'daysText': globe.daysText.${locale}
  },
  'heading': heading.${locale},
  'leadText': leadText.${locale},
  ${adventureRotatorProjection},
  ${cardGroupProjection},
  ${cardItemsProjection},
  disruptionBanner->${disruptionBannerProjection},
  'countries': ${countriesProjection},
  'destinations': ${destinationsLiteProjection}
}
`;

export default destinationsPageProjection;
