import React from "react";
// import { connect } from 'react-redux'
// import {createPropsSelector} from 'reselect-immutable-helpers'
// import { cartData, productData, locale, currency, content } from '../../containers/app/selectors'
// import Button from '../button/index'
// import { toAmpCheckout } from '../../modules/analytics-events'
// import { trackAbandonCartModalClicked } from '../../modules/at-events'
// import {getAgeRangeByAgeCategory, getNumberWithCommas} from '../../modules/utils'
// import { formatSeasonWithSlash } from '../../modules/utils'
// import env from '../../modules/environments'
// import {createRichText} from '../../modules/rich-text'
// import {createMarkup} from '../../modules/markdown.js.js.js'
import classNames from "classnames";
import { toAmpCheckout } from "../../../../../modules/analytics-events";
import { trackAbandonCartModalClicked } from "../../../../../modules/at-events";
import env from "../../../../../modules/environments";
import {
  getAgeRangeByAgeCategory,
  getNumberWithCommas,
  formatSeasonWithSlash,
  getLocale,
  renderDollarSign,
} from "../../../../../utils/utils";
import SanityImage from "../../../../sanity-image";

// inline styles
import "./_style.scss";

class CartModal extends React.Component {
  constructor(props) {
    super(props);
    this.sortedCartData = this.sortedCartData.bind(this);
    this.combineVariantPrice = this.combineVariantPrice.bind(this);
    this.combineVariantOriginalPrice =
      this.combineVariantOriginalPrice.bind(this);
    this.prettyAge = this.prettyAge.bind(this);
    this.buildVariantLines = this.buildVariantLines.bind(this);
    this.handleViewCartButtonClick = this.handleViewCartButtonClick.bind(this);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    let catalogById = [];
    if (nextProps.productData) {
      catalogById = nextProps.productData.reduce((products, product) => {
        products[product.id] = product;

        const variantsById = products[product.id].variants.reduce(
          (variants, variant) => {
            variants[variant.ageCategory] = variant;
            return variants;
          },
          {}
        );

        products[product.id]["variantsById"] = variantsById;
        return products;
      }, {});

      if (Object.keys(catalogById).length > 0) {
        this.setState({
          catalogById: catalogById,
        });
      }
    }
  }

  handleViewCartButtonClick() {
    trackAbandonCartModalClicked();
    toAmpCheckout(this.props.cartData, this.props.productData);
    window.location.assign(`${env.amp.cartUrl}/${getLocale()}/cart`);
  }

  sortedCartData(cartData) {
    if (!this.state.catalogById) {
      return [];
    }

    // Make cart object with product type as keys for array of those products -- { ikonPass: [products], ikonBase: [products] }
    // cartData.profiles = null;
    const productsByType = cartData.profiles.reduce((products, product) => {
      // profiles dont always have items in them
      if (product.items.length) {
        let type = product.items[0].type,
          variant = product.items[0].variant;
        // create keys with type
        products[type] = products[type] || {};
        // create deeper keys with variant name
        products[type][variant] = products[type][variant] || [];
        // add products to each variant object, include age range and display name for easier use later
        products[type][variant].push({
          ageRange: getAgeRangeByAgeCategory(
            this.state.catalogById[type],
            variant
          ),
          displayName:
            this.state.catalogById[type].variantsById[variant].displayName,
          ...product.items[0],
        });
      }
      return products;
    }, {});

    // Once grouped by type and variant, get back into array format
    let cartByTypeArray = [];
    for (let type in productsByType) {
      // Transform variant objects back into arrays
      let typeVariants = [];
      for (let variant in productsByType[type]) {
        typeVariants.push(productsByType[type][variant]);
      }

      // If this type is an Ikon product, and the season to the title
      let title = this.state.catalogById[type].title;
      if (
        title === "Ikon Pass" ||
        title === "Ikon Base Pass" ||
        title === "Ikon Base Plus Pass" ||
        title === "Ikon Session Pass 4-Day"
      ) {
        title =
          formatSeasonWithSlash(this.state.catalogById[type].season) +
          " " +
          title;
      }

      // Put types back into array, include the nice type title
      cartByTypeArray.push({
        title: title,
        type: type,
        products: typeVariants,
      });
    }

    return cartByTypeArray;
  }

  // Combine variant 'paid' prices and format nicely
  combineVariantPrice(variants) {
    const price = variants.reduce((total, variant) => {
      total += variant["paid_price"];
      return total;
    }, 0);

    const prettyPrice = renderDollarSign(
      getNumberWithCommas((price / 100).toFixed(2)),
      getLocale(),
      true
    );
    return prettyPrice;
  }

  // Combine variant original prices, compare with paid price, and format nicely
  combineVariantOriginalPrice(variants) {
    let paidPrice = 0;
    const price = variants.reduce((total, variant) => {
      paidPrice += variant["paid_price"];
      total += variant["original_price"];
      return total;
    }, 0);

    // If the original and paid are the same, don't show
    if (paidPrice === price) {
      return null;
    }

    // If showing this price, we dont want the .00's
    // const prettyPrice = `$${getNumberWithCommas((price / 100))}`
    const prettyPrice = renderDollarSign(
      getNumberWithCommas(price / 100),
      getLocale(),
      false
    );
    return prettyPrice;
  }

  prettyAge(ageRange) {
    let age = ageRange;
    // Account for English and French
    if (this.props.locale?.toLowerCase() === "en") {
      age = ageRange.replace("Ages", "");
      age = age.replace("Age", "");
    } else {
      age = ageRange.replace("Âges", "");
      age = age.replace("Âge", "");
    }
    age = age.replace(/\s/g, "");
    age = age.trim();
    return age;
  }

  buildVariantLines(variants, key) {
    // Need to allow for the possibility of a single variant having different prices

    // Create price buckets within each variant -- { 599: [product1], 799: [product2,product3] }
    let prodByPrice = {};
    variants.forEach((variant) => {
      prodByPrice[variant["paid_price"]] =
        prodByPrice[variant["paid_price"]] || [];
      prodByPrice[variant["paid_price"]].push(variant);
    });

    // Get back into array format for mapping -- [ [product1], [product2,product3] ]
    let prodByPriceArr = [];
    for (let price in prodByPrice) {
      prodByPriceArr.push(prodByPrice[price]);
    }

    // Build the HTML needed for each variant, 1 line or more if more than one price is found
    return prodByPriceArr.map((prod, x) => {
      return (
        <div key={x} className="mini-cart-variants">
          <div className="mini-cart-variant">
            <div className="mini-cart-variant-age">
              {prod.length > 1 && `${prod.length}x`} {prod[0].displayName}{" "}
              {this.prettyAge(prod[0].ageRange)}
            </div>
            <div className="mini-cart-variant-price">
              {this.combineVariantOriginalPrice(prod) && (
                <del>{this.combineVariantOriginalPrice(prod)}</del>
              )}
              {this.combineVariantPrice(prod)}
            </div>
          </div>
        </div>
      );
    });
  }

  renderItemsList(content, cartItemCount, cartData) {
    const simpleCartData = this.sortedCartData(cartData);

    const RenderProductItemCount = () => {
      if (cartItemCount === 1) {
        return null;
      } else {
        return (
          <div className="cart-modal-product-count">
            <p className="cart-modal-product-count">
              {content.cartItemPreText}
            </p>
            <p>{cartItemCount - 1}</p>
            <p className="cart-modal-product-count">
              {content.cartItemTrailText}
            </p>
          </div>
        );
      }
    };

    return (
      <>
        <div className="cart-modal-icon">
          <div className="cart-modal-count-badge">{cartItemCount}</div>
          <i className="icon-bag cart-modal-icon-bag"></i>
          <p className="cart-modal-icon-text">{content.cartIconText}</p>
        </div>

        <div className="mini-cart-types cart-modal-products">
          {simpleCartData.slice(0, 1).map((type, i) => {
            return (
              <div className="mini-cart-type" key={i}>
                <h4 className="mini-cart-type-title cart-modal-type-title">
                  {type.title}
                </h4>
                <RenderProductItemCount />
              </div>
            );
          })}
        </div>

        {/* <div className="mini-cart-subtotal">
                    <div className="mini-cart-subtotal-label">
                        {content.subtotalText}
                    </div>
                    <div className="mini-cart-subtotal-price">
                        <span className="mini-cart-subtotal-small">
                            $
                        </span>
                            {cartTotalDisplay}
                        <span className="mini-cart-subtotal-small">
                            {cartCents}
                        </span>
                        <span className="mini-cart-subtotal-currency">
                            {cartData.payload_currency}
                        </span>
                    </div>

                    <div className="cart-modal-terms mini-cart-terms">
                        {content.disclaimerText && <small dangerouslySetInnerHTML={createRichText(content.disclaimerText)} />}
                    </div>
                </div> */}
      </>
    );
  }

  render() {
    const {
      cartModalToggle,
      cartData,
      handleCloseModal,
      productData,
      content,
    } = this.props;

    let cartItemCount = 0;

    if (cartData && cartData.profiles && cartData.profiles.length >= 1) {
      cartData.profiles.forEach((item) => {
        if (item.items[0]) {
          cartItemCount++;
        }
      });
    }

    if (cartItemCount === 0 || !productData || !content) return null;

    // VP Check feature toggle
    if (!content.showModal) return null;

    const closeModalOverlay = cartModalToggle
      ? "modal-overlay"
      : "modal-over-collapsed";
    const closeModalElement = cartModalToggle
      ? "cart-modal-open"
      : "cart-modal-collapsed";

    return content && cartModalToggle && cartData.total ? (
      <div
        id="abandoned-cart-modal"
        className={classNames("abandoned-cart-modal", closeModalOverlay)}
        onClick={handleCloseModal}
      >
        <div className="cart-modal-container">
          <div className="cart-modal-image-container">
            <div className="cart-modal-image-container-inner">
              <SanityImage className="cart-modal-image" image={content.image} />
            </div>
          </div>

          <div
            className={closeModalElement}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="cart-modal-header">{content.header}</div>
            <div className="cart-modal-subheader">{content.subHeader}</div>

            {content.isDetailedCart ? (
              this.renderItemsList(content, cartItemCount, cartData)
            ) : (
              <div className="cart-modal-text">
                <p>{content.cartText}</p>
              </div>
            )}

            {content.ctaText && (
              <div className="cart-button-container">
                <button
                  className="button primary"
                  onClick={this.handleViewCartButtonClick}
                >
                  <span className="inner">{content.ctaText}</span>
                </button>
              </div>
            )}

            <button className="close" onClick={handleCloseModal}>
              <i className="icon-close-grey" />
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default CartModal;
