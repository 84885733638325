import affirmCalloutProjection from "./affirm-callout-projection";
import buttonProjection from "./button-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const passSideBySidePassBlockProjection = `{
  _id,
  _type,
  affirmCallout -> ${affirmCalloutProjection},
  'blackoutDatesTooltipContent': blackoutDatesTooltipContent.${locale},
  cta ${buttonProjection},
  'description': description.${locale},
  'drawerButtonText': drawerButtonText.${locale},
  facts[] -> {
    'label': label.${locale},
    'preLabel': preLabel.${locale},
    'subLabel': subLabel.${locale},
    'title': title.${locale},
    'tooltipContent': tooltipContent.${locale},
    'value': value.${locale},
  }, 
  entryTitle,
  ikonPass -> {
    _id,
    entryTitle,
    passId,
    'description': description.${locale},
    'accessDetails': accessDetails.${locale},
    'offsaleLong': offsaleLong.${locale},
    'offsaleShort': offsaleShort.${locale},
    'passTabTitle': passTabTitle.${locale},
    passYear,
    'blackoutDates': blackoutDates.${locale},
    preSaleEnabled,
    passOnSale,
    offSaleContent -> {
      _id,
      entryTitle,
      passOnSale,
      'offSaleBlurb': offSaleBlurb.${locale},
      'offSaleBlurbDetails': offSaleBlurbDetails.${locale},
      'offSaleBlurbCtaText': offSaleBlurbCtaText.${locale},
      'offSaleBlurbCtaLink': offSaleBlurbCtaLink.${locale},
    },
  },
  'numberBlackoutDates': numberBlackoutDates.${locale},
  'renewalText': renewalText.${locale},
  'renewalTooltipContent': renewalTooltipContent.${locale},
  tabTitle,
  'title': title.${locale},
  tooltipEnabled,
  'topCalloutText': topCalloutText.${locale},
}`;

export default passSideBySidePassBlockProjection;
