import { getLocale } from "../../utils";
// import imageProjection from "./image-projection";

const locale = getLocale();

const metadataProjection = `
{
  _id,
  'title': title.${locale},
  'keywords': keywords.${locale},
  'description': description.${locale},
  image,
  sitemapPriority,
  hideFromSitemap
}
`;

export default metadataProjection;
