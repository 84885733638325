import React from "react";
import Modal from "../../../modal";

class NewsletterModal extends React.Component {
  constructor(props) {
    super(props);

    const getParameterByName = (name, url) => {
      if (!url) {
        url = window.location.href;
      }

      name = name.replace(/[[\]]/g, "\\$&");
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);

      if (!results) {
        return null;
      }

      if (!results[2]) {
        return "";
      }
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    const wasFormSubmitted = getParameterByName("modal_signup") === "true";

    this.state = {
      modal: false,
      backdrop: true,
      isSubmitted: wasFormSubmitted,
      emailError: false,
      locale: "en",
    };

    this.handleClick = this.handleClick.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.toggle = this.toggle.bind(this);
    // const content = this.props.content.email;

    document.addEventListener("click", this.handleClick);
  }

  componentDidMount() {
    //show modal
    if (typeof window !== "undefined" && this.state.isSubmitted) {
      setTimeout(() => {
        this.toggle();
      }, 1000);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick);
  }

  handleClick(e) {
    if (e.target.hash === "#toggleNewsletterModal") {
      this.toggle(e);
      e.preventDefault();
    }
  }

  validateEmail(e) {
    let emailInput = document.getElementById("control_MODAL_EMAIL");
    // $FlowFixMe
    let email = emailInput.value;

    if (email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      this.setState({
        emailError: false,
      });
      return true;
    }

    this.setState({
      emailError: true,
    });
    return false;
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const content = this.props.content || {};
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          portalClassName="newsletter-modal"
        >
          <div className="modal-header">
            <h5>{content.title}</h5>
          </div>
          <div className="modal-body">
            <p>{content.paragraph}</p>
            <form
              id="modalSignupForm"
              onSubmit={this.validateEmail}
              action={content.postUrl}
              className="form-inline"
              method="post"
              data-pageid={content.pageId}
              data-siteid={content.siteId}
              data-parentpageid={content.formParentPageId}
            >
              <div className="input-with-button form-group has-warning">
                {this.state.isSubmitted ? (
                  <p className="ml-2">{content.success}</p>
                ) : (
                  <div>
                    <input
                      aria-label="Email"
                      required
                      name="Email"
                      id="control_MODAL_EMAIL"
                      type="email"
                      className="form-control"
                      placeholder={content.placeHolder}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                      title={
                        this.state.locale?.toLowerCase() === "en"
                          ? "Please enter a valid email address. Eg. name@host.com"
                          : "Veuillez entrer une adresse email valide. Par ex. nom@host.com"
                      }
                    />
                    <button
                      className="amp-button primary-button btn btn-secondary"
                      type="submit"
                      value="submit"
                    >
                      <div className="inner">{content.button}</div>
                    </button>
                  </div>
                )}
              </div>
              {this.state.emailError && (
                <p className="mb-0">
                  {this.state.locale?.toLowerCase() === "en"
                    ? "Please enter a valid email address!"
                    : "Veuillez entrer une adresse email valide"}
                </p>
              )}
              <input type="hidden" name="pageId" value={content.pageId} />
              <input type="hidden" name="siteId" value={content.siteId} />
              <input
                type="hidden"
                name="parentPageId"
                value={content.parentPageId}
              />
              <input type="hidden" name="formSourceName" value="StandardForm" />
              <input type="hidden" name="sp_exp" value="yes" />
            </form>
          </div>
          <div className="modal-footer">
            <p>{content.formHelp}</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default NewsletterModal;
