import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

//import {createPropsSelector} from 'reselect-immutable-helpers'
//import {connect} from 'react-redux'
//import {sitePreferences} from '../../app/selectors'
import {
  SEARCH_SETTINGS,
  useCMSContent,
} from "../../../utils/context/CMSContentContext";
// import { useEffect, useState } from "react";
// import { getCookieValue } from "../../../utils/utils";

const SuggestionCategoryTitle = ({ section, searchState }) => {
  const [{ searchSettings }] = useCMSContent((store) => ({
    searchSettings: store[SEARCH_SETTINGS],
  }));

  const {
    searchPagesTitle = "",
    searchFaqsTitle = "",
    searchQuickLinksTitle = "",
    searchQuickLinks = [],
  } = searchSettings || {};

  const { query = "" } = searchState || {};
  const showQuickLinks = query.length === 0 && searchQuickLinks.length > 0;
  const sectionTitleMap = {
    Pages: showQuickLinks ? searchQuickLinksTitle : searchPagesTitle,
    Faqs: searchFaqsTitle,
    sanityPages: showQuickLinks ? searchQuickLinksTitle : searchPagesTitle,
    sanityFaqs: searchFaqsTitle,
  };
  const sectionTitle = sectionTitleMap[section.index] || section.index;
  return <div className="suggestion-category-title">{sectionTitle}</div>;
};

//const mapStateToProps = createPropsSelector({sitePreferences})
//export default connectStateResults(connect(mapStateToProps)(SuggestionCategoryTitle))
export default connectStateResults(SuggestionCategoryTitle);
