// TO DO! TO REVIEW, this does not work with new site yet MP 20220616
// MP 20220711 - getSearchPageDetails() now moved as CMS content context into other files, so I guess can be removed?!?

import history from "../../utils/history";

//import { createAction } from 'redux-actions';
//import {createPropsSelector} from 'reselect-immutable-helpers'
//import * as connectors from '../../connectors'
//import {locale} from '../../containers/app/selectors'
//import { history } from '../../store'
//import {setBreadcrumbTitle} from '../app/actions'

// import {createBrowserHistory} from 'history'
// const history = createBrowserHistory()

//const getLocale = createPropsSelector({locale: locale})

/*
export const setPageDetails = createAction('set page details')

export const getSearchPageDetails = () => dispatch => {

    return dispatch(connectors.contentful.GetEntries({
        content_type: 'siteSearchPage',
    }))
    .then((data) => {
        if(data) {
            const content = {
                pageHeading: data.items[0].fields.pageHeading || '',
                pageTitle: data.items[0].fields.seoPageTitle || '',
                metaDescription: data.items[0].fields.metaDescription || '',
                placeholder: data.items[0].fields.searchFieldPlaceholderText || '',
                searchError: data.items[0].fields.searchErrorText || '',
            }

            dispatch(setBreadcrumbTitle(null))
            dispatch(setPageDetails(content))
        }
    })
}
*/

export const doSiteSearch = (
  searchQuery,
  needRedirect,
  locale,
  faqOnlySearch
) => {
  if (searchQuery.length > 0) {
    let faqOnly = "";

    if (faqOnlySearch) {
      faqOnly = "&faqOnlySearch=true";
    }

    if (needRedirect) {
      history.push(`/${locale}/search?term=${searchQuery}${faqOnly}`);
      history.go();
    }
  }
};
