import { useEffect, useState, useCallback } from "react";
import { updateVisistedDestinationPage } from "../../../../../../utils/utils";
const Sticker = ({ container, image, index, name, url }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [amp, setAmp] = useState(null);
  const [containerDims, setContainerDims] = useState(null);

  const generateDimensions = useCallback((imageUrl) => {
    const imageTest = document.createElement("img");
    imageTest.src = imageUrl;
    imageTest.onload = () => {
      setImageDimensions({
        width: index === 0 ? "0" : imageTest.width,
        height: index === 0 ? "0" : imageTest.height,
      });
    };
  }, [index]) 

  useEffect(() => {
    setContainerDims(container.current.getBoundingClientRect());
    setAmp(container.current.getBoundingClientRect().height);
    import(`../../../../../../static/images/stickers/${image}.webp`)
      .then((e) => {
        setImageUrl(e.default);
        generateDimensions(e.default);
      })
      .catch((e) => {
        return null;
      });
    return () => {};
  }, [container, generateDimensions, image]);

  

  const getCachedPosition = (resortCode) => {
    if (!localStorage.ikonpassVisitedDestinations) return;
    const storedVisitedDestinations = JSON.parse(
      localStorage.ikonpassVisitedDestinations
    );
    const cachedResort = storedVisitedDestinations.filter(
      (resort) => resort.resortCode === resortCode
    )[0];

    if (!cachedResort) return null;

    if (cachedResort.position.x) {
      return cachedResort.position;
    } else {
      return null;
    }
  };

  const getLeftPosition = (i, resortCode) => {
    const cachedPosition = getCachedPosition(resortCode);
    if (!cachedPosition) {
      const pos =
        containerDims.width * 0.95 -
        Math.abs(
          Math.cos(i) +
            (amp * Math.random() +
              (index > 1 ? imageDimensions.width * 1.25 : 0))
        );
      return `${
        ((pos - imageDimensions.width * 0.5) / containerDims.width) * 100
      }%`;
    } else {
      return `${cachedPosition.x}`;
    }
  };

  const randomRotation = (resortCode) => {
    const cachedPosition = getCachedPosition(resortCode);
    if (!cachedPosition) {
      let sign = Math.random() >= 0.5 ? "+" : "-";
      let maxRot = 35;

      return `${sign}${Math.round(Math.floor(Math.random() * maxRot))}`;
    } else {
      return `${cachedPosition.rotation}`;
    }
  };

  const getBottomPosition = (i, resortCode) => {
    const cachedPosition = getCachedPosition(resortCode);
    if (!cachedPosition) {
      const pos =
        containerDims.height -
        Math.abs(
          Math.sin(i) +
            ((amp / 2) * Math.random() + imageDimensions.height * 0.5)
        );
      return `${pos}px`;
    } else {
      return `${cachedPosition.y}`;
    }
  };

  if (!imageDimensions || !imageUrl) return null;

  const left = getLeftPosition(index, image);
  const top = getBottomPosition(index, image);
  const rotation = randomRotation(image);

  // caches the position into localStorage so that subsequent loads don't make the
  // stickers move around
  updateVisistedDestinationPage(image, left, top, rotation);

  return (
    <a href={url}>
      <img
        alt={`Link to ${name || url}`}
        className="sticker"
        style={{
          bottom: index === 0 ? "0px" : "auto",
          top: index === 0 ? "auto" : top,
          left: index === 0 ? "66%" : left,
          transform: `scale(0.66) rotateZ(${rotation}deg)`,
        }}
        src={imageUrl}
      />
    </a>
  );
};

export default Sticker;
