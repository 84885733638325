import Markdown from "../../../markdown";
import React, { Component } from "react";

export default class NavigationPromoArea extends Component {
  // I don't think this is needed anymore because of markdown... Keeping for future ref - Carl
  // Block link: clicking parent activates this.linkElRef hyperlink.
  // reference: https://css-tricks.com/block-links-the-search-for-a-perfect-solution/
  // onClickPromoArea(e) {
  //   // link comes from promoAreaLink field in contentful
  //   // only using block link if this field is populated.
  //   const link = this.props.link;
  //   if (!link) {
  //     return;
  //   }

  //   // don't activate promo link if click target is actually a different link
  //   if (e.target.tagName === "A") {
  //     return;
  //   }

  //   if (this.linkElRef.current) {
  //     this.linkElRef.current.click();
  //   }
  // }

  render() {
    const { content } = this.props;

    if (!content) {
      return null;
    }

    return (
      <div>
        <Markdown>{content}</Markdown>
      </div>
    );
  }
}
