import { useEffect, useState } from "react";
import {
  trackAbandonCartModalClosed,
  trackAbandonCartModalDisplayed,
} from "../../../../modules/at-events";
import {
  CART_DATA,
  PRODUCT_DATA,
  useAmp,
} from "../../../../utils/context/AmpContext";
import {
  ABANDON_CART_PREVIEW_MODE,
  useCMSContent,
} from "../../../../utils/context/CMSContentContext";
import {
  getCookieValue,
  getQueryParamByName,
  writeCookie,
} from "../../../../utils/utils";
import CartModal from "./cart-modal";

const AbandonedCartModal = ({ content }) => {
  const [overrideTimer, setOverrideTimer] = useState(null);
  const [{ abandonCartPreviewMode }, { setAbandonCartPreviewMode }] =
    useCMSContent((store) => ({
      abandonCartPreviewMode: store[ABANDON_CART_PREVIEW_MODE],
    }));
  const [{ cartData, productData }, { fetchProductData }] = useAmp((store) => ({
    cartData: store[CART_DATA],
    productData: store[PRODUCT_DATA],
  }));

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!productData || !productData.length) {
      fetchProductData();
    }
  }, [productData, fetchProductData]);

  useEffect(() => {
    const checkForOverride = () => {
      if (process.env.REACT_APP_ENVIRONMENT === "production") return;
      const cartQuery = getQueryParamByName("abandon-cart");

      if (!cartQuery) return;

      if (cartQuery === "true") {
        setAbandonCartPreviewMode(true);
        setOverrideTimer(cartQuery);
      }
    };

    checkForOverride();
  }, []);

  useEffect(() => {
    const activateAbandonCartModal = () => {
      if (window.pageYOffset > 1000) {
        if (overrideTimer) {
          // skip cookies if override is set so it shows on reload
          document.removeEventListener("scroll", activateAbandonCartModal);
          setIsOpen(true);
        } else {
          const acmCookieName = "abandon_cart_modal_shown";
          writeCookie(
            acmCookieName,
            new Date().toISOString(),
            5,
            null,
            null,
            null
          );

          // User has seen the modal, no need to continue to check
          document.removeEventListener("scroll", activateAbandonCartModal);
          setIsOpen(true);
          if (content.showModal) {
            setIsOpen(true);

            // trigger analytics because we will show the modal.  When we switch to
            // showing either the simple-cart-modal or detailed-cart-modal we will
            // want to make the tracking more specific
            trackAbandonCartModalDisplayed();
          }
        }
      }
    };

    const now = new Date();

    if (overrideTimer === "true") {
      document.addEventListener("scroll", activateAbandonCartModal, {
        passive: true,
      });
    } else {
      // check to see if we have popped up the modal already
      const acmCookieName = "abandon_cart_modal_shown";
      const acmCookie = getCookieValue(acmCookieName);

      // compare the last time the modal was shown + 24 hours
      let compareDate;

      if (acmCookie) {
        compareDate = new Date(
          new Date(Date.parse(acmCookie)).getTime() + 60 * 60 * 24 * 1000
        );
      } else {
        compareDate = now;
        // Write current date for comparison on revisit
        writeCookie(acmCookieName, compareDate, 5, null, null, null);
      }

      if (compareDate < now) {
        document.addEventListener("scroll", activateAbandonCartModal, {
          passive: true,
        });
      }
      return () => {
        if (compareDate < now) {
          document.removeEventListener("scroll", activateAbandonCartModal, {
            passive: true,
          });
        }
      };
    }
  }, [setIsOpen, content.showModal, overrideTimer]);

  // when clicking outside of the abandon cart modal, this will close modal
  const handleCloseCartModal = () => {
    setIsOpen(false);
    trackAbandonCartModalClosed();
  };

  return (
    <CartModal
      cartData={cartData}
      cartModalToggle={isOpen}
      handleCloseModal={handleCloseCartModal}
      productData={productData}
      content={content}
    />
  );
};

export default AbandonedCartModal;
