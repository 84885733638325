// User specific utilities
// import { updateEnvSpecificAccountLink } from './utils'
import { getActiveSeason, getPreviousSeason } from "../utils/users";
import { updateEnvSpecificAccountLink } from "../utils/utils";

export const getPrimaryUserRedemptions = (userId, redemptions) => {
  let primaryUserRedemptions = null;
  if (userId && redemptions) {
    for (let i = 0; i < redemptions.length; i++) {
      if (redemptions[i].profileId === userId) {
        primaryUserRedemptions = redemptions[i];
        break;
      }
    }
  }

  return primaryUserRedemptions;
};

// Get primary users' favorite resorts
export const getPrimaryResortFavorites = (userId, resortFavorites) => {
  let primaryResortFavorites = null;
  if (userId && resortFavorites) {
    for (let i = 0; i < resortFavorites.length; i++) {
      if (resortFavorites[i].profileId === userId) {
        primaryResortFavorites = resortFavorites[i];
        break;
      }
    }
  }

  return primaryResortFavorites;
};

// Get the primary users product out of a list that includes guests
export const getPrimaryUserProduct = (userId, products) => {
  let primaryUserProducts = [];
  let primaryProduct = null;

  if (userId && products) {
    // get all products that match the profile id of the account holder
    for (let i = 0; i < products.length; i++) {
      if (products[i].profileId === userId) {
        primaryUserProducts.push(products[i]);
      }
    }

    // if there is only one, use it as the primary
    if (primaryUserProducts.length === 1) {
      primaryProduct = primaryUserProducts[0];
    }

    const activeSeason = getActiveSeason();
    const previousSeason = getPreviousSeason();

    if (primaryUserProducts.length > 1) {
      // make array of the passes with the active season
      const passesInActiveSeason = primaryUserProducts.reduce(
        (passes, pass) => {
          if (pass.season === activeSeason) {
            passes.push(pass);
          }
          return passes;
        },
        []
      );
      // make an array of the passes with the previous season

      const passesInPreviousSeason = primaryUserProducts?.reduce(
        (passes, pass) => {
          if (pass.season === previousSeason) {
            passes.push(pass);
          }
          return passes;
        },
        []
      );

      // if there's only one, use it as the primary pass
      if (passesInActiveSeason.length === 1) {
        primaryProduct = passesInActiveSeason[0];
      } else if (passesInActiveSeason.length > 1) {
        // check the pass 'value' by name -  Ikon Pass > Ikon Base Pass > Other Passes
        for (let i = 0; i < passesInActiveSeason.length; i++) {
          if (passesInActiveSeason[i].productName === "Ikon Pass") {
            primaryProduct = passesInActiveSeason[i];
            break;
          }
          if (passesInActiveSeason[i].productName === "Ikon Base Pass") {
            primaryProduct = passesInActiveSeason[i];
          }
        }
        // if no ikon or ikon base passes are present, used the first in the list as the primary
        if (!primaryProduct) {
          primaryProduct = passesInActiveSeason[0];
        }
      } else if (passesInActiveSeason.length === 0) {
        if (passesInPreviousSeason.length >= 1) {
          primaryProduct = passesInPreviousSeason[0];
        }
      }
    }
  }

  return primaryProduct;
};

// Converts YYY-MM-DD to date object
const convertStringYYYMMDDToDate = (str) => {
  let date = null;
  if (str) {
    const strObj = str.split("-");
    date = new Date(strObj[0], strObj[1] - 1, strObj[2]);
  }
  return date;
};

/*
    Check for upcoming blackout dates
    Compare the blackout dates of products to today to determine if a notification should show to the user.
    @param {array} products - All products related to the users account
    @param {string} activeSeason - The active season based off the primary pass holders
    @param {string} warningDays - The amount of days before a blackout period to show this notification (defaults to 5)
    @return {object} anonymous - An object containing active blackout dates, upcoming blackout dates, and the warningDays
 */

export const getUserProductBlackoutDates = (
  products,
  activeSeason,
  warningDays = null
) => {
  if (!products) {
    return null;
  }

  // for testing - comment out for prod
  // accessWithBlackouts.push({blackout_dates: [{from: '2020-06-10', to: '2021-06-20'}], resorts: [{name: 'Matts Mountain'}, {name: 'Johns Mountain'}]})
  // accessWithBlackouts.push({blackout_dates: [{from: '2020-06-14', to: '2021-06-20'}], resorts: [{name: 'Shark Mountain'}, {name: 'Big Mountain'}]})

  if (!warningDays) {
    console.log(
      "Number of warning days for blackouts notification not supplied. Defaults to 5."
    );
    warningDays = 5;
  }

  const today = new Date();
  let upcomingBlackouts = [];
  let activeBlackouts = [];

  // loop through all products linked to this account
  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (product.access) {
      // loop through the access details of each account
      for (let j = 0; j < product.access.length; j++) {
        let accessPeriod = product.access[j];
        // some products have an empty array for blackout_dates
        if (
          accessPeriod.blackoutDates &&
          accessPeriod.blackoutDates.length > 0
        ) {
          // loop through each blackout period and compare dates to now
          for (let k = 0; k < accessPeriod.blackoutDates.length; k++) {
            const blackoutDate = accessPeriod.blackoutDates[k];
            const fromDate = convertStringYYYMMDDToDate(blackoutDate.from);
            let fromDateMinusWarningDays = convertStringYYYMMDDToDate(
              blackoutDate.from
            );
            fromDateMinusWarningDays.setDate(
              fromDateMinusWarningDays.getDate() - warningDays
            );
            const toDate = convertStringYYYMMDDToDate(blackoutDate.to);

            // if today is greater than the warning date && today is less then the start of the blackout period
            if (today >= fromDateMinusWarningDays && today < fromDate) {
              //console.log('Within the ' + warningDays + ' warning days')
              upcomingBlackouts.push({
                from: blackoutDate.from,
                to: blackoutDate.to,
                resorts: accessPeriod.resorts,
              });
            }
            // if today is greater than the from date and less then the closing date
            if (today > fromDate && today < toDate) {
              // console.log('There are blackouts currently active')
              activeBlackouts.push({
                from: blackoutDate.from,
                to: blackoutDate.to,
                resorts: accessPeriod.resorts,
              });
            }
          }
        }
      }
    }
  }

  // console.log('upcomingBlackouts')
  // console.log(upcomingBlackouts)
  // console.log('activeBlackouts')
  // console.log(activeBlackouts)

  return {
    warningDays: warningDays,
    upcoming: upcomingBlackouts,
    active: activeBlackouts,
  };
};

export const buildEmailPreferencesLink = (link, email) => {
  let emailLink = updateEnvSpecificAccountLink(link);
  if (email) {
    emailLink += `&email=${email}`;
    return emailLink;
  }
  return null;
};

// Used to calculate the total number of days used for the active pass - the total_days property is for multiple passes (can be)
// VP this method used to do a calculation but Trineo (AMP) now provides the calculated number in the API
export const getDaysUsedForActivePass = (redemptionProducts, activePass) => {
  if (!redemptionProducts || !activePass) {
    return 0;
  }

  // get the active pass's redemptions
  let activePassRedemptions = null;
  if (redemptionProducts.length) {
    for (let i = 0; i < redemptionProducts.length; i++) {
      if (
        redemptionProducts[i].season === activePass.season &&
        redemptionProducts[i].productName === activePass.productName
      ) {
        activePassRedemptions = redemptionProducts[i];
      }
    }
  }

  let liftRedemptions = activePassRedemptions
    ? activePassRedemptions.totalDays
    : null;
  // VP we no longer need to do this calculation because it's provided by the API
  /*if (activePassRedemptions && activePassRedemptions.lift_redemptions) {
        // loop through the active pass's lift redemptions array
        liftRedemptions = activePassRedemptions.lift_redemptions.reduce((numberOfRedemptions, liftRedemption) => {
            // if there are some redemptions
            if (liftRedemption.redemptions.length) {
                // calculate the total of redemptions within - the quantity can be more than 1 fyi
                const qty = liftRedemption.redemptions.reduce((total, redemption) => {
                    if (redemption.quantity) {
                        total += redemption.quantity
                    }
                    return total
                }, 0)
                numberOfRedemptions += qty
            }
            return numberOfRedemptions
        }, 0)
    }*/

  return liftRedemptions;
};

export const getCloudinaryPhoto = (photoId) => {
  if (!photoId) {
    console.log("No cloudinary photo ID was supplied");
    return null;
  }
};
