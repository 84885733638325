import disruptionBannerProjection from "../modules/disruption-banner-projection";
import faqGroupProjection from "../modules/faq-group-projection";
import flexGroupProjection from "../modules/flex-group-projection";
import genericHeroProjection from "../modules/generic-hero-projection";
import passCartPromoProjection from "../modules/pass-cart-promo-projection";
import mountainVectorTripleFeatureProjection from "../modules/mountain-vector-triple-feature-projection";
import passesSideBySideProjection from "../modules/passes-side-by-side-projection";
import seoProjection from "../seo-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const shopPassesPageProjection = `{
  disruptionBanner -> ${disruptionBannerProjection},
  _id,
  hero -> ${genericHeroProjection},
  genericHeroAdobeTarget[] -> ${genericHeroProjection},
  mountainVectorTripleFeature ${mountainVectorTripleFeatureProjection},
  passPromosBlock -> {
    _id,
    entryTitle,
    promotions[] -> ${passCartPromoProjection}
  },
  perksFlexGroup -> ${flexGroupProjection},
  passesSideBySide ${passesSideBySideProjection},
  passesSideBySideAdobeTarget[] ${passesSideBySideProjection},
  faqGroup ${faqGroupProjection},
  'pageExtraContent': pageExtraContent.${locale},
  seo ${seoProjection}
}`;

export default shopPassesPageProjection;
