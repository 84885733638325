// import {
//   setCartData,
//   setAccessDetailsApiData,
//   setProductData,
//   setUserLoggedInState,
//   setLoggedInUserData,
// } from "../../containers/app/actions";

import { camelizeKeys } from "humps";
import env from "../modules/environments";
import {
  getCookieValue,
  // writeCookie,
  writeCrossDomainCookie,
  deleteCrossDomainCookie,
  setQueryParamByName,
  // } from "../../modules/utils";
} from "./utils";
import {
  getPrimaryUserRedemptions,
  getPrimaryUserProduct,
} from "../modules/user";
import { Headers, fetch } from "whatwg-fetch";

// import fakeAccessDetailsJSON from "../mock/access-details.json";
// import fakeProductDataJSON from "../mock/product-catalog.json";
// import fakeMeResponseJSON from "../mock/me.json";
// import fakeMountainChecklistResponseJSON from "../mock/mountain-checklist.json";
// import fakeMyProductsResponseJSON from "../mock/my-products.json";
// import fakeMyRedemptionsResponseJSON from "../mock/my-redemptions.json";
// import fakeResortFavoritesResponseJSON from "../mock/resort-favorites.json";

import { generalTracking } from "../modules/analytics-events";

let // dispatch,
  fakeAccessDetailsJSON,
  fakeProductDataJSON,
  fakeMeResponseJSON,
  fakeMountainChecklistResponseJSON,
  fakeMyProductsResponseJSON,
  fakeMyRedemptionsResponseJSON,
  fakeResortFavoritesResponseJSON;

// FOR TESTING SET TO TRUE
// if false we should comment out the import as well
const testingApiOutage = false;
const testingFakeAccessDetails = false;
const testingFakeProductData = false;
// const testingFakeSoftUserData = false;
const testingFakeUserData = false;

// let
// setCartData,
// setAccessDetailsApiData,
// setProductData,
// setUserLoggedInState,
// setLoggedInUserData;

export const updateCartCurrency2 = (currency) => {
  const xsrfVal = getCookieValue(env.amp.xsrfCookie) || "";

  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Basic " + btoa(env.amp.apiKey));

  // append XSRF header populated with the xsrf cookie value
  if (xsrfVal) {
    headers.append("X-CSRF-Token", xsrfVal);
  }

  var myInit = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({ data: { payload_currency: currency } }),
    credentials: "include",
  };

  return fetch(env.amp.cartApi, myInit)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong ...");
      }
    })
    .then((data) => {
      if (data && data.data) {
        // replace the cart data with the newly returned data
        return data.data.payload_currency;
      } else {
        console.log("No cart data returned");
        return null;
      }
    })
    .catch((error) => {
      console.error("update cart currency error", error);
    });
};

export const getCartData2 = () => {
  const xsrfVal = "UAT-XSRF-TOKEN"; //getCookieValue(env.amp.xsrfCookie) || "";
  let headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(env.amp.apiKey));

  // append XSRF header populated with the xsrf cookie value
  if (xsrfVal) {
    headers.append("X-CSRF-Token", xsrfVal);
  }
  return fetch(env.amp.cartApi, { credentials: "include", headers: headers })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong ...");
      }
    })
    .then((data) => {
      if (testingApiOutage) {
        console.log("Testing API Outage - getCartData");
        return null;
      }
      // console.log('Cart data received')
      return data;
    })
    .catch((error) => {
      console.error("get cart error", error);
      return null;
    });
};

export const sendCartData2 = (variant, type, count, cartData = null) => {
  var headers = new Headers();
  // const self = this
  const xsrfVal = getCookieValue(env.amp.xsrfCookie) || "";

  // append XSRF header populated with the xsrf cookie value
  if (xsrfVal) {
    headers.append("X-CSRF-Token", xsrfVal);
  }

  const sendData = {
    variant,
    type,
    count,
  };

  headers.append("Content-Type", "application/json");

  var myInit = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify({ data: sendData }),
    credentials: "include",
  };

  // fallback to USD if need be
  let currency = "USD";

  if (cartData && cartData.payload_currency) {
    currency = cartData.payload_currency;
  }
  let currencyParam = `?currency=${currency}`;

  return fetch(`${env.amp.cartApi}/items${currencyParam}`, myInit)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      if (!json.errors) {
        return json.data;
      } else {
        return json.errors;
      }
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const getAccessDetailsApiData2 = () => {
  //   try {
  //       // we cache this response in the build process
  //       if (!accessDetailsJSON || !accessDetailsJSON.data) {
  //           throw "Access Details info not in cache";
  //       }
  //       return camelizeKeys(accessDetailsJSON.data);
  //   } catch (err) {

  // call the API
  let urlToUse = env.amp.accessDetailsApi;
  return fetch(urlToUse, {
    credentials: "include",
    headers: new Headers({
      Authorization: "Basic " + btoa(env.amp.apiKey),
    }),
  })
    .then((response) => {
      if (testingFakeAccessDetails) {
        console.log(
          "WARNING: testingFakeAccessDetails is true and we are using mock data"
        );
        return camelizeKeys(fakeAccessDetailsJSON);
      }
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong ...");
      }
    })
    .then((data) => {
      return camelizeKeys(data.data);
    })
    .catch((error) => {
      console.error("get access data error", error);
      return null;
    });
  //   }
};

export const getProductData = (currency, locale) => (dispatch, getStore) => {
  console.log("getProductData WRONG ONE!!!!");
};

export const getProductData2 = (currency, locale) => {
  console.log(`getProductData2`, currency, locale);
  // // console.log("get product data start")
  let urlToUse = env.amp.catalogApi;

  if (currency) {
    urlToUse = setQueryParamByName(env.amp.catalogApi, "currency", currency);
  }

  if (locale) {
    urlToUse = setQueryParamByName(urlToUse, "locale", locale);
  }

  return fetch(urlToUse)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("response:", response);
        throw new Error("Something went wrong ...");
      }
    })
    .then((data) => {
      if (testingApiOutage) {
        console.log("Testing API Outage - getProductData");
        return null;
      }
      if (testingFakeProductData) {
        console.log(
          "WARNING: testingFakeProductData is true and we are using mock data"
        );
        return camelizeKeys(fakeProductDataJSON.data);
      }
      return camelizeKeys(data.data);
    })
    .catch((error) => {
      console.error("get product data error", error);
      return null;
    });
};

// export const isUserLoggedIn = () => (dispatch, getStore) => {

//   const sessionCookieValue = getCookieValue(env.amp.sessionCookie);

//   let parsedValue = "";
//   if (sessionCookieValue) {
//       try {
//           parsedValue = JSON.parse(decodeURIComponent(sessionCookieValue));
//       } catch(e) {
//           console.log({e})
//       }
//   } else {
//       console.log("Amp session cookie does not exist.");
//       dispatch(doSoftLogin(env.amp.apiKey));
//       // hit the account api to get the cookies
//       return dispatch(getAllUserData("false"));
//   }

//   if (parsedValue && parsedValue.authenticated && parsedValue.expires_at) {
//       const expiresAt = new Date(parsedValue.expires_at);
//       const now = new Date();
//       if (now > expiresAt) {
//           console.log("User session is expired.");
//           return dispatch(getAllUserData("false"));
//       } else {
//           const amp_token = getCookieValue(env.amp.sessionTokenCookie);
//           if (amp_token) {
//               return dispatch(getAllUserData(amp_token)); // get user details here
//           } else {
//               writeCookie(
//                   env.amp.sessionTokenCookie,
//                   "true",
//                   null,
//                   null,
//                   null,
//                   null
//               );
//               return dispatch(getAllUserData(amp_token)); // get user details here
//               // console.log('Amp token cookie not found')
//           }
//       }
//   } else {
//       // console.log('Session cookie doesnt exist or the user is not authenticated.')
//   }

//   return dispatch(getAllUserData("false"));
// };

// VP My understanding is that this method looks for a token in our cookie
// and then will try to call the AMP API with it
export const isUserLoggedIn2 = () => {
  if (testingFakeUserData) {
    return getAllUserData2("");
  }
  const sessionCookieValue = getCookieValue(env.amp.sessionCookie);
  let parsedValue = "";
  if (sessionCookieValue) {
    try {
      parsedValue = JSON.parse(decodeURIComponent(sessionCookieValue));
    } catch (e) {
      console.log({ e });
    }
  } else {
    //return Promise.reject(new Error('Amp session cookie does not exist.'));
    console.log("Attempting soft login (1)");
    return doSoftLogin2(env.amp.apiKey);
  }
  if (parsedValue && parsedValue.authenticated && parsedValue.expires_at) {
    const expiresAt = new Date(parsedValue.expires_at);
    const now = new Date();
    if (now > expiresAt) {
      console.log("User session is expired.");
      return doSoftLogin2(env.amp.apiKey);
    } else {
      console.log("User session is active.");
      const amp_token = getCookieValue(env.amp.sessionTokenCookie);
      if (amp_token) {
        return getAllUserData2(amp_token); // get user details here
      }
    }
  } else {
    console.log("Attempting soft login (2)");
    return doSoftLogin2(env.amp.apiKey);
  }
  return getAllUserData2(false);
};

const softLoginCookieName = "guest_summary";

// Make all API calls needed for user data
// export const getAllUserData = (token) => (dispatch, getStore) => {

//   // Start by checking to see if they are logged in, and have access to their data
//   return dispatch(makeBasicAmpApiRequest(token, env.amp.userApi)).then(
//       (userData) => {

//           if(testingFakeUserData) {
//               console.log('Using mock login data')
//               let fakeData = fakeUserDataJSON
//               let softUserObject = {
//                   me: {
//                       profile: {
//                           first_name: fakeData.me.profile.first_name,
//                           last_name: fakeData.me.profile.last_name,
//                           cloudinary_photo:
//                               fakeData.me.profile.cloudinary_photo,
//                       },
//                   },
//                   redemptions: null,
//                   products: null,
//                   resort_favorites: null,
//                   mountain_checklist: null,
//               };
//               writeCrossDomainCookie(
//                   softLoginCookieName,
//                   JSON.stringify(softUserObject),
//                   365,
//                   null,
//                   null,
//                   null
//               );
//               dispatch(setUserLoggedInState(true));
//               return dispatch(setLoggedInUserData(fakeData))
//           } else if(testingFakeSoftUserData) {
//               console.log('Using mock soft login data')
//               let fakeData = fakeSoftUserDataJSON
//               dispatch(setUserLoggedInState(false));
//               return dispatch(setLoggedInUserData(fakeData))
//           }

//           if (
//               userData &&
//               userData.data &&
//               userData.data.profile &&
//               !userData.errors.length
//           ) {
//               let userObject = {
//                   me: userData.data,
//                   redemptions: null,
//                   products: null,
//                   resort_favorites: null,
//                   mountain_checklist: null,
//               };

//               let softUserObject = {
//                   me: {
//                       profile: {
//                           first_name: userData.data.profile.first_name,
//                           last_name: userData.data.profile.last_name,
//                           cloudinary_photo:
//                               userData.data.profile.cloudinary_photo,
//                       },
//                   },
//                   redemptions: null,
//                   products: null,
//                   resort_favorites: null,
//                   mountain_checklist: null,
//               };

//               // Now retrieve their products and redemptions (if they have any)
//               return Promise.all([
//                   dispatch(
//                       makeBasicAmpApiRequest(
//                           token,
//                           env.amp.userRedemptionsApi
//                       )
//                   ),
//                   dispatch(
//                       makeBasicAmpApiRequest(token, env.amp.userProductsApi)
//                   ),
//                   dispatch(
//                       makeBasicAmpApiRequest(
//                           token,
//                           env.amp.userResortFavoritesApi
//                       )
//                   ),
//                   dispatch(
//                       makeBasicAmpApiRequest(
//                           token,
//                           env.amp.userMountainChecklistApi
//                       )
//                   ),
//               ]).then((values) => {
//                   let [
//                       redemptions,
//                       products,
//                       resort_favorites,
//                       mountain_checklist,
//                   ] = values;

//                   if (redemptions && redemptions.data) {
//                       userObject.redemptions = {
//                           primary: getPrimaryUserRedemptions(
//                               userObject.me.profile.id,
//                               redemptions.data
//                           ),
//                           all: redemptions.data,
//                       };
//                       // softUserObject.redemptions = userObject.redemptions
//                   }

//                   if (products && products.data && products.data.length) {
//                       userObject.products = {
//                           primary: getPrimaryUserProduct(
//                               userObject.me.profile.id,
//                               products.data
//                           ),
//                           all: products.data,
//                       };
//                       // softUserObject.products = userObject.products
//                   }

//                   if (resort_favorites && resort_favorites.data) {
//                       userObject.resort_favorites = resort_favorites.data;
//                   }

//                   if (mountain_checklist && mountain_checklist.data) {
//                       userObject.mountain_checklist = mountain_checklist.data;
//                   }

//                   writeCrossDomainCookie(
//                       softLoginCookieName,
//                       JSON.stringify(softUserObject),
//                       365,
//                       null,
//                       null,
//                       null
//                   );

//                   dispatch(setUserLoggedInState(true));
//                   return dispatch(setLoggedInUserData(userObject));
//               });
//           } else {
//               console.log("User is not logged in, or an error occurred");
//               dispatch(setUserLoggedInState(false));
//               return dispatch(setLoggedInUserData(null));
//           }
//       }
//   );
// };

export const getAllUserData2 = (token) => {
  // Start by checking to see if they are logged in, and have access to their data
  return makeBasicAmpApiRequest2(token, env.amp.userApi).then((userData) => {
    if (testingFakeUserData) {
      console.log(
        "WARNING: testingFakeUserData is true and we are using mock data"
      );
      userData = fakeMeResponseJSON;
    }

    if (
      userData &&
      userData.data &&
      userData.data.profile &&
      !userData.errors.length
    ) {
      let userObject = {
        me: userData.data,
        redemptions: null,
        products: null,
        resortFavorites: null,
        mountainChecklist: null,
      };
      // soft login shouldn't have any personal info (eg. redeptions, products, etc.)
      let softUserObject = {
        me: {
          profile: {
            firstName: userData.data.profile.first_name,
            lastName: userData.data.profile.last_name,
            cloudinaryPhoto: userData.data.profile.cloudinary_photo,
          },
        },
        redemptions: null,
        products: null,
        resortFavorites: null,
        mountainChecklist: null,
      };

      // Now retrieve their products and redemptions (if they have any)
      return Promise.all([
        makeBasicAmpApiRequest2(token, env.amp.userRedemptionsApi),
        makeBasicAmpApiRequest2(token, env.amp.userProductsApi),
        makeBasicAmpApiRequest2(token, env.amp.userResortFavoritesApi),
        makeBasicAmpApiRequest2(token, env.amp.userMountainChecklistApi),
      ]).then((values) => {
        let [redemptions, products, resortFavorites, mountainChecklist] =
          values;

        if (testingFakeUserData) {
          console.log(
            "WARNING: testingFakeUserData is true and we are using mock data"
          );
          redemptions = fakeMyRedemptionsResponseJSON;
          products = fakeMyProductsResponseJSON;
          resortFavorites = fakeResortFavoritesResponseJSON;
          mountainChecklist = fakeMountainChecklistResponseJSON;
        }

        if (redemptions && redemptions.data) {
          userObject.redemptions = {
            primary: getPrimaryUserRedemptions(
              userObject.me.profile.id,
              camelizeKeys(redemptions.data)
            ),
            all: redemptions.data,
          };
          // softUserObject.redemptions = userObject.redemptions
        }

        if (products && products.data && products.data.length) {
          userObject.products = {
            primary: getPrimaryUserProduct(
              userObject.me.profile.id,
              camelizeKeys(products.data)
            ),
            all: products.data,
          };
          // softUserObject.products = userObject.products
        }

        if (resortFavorites && resortFavorites.data) {
          userObject.resortFavorites = resortFavorites.data;
        }

        if (mountainChecklist && mountainChecklist.data) {
          userObject.mountainChecklist = mountainChecklist.data;
        }

        writeCrossDomainCookie(
          softLoginCookieName,
          JSON.stringify(camelizeKeys(softUserObject)),
          365,
          null,
          null,
          null
        );
        return camelizeKeys(userObject); //setUserData(userObject);
      });
    } else {
      console.log("User is not logged in, or an error occurred");
      return null; //setUserData(null);
    }
  });
};

// export const makeBasicAmpApiRequest = (token, url) => (dispatch, getStore) => {
//   if (!url) {
//       console.log("URL is missing for fetch request");
//       return;
//   }

//   const xsrfVal = getCookieValue(env.amp.xsrfCookie) || "";

//   let headers = new Headers();
//   headers.append("Authorization", "Basic " + btoa(token));

//   // append XSRF header populated with the xsrf cookie value
//   if (xsrfVal) {
//       headers.append("X-CSRF-Token", xsrfVal);
//   }

//   return fetch(url, { credentials: "include", headers: headers })
//       .then((response) => {
//           if (response.ok) {
//               return response.json();
//           } else {
//               throw new Error(`Something went wrong fetching: ${url}`);
//           }
//       })
//       .then((data) => {
//           // console.log(`Fetch ${url} successful`)
//           return data;
//       })
//       .catch((error) => {
//           console.log(error);
//           return null;
//       });
// };

export const makeBasicAmpApiRequest2 = (token, url) => {
  if (!url) {
    console.log("URL is missing for fetch request");
    return;
  }

  const xsrfVal = getCookieValue(env.amp.xsrfCookie) || "";

  let headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(token));

  // append XSRF header populated with the xsrf cookie value
  if (xsrfVal) {
    headers.append("X-CSRF-Token", xsrfVal);
  }

  return fetch(url, { credentials: "include", headers: headers })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`Something went wrong fetching: ${url}`);
      }
    })
    .then((data) => {
      // console.log(`Fetch ${url} successful`)
      return data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const pingUserApi = () => {
  console.log("pingUserApi");
  if (testingFakeUserData) {
    return true;
  }
  let headers = new Headers();
  return fetch(env.amp.pingUrl, { credentials: "include", headers: headers })
    .then((response) => {
      if (response.ok) {
        // console.log('Ping success')
      } else {
        console.log("Something went wrong with ping");
      }
    })
    .catch((error) => {
      console.log("Ping user api error");
      console.log(error);
    });
};

// export const logoutUser = () => (dispatch, getStore) => {
//   // start by removing ping event from document so any more clicks don't ping the api
//   removeUserPingEvent();
//   generalTracking("userLogout", "Login State", "loginState", "logged-out");
//   deleteCrossDomainCookie("guest_summary"); // delete soft login cookie

//   // fade out the dropdown for a smoother experience
//   const accountDropdowns = document.getElementsByClassName(
//       "header-account-dropdown"
//   );
//   for (let i = 0; i < accountDropdowns.length; i++) {
//       accountDropdowns[i].classList.add("fade-out");
//   }
//   // we can make assumption this will work to avoid a possibly slow response
//   setTimeout(() => {
//       dispatch(setLoggedInUserData(null));
//       dispatch(setUserLoggedInState(false));
//       // and remove faded class
//       for (let i = 0; i < accountDropdowns.length; i++) {
//           accountDropdowns[i].classList.remove("fade-out");
//       }
//   }, 750);

//   let headers = new Headers();
//   return fetch(env.amp.logoutUrl, {
//       credentials: "include",
//       headers: headers,
//   })
//       .then((response) => {
//           if (response.ok) {
//               // console.log('Logout success')
//               // window.location.reload()
//               dispatch(setLoggedInUserData(null));
//               return dispatch(setUserLoggedInState(false));
//           } else {
//               console.log("Something went wrong");
//           }
//       })
//       .catch((error) => {
//           console.log("Log out user error");
//           console.log(error);
//       });
// };

export const logoutUser2 = () => {
  // start by removing ping event from document so any more clicks don't ping the api
  removeUserPingEvent();
  generalTracking("userLogout", "Login State", "loginState", "logged-out");
  deleteCrossDomainCookie("guest_summary"); // delete soft login cookie
  return fetch(env.amp.logoutUrl, {
    credentials: "include",
    headers: new Headers(),
  })
    .then((response) => {
      if (response.ok) {
        // window.location.reload()
        // dispatch(setLoggedInUserData(null));
        // return dispatch(setUserLoggedInState(false));
        return Promise.success("Logout success");
      } else {
        // console.log("Something went wrong");
        return Promise.reject(new Error("Something went wrong"));
      }
    })
    .catch((error) => {
      // console.log("Log out user error");
      console.log(error);
      return Promise.reject(new Error("Log out user error"));
    });
};

const removeUserPingEvent = () => {
  document.removeEventListener("click", pingUserApi);
};

export const doSoftLogin2 = (token) => {
  return makeBasicAmpApiRequest2(token, env.amp.softLoginApi)
    .then((response) => {
      if (response && response.data) {
        console.log("soft login ok");
        const softLoginData = setUpSoftLoginData(response.data);
        return softLoginData;
      } else {
        console.log("soft login response is not ok");
        return null;
      }
    })
    .catch((err) => {
      console.log("soft login request failed", err);
    });
};

const setUpSoftLoginData = (data) => {
  let userObject = {
    me: {
      profile: {
        firstName: data.first_name,
        lastName: data.last_name,
        cloudinaryPhoto: {
          publicId: data.photo_public_id,
          version: "1491915647",
        },
      },
    },
    redemptions: [],
    products: [],
    resortFavorites: [],
    mountainChecklist: null,
  };

  let profileId = null;
  if (data.profile_products && data.profile_products.length) {
    profileId = data.profile_products[0].profile_id;
    userObject.products = {
      primary: getPrimaryUserProduct(
        profileId,
        camelizeKeys(data.profile_products)
      ),
      all: data.profile_products,
    };
  }

  if (profileId && data.redemptions) {
    userObject.redemptions = {
      primary: getPrimaryUserRedemptions(
        profileId,
        camelizeKeys(data.redemptions)
      ),
      all: data.redemptions,
    };
  }

  if (data.resort_favorites) {
    userObject.resortFavorites = data.resort_favorites;
  }

  if (data.mountain_checklist) {
    userObject.mountainChecklist = data.mountain_checklist;
  }

  return camelizeKeys(userObject);
};
