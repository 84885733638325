// ==========================
// Initilizing the store to fetch/save a data from the CMS
// Steps
//    1. Create an exportable constant. This is used as the key to access the content in the store.
//          e.i. export const MY_DATA = 'myData'
//    2. Inside the `useStoreData` function and during the initilization of the 'store' variable,
//       add a default value for the newly fetched data.
//          e.i
//              const ...useRef({
//                  ...
//                  [MY_DATA]: null
//                  ...
//              })
//    3. Inside the `useStoreData` function, create/modify someFunction to include a `set` call that updates the value of the key
//           e.i
//               const someFunction = (data) => {
//                   ...
//                   set({ [MY_DATA]: data });
//                   ...
//               }
//    4. Add the created function inside the `setter` object in the return value of `useStoreData`.
//           e.i
//               return {
//                  ...
//                  setters: {
//                      ...,
//                      getNewlyFetchedData,
//                      ...
//                  },
//                  ...
//               }
// ==========================
// Using the context in a Functional Component:
// Steps
//    1. import `useAppContext` in the file
//            e.i. `import { useAppContext } from '../utils/context/AppContext';`
//    2. Initialized the the state variables
//            e.i. const [subContext, getterFunctions] = useAppContext(selectors)
//                      - subContext: the context you requested using the selector - the return value of your selector
//                      - getterFunctions: functions to fetch the data from the CMS (getThis, getThat, getThose),
//                                         it also saves the data into the store - to be accessed by values in the subContext
//                      - selectore: A function that takes a store and returns the subcontext
//            e.i. More example - grabs the locale content from the store and its fetcher function.
//                      const [
//                        { locale },
//                        { changeLocale }
//                      ] = useAppContext((store) => ({
//                        locale: store.locale
//                      }))
//            e.i. Using a Key example - Unifying the keys so you don't have to keep guesing
//                      const [
//                        { locale },
//                        { changeLocale }
//                      ] = useAppContext((store) => ({
//                        locale: store[LOCALE]
//                      }))
//           e.i. Renaming the store value - changing the store key (global) in the state key (local)
//                      const [
//                        { THIS_CAN_ALSO_CHANGE },
//                        { changeLocale }
//                      ] = useAppContext((store) => ({
//                        THIS_CAN_ALSO_CHANGE: store[LOCALE]
//                      }))
//    3. Fetched the data using the fetcher function
//           e.i. Fetch the data inside a useEffect
//                      useEffect(() => {
//                        changeLocale()
//                      }, [changeLocale])
//           e.i. Fetch the data inside an onclick method
//                      const someOnclick = () => {
//                          changeLocale()
//                      }
// ==========================
// Using the context in a Class Component - We're moving towards functional components... so you can just ignore this:
// Steps
//    1. Check AllContents.js if the the data is included in the selector. If so, skip Step 2
//    2. Include the data in the selector
//           e.i. ... = useAppContext((store) => ({
//                  ...
//                  locale: store[LOCALE]
//                  ...
//                })
//    3. Import `AllContenxt` in the file
//           e.i. `import { AllContexts } from '../utils/context/AllContexts';`
//    4. Inject AllContext to the context of the component.
//          e.i. ComponentClassName.contextType = AllContexts
//    5. Access the data and its fetcher functions using `this.context`
//           e.i. const { locale, changeLocale } = this.context;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  // useState,
} from "react";
import { updateCartCurrency2 } from "../amp-client";
import {
  writeCookie,
  getLocale,
  getCurrency,
  getQueryParamByName,
} from "../utils";
import { useCMSContent } from "./CMSContentContext";

import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";
import { getOffer } from "../../modules/at-events";

import env from "../../modules/environments";

const AppContext = createContext();

export const LOCALE = "locale";
export const CURRENCY = "currency";
export const SHOW_STICKY_CART_BAR = "showStickyCartBar";
export const SHOW_CART_POPUP = "showCartPopup";
export const MINI_CART_OPEN = "miniCartOpen";
export const HIDDEN_NAVIGATION = "hiddenNavigation";
export const SHOW_SEARCH = "showSearch";
export const BREADCRUMB_TITLE = "breadcrumbTitle";
export const ADOBE_TARGET_ACTIVITY_ID = "adobeTargetActivityID";

const useStoreData = () => {
  const [, { getCurrencyCodes, sanityRequest }] = useCMSContent(() => ({}));

  const store = useRef({
    [LOCALE]: getLocale(),
    [CURRENCY]: getCurrency(),
    [SHOW_STICKY_CART_BAR]: false,
    [SHOW_CART_POPUP]: false,
    [MINI_CART_OPEN]: false,
    [HIDDEN_NAVIGATION]: false,
    [SHOW_SEARCH]: false,
    [BREADCRUMB_TITLE]: "",
  });

  const get = useCallback(
    (keyword) => (keyword ? store?.current[keyword] : store.current),
    []
  );

  const subscribers = useRef(new Set());

  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    subscribers.current.forEach((callback) => {
      return callback();
    });
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  useEffect(() => {
    // Note - Carl: Adobe Target only works in English!
    if (get(LOCALE) == "en") {
      if (env.isProduction) {
        getOffer((adobeTargetActivityId) => {
          set({ [ADOBE_TARGET_ACTIVITY_ID]: adobeTargetActivityId });
        });
      } else {
        const atQuery = getQueryParamByName("at_query");
        if (atQuery) {
          set({ [ADOBE_TARGET_ACTIVITY_ID]: atQuery });
        }
      }
    }
  }, [get, set]);

  const changeLocale = (newLocale) => {
    if (get(LOCALE) !== newLocale) {
      const cookieName = "currentLocale";
      writeCookie(cookieName, newLocale, 5, null, null, null);
      set({ [LOCALE]: newLocale });
    }
  };

  const changeCurrency = async (newCurrency) => {
    if (get(CURRENCY) !== newCurrency) {
      // VP We need to make sure the Amp cart currency is synced
      // with ours.

      const [currencyCodes, newCartCurrency] = await Promise.all([
        sanityRequest(getCurrencyCodes()),
        updateCartCurrency2(newCurrency),
      ]);

      const lowerCaseCodes = currencyCodes.map((c) => c.toLowerCase());

      if (lowerCaseCodes.includes(newCartCurrency.toLowerCase())) {
        const cookieName = "currentCurrency";
        writeCookie(cookieName, newCartCurrency, 5, null, null, null);
        set({ [CURRENCY]: newCartCurrency });
      }
    }
  };

  const setCurrency = (value) => set({ [CURRENCY]: value });

  const setShowStickyCartBar = (value) =>
    set({ [SHOW_STICKY_CART_BAR]: value });

  const setShowCartPopup = (value) => set({ [SHOW_CART_POPUP]: value });

  const setMiniCartOpen = (value) => set({ [MINI_CART_OPEN]: value });

  const setHiddenNavigation = (value) => set({ [HIDDEN_NAVIGATION]: value });

  const setShowSearch = (value) => set({ [SHOW_SEARCH]: value });

  const setBreadcrumbTitle = (value) => set({ [BREADCRUMB_TITLE]: value });

  return {
    get,
    subscribe,
    setters: {
      changeLocale,
      changeCurrency,
      setCurrency,
      setShowStickyCartBar,
      setShowCartPopup,
      setMiniCartOpen,
      setHiddenNavigation,
      setShowSearch,
      setBreadcrumbTitle,
    },
  };
};

function AppContextProvider({ children }) {
  return (
    <AppContext.Provider value={useStoreData()}>{children}</AppContext.Provider>
  );
}

function useAppContext(selector) {
  const store = useContext(AppContext);

  if (store === undefined) {
    throw new Error("useApp must be used within a AppContextProvider");
  }

  const state = useSyncExternalStoreWithSelector(
    store.subscribe,
    store.get,
    null,
    selector,
    // Custom Equality
    (oldState, newState) => {
      let isEqual = true;

      Object.entries(oldState).forEach(([key, entry]) => {
        if (!Object.is(entry, newState[key])) {
          isEqual = false;
        }
      });

      return isEqual;
    }
  );

  return [state, store.setters];
}

function AppContextConsumer({ children }) {
  return (
    <AppContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            "AppContextConsumer must be used within a AppContextProvider"
          );
        }
        return children(context);
      }}
    </AppContext.Consumer>
  );
}

export {
  AppContext, // Imported directly into class-based components
  AppContextProvider, // Wraps components that need access to useApp
  useAppContext, // Custom hook to access context in function-based components
  AppContextConsumer, // Can be used to consume context in a class-based component, maybe unnecessary
};
