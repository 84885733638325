import imageProjection from "../items/image-projection";
import { getLocale } from "../../utils";
import passSideBySidePassBlockProjection from "./pass-side-by-side-pass-block-projection";

const locale = getLocale();

const passesSideBySideProjection = `{
  backgroundColor,
  backgroundImage ${imageProjection},
  mobileBackgroundImage ${imageProjection},
  'bluePromoBanner': bluePromoBanner.${locale},
  passBlocks[] -> {
    _type == 'passSideBySidePassBlock' => ${passSideBySidePassBlockProjection},
    _type == 'passBlockGroup' => {
      _id,
      _type,
      defaultSelectedPassBlock[0] -> ${passSideBySidePassBlockProjection},
      entryTitle,
      'groupTitle': groupTitle.${locale},
      passBlocks[] -> ${passSideBySidePassBlockProjection},
      'title': groupTitle.${locale},
    },
    _type == 'fillerObject' => {
      _type,
    }
  },
  style,
  _type,
  adobeTargetActivityID,
}`;

export default passesSideBySideProjection;
