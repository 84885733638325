const getSearchQuickLinksPageHits = (searchPageData, locale) => {
  const { searchQuickLinks = [] } = searchPageData || {};

  return searchQuickLinks.map((searchQuickLinkEntry) => {
    // define values from searchQuickLinkEntry
    const {
      _id: objectID,
      linkTitle: title = "",
      description: content = "",
      urlSlug: url = "",
    } = searchQuickLinkEntry;

    /*
        const {fields: searchQuickLink = {}} = searchQuickLinkEntry
        const {sys} = searchQuickLinkEntry
        const {id: objectID} = sys
        const {
            siteSearchDisplayTitle: title = '',
            siteSearchDisplayDescription: content = '',
            url = '',
        } = searchQuickLink
        */

    const getEllipsisText = (text) => {
      if (!text) {
        return "";
      }
      const splitWords = text.split(" ") || [];
      return splitWords.length <= 30
        ? splitWords.join(" ")
        : `${splitWords
            .slice(0, 30)
            .join(
              " "
            )} <ais-highlight-0000000000>[...]</ais-highlight-0000000000>`;
    };
    const lang = locale.slice(0, 2);
    return {
      objectID: `${objectID}-quick-link`,
      [`siteSearchDisplayTitle_${lang}`]: title,
      [`siteSearchDisplayDescription_${lang}`]: content,
      url,
      _highlightResult: {
        [`siteSearchDisplayTitle_${lang}`]: {
          value: getEllipsisText(title),
        },
      },
      _snippetResult: {
        [`siteSearchDisplayDescription_${lang}`]: {
          value: getEllipsisText(content),
        },
      },
    };
  });
};

export default getSearchQuickLinksPageHits;
