import React, { Component } from "react";
import { addLocaleUrl } from "../../../../utils/utils";
import Button from "../../../button";

export default class NavigationSecondaryCTAs extends Component {
  render() {
    const { links, locale } = this.props;

    if (!links || !(links.length > 0)) {
      return null;
    }

    return (
      <ul className="side-nav-content__cta-wrapper side-nav-content__cta-wrapper--secondary">
        {links.length > 0 &&
          links.map((link, i) => (
            <li
              key={`${i}-${link.entryTitle}`}
              className="side-nav-content__cta side-nav-content__cta--secondary"
            >
              <Button
                url={addLocaleUrl(locale, link.url)}
                text={link.title}
                type="plain"
              />
              {link.description && link.description.length > 0 && (
                <p>{link.description}</p>
              )}
            </li>
          ))}
      </ul>
    );
  }
}
