import { getLocale } from "../../utils";

const locale = getLocale();

const affirmCalloutProjection = `{
    'affirmCalloutCTA': affirmCalloutCTA.${locale},
    affirmClass,
    affirmColor,
    'affirmLeadText': affirmLeadText.${locale},
    'affirmModalButtonText': affirmModalButtonText.${locale},
    affirmPageType,
    'affirmTrailingText': affirmTrailingText.${locale},
    affirmType,
    entryTitle,
    hasTooltip,
    productCodeForAmount,
    productVariant,
    showAffirmModal,
    'tooltipContent': tooltipContent.${locale},
    _id,
    _ref,
    _type
  }`;

export default affirmCalloutProjection;
