import { getLocale } from "../../utils";

const locale = getLocale();

const imageProjection = `{
  _id,
  asset ->,
  'alt': alt.${locale},
  crop,
  hotspot
}
`;

export default imageProjection;
