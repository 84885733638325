import flexItemProjection from "./flex-item-projection";
import headingOverrideProjection from "./heading-override-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const flexGroupProjection = `{
  _id,
  'header': header.${locale},
  headlineOverride ${headingOverrideProjection},
  headerBackgroundImage,
  modalCardGroup,
  vectorMountainBg,
  'subheader': subheader.${locale},
  flexList[] -> ${flexItemProjection}
}`;

export default flexGroupProjection;
