import genericHeroProjection from "../modules/generic-hero-projection";
import seoProjection from "../seo-projection";
import buttonProjection from "../modules/button-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

const allEventsPageProjection = `{
  _id,
  hero -> ${genericHeroProjection},
  seo ${seoProjection},
  eventSlider {
    cta -> ${buttonProjection},
    'headline':headline.${locale},
    'leadText': leadText.${locale}
  }
}`;

export default allEventsPageProjection;
