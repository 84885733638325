import { getDaysUsedForActivePass } from "../../../../../utils/users";

const Stats = ({
  userData,
  content,
  canRenew,
  showFriendsAndFam,
  userHasProducts,
}) => {
  const shouldRender =
    userHasProducts &&
    userData.redemptions &&
    userData.redemptions.primary &&
    !canRenew;

  if (!shouldRender) {
    return null;
  }

  return (
    <div className="account-dropdown-stats">
      <div className="user-stat">
        <div className="user-stat-num">
          {getDaysUsedForActivePass(
            userData.redemptions.primary.products,
            userData.products.primary
          )}
        </div>
        <div className="user-stat-desc">
          {content.passDaysUsed && content.passDaysUsed}
        </div>
      </div>
      {userData.redemptions.primary.products &&
      userData.redemptions.primary.products.length &&
      showFriendsAndFam ? (
        <div className="user-stat">
          <div className="user-stat-num">
            {userData.redemptions.primary.products.forEach((item, i) => {
              // make sure we are looking at the active pass by checking season and name
              if (
                item.season === userData.products.primary.season &&
                item.productName === userData.products.primary.productName &&
                item.profileProductId === userData.products.primary.id
              ) {
                return (
                  <div key={i} className="user-stat-num">
                    {item.maxVouchers &&
                      item.voucherRedemptions &&
                      item.maxVouchers - item.voucherRedemptions.length}
                    <span className="user-stat-num-fraction">
                      /{item.maxVouchers && item.maxVouchers}
                    </span>
                  </div>
                );
              }
            })}
          </div>

          <div className="user-stat-desc">
            {content.friendsAndFamily && content.friendsAndFamily}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Stats;
