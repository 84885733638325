import { getLocale } from "../utils";
import imageProjection from "./items/image-projection";

const locale = getLocale();

const seoProjection = `{
  'pageTitle': pageTitle.${locale},
  siteMapPriority,
  hideFromSiteMap,
  'metaDescription': metaDescription.${locale},
  metaImage ${imageProjection},
  'schemaOrg': schemaTags,
}
`;

export default seoProjection;
