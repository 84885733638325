import React from "react";
// import Helmet from "react-helmet";
///import {breadcrumbTitle} from '../../containers/app/selectors'
//import {createPropsSelector} from 'reselect-immutable-helpers'
//import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";
import { AllContexts } from "../../utils/context/AllContexts";

class Breadcrumbs extends React.Component {
  constructor() {
    super();

    this.state = {
      segments: [],
      segmentPath: [`${window.location.origin}`],
      pageTitle: null,
    };

    this.getPath = this.getPath.bind(this);
    this.getSegmentName = this.getSegmentName.bind(this);
    this.generateBreadcrumbTitle = this.generateBreadcrumbTitle.bind(this);
  }

  getPath(lastBreadcrumbTitle = null) {
    const fullPath = this.props.location.pathname;
    const pathSegments = fullPath.split("/");
    let isFrench =
      pathSegments.length > 1 && pathSegments[1].toLowerCase() === "fr";
    let segments = [];
    let segmentPath = [`${window.location.origin}/`];
    pathSegments.forEach((segment, i) => {
      const segmentName = this.getSegmentName(segment);

      if (segment !== "") {
        segmentPath.push(`${segmentPath[i - 1]}${segment}/`);
      }

      // Add home (if longer than 2 (/en/whatever))
      if (pathSegments.length > 2 && i === 0) {
        segments.push({
          name: isFrench ? "Accueil" : "Home",
        });
        // segmentPath.push('/')
      }

      if (segment !== "" && segment !== "en" && segment !== "fr") {
        segments.push({
          // we want to use the proper title for the actual page
          name:
            i === pathSegments.length - 1 && lastBreadcrumbTitle
              ? lastBreadcrumbTitle
              : segmentName,
          // name: segmentName,
        });

        // segmentPath.push(`${segment}/`)
        this.setState({
          segments,
          segmentPath,
        });
      }
    });
  }

  generateBreadcrumbTitle(match, offset) {
    return match.toUpperCase();
  }

  // turn segments into their titles
  getSegmentName(segment, i) {
    const checkHyphen = /-/g;
    const checkFirst = /\b[a-zA-Z0-9]/g;
    const cleanName = segment
      .replace(checkHyphen, " ")
      .replace(checkFirst, this.generateBreadcrumbTitle);
    return cleanName;
  }

  getMetaData() {
    // Get the proper meta tag instead of simply transforming the URL
    // This fires too early
    // const helmetData = Helmet.peek();
    // var result = helmetData.metaTags.find((obj) => {
    //   return obj.name === "og:title";
    // });
  }

  // Make sure path segments are empty
  clearPath() {
    this.setState({
      segments: [],
    });
  }

  // Make sure the breadcrumbs update even if the footer doesn't
  componentDidUpdate(prevProps) {
    const { pageSeo } = this.context;
    const pageTitle = pageSeo?.pageTitle;
    if (pageTitle && !this.state.pageTitle) {
      this.setState({ pageTitle });
      this.clearPath();
      this.getPath(pageTitle);
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.clearPath();
      this.getPath();
    }
  }

  /* MP 20220804 - I believe we do not need this anymore
    componentWillReceiveProps(nextProps) {
        // first run through where title doesn't yet exist
        if (!this.props.breadcrumbTitle && nextProps.breadcrumbTitle) {
            this.getPath(nextProps.breadcrumbTitle)
        }
        // if the breadcrumb title gets changed, we need to update the title to match
        if (this.props.breadcrumbTitle && nextProps.breadcrumbTitle && this.props.breadcrumbTitle !== nextProps.breadcrumbTitle) {
            this.getPath(nextProps.breadcrumbTitle)
        }
    }
    */

  componentDidMount() {
    const { pageSeo } = this.context;

    const pageTitle = pageSeo?.pageTitle;
    this.setState({ pageTitle });
    this.getPath();
  }

  render() {
    if (!this.state.pageTitle) return null;

    if (this.state.segments.length) {
      return (
        <div className="breadcrumbs">
          <ul
            className="breadcrumbs__list"
            vocab="https://schema.org/"
            typeof="BreadcrumbList"
          >
            {this.state.segments.map((segment, i) => {
              if (segment.name.indexOf("?")) {
                segment.name = segment.name.split("?")[0];
              }

              // Markup based on google's recommendation for breadcrumbs
              return (
                <li
                  className="breadcrumbs__breadcrumb"
                  key={i}
                  property="itemListElement"
                  typeof="ListItem"
                >
                  <a
                    href={this.state.segmentPath[i + 1]}
                    property="item"
                    typeof="WebPage"
                  >
                    <span property="name">{segment.name}</span>
                  </a>
                  <meta property="position" content={i} />
                  {/* This sort of worked, but fires too early */}
                  {/* { Helmet.peek().metaTags[1].content } */}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return "";
    }
  }
}

/*
const mapStateToProps = createPropsSelector({
    breadcrumbTitle: breadcrumbTitle
})
*/

Breadcrumbs.contextType = AllContexts;

//export default withRouter(connect(mapStateToProps)(Breadcrumbs))
export default withRouter(Breadcrumbs);
