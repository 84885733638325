import React from "react";
import { Configure } from "react-instantsearch-dom";
import { CUSTOM_ELLIPSIS_TEXT } from "../../constants";

const ConfigureFaqs = ({ locale }) => (
  <Configure
    hitsPerPage={6}
    attributesToRetrieve={[`title_${locale}`, `rawContent_${locale}`]}
    attributesToHighlight={[`title_${locale}:100`]}
    attributesToSnippet={[`title_${locale}:30`, `rawContent_${locale}:30`]}
    restrictSearchableAttributes={[`title_${locale}`, `content_${locale}`]}
    filters="NOT removeFromSearch:true"
    snippetEllipsisText={CUSTOM_ELLIPSIS_TEXT}
    queryLanguages={[locale === "fr-CA" ? "fr" : "en"]}
  />
);
export default ConfigureFaqs;
