import adventuresProjection from "../lists/adventures-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const adventureRotatorProjection = `
  adventureRotator->{
    _id,
    _type,
    'title': entryTitle,
    'addToWishlistText': addToWishlistText.${locale},
    'detailsButtonText': detailsButtonText.${locale},
    slug,
    ${adventuresProjection}
}`;

export default adventureRotatorProjection;
