import { getLocale } from "../utils";
import imageProjection from "./items/image-projection";
import passCartWidgetStringsProjection from "./items/pass-cart-widget-strings-projection";

const locale = getLocale();

const sitePreferencesProjection = `{
  _id,
  _type,
  activeSeason,
  'addToWishlistText': addToWishlistText.${locale},
  'addToFavoritesText': addToFavoritesText.${locale},
  'accessDetails': {
    'reservationsRequiredText': accessDetailsReservationsRequiredText.${locale},
    'destinationsFilterText': accessDetailsDestinationsFilterText.${locale},
    'destinationsAccessFilterText': accessDetailsDestinationAccessesFilterText.${locale},

    'unlimitedText': accessDetailsUnlimitedText.${locale},
    'unlimitedTextMobile': accessDetailsUnlimitedTextMobile.${locale},
    'accessDetailsBlackoutDatesText': accessDetailsBlackoutDatesText.${locale},
    'accessDetailsNoAccessText': accessDetailsNoAccessText.${locale},
    'accessDetailsNoBlackoutText': accessDetailsNoBlackoutText.${locale},

    'destinationAccessDetailsTitle': destinationAccessDetailsTitle.${locale},
    destinationAccessDetailsYear,
    'accessDetailsDaysText': accessDetailsDaysText.${locale},
    'accessDetails4DayText': accessDetails4DayText.${locale},

    'blackoutAccordionHeader': accessDetailsBlackoutAccordionHeader.${locale},

    'filterDropdown': {
      'filterDropdownTitle': accessDetailsFilterDropdownTitle.${locale},
      'unlimitedFilterText': accessDetailsFilterDropdownUnlimitedFilterText.${locale},
      'liftReservationsRequiredFilterText': accessDetailsFilterDropdownLiftReservationsRequiredFilterText.${locale},
      'alphabeticalFilterText': accessDetailsFilterDropdownAlphabeticalFilterText.${locale},
    },
  },
  'accessDetailsSpecialCondition': accessDetailsSpecialCondition.${locale},
  'accessDetailsNorthernHemisphereText': accessDetailsNorthernHemisphereText.${locale},
  'accessDetailsSouthernHemisphereText': accessDetailsSouthernHemisphereText.${locale},
  // 'accessDetailsUnlimitedText': accessDetailsUnlimitedText.${locale},
  'accessDetailsIkon4DayPassTitleLink': accessDetailsIkon4DayPassTitleLink,
  'accessDetailsIkonBasePassTitleLink': accessDetailsIkonBasePassTitleLink,
  'accessDetailsIkonPassTitleLink': accessDetailsIkonPassTitleLink,
  'accessDetailsTitleTextForDestinations': accessDetailsTitleTextForDestinations.${locale},
  androidStoreBadge ${imageProjection},
  'androidStoreUrl': androidStoreUrl.${locale},
  'androidStoreCaUrl': androidStoreCaUrl.${locale},
  appleStoreBadge ${imageProjection},
  'appleStoreUrl': appleStoreUrl.${locale},
  'appleStoreCaUrl': appleStoreCaUrl.${locale},
  'cartPopupButtonText': cartPopupButtonText.${locale},
  'cartPopupCurrencyChangedText': cartPopupCurrencyChangedText.${locale},
  'CartPopupFadeOutTime': CartPopupFadeOutTime.${locale},
  'cartPopupItemAddedText': cartPopupItemAddedText.${locale},
  'cartPopupItemRemovedText': cartPopupItemRemovedText.${locale},
  'changedText': changedText.${locale},
  'daysConsideredNew': daysConsideredNew.${locale},
  'fiveDaysText': fiveDaysText.${locale},
  'liftsOpenText': liftsOpenText.${locale},
  'newText': newText.${locale},
  'newToPassText': newToPassText.${locale},
  'notAvailableTitle': notAvailableTitle.${locale},
  'notAvailableDescription': notAvailableDescription.${locale},
  'oneDayText': oneDayText.${locale},
  passCartWidgets -> ${passCartWidgetStringsProjection},
  'removeFromFavoritesText': removeFromFavoritesText.${locale},
  'removeFromWishlistText': removeFromWishlistText.${locale},
  'seasonTotalText': seasonTotalText.${locale},
  'sessionPassDaysText': sessionPassDaysText.${locale},
  'threeDaysText': threeDaysText.${locale},
  'twoDaysText': twoDaysText.${locale},
  'benefitCategory': {
    'filterByText': benefitCategoryFilterByText.${locale},
    'allText': benefitCategoryAllText.${locale}
  }
}`;

export default sitePreferencesProjection;
