import classNames from "classnames";
import {
  ABANDON_CART_PREVIEW_MODE,
  AMP_PREVIEW_MODE,
  PREVIEW_MODE,
  OVER_PREVIEW_DOCUMENT_LIMIT,
  useCMSContent,
} from "../../utils/context/CMSContentContext";
import { deleteCookie } from "../../utils/utils";
import downArrowBlue from "../../static/images/svg/down-arrow-blue.svg";
import { useState } from "react";

import "./styles.scss";

const ContentPreview = () => {
  const [
    {
      abandonCartPreviewMode,
      ampPreviewMode,
      previewMode,
      overPreviewDocumentLimit,
    },
    { setAbandonCartPreviewMode, setAmpPreviewMode, setPreviewMode },
  ] = useCMSContent((store) => ({
    abandonCartPreviewMode: store[ABANDON_CART_PREVIEW_MODE],
    ampPreviewMode: store[AMP_PREVIEW_MODE],
    previewMode: store[PREVIEW_MODE],
    overPreviewDocumentLimit: store[OVER_PREVIEW_DOCUMENT_LIMIT],
  }));
  const [open, setOpen] = useState(true);
  const isUsingDevelopmentDataset =
    process.env.REACT_APP_DATASET === "development-2024" || false;

  if (
    !previewMode &&
    !abandonCartPreviewMode &&
    !ampPreviewMode &&
    !overPreviewDocumentLimit &&
    !isUsingDevelopmentDataset
  ) {
    return null;
  }

  const exitPreview = () => {
    deleteCookie("preview");
    setPreviewMode(false);
    const url = new URL(window.location);
    url.searchParams.delete("preview");

    window.location.href = url;
  };

  const exitAbandonCartPreview = () => {
    deleteCookie("abandon-cart");
    setAbandonCartPreviewMode(false);
    const url = new URL(window.location.href);

    url.searchParams.delete("abandon-cart");
    window.location.href = url;
  };

  const exitAmpPreview = () => {
    deleteCookie("preview-amp");
    setAmpPreviewMode(false);

    const url = new URL(window.location);
    url.searchParams.delete("preview-amp");

    window.location.href = url;
  };

  const toggleUI = () => {
    setOpen(!open);
  };

  const componentClasses = classNames("content-preview", {
    "content-preview--open": open,
  });

  if (overPreviewDocumentLimit) {
    return (
      <div className={componentClasses}>
        Unable to activate Live Preview mode: too many documents.{" "}
        <button onClick={exitPreview}>Exit</button>
        <button
          aria-label="Hide preview toggle"
          className="content-preview__toggle"
          onClick={toggleUI}
        >
          <img src={downArrowBlue} alt="" aria-label="Down Arrow" />
        </button>
      </div>
    );
  } else {
    return (
      <div className={componentClasses}>
        {previewMode && (
          <div>
            Preview mode active!
            <button
              className="amp-button small"
              aria-label="Exit Preview Mode"
              onClick={exitPreview}
            >
              Exit
            </button>
          </div>
        )}
        {abandonCartPreviewMode && (
          <div>
            Abandon Cart Modal preview is active!
            <button
              aria-label="Exit Abandoned Cart Preview"
              className="amp-button small"
              onClick={exitAbandonCartPreview}
            >
              Exit
            </button>
          </div>
        )}
        {ampPreviewMode && (
          <div>
            Amp is using preview data{" "}
            <button
              className="amp-button small"
              aria-label="Exit Amp Preview Data"
              onClick={exitAmpPreview}
            >
              Exit
            </button>
          </div>
        )}
        {isUsingDevelopmentDataset && (
          <div>Reading data from the development dataset.</div>
        )}
        <button
          aria-label="Hide preview toggle"
          className="content-preview__toggle"
          onClick={toggleUI}
        >
          <img src={downArrowBlue} alt="" aria-label="Down Arrow" />
        </button>
      </div>
    );
  }
};

export default ContentPreview;
