import { getLocale } from "../../utils";

const locale = getLocale();

const promoInformationBoxProjection = `{
  'header': header.${locale},
  'instructions': instructions.${locale},
  'disclaimer': disclaimer.${locale}
}`;

export default promoInformationBoxProjection;
