import React, { Component } from "react";
// import { addLocaleUrl } from "../../../../modules/utils";
// import NavFaqItem from "../nav-faq-group/nav-faq-item";
// import Button from "../../../button/index";
// import classNames from "classnames";
import Flyout from "../flyout";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { addLocaleUrl } from "../../../../../utils/utils";
import Button from "../../../../button";

class FaqGroup extends Component {
  state = {
    isOpen: false,
    activeFaqItems: [],
  };

  onToggle = (faqItemId = null) => {
    this.setState(
      ({ activeFaqItems }) => {
        const isAlreadyItemOpen = activeFaqItems.includes(faqItemId);

        if (faqItemId == null) {
          // FAQ Flyout Close
          return {
            isOpen: false,
            activeFaqItems: [],
          };
        } else {
          // FAQ Item Click -> Flyout Open
          return {
            isOpen: true,
            activeFaqItems: isAlreadyItemOpen
              ? activeFaqItems.filter((id) => id !== faqItemId)
              : [...activeFaqItems, faqItemId],
          };
        }
      },
      () => {
        // Callback -> adjust positioning
        console.log("FAQ Toggle Callback");
      }
    );

    // if (idx < 0) {
    //   // closing faq flyout
    //   this.setState({
    //     isOpen: false,
    //     faqActiveItemIndicies: [],
    //   });
    //   var ele = document.querySelector(".header-account-dropdown.open");
    //   ele.scrollTop = this.state.scrollPosition ? this.state.scrollPosition : 0;
    // } else {
    //   // opening faq flyout
    //   let faqActiveItemIndiciesCopy = this.state.faqActiveItemIndicies.slice();
    //   if (shouldRemoveIdx) {
    //     // remove the idx from the state so that it in considered inactive
    //     faqActiveItemIndiciesCopy = faqActiveItemIndiciesCopy.filter(function (
    //       itm
    //     ) {
    //       return itm !== idx;
    //     });
    //   } else {
    //     // add the idx to the state as active
    //     faqActiveItemIndiciesCopy.push(idx);
    //   }
    //   // scroll the parent to the top or it will cut off the flyout
    //   var ele = document.querySelector(".header-account-dropdown.open");
    //   this.setState({
    //     isOpen: true,
    //     faqActiveItemIndicies: faqActiveItemIndiciesCopy,
    //     scrollPosition: ele.scrollTop,
    //   });
    //   ele.scrollTop = 0;
    // }
  };

  renderFaqItemQuestion(key, index, faqItem) {
    const { title } = faqItem;
    return (
      <div
        key={key}
        className="faq-item-question-container"
        onClick={() => this.onToggle(index)}
      >
        <p className="faq-item-question">{title}</p>
        <i className="icon-chevron-right faq-chevron" />
      </div>
    );
  }

  renderFaqItenAnswer(key, faqItem) {
    const { content } = faqItem;
    if (this.state.activeFaqItems.includes(key)) {
      return (
        <div key={key} className="faq-item-answer-container">
          <ReactMarkdown
            className="faq-item-answer"
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          >
            {content}
          </ReactMarkdown>
        </div>
      );
    }
  }

  renderFaqCTA() {
    const {
      locale,
      content: { faqCta },
    } = this.props;

    if (faqCta) {
      const { title, url } = faqCta;

      return (
        <div className="faq-cta-container">
          <Button url={addLocaleUrl(locale, url)} text={title} type="plain" />
        </div>
      );
    }
  }

  render() {
    const {
      content: { title, faqList },
    } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="account-dropdown-faq-group">
        <div>
          <p className="faq-group-title">{title}</p>
        </div>
        {faqList && (
          <div className="faq-list">
            {faqList.map((faqItem, index) => {
              return this.renderFaqItemQuestion(
                `faq-item-question-${index}`,
                `faq-item-answer-${index}`,
                faqItem
              );
            })}
          </div>
        )}
        {this.renderFaqCTA()}

        <Flyout
          isOpen={isOpen}
          onBackButtonClick={() => this.onToggle()}
          onCloseButtonClick={() => this.onToggle()}
        >
          <div className="flyout-title">
            <p>{title}</p>
          </div>
          {faqList.map((faqItem, index) => {
            return (
              <>
                <div className="faq-list">
                  {this.renderFaqItemQuestion(
                    `faq-item-question-${index}`,
                    `faq-item-answer-${index}`,
                    faqItem
                  )}
                  {this.renderFaqItenAnswer(
                    `faq-item-answer-${index}`,
                    faqItem
                  )}
                </div>
                {this.renderFaqCTA()}
              </>
            );
          })}
        </Flyout>
      </div>
    );
  }
}

export default FaqGroup;
