import { getLocale } from "../../utils";
import buttonProjection from "../modules/button-projection";
import headingOverrideProjection from "../modules/heading-override-projection";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const cardItemsProjection = `
'stories': destinationStories->stories[]->{
  _id,
  'headline': headline.${locale},
  headlineOverride ${headingOverrideProjection},
  cta->${buttonProjection},
  image ${imageProjection},
  'altText': image->alt.${locale},
  'category': postCategory[0]->title.${locale},
  'link': postLink
}`;

export default cardItemsProjection;
