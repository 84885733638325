import columnedContentProjection from "../modules/columned-content-projection.js";
import faqGroupProjection from "../modules/faq-group-projection.js";
import genericHeroProjection from "../modules/generic-hero-projection.js";
import imageProjection from "../items/image-projection.js";
import { getLocale } from "../../utils";
import seoProjection from "../seo-projection.js";
import vectorMountainsProjection from "../modules/vector-mountains-projection.js";
import videoEmbedProjection from "../modules/video-embed-projection.js";

const locale = getLocale();

const appPageProjection = `{
  hero -> ${genericHeroProjection},
  sections[] -> {
    _id,
    _type,
    _type == 'columnedContent' => ${columnedContentProjection},
    _type == 'faqGroup' => ${faqGroupProjection},
    _type == 'vectorMountains' => ${vectorMountainsProjection},
    _type == 'featureListMedia' => {
      _id,
      lightSection,
      textSide,
      'title': title.${locale},
      'listItems': listItems.${locale},
      background {
        _type,
        asset ->
      },
      backgroundPoster ${imageProjection},
      mobileBackground {
        _type,
        _id,
        asset ->
      },
      mobileBackgroundPoster ${imageProjection},
      hasDiagonalBottom,
      diagonalDirection
    },
    _type == 'videoEmbed' => ${videoEmbedProjection}
  },
  seo ${seoProjection}
}`;

export default appPageProjection;
