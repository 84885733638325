import { getClient } from "../../client";
import ImageUrlBuilder from "@sanity/image-url";
import {
  PREVIEW_MODE,
  useCMSContent,
} from "../../utils/context/CMSContentContext";

const SanityImage = (props) => {
  const {
    // _id,
    image,
    // layout,
    // sizes,
    // format,
    width,
    height,
    className,
    // objectFit,
    alt,
    // noNext,
    mobileAt,
    mobileWidth,
    mobileHeight,
    noFigure,
    loading,
    quality,
  } = props;

  const [{ previewMode }] = useCMSContent((store) => ({
    previewMode: store[PREVIEW_MODE],
  }));
  const builder = ImageUrlBuilder(getClient(previewMode));

  const urlFor = (source) => {
    return builder.image(source);
  };

  if (image === undefined || !image) {
    return null;
  }

  if (!image.asset) {
    return null;
  }

  const altProp = alt || image.alt || image?.asset?.altText;

  /* next/image complains on SVG's, output normal <img> tag */
  if (image.asset.extension === "svg") {
    return (
      <img
        id={image.asset._ref}
        src={image.asset.url}
        width={width}
        height={height}
        alt={altProp || ""}
        loading={loading}
      />
    );
  }

  let src = urlFor(image)
    .auto("format")
    .quality(quality || 75)
    .width(width || 2048)
    .height(height ? height : undefined)
    .fit("clip");

  let widthProp, heightProp;

  widthProp = width || image.asset.metadata.dimensions.width;
  heightProp = height || image.asset.metadata.dimensions.height;

  /* Escape hatch for when next/image just doesn't want to play nice, or there is custom cropping in the CMS so you don't know the width or height */
  // This could use some work
  if (!mobileWidth) {
    const figureStyle = {
      // backgroundImage: `url(${image.asset.lqip})`,
    };

    if (!noFigure) {
      return (
        <figure
          className="image-blur-container full"
          style={figureStyle}
          id={image.asset._id}
        >
          <img
            src={`${src.url()}`}
            alt={altProp || ""}
            className={className}
            width={widthProp}
            height={heightProp}
            loading={loading}
          />
        </figure>
      );
    } else {
      return (
        <img
          id={image.asset._id}
          src={`${src.url()}`}
          alt={altProp || ""}
          className={className}
          width={widthProp}
          height={heightProp}
          loading={loading}
        />
      );
    }
  }

  /* We sometimes have mobile specific cropping we need to specify, in which case use figure/srcset */
  if (mobileAt && mobileWidth && mobileHeight) {
    const figureStyle = {
      // backgroundImage: `url(${image.asset.metadata.lqip})`,
      // zIndex: -1
    };

    let mobileSrc = urlFor(image)
      .auto("format")
      .quality(65)
      .width(mobileWidth)
      .height(mobileHeight);

    return (
      <figure
        className="image-blur-container full"
        style={figureStyle}
        id={image.asset._id}
      >
        <picture>
          <source
            srcSet={`${src.url()}`}
            media={`(min-width: ${mobileAt}px)`}
          />
          <img
            src={`${mobileSrc.url()}`}
            alt={altProp || ""}
            className={className}
            loading={loading}
          />
        </picture>
      </figure>
    );
  }

  // this was pulled from cmh_heli_next. i believe this swapped out the lqip version with the proper one when the user scrolled into view.
  // const onLoadingCompleteProp = () => {
  //   // fancy function check stolen from https://stackoverflow.com/a/7356528
  //   if (
  //     onLoadingComplete &&
  //     {}.toString.call(onLoadingComplete) === "[object Function]"
  //   ) {
  //     onLoadingComplete();
  //   }
  // };
};

export default SanityImage;
