import React from "react";
import { Index, Stats } from "react-instantsearch-dom";
import { connectStateResults } from "react-instantsearch-dom";

//import {connect} from 'react-redux'
//import {createPropsSelector} from 'reselect-immutable-helpers'
//import { sitePreferences } from '../../../app/selectors'
import {
  SEARCH_SETTINGS,
  useCMSContent,
} from "../../../../utils/context/CMSContentContext";
// import { useEffect, useState } from "react";

const DisplayingHitResultsCount = ({ section, searchState }) => {
  const [{ searchSettings }] = useCMSContent((store) => ({
    searchSettings: store[SEARCH_SETTINGS],
  }));

  const { searchDisplayingText = "", searchQuickLinks = [] } =
    searchSettings || {};
  const { query = "" } = searchState || {};
  const showQuickLinks = query.length === 0 && searchQuickLinks.length > 0;
  if (showQuickLinks) {
    return null;
  }
  return (
    <Index indexName={section.index}>
      <Stats
        translations={{
          stats(nbHits) {
            const lowerBound = nbHits >= 3 ? 3 : nbHits;
            return searchDisplayingText
              .replace("?", lowerBound)
              .replace("?", nbHits);
          },
        }}
      />
    </Index>
  );
};

//const mapStateToProps = createPropsSelector({sitePreferences})
//export default connectStateResults(connect(mapStateToProps)(DisplayingHitResultsCount))

export default connectStateResults(DisplayingHitResultsCount);
