import searchQuickLinkProjection from "../modules/search-quick-link-projection.js";
import seoProjection from "../seo-projection.js";
import { getLocale } from "../../utils";

const locale = getLocale();

const searchPageProjection = `{
  _id,
  _type,
  'searchPagesTitle': searchPagesTitle.${locale},
  'searchFaqsTitle': searchFaqsTitle.${locale},
  'searchQuickLinksTitle': searchQuickLinksTitle.${locale},
  'searchDisplayingText': searchDisplayingText.${locale},
  'searchDisplayingTotalText': searchDisplayingTotalText.${locale},
  'searchLoadMoreText': searchLoadMoreText.${locale},
  'searchPageHeading': searchPageHeading.${locale},
  'searchPagePlaceholder': searchPagePlaceholder.${locale},
  searchQuickLinks[] -> ${searchQuickLinkProjection},
  seo ${seoProjection}
}`;

export default searchPageProjection;
