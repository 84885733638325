import columnedContentProjection from "../modules/columned-content-projection";
import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const itineraryContentBlockProjection = `{
  _id,
  _type,
  _type == 'adventureItineraryItemDestination' => {
    _id,
    _type,
    entryTitle,
    'heading': heading.${locale},
    heroImage ${imageProjection},
    resortLogo -> {
      coBrandedCartColor,
      coBrandedLogo ${imageProjection},
      colorLogo ${imageProjection},
      darkLogo ${imageProjection},
      logo ${imageProjection},
    },
    url,
  },
  _type == 'columnedContent' => ${columnedContentProjection},
}`;

export default itineraryContentBlockProjection;
