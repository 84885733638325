import headingOverrideProjection from "../modules/heading-override-projection";
import regionProjection from "../modules/region-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

const countriesProjection = `
*[_type == "country"]|order(orderRank asc) {
  _type,
  _id,
  'title': name.${locale},
  headlineOverride ${headingOverrideProjection},
  'regions': *[
    _type == 'region' &&
    references(^._id)
  ]|order(orderRank asc)${regionProjection}
}`;

export default countriesProjection;
