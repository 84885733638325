import { getLocale } from "../../utils";
import buttonProjection from "../modules/button-projection";
import destinationsNameProjection from "../lists/destinations-name-projection";
import genericHeroProjection from "../modules/generic-hero-projection";
import imageProjection from "../items/image-projection";
import videoProjection from "../items/video-projection";
import seoProjection from "../seo-projection";

const locale = getLocale();

const homePageProjection = `
{
  _id,
  _type,
  seo ${seoProjection},
  hero ${genericHeroProjection},
  heroAdobeTarget[] -> ${genericHeroProjection},
  isCurrentPage,
  globeSection-> {
    'cta': cta -> ${buttonProjection},
    'description': description.${locale},
    'imageDesktop': imageDesktop ${imageProjection},
    'title': title.${locale}
  },
  adventureSection-> {
    'cta': cta -> ${buttonProjection},
    'description': description.${locale},
    'imageDesktop': imageDesktop ${imageProjection},
    'title': title.${locale}
  },
  appSection-> {
    'cta': cta -> ${buttonProjection},
    'headline': headline.${locale},
    'description': description.${locale},
    'image': image ${imageProjection},
    'imageDesktop': imageDesktop ${imageProjection},
    'imageMobile': imageMobile ${imageProjection},
    'videoOne': videoOne ${videoProjection},
    'videoOneURL': videoOneURL.${locale},
    'videoOneFrench': videoOneFrench ${videoProjection},
    'videoOnePoster': videoOnePoster ${imageProjection},
    'videoOnePosterFrench': videoOneFrenchPoster ${imageProjection},
    'videoTwo': videoTwo ${videoProjection},
    'videoTwoURL': videoTwoURL.${locale},
    'videoTwoFrench': videoTwoFrench ${videoProjection},
    'videoTwoPoster': videoTwoPoster ${imageProjection},
    'videoTwoPosterFrench': videoTwoFrenchPoster ${imageProjection},
    'videoThree': videoThree ${videoProjection},
    'videoThreeURL': videoThreeURL.${locale},
    'videoThreeFrench': videoThreeFrench ${videoProjection},
    'videoThreePoster': videoThreePoster ${imageProjection},
    'videoThreePosterFrench': videoThreeFrenchPoster ${imageProjection},
  },
  benefitsSlider-> {
    'cta': cta -> ${buttonProjection},
    'title': title.${locale},
    'description': description.${locale},
    'slides': benefitsSlides[]-> {
      'title': title.${locale},
      'image': image ${imageProjection},
      'imageDuotone': imageDuotone ${imageProjection},
      'benefitLink': benefitLink.${locale},
      'benefitCategory': benefitCategory-> {
        'title': title.${locale}
      }
    }
  },
  polaroidsSection-> {
    'title': title.${locale},
    'imageOne': imageOne ${imageProjection},
    'imageTwo': imageTwo ${imageProjection},
    'imageThree': imageThree ${imageProjection},
    'imageFour': imageFour ${imageProjection},
    'imageFive': imageFive ${imageProjection},
    'imageSix': imageSix ${imageProjection},
    'imageSeven': imageSeven ${imageProjection},
    'imageEight': imageEight ${imageProjection},
    'imageNine': imageNine ${imageProjection},
  },
  passSection -> {
    'image':  image ${imageProjection},
    'text': text.${locale} ,
    'title': title.${locale},
    'cta': cta -> ${buttonProjection} ,
    'renewText': renewText.${locale},
    'renewPrimaryCTA': renewPrimaryCta -> ${buttonProjection},
    'renewSecondaryCTA': renewSecondaryCta -> ${buttonProjection},
  }
}`;

export default homePageProjection;
