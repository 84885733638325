import affirmCalloutProjection from "../modules/affirm-callout-projection";
import { getLocale } from "../../utils";
import headingOverrideProjection from "../modules/heading-override-projection";

const locale = getLocale();

const ikonTileProjection = `{
  _id,
    affirmCallout -> ${affirmCalloutProjection},
    'benefitCheckMarks': benefitCheckMarks.${locale},
    'buttonText': buttonText.${locale},
    destinationReference -> {
      'url': url.current
    },
    offSaleContent -> {
      _id,
      entryTitle,
      passOnSale,
      'offSaleBlurb': offSaleBlurb.${locale},
      'offSaleBlurbDetails': offSaleBlurbDetails.${locale},
      'offSaleBlurbCtaText': offSaleBlurbCtaText.${locale},
      'offSaleBlurbCtaLink': offSaleBlurbCtaLink.${locale},
    },
    passId,
    passOnSale,
    passNameOverride,
    'tagText': passYear,
    'priceLabel': priceLabel.${locale},
    'promoText': promoText.${locale},
    'shortDescription': shortDescription.${locale},
    sortOrder,
    'title': entryTitle,
    headlineOverride ${headingOverrideProjection},
    urlSlug,
}`;

export default ikonTileProjection;
