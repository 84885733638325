/*
    Adobe Test and Target specific functions
 */

import { getGenericPassID, getGenericProductName } from "../utils/utils";

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/local-pass-cart-widget/index.jsx -> src/components/local-pass-cart-widget/index.js
// app/src/components/content-blocks/pass-cart-widget/index.jsx -> src/components/pass-cart-widget/index.js
// app/src/modules/analytics-events.js -> src/modules/analytics-events.js
export const targetView = (location) => {
  // Validate if the Target Libraries are available on your website
  if (
    typeof adobe != "undefined" &&
    adobe.target &&
    typeof adobe.target.triggerView === "function"
  ) {
    // A really simple example where we are assigning the location as the view name
    if (location && location.pathname) {
      adobe.target.triggerView(location.pathname);
    } else {
      adobe.target.triggerView(location);
    }
  }
  console.log("--- targetView fired ---", location);
};

// Usage from old build -> Usage from new Site
// app/src/components/global-header/index.js -> src/components/header/components/abandonned-cart/index.js
export const trackAbandonCartModalDisplayed = () => {
  if (typeof s != "undefined") {
    console.log("--- trackAbandonCartModalDisplayed ---");
    s.linkTrackVars = "pageName,prop1,eVar1,prop3,eVar3,eVar12,prop12,events";
    s.linkTrackEvents = "event12";
    s.events = "event12";
    s.pageName = s.eVar1 = s.prop1 = document.location;
    s.eVar3 = s.prop3 = document.URL;
    s.eVar12 = s.prop12 = "AC Modal - Display";
    s.tl(true, "o", "AC Modal - Display");
  }
};

// Usage from old build -> Usage from new Site
// app/src/components/global-header/index.js -> src/components/header/components/abandonned-cart/index.js
export const trackAbandonCartModalClosed = () => {
  if (typeof s != "undefined") {
    console.log("--- trackAbandonCartModalClosed ---");
    s.linkTrackVars = "pageName,prop1,eVar1,prop3,eVar3,eVar12,prop12,events";
    s.linkTrackEvents = "event12";
    s.events = "event12";
    s.pageName = s.eVar1 = s.prop1 = document.location;
    s.eVar3 = s.prop3 = document.URL;
    s.eVar12 = s.prop12 = "AC Modal - Close";
    s.tl(this, "o", "AC Modal - Click");
  }
};

// Usage from old build -> Usage from new Site
// app/src/components/cart-modal/index.jsx -> src/components/header/components/abandonned-cart/cart-modal/index.js
export const trackAbandonCartModalClicked = () => {
  if (typeof s != "undefined") {
    console.log("--- trackAbandonCartModalClicked ---");
    s.linkTrackVars = "pageName,prop1,eVar1,prop3,eVar3,eVar12,prop12,events";
    s.linkTrackEvents = "event12";
    s.events = "event12";
    s.pageName = s.eVar1 = s.prop1 = document.location;
    s.eVar3 = s.prop3 = document.URL;
    s.eVar12 = s.prop12 = "AC Modal - CTA";
    s.tl(this, "o", "AC Modal - Click");
  }
};

export const getOffer = async (onSuccessCallback) => {
  // Check if an offer exists in Adobe Target and then set the state of the component
  // Validate if the Target Libraries are available on your website
  if (
    typeof adobe != "undefined" &&
    adobe.target &&
    typeof adobe.target.getOffer === "function"
  ) {
    adobe.target.getOffer({
      mbox: "target-global-mbox",
      success: function (offer) {
        if (offer.length > 0) {
          onSuccessCallback(
            offer[0].content[offer[0].content.length - 1].activityID
          );
        }
      },
      error: function (status, error) {
        console.log("Error", status, error);
      },
    });
  }
};

export const ATElementClickTracking = (trackingName, activityID) => {
  console.log(`${trackingName} - [${activityID}]`);
  if (typeof s !== "undefined" && trackingName && activityID) {
    s.linkTrackVars = "pageName,prop1,eVar1,prop3,eVar3,eVar12,prop12,events";
    s.linkTrackEvents = "event12";
    s.events = "event12";
    s.pageName = s.eVar1 = s.prop1 = document.location;
    s.eVar3 = s.prop3 = document.URL;
    s.eVar12 = s.prop12 = `${trackingName} - [${activityID}]`;
    s.tl(true, "o", "AT Element Click");
  }
};
