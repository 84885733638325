import { Highlight } from "react-instantsearch-dom";
import React from "react";
import CustomSnippet from "./custom-snippet";
import CustomMatchedArraySnippet from "./custom-matched-array-snippet";
import getIn from "lodash-es/get";

const PageHit = ({ hit, locale }) => {
  const lang = locale.slice(0, 2);
  const contentMatch =
    getIn(hit, [
      "_snippetResult",
      `siteSearchDisplayDescription_${lang}`,
      "matchLevel",
    ]) === "full";
  const firstNestedContentMatch = getIn(
    hit,
    ["_snippetResult", `nestedContent_${lang}`],
    []
  ).find(({ matchLevel }) => matchLevel === "full");
  return (
    <div className="hit">
      <div className="hit-title">
        <Highlight
          className="title"
          attribute={`siteSearchDisplayTitle_${lang}`}
          hit={hit}
          tagName="mark"
        />
      </div>
      <div className="hit-content">
        {(contentMatch || firstNestedContentMatch === undefined) && (
          <CustomSnippet
            attribute={`siteSearchDisplayDescription_${lang}`}
            hit={hit}
          />
        )}
        {!contentMatch && firstNestedContentMatch !== undefined && (
          <CustomMatchedArraySnippet
            attribute={`nestedContent_${lang}`}
            matchedContent={firstNestedContentMatch}
          />
        )}
      </div>
    </div>
  );
};

export default PageHit;
