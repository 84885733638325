import { getLocale } from "../../utils";
import multiFaqGroupProjection from "../pages/multi-faq-group-projection";

const locale = getLocale();

const faqParentCategoryProjection = `{
  _id,
  _type,
  entryTitle,
  'title': title.${locale},
  multiFaqGroups[] -> ${multiFaqGroupProjection}
}`;

export default faqParentCategoryProjection;
