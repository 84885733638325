import imageProjection from "../items/image-projection";

const currencyProjection = `{
  code,
  darkFlag->${imageProjection},
  entryTitle,
  exchangeRate,
  name,
  whiteFlag->${imageProjection},
  _id,
  _type
}
`;

export default currencyProjection;
