import { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import {
  CART_DATA,
  PRODUCT_DATA,
  useAmp,
} from "../../../../utils/context/AmpContext";

import MiniCart from "./mini-cart";
import { toAmpCheckout } from "../../../../modules/analytics-events";
import environments from "../../../../modules/environments";
import { getLocale } from "../../../../utils/utils";

// inline styles
import "./styles.scss";
import Modal from "../../../modal";
import Markdown from "../../../markdown";

const Cart = ({ iconColor = "FFFFFF", cartMismatchError, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const miniCartRef = useRef(null);
  const [{ cartData, productData }, { fetchProductData }] = useAmp((store) => ({
    cartData: store[CART_DATA],
    productData: store[PRODUCT_DATA],
  }));

  const handleBroadClick = (event) => {
    const miniCart = miniCartRef.current;
    if (miniCart && !miniCart.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!productData || !productData.length) {
      fetchProductData();
    }
  }, [productData, fetchProductData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleBroadClick);
    return () => {
      document.removeEventListener("mousedown", handleBroadClick);
    };
  }, []);

  let cartItemCount = 0;

  if (cartData && cartData.profiles && cartData.profiles.length >= 1) {
    cartData.profiles.forEach((item) => {
      if (item.items[0]) {
        cartItemCount++;
      }
    });
  }

  let cartError = false;
  if (
    cartData &&
    !cartData.profiles &&
    cartData.cart_item_purchase_group_mismatch
  ) {
    cartError = true;
  }

  const Icon = () => {
    return (
      <div id="header-cart-count">
        <div className="cart-icon">
          <span className="hidden-text">Cart</span>
          <i className="icon-bag" style={{ color: `#${iconColor}` }}></i>
          {cartItemCount > 0 ? (
            <div className="cart-count-badge" data-testid="cart-count-badge">
              {cartItemCount}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const CartIcon = () => {
    return (
      <button
        className="button unstyled cart-checkout"
        aria-label="Cart"
        onClick={() => {
          if (cartItemCount > 0 || cartError) {
            setIsOpen(!isOpen);
          } else {
            toAmpCheckout(cartData, productData);
            window.open(
              `${environments.amp.cartUrl}/${getLocale()}/cart`,
              "_self",
              "noopener,noreferrer"
            );
          }
        }}
      >
        <Icon />
      </button>
    );
  };

  return (
    <>
      {cartData && productData && <CartIcon />}
      {cartItemCount === 0 && cartError && cartMismatchError && (
        <Modal isOpen={true} hideClose={true}>
          <Markdown>{cartMismatchError.errorText}</Markdown>

          <p>
            <button
              className="amp-button primary button"
              onClick={(e) => {
                window.location.reload();
              }}
            >
              <span className="inner">{cartMismatchError.buttonCopy}</span>
            </button>
          </p>
        </Modal>
      )}
      {cartItemCount > 0 && isOpen && (
        <div className="mini-cart-container" ref={miniCartRef}>
          <MiniCart content={content} />
        </div>
      )}
    </>
  );
};

export default Cart;
