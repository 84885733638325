import { imageUrlFor } from "../../../../../utils/sanity";

const Recommendations = ({ userData, content }) => {
  if (!userData) {
    return null;
  }
  // Account Header Recommendations for you (title for blog post)
  return (
    <div className="account-dropdown-recommended">
      {content.headerAccountRecommendedForYou ? (
        <div className="recommended-for-you">
          <p className="recommended-for-you-text">
            {content.headerAccountRecommendedForYou}
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Account Header Blog Post */}
      {content.accountHeaderCardItem ? (
        <div className="account-blog-post">
          <a to={content.accountHeaderCardItem.postLink} target="_blank">
            <img
              src={imageUrlFor(content.accountHeaderCardItem.image).url()}
              className="account-blog-post-image"
              alt="Blog post header"
            />
          </a>
          <div className="account-blog-post-title">
            <a
              href={content.accountHeaderCardItem.postLink}
              target="_blank"
              className="arrow"
            >
              <span>{content.accountHeaderCardItem.title}</span>
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Recommendations;
