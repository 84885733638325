import affirmCalloutProjection from "./affirm-callout-projection";
import buttonProjection from "../modules/button-projection";
import iconProjection from "./icon-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

// the reorganizing of affirmCallouts is to match the old data src

const columnedContentProjection = `{
  _id,
  _type,
  createOrderedList,
  cta ${buttonProjection},
  'backgroundColor': backgroundColor.value,
  accordionList[] -> {
    _id,
    'title': title.${locale},
    'content': content.${locale}
  },
  'column1': column1.${locale},
  column1NaturalWidth,
  column1NoMobileMargin,
  column1AddBottomHr,
  column1AccordionList,
  column1Icon->${iconProjection},
  column1Link->${buttonProjection},
  'column1AffirmCallout': {
    'affirmCallout': column1AffirmCallout -> ${affirmCalloutProjection}
  },
  'column2': column2.${locale},
  column2NaturalWidth,
  column2NoMobileMargin,
  column2AddBottomHr,
  column2AccordionList,
  column2Icon->${iconProjection},
  column2Link->${buttonProjection},
  'column2AffirmCallout': {
    'affirmCallout': column2AffirmCallout -> ${affirmCalloutProjection}
  },
  'column3': column3.${locale},
  column3NaturalWidth,
  column3NoMobileMargin,
  column3AddBottomHr,
  column3AccordionList,
  column3Icon->${iconProjection},
  column3Link->${buttonProjection},
  'column3AffirmCallout': {
    'affirmCallout': column3AffirmCallout -> ${affirmCalloutProjection}
  },
  'column4': column4.${locale},
  column4NaturalWidth,
  column4NoMobileMargin,
  column4AddBottomHr,
  column4AccordionList,
  column4Icon->${iconProjection},
  column4Link->${buttonProjection},
  'column4AffirmCallout': {
    'affirmCallout': column4AffirmCallout -> ${affirmCalloutProjection}
  },
  paddingBottom,
  paddingTop,
  pullUp,
  textAlignment,
  centerContent,
  width
}
`;

export default columnedContentProjection;
