import { getLocale } from "../../utils";

const locale = getLocale();

const diagonalTextProjection = `{
  'lineOne': lineOne.${locale},
  'lineTwo': lineTwo.${locale},
  'lineThree': lineThree.${locale},
  'lineFour': lineFour.${locale},
}`;

export default diagonalTextProjection;
