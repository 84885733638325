import buttonProjection from "./button-projection";
import imageProjection from "../items/image-projection";
import storyProjection from "../items/story-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

const blogTilesProjection = `
blogTiles->{
  _id,
  _type,
  'headline': headline.${locale},
  image ${imageProjection},
  'leadText': leadText.${locale},
  stories[]->${storyProjection},
  'cta': ${buttonProjection},
  'viewMoreText': viewMoreText.${locale}
}
`;
//TODO: determine if we can use a cardGroup object for this.
export default blogTilesProjection;
