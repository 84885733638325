import faqParentCategoryProjection from "../modules/faq-parent-category-projection";
import { getLocale } from "../../utils";
import seoProjection from "../seo-projection";

const locale = getLocale();

const faqPageProjection = `{
  _id,
  _type,
  entryTitle,
  'title': title.${locale},
  faqParentCategories[] -> ${faqParentCategoryProjection},
  seo ${seoProjection}
}`;

export default faqPageProjection;
