import affirmCalloutProjection from "../modules/affirm-callout-projection";
import automatedPromotionEligibilityProjection from "../modules/automated-promotion-eligibility-projection";
import buttonProjection from "../modules/button-projection";
import contentBlockProjections from "../modules/content-blocks-projection";
import destinationProjection from "../items/destinations/destination-projection";
import { getLocale } from "../../utils";
import genericHeroProjection from "../modules/generic-hero-projection";
import promoInformationBoxProjection from "../modules/promo-information-box-projection";
import seoProjection from "../seo-projection";

const locale = getLocale();

const localPassPageProjection = `{
  _id,
  _type,
  'localPass': {
    affirmCallout -> ${affirmCalloutProjection},
    'destination': destinationReference -> ${destinationProjection},
    entryTitle,
    hideLogo,
    'shortDescription': shortDescription.${locale},
    'priceLabel': priceLabel.${locale},
    passId,
    'benefitCheckmarks': benefitCheckmarks.${locale},
    'benefits' : benefits.${locale},
    passOnSale,
    'title': title.${locale},
    urlSlug,
    'buttonText': buttonText.${locale}
  },
  'secondaryPass': {
    affirmCallout -> ${affirmCalloutProjection},
    'destination': destinationReference -> ${destinationProjection},
    entryTitle,
    hideLogo,
    'shortDescription': shortDescription.${locale},
    'priceLabel': priceLabel.${locale},
    'passId': secondaryPassId,
    'benefitCheckmarks': benefitCheckmarks.${locale},
    'benefits' : benefits.${locale},
    passOnSale,
    'title': title.${locale},
    urlSlug,
    'buttonText': buttonText.${locale}
  },
  'tertiaryPass': {
    affirmCallout -> ${affirmCalloutProjection},
    'destination': destinationReference -> ${destinationProjection},
    entryTitle,
    hideLogo,
    'shortDescription': shortDescription.${locale},
    'priceLabel': priceLabel.${locale},
    'passId': tertiaryPassId,
    'benefitCheckmarks': benefitCheckmarks.${locale},
    'benefits' : benefits.${locale},
    passOnSale,
    'title': title.${locale},
    urlSlug,
    'buttonText': buttonText.${locale}
  },
  affirmCallout -> ${affirmCalloutProjection},
  'promotionDropdown': {
    'promotionEligibility': automatedPromotionEligibility[] -> ${automatedPromotionEligibilityProjection} | order(orderRank asc),
    'header': automatedPromotionDropdownTitle.${locale},
    'subtitle': automatedPromotionSubtitle.${locale}, 
    'disclaimer': automatedPromotionDisclaimer.${locale},
    'tooltip': automatedPromotionTooltip.${locale},
    'cta': automatedPromotionCTA->${buttonProjection},
    'informationBox': promoInformationBox->${promoInformationBoxProjection},
  },
  hero->${genericHeroProjection},
  'contentBlocks': contentBlocks[] -> ${contentBlockProjections},
  seo ${seoProjection}
}`;

export default localPassPageProjection;
