import buttonProjection from "./button-projection";
import imageProjection from "../items/image-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const mountainVectorTripleFeatureProjection = `{
  '_id': ^._id,
  'backgroundColor': backgroundColor.title,
  backgroundImage ${imageProjection},
  'description': description.${locale},
  entryTitle,
  'headline': headline.${locale},
  largeImage ${imageProjection},
  link ${buttonProjection},
  smallImage ${imageProjection}
}`;

export default mountainVectorTripleFeatureProjection;
