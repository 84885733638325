import { getLocale } from "../../utils";

const locale = getLocale();

// MQ removing the global request here

const buttonProjection = `{
  _id,
  _type,
  style,
  'text': text.${locale},
  url
}
`;

export default buttonProjection;
