// import classNames from "classnames";
// import Button from "components/button";
// import { showMountainChecklist } from "../utils/index";
import { useState, useEffect } from "react";
import Flyout from "../flyout";
import Markdown from "../../../../markdown";
import { showMountainChecklist } from "../../../../../utils/users";
import { getCookieValue, writeCookie } from "../../../../../utils/utils";

const Notifications = ({
  onToggle,
  numberOfNotifications,
  updateNumberOfNotifications,
  sitePreferences,
  pass,
  isOpen,
  content,
  userData,
  userHasProducts,
  blackouts
}) => {

  const [readNotifications, setReadNotifications] = useState([])

  useEffect(() => {
    const initialReadNotifications = content.userNotifications
      .filter(notification => isNotificationRead(notification.uniqueId))
      .map(notification => notification.uniqueId);

    setReadNotifications(initialReadNotifications);
  }, [content.userNotifications]);
  
  const pluralize = (word, count) => {
    return count === 1 ? word : `${word}S`;
  };

  const renderRibbon = () => (
    <button className="notification-button" onClick={onToggle}>
      {numberOfNotifications}
      <div className="notification-title">
        {pluralize("NOTIFICATION", numberOfNotifications)}
      </div>
      <i className="icon-chevron-right1" />
    </button>
  );

  const markNotificationAsRead = (notificationId) => {
    writeCookie(`readNotification_${notificationId}`, 'true', null, null, null, null, true);
    setReadNotifications(prevReadNotifications => [...prevReadNotifications, notificationId]);
  }

  const isNotificationRead = (notificationId) => {
    return readNotifications.includes(notificationId) || getCookieValue(`readNotification_${notificationId}`) === 'true';
  }

  const closeNotification = (notificationId) => {
    markNotificationAsRead(notificationId)
    updateNumberOfNotifications(prev => prev - 1)
  }

  const renderNotificationCard = ({ notification, showBlackout = false, key }) => {
    const { uniqueId, notificationTitle, message, id, icon, notificationCta } = notification;

    if (isNotificationRead(uniqueId)) return null;

    return (
      <div key={key} className="notification notification-grey" id={id}>
        <div className="notification-inner">
          <button 
            className="button unstyled close"
            onClick={() => closeNotification(uniqueId)}
          >
            <i className="icon-close-grey" />
          </button>
          {notificationTitle && (
            <span className="notification-title">{notificationTitle}</span>
          )}

          {pass && pass.blackouts && showBlackout && (
            <div className="blackouts-container">
              {pass.blackouts.northern && (
                <div className="cell">
                  <p className="title">
                    {sitePreferences.accessDetailsNorthernHemisphereText}
                  </p>
                  <ul>
                    {pass.blackouts.northern.map((date, i) => {
                      return (
                        <li key={i} className="date">
                          {date.from} - {date.to}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {pass.blackouts.southern && (
                <div className="cell">
                  <p className="title">
                    {sitePreferences.accessDetailsSouthernHemisphereText}
                  </p>
                  <ul>
                    {pass.blackouts.southern.map((date, i) => {
                      return (
                        <li key={i} className="date">
                          {date.from} - {date.to}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}

          <span className="notifications-item">
            {icon && <i className={`pre-text-icon icon-${icon}`} />}

            {message && (
              <Markdown className="notification-text">{message}</Markdown>
            )}
          </span>

          {notificationCta && (
            <span className="new-destination">
              <a href={notificationCta.url}>{notificationCta.title}</a>
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderFlyout = () => {
    const shouldShowMountainChecklist = showMountainChecklist(userData);

    return (
      <Flyout
        isOpen={isOpen}
        onBackButtonClick={onToggle}
        onCloseButtonClick={onToggle}
      >
        <div className="flyout-title">
          <p>NOTIFICATIONS</p>
        </div>

        {/* notifications open */}
        <div className="header-account-dropdown-notifications">
          {userHasProducts &&
            content.mountainChecklistNotification &&
            shouldShowMountainChecklist &&
            renderNotificationCard({
              notification: content.mountainChecklistNotification,
            })}

          {blackouts &&
            blackouts.upcoming &&
            blackouts.upcoming.length > 0 &&
            content.upcomingBlackoutsNotification &&
            renderNotificationCard({
              notification: content.upcomingBlackoutsNotification,
              showBlackout: true,
            })}

          {content.userNotifications &&
            content.userNotifications.length > 0 &&
            content.userNotifications.map((item, i) => {
              return renderNotificationCard({
                notification: item,
                key: i,
              });
            })}
        </div>
      </Flyout>
    );
  };

  return (
    <>
      <div className="notification-container">{renderRibbon()}</div>
      {renderFlyout()}
    </>
  );
};

export default Notifications;
