import React, { useState, useEffect } from "react";
import Modal from "../modal";
import CustomDropdown from "../custom-dropdown";
import {
  CURRENCY,
  LOCALE,
  useAppContext,
} from "../../utils/context/AppContext";
import { addLocaleUrl } from "../../utils/utils";

// inline styles
import "./styles.scss";

const CurrencyLanguageSelector = ({ content: { currencies, ...content } }) => {
  const [rememberSelection, setRememberSelection] = useState(false);

  const [{ locale, currency }, { changeLocale, changeCurrency }] =
    useAppContext((store) => ({
      locale: store[LOCALE],
      currency: store[CURRENCY],
    }));

  const [selectedCurrency, setSelectedCurrency] = useState({
    value: currency,
    content: `$ ${currency}`,
  });

  const [selectedLanguage, setSelectedLanguage] = useState({
    value: locale,
    content: locale,
  });

  const selectedCurrencyContent = currencies.filter(
    (currency) => currency.code === selectedCurrency.value
  )[0];

  useEffect(() => {
    setSelectedCurrency({
      value: currency,
      content: `$ ${currency}`,
    });
  }, [currency]);

  const applyChanges = () => {
    // Wait to update currency and locale before refreshing
    Promise.all([
      changeCurrency(selectedCurrency.value),
      changeLocale(selectedLanguage.value),
    ]).then(() => {
      const currentUrl = window.location.pathname;
      let newUrl = addLocaleUrl(selectedLanguage.value, currentUrl);

      if (newUrl !== currentUrl) {
        window.location = newUrl;
      } else {
        window.location.reload();
      }
    });
  };

  if (!selectedCurrency || !locale || !currencies || !content) {
    return null;
  }

  const setDefaultOption = (lang) => {
    //@TODO: this matches prod, but expand to translate
    if (lang.value === "en") {
      return {
        content: locale?.toLowerCase() === "en" ? "English" : "Anglais",
      };
    } else {
      return {
        content: locale?.toLowerCase() === "en" ? "French" : "Français",
      };
    }
  };

  const ModalContent = () => {
    const {
      title,
      description,
      submitButtonText,
      currencyLabel,
      languageLabel,
    } = content;

    return (
      <>
        <div className="modal-header">
          <h5>{title}</h5>
        </div>
        <div className="modal-body">
          {description && description.length > 0 && <p>{description}</p>}
          <div className="custom-dropdowns custom-dropdowns-split currency-language-dropdowns">
            <CustomDropdown
              ariaHideApp={false}
              label={currencyLabel}
              options={currencies.map((currency) => ({
                value: currency.code,
                content: `$ ${currency.code}`,
              }))}
              defaultOption={selectedCurrency}
              onChange={(option) => setSelectedCurrency(option)}
            />
            <CustomDropdown
              ariaHideApp={false}
              label={languageLabel}
              options={[
                {
                  value: "en",
                  content:
                    locale?.toLowerCase() === "en" ? "English" : "Anglais",
                },
                {
                  value: "fr",
                  content:
                    locale?.toLowerCase() === "en" ? "French" : "Français",
                },
              ]}
              defaultOption={setDefaultOption(selectedLanguage)}
              onChange={(option) => setSelectedLanguage(option)}
            />
          </div>
          <div className="currency-lang-modal-submit">
            <button className="button dark" onClick={() => applyChanges()}>
              <span className="inner">{submitButtonText}</span>
            </button>
          </div>

          <div className="currency-lang-modal-remember">
            <label className="custom-checkbox">
              {content.rememberSelectionText}
              <input
                type="checkbox"
                id="rememberLangCurrency"
                checked={rememberSelection}
                onChange={() => setRememberSelection(!rememberSelection)}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      portalClassName="currency-language-modal"
      launcher={
        <button
          className="currency-language-selector"
          title="Currency & Language Selection"
        >
          {selectedCurrencyContent?.whiteFlag && (
            <img
              className="language-icon language-icon-white"
              src={selectedCurrencyContent.whiteFlag.asset.url}
              alt="Currency flag"
            />
          )}
          {selectedCurrencyContent?.darkFlag && (
            <img
              className="language-icon language-icon-blue"
              src={selectedCurrencyContent.darkFlag.asset.url}
              alt="Currency flag"
            />
          )}
          <div>
            {selectedCurrency.value} / {selectedLanguage.value.toUpperCase()}
          </div>
        </button>
      }
    >
      <ModalContent />
    </Modal>
  );
};

export default CurrencyLanguageSelector;
