import { getLocale } from "../../../utils";

const destinationFavoriteProjection = `{
    _id,
    resortCode,
    resortLink,
    'resortLinkText': resortLinkText.${getLocale()}
}`;

export default destinationFavoriteProjection;
