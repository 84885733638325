import React, { createContext, useContext } from "react";
import { PAGE_SEO, useCMSContent } from "./CMSContentContext";
import {
  CURRENCY,
  LOCALE,
  SHOW_STICKY_CART_BAR,
  useAppContext,
} from "./AppContext";
import { CART_DATA, PRODUCT_DATA, useAmp, USER_DATA } from "./AmpContext";

const AllContexts = createContext();

function AllContextsProvider({ children }) {
  const [cmsContext, cmsContextSetters] = useCMSContent((store) => ({
    pageSeo: store[PAGE_SEO],
  }));
  const [appContext, appContextSetters] = useAppContext((store) => ({
    locale: store[LOCALE],
    currency: store[CURRENCY],
    showStickyCartBar: store[SHOW_STICKY_CART_BAR],
  }));
  const [ampContext, ampContextSetters] = useAmp((store) => ({
    productData: store[PRODUCT_DATA],
    cartData: store[CART_DATA],
    userData: store[USER_DATA],
  }));
  // console.log('appContext', appContext, appContextSetters);

  const context = {
    ...cmsContext,
    ...cmsContextSetters,
    ...appContext,
    ...appContextSetters,
    ...ampContext,
    ...ampContextSetters,
  };

  return (
    <AllContexts.Provider value={context}>{children}</AllContexts.Provider>
  );
}

function useAllContexts() {
  const context = useContext(AllContexts);
  if (context === undefined) {
    throw new Error("useAllContexts must be used within a AllContextsProvider");
  }
  return context;
}

function AppContextConsumer({ children }) {
  return (
    <AllContexts.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            "AppContextConsumer must be used within a AllContextsProvider"
          );
        }
        return children(context);
      }}
    </AllContexts.Consumer>
  );
}

export { AllContexts, AllContextsProvider, useAllContexts, AppContextConsumer };
