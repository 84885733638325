import classNames from "classnames";
import React, { Component } from "react";

export default class NavigationPanel extends Component {
  render() {
    const { onClick, isOpen, title } = this.props;

    const sideNavTitleClassN = classNames("side-nav__navigation-panel__title", {
      open: isOpen,
    });

    return (
      <div className="side-nav__navigation-panel">
        <button className={sideNavTitleClassN} onClick={onClick} tabIndex="0">
          <span className="side-nav__navigation-panel__title__text">
            {title}
          </span>
          <i className="icon-chevron-right" />
          <span className="arrow-head"></span>
        </button>
      </div>
    );
  }
}
