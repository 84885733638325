import imageProjection from "../items/image-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const adventuresProjection = `
'adventures': *[_type == "adventurePage"]|order(orderRank asc) {
  _id,
  adventureId,
  featuredAdventure,
  'title': title.${locale},
  'image': listingDisplayImage ${imageProjection},
  'body': adventureBodyText.${locale},
  'url': url.current
}`;

export default adventuresProjection;
