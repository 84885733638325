import affirmCalloutProjection from "./affirm-callout-projection.js";
import automatedPromotionEligibilityProjection from "./automated-promotion-eligibility-projection.js";
import buttonProjection from "./button-projection.js";
import cartPromotionBannerProjection from "./cart-promotion-banner-projection.js";
import customPromotionsProjection from "../modules/custom-promotions-projection.js";
import imageProjection from "../items/image-projection.js";
import { getLocale } from "../../utils";
import promoInformationBoxProjection from "./promo-information-box-projection.js";

const locale = getLocale();

const passCartWidgetProjection = `{
  _id,
  adobeTargetActivityID,
  'addToCartButtonText': addToCartButtonText.${locale},
  affirmCallout -> ${affirmCalloutProjection},
  'promotionDropdown': {
    'promotionEligibility': automatedPromotionEligibility[] -> ${automatedPromotionEligibilityProjection} | order(orderRank asc),
    'header': automatedPromotionDropdownTitle.${locale},
    'subtitle': automatedPromotionSubtitle.${locale}, 
    'disclaimer': automatedPromotionDisclaimer.${locale},
    'tooltip': automatedPromotionTooltip.${locale},
    'cta': automatedPromotionCTA->${buttonProjection},
    'informationBox': promoInformationBox->${promoInformationBoxProjection},
  },
  entryTitle,
  isSelected,
  linkedPass -> {
    appCarouselCtaButtonCopy,
    'appWebPurchaseCtaUrl': appWebPurchaseCtaUrl.${locale},
    'blackoutDates': blackoutDates.${locale},
    'description': description.${locale},
    'detailsBottomSection': detailsBottomSection.${locale},
    entryTitle,
    landingPageBackground -> ${imageProjection},
    offSaleContent -> {
      _id,
      entryTitle,
      passOnSale,
      'offSaleBlurb': offSaleBlurb.${locale},
      'offSaleBlurbDetails': offSaleBlurbDetails.${locale},
      'offSaleBlurbCtaText': offSaleBlurbCtaText.${locale},
      'offSaleBlurbCtaLink': offSaleBlurbCtaLink.${locale},
    },
    'offsaleLong': offsaleLong.${locale},
    passId,
    passOnSale,
    'passTabTitle': passTabTitle.${locale},
    passYear,
    preSaleEnabled,
    'familyDiscountLabel': familyDiscountLabel.${locale},
    'familyDiscountPriceUsd': familyDiscountPriceUsd,
    'familyDiscountLabelCad': familyDiscountLabelCad.${locale},
    'familyDiscountPriceCad': familyDiscountPriceCad,
    _id,
    _type
  }, 
  'text': subheading.${locale},
  'upsellText': passUpsellText.${locale},
  'renewalPriceText': renewalPriceText.${locale}, 
  'renewalPriceTextMobile': renewalPriceTextMobile.${locale},   
  cartPromotionBanner[] -> ${cartPromotionBannerProjection},
  customPromotions[] -> ${customPromotionsProjection},
  'moreOptionsText': moreOptionsText.${locale},
  'validDatesPostText': validDatesPostText.${locale}
}
`;

export default passCartWidgetProjection;
