// import benefitTileProjection from "../modules/benefit-tile-projection.js";
import buttonProjection from "../modules/button-projection.js";
import disruptionBannerProjection from "../modules/disruption-banner-projection.js";
import flexGroupProjection from "../modules/flex-group-projection.js";
import genericHeroProjection from "../modules/generic-hero-projection.js";
import { getLocale } from "../../utils.js";
import seoProjection from "../seo-projection.js";
import passCartWidgetGroupProjection from "../modules/pass-cart-widget-group-projection.js";
import countdownTimerProjection from "../modules/countdown-timer-projection.js";

const locale = getLocale();

const passPageProjection = `{
  _id, 
  hero->${genericHeroProjection},
  heroAdobeTarget[]->${genericHeroProjection},
  'unlimitedHeadline': unlimitedHeadline.${locale},
  unlimitedImage,
  'unlimitedLeadText': unlimitedLeadText.${locale},
  'unlimitedBlackoutDatesText': unlimitedBlackoutDatesText.${locale},
  'unlimitedBlackoutDatesDetails': unlimitedBlackoutDatesDetails.${locale},
  'unlimitedBlackoutDatesUpsellText': unlimitedBlackoutDatesUpsellText.${locale},
  unlimitedBlackoutDatesUpsellLink ${buttonProjection},
  'passLimitedExceptionsHeader': passLimitedExceptionsHeader.${locale},
  'limitedHeadline': limitedHeadline.${locale},
  'limitedLeadText': limitedLeadText.${locale},
  'limitedReservationsRequiredText': limitedReservationsRequiredText.${locale},
  'limitedBlackoutDatesText': limitedBlackoutDatesText.${locale},
  'limitedBlackoutDatesDetails': limitedBlackoutDatesDetails.${locale},
  'limitedBlackoutDatesUpsellText': limitedBlackoutDatesUpsellText.${locale},
  limitedBlackoutDatesUpsellLink ${buttonProjection},
  ${countdownTimerProjection},
  disruptor->${disruptionBannerProjection},
  disruptorSecondary->${disruptionBannerProjection},
  perksFlexGroup -> ${flexGroupProjection},
  'pass': passReference-> {
    _id,
    entryTitle,
    passId,
    'description': description.${locale},
    'accessDetails': accessDetails.${locale},
    'offsaleLong': offsaleLong.${locale},
    'offsaleShort': offsaleShort.${locale},
    'passTabTitle': passTabTitle.${locale},
    passYear,
    'blackoutDates': blackoutDates.${locale},
    preSaleEnabled,
    passOnSale,
    showReservationsRequired,
    offSaleContent -> {
      _id,
      entryTitle,
      passOnSale,
      'offSaleBlurb': offSaleBlurb.${locale},
      'offSaleBlurbDetails': offSaleBlurbDetails.${locale},
      'offSaleBlurbCtaText': offSaleBlurbCtaText.${locale},
      'offSaleBlurbCtaLink': offSaleBlurbCtaLink.${locale},
    },
  },
  passCartWidgetGroup -> ${passCartWidgetGroupProjection},
  passCartWidgetGroupAdobeTarget[] ${passCartWidgetGroupProjection},
  seo ${seoProjection},
  url,
}`;

export default passPageProjection;

//@TODO: rename the pass cart widget to group and remove the array!!!!!
