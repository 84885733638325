import { getLocale } from "../../utils";

const locale = getLocale();

const countdownTimerProjection = `countdownTimer->{
  _id,
  _type,
  countdownDate,
  'countdownHeading': countdownHeading.${locale},
  countdownIcon->{
    title
  },
  'countdownLegalText': countdownLegalText.${locale},
  countdownTextColor,
}
`;

export default countdownTimerProjection;
