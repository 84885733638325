import { getLocale } from "../../utils";

const locale = getLocale();

const passCartWidgetStringsProjection = `{
  _id,
  entryTitle,
  'moreLocalPassesLinkText': moreLocalPassesLinkText.${locale},
  'addToCartButtonText': addToCartButtonText.${locale},
  'passOffSaleMessage': passOffSaleMessage.${locale},
  'notAvailableInCurrencyMessage': notAvailableInCurrencyMessage.${locale},
  'notAvailableButtonText': notAvailableButtonText.${locale},
  'renewalPriceText': renewalPriceText.${locale},
  'renewalPriceTextMobile': renewalPriceTextMobile.${locale}
}
`;

export default passCartWidgetStringsProjection;
