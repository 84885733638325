import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";

const locale = getLocale();

const adventureItineraryItemDestination = `{
  _id,
  _type,
  entryTitle,
  'heading': heading.${locale},
  heroImage ${imageProjection},
  resortLogo -> ${imageProjection},
  url,
}
`;

export default adventureItineraryItemDestination;
