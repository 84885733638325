import classNames from "classnames";
import { Link } from "react-router-dom";
import React from "react";

import { addLocaleUrl, getLocale } from "../../utils/utils";

const Button = ({
  url,
  text,
  type = "primary",
  disable = "false",
  style,
  tabIndex,
  id,
  tracking,
}) => {
  const buttonClasses = classNames({
    button: true,
    "amp-button": true, // This is legacy stuff. Leaving for now, we can probably remove.
    primary:
      type === "blue-creek" || style === "blue-creek" || type === "primary",
    light: type === "blue-outline" || style === "blue-outline",
    "white-outline": type === "white-outline" || style === "white-outline",
    plain:
      type === "text-with-caret" ||
      type === "plain" ||
      style === "plain" ||
      style === "text-with-caret",
    unstyled: type === "unstyled",
  });

  const innerLink = React.forwardRef(({ navigate, ...props }, ref) => {
    const determineIfExternal = (url) => {
      return /^[a-z][a-z0-9+.-]*:|\/\//.test(url);
    };
    let componentProps = {
      ...(id && { id }),
      ...(tabIndex && { tabIndex }),
    };

    if (determineIfExternal(props.href)) {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          ref={ref}
          {...props}
          {...componentProps}
          className={props.className}
        >
          <span className="inner">
            <span className="inner-text">{props.text}</span>
            {type === "text-with-caret" && (
              <i className="icon icon-chevron-right1" />
            )}
            {style === "text-with-caret" && (
              <i className="icon icon-chevron-right1" />
            )}
          </span>
        </a>
      );
    } else {
      // Leave urls with leading slash alone (i.e urls with localization and some urls from CMS)
      props.href =
        props.href.charAt(0) === "/"
          ? `${props.href}`
          : addLocaleUrl(getLocale(), props.href);

      return (
        <a onClick={tracking} ref={ref} {...props} className={props.className}>
          <span className="inner">
            <span className="inner-text">{props.text}</span>
            {type === "text-with-caret" && (
              <i className="icon icon-chevron-right1" />
            )}
          </span>
        </a>
      );
    }
  });

  return (
    <Link
      className={buttonClasses}
      to={{ pathname: url }}
      text={text}
      component={innerLink}
    />
  );
};

export default Button;
