import genericHeroProjection from "../modules/generic-hero-projection";
import destinationLocalPassProjection from "../items/destinations/destination-local-pass-projection";
import seoProjection from "../seo-projection";
import disruptionBannerProjection from "../modules/disruption-banner-projection";
import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";

// const noDrafts = `!(_id  in path("drafts.**"))`
const locale = getLocale();

const localPassLandingPageProjection = `{
  _id,
  hero -> ${genericHeroProjection},
  'destinations': *[_type == "destination" && hasLocalPasses == true] |order(fullName asc) ${destinationLocalPassProjection},
  twoColumnsWithDiagonalAndOffset {
    'title': title.${locale},
    'introText': introText.${locale},
    'selectionText': selectionText.${locale},
    leadImage ${imageProjection}
  },
  disruptionBanner -> ${disruptionBannerProjection},
  seo ${seoProjection}
}`;

export default localPassLandingPageProjection;
