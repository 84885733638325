import { camelize } from "humps";
import merge from "lodash-es/mergeWith";
import { getActiveSeason } from "./users";
import { getCartData2 } from "./amp-client";

export const stripLocaleUrl = (locale, url) => {
  if (!url) {
    url = window.location.pathname;
  }
  const RE = new RegExp(locale + "/");
  return url.replace(RE, "");
};

export const getCurrency = () => {
  const cookieName = "currentCurrency";
  const cookieCurrency = getCookieValue(cookieName);
  let defaultCurrency = cookieCurrency ? cookieCurrency : "USD";
  getCartData2().then((newCartData) => {
    if (newCartData && newCartData.currency) {
      writeCookie(cookieName, newCartData.currency, 5, null, null, null);
    }
    defaultCurrency = cookieCurrency ? cookieCurrency : newCartData.currency;
    return defaultCurrency;
  });
  return defaultCurrency;
};

export const getLocale = () => {
  const cookieName = "currentLocale";
  const cookieLocale = getCookieValue(cookieName);
  let defaultLocale = cookieLocale ? cookieLocale : "en";
  return defaultLocale;
};

export const localeCookie = () => {
  const cookieName = "currentLocale";
  const frenchTest = /^\/fr/i;
  const cookieLocale = getCookieValue(cookieName);
  let defaultLocale = cookieLocale;

  if (frenchTest.test(window.location.pathname)) {
    defaultLocale = "fr";
  } else {
    defaultLocale = "en";
  }

  if (!cookieLocale || (cookieLocale && cookieLocale !== defaultLocale)) {
    writeCookie(cookieName, defaultLocale, 5, null, null, null);
  } else {
    defaultLocale = cookieLocale;
  }

  // change html lang attribute if necessary
  document.querySelector("html").setAttribute("lang", defaultLocale);

  return defaultLocale;
};

export const addLocaleUrl = (locale, url) => {
  if (!url) {
    url = window.location.pathname;
  }

  if (!locale) {
    const pathName = window.location.pathname;
    const prefixTest = /(^\/en)/i;
    locale = prefixTest.test(pathName) ? "en" : "fr";
  }

  url = url.replace(/\/+$/, "");

  // check for relative URLS, or if it's a mailto/tel, etc.
  const protocolCheck = /^[a-z][a-z0-9+.-]*:|\/\//gi;

  if (protocolCheck.test(url)) {
    return url;
  }

  url = url.replace(/^\/en/, "").replace(/^\/fr/, "").replace(/^\//, "");

  const urlWithLangPath = url === "" ? `/${locale}` : `/${locale}/${url}`;

  if (urlWithLangPath === "/en" || urlWithLangPath === "/en/") {
    return "/";
  }

  return urlWithLangPath;
};

export const writeCookie = (
  key,
  value,
  expireDays,
  expireHours,
  expireMinutes,
  expireSeconds,
  nonExpiring = false
) => {
  var expireDate = new Date();
  if (nonExpiring) {
    expireDate.setFullYear(expireDate.getFullYear() + 1000);
  } else {
    if (expireDays) {
      expireDate.setDate(expireDate.getDate() + expireDays);
    }
    if (expireHours) {
      expireDate.setHours(expireDate.getHours() + expireHours);
    }
    if (expireMinutes) {
      expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
    }
    if (expireSeconds) {
      expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
    }
  }

  document.cookie =
    key +
    "=" +
    escape(value) +
    ";domain=" +
    window.location.hostname +
    ";path=/" +
    ";expires=" +
    expireDate.toUTCString();
};

export const writeCrossDomainCookie = (
  key,
  value,
  expireDays,
  expireHours,
  expireMinutes,
  expireSeconds
) => {
  var expireDate = new Date();
  if (expireDays) {
    expireDate.setDate(expireDate.getDate() + expireDays);
  }
  if (expireHours) {
    expireDate.setHours(expireDate.getHours() + expireHours);
  }
  if (expireMinutes) {
    expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
  }
  if (expireSeconds) {
    expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
  }
  let domain = "ikonpass.com";
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "ikonpass-sanity.netlify.app" ||
    window.location.hostname === "develop--ikonpass.netlify.com" ||
    window.location.hostname === "staging--ikonpass.netlify.com" ||
    window.location.hostname === "dev.ikonpass.com"
  ) {
    domain = window.location.hostname;
  }
  document.cookie =
    key +
    "=" +
    value +
    ";domain=" +
    domain +
    ";path=/" +
    ";expires=" +
    expireDate.toUTCString();
};

export const getCookieValue = (cookieName) => {
  const result = document.cookie.replace(
    new RegExp(`(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`),
    "$1"
  );
  return unescape(result);
};

export const deleteCookie = (cookieName) => {
  writeCookie(cookieName, "", null, null, null, 1);
};

export const deleteCrossDomainCookie = (cookieName) => {
  writeCrossDomainCookie(cookieName, "", null, null, null, 1);
};

// ex: Strips 23/24. (23/24 Ikon Pass) => Ikon Pass
export const stripYearFromPassName = (title) => {
  return title.replace(/\d{2}\/\d{2}/, "");
};

// ex: Strips 2324. (ikonpass2324) => ikonpass
export const getGenericPassID = (fullPassID) => {
  if (!fullPassID) return;
  return fullPassID.replace(/\d{4}$/, ""); // strips last 4 digits at end of fullPassID
};

export const getGenericProductName = (productCode) => {
  const genericPassCode = getGenericPassID(productCode);
  switch (genericPassCode) {
    case "ikonpass":
      return "Ikon Pass";
    case "ikonbase":
      return "Ikon Base Pass";
    case "ikonbaseplus":
      return "Ikon Base Plus Pass";
    default:
      return `Ikon Session Pass ${genericPassCode.slice(-1)}`;
  }
};

export const redirectToNotFound = (path) => {
  const paths = path.split("/");
  const pathsLength = paths.length;
  const lastItem = paths[pathsLength - 1];
  window.location.pathname = `${getLocale()}/${lastItem}`;
};

export const backToTop = () => {
  window.scrollTo(0, 0);
};

export const buildQueryString = (query) => {
  return query.replace(/ /g, "+");
};

export const getQueryParamByName = (name, url) => {
  if (!url) url = window.location.href;

  name = name.replace(/[[]]/g, "\\$&"); // unecessary escape character?

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const setQueryParamByName = (url, param, value) => {
  var re = new RegExp("([?&])" + param + "=.*?(&|$)", "i");
  var separator = url.indexOf("?") !== -1 ? "&" : "?";
  if (url.match(re)) {
    return url.replace(re, "$1" + param + "=" + value + "$2");
  } else {
    return url + separator + param + "=" + value;
  }
};

export const arrayIncludesObj = (arr, key, valueToCheck) => {
  return arr.some((value) => value[key] === valueToCheck);
};

export const getUTMCampaignCoBranded = (param) => {
  if (!param) {
    return null;
  }

  const coBrandedId = param.split("_");
  return coBrandedId[0];
};

export const getProductFromCatalogById = (catalog, passId) => {
  const product = catalog.find((pass) => pass.id === passId);
  return product;
};

export const getPriceByAgeCategory = (product, ageCat) => {
  let price = null;
  const key = camelize("age_category");

  if (product && product.variants) {
    product.variants.forEach((prices) => {
      if (prices[key] === ageCat) {
        price = prices.price / 100;
      }
    });
  }
  return price;
};

export const getAgeRangeByAgeCategory = (product, ageCat) => {
  let ageRange = null;
  if (product && product.variants) {
    const variant = product.variants.find(
      (variant) => variant.ageCategory === ageCat
    );
    if (variant) {
      ageRange = variant.ageRange;
    }
  }
  return ageRange;
};

// VP these get and set methods are used to add stickers into the nav
//
export const getVisitedDestinationPages = () => {
  if (
    typeof localStorage != "undefined" &&
    localStorage.ikonpassVisitedDestinations
  ) {
    let visitedDestinations = JSON.parse(
      localStorage.ikonpassVisitedDestinations
    );
    return visitedDestinations ? visitedDestinations : [];
  }
  return [];
};

export const updateVisistedDestinationPage = (
  resortCode,
  positionX,
  positionY,
  rotation
) => {
  if (!localStorage.ikonpassVisitedDestinations) return;
  const storedVisitedDestinations = JSON.parse(
    localStorage.ikonpassVisitedDestinations
  );
  const destinationToUpdate = storedVisitedDestinations.filter(
    (resort) => resort.resortCode === resortCode
  )[0];

  if (!destinationToUpdate) return;
  if (destinationToUpdate.position.x) return;

  destinationToUpdate.position = {
    x: positionX,
    y: positionY,
    rotation: rotation,
  };
  storedVisitedDestinations.forEach((resort) => {
    if (resort.resortCode === destinationToUpdate.resortCode) {
      resort.position = destinationToUpdate.position;
    }
  });

  localStorage.ikonpassVisitedDestinations = JSON.stringify(
    storedVisitedDestinations
  );
};

export const arrayContains = (arr, key, value) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) {
      return true;
    }
  }
  return false;
};

export const setDestinationPageVisited = (destinationCode, name) => {
  if (typeof localStorage != "undefined") {
    let storedVisitedDestinations = localStorage.ikonpassVisitedDestinations;
    let visitedDestinations = storedVisitedDestinations
      ? JSON.parse(storedVisitedDestinations)
      : [];
    if (!arrayContains(visitedDestinations, "resortCode", destinationCode)) {
      visitedDestinations.push({
        name,
        position: {
          x: null,
          y: null,
          rotation: null,
        },
        resortCode: destinationCode,
        url: window.location.href,
      });
      localStorage.ikonpassVisitedDestinations =
        JSON.stringify(visitedDestinations);
    }
  }
};

export const getFullScreenImageSizes = (factor = 1) => [
  {
    mediaQuery: "default",
    params: { w: 1200 * factor },
  },
  {
    mediaQuery: "xs",
    params: { w: 320 * factor },
  },
  {
    mediaQuery: "sm",
    params: { w: 576 * factor },
  },
  {
    mediaQuery: "md",
    params: { w: 768 * factor },
  },
  {
    mediaQuery: "lg",
    params: { w: 992 * factor },
  },
  {
    mediaQuery: "xl",
    params: { w: 1200 * factor },
  },
  {
    mediaQuery: "xxl",
    params: { w: 1320 * factor },
  },
];

export const renderDollarSign = (price, locale, sup = false) => {
  let language = locale;
  if (typeof locale === "string") {
    language = locale.toLowerCase();
  }

  if (language === "fr") {
    return sup === true ? (
      <>
        {price}
        <sup>$</sup>
      </>
    ) : (
      <>{price}$</>
    );
  } else {
    return sup === true ? (
      <>
        <sup>$</sup>
        {price}
      </>
    ) : (
      <>${price}</>
    );
  }
};

export const getNumberWithCommas = (n) => {
  if (!(typeof n === "number" || typeof n === "string")) {
    return n;
  }

  let number = parseFloat(n);
  const integerPart = Math.floor(number);

  const hasCents = number - integerPart !== 0;
  const decimalPart = hasCents
    ? (number - integerPart).toFixed(2).substring(2)
    : "";

  const numberWithCommas = integerPart
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return hasCents ? `${numberWithCommas}.${decimalPart}` : numberWithCommas;
};

export const isLinkActive = (url, activeOnly = false) => {
  if (!url) return null;

  // Add a top level variable to check for active states when depth > 1
  let topLevel = "";
  if (url.includes("/en/") || url.includes("/fr/")) {
    topLevel = url.split("/")[2];
  } else if (url.includes("http")) {
    // external links
    topLevel = url;
  } else {
    topLevel = url.split("/")[1];
  }

  let activePath =
    typeof window !== "undefined"
      ? window.location.pathname.replace(/\//g, "")
      : ""; // remove slash variations
  let cleanUrl = url.replace(/\//g, "");

  if (activePath === cleanUrl || activePath.includes(topLevel)) {
    if (!activeOnly) {
      return "active disabled";
    } else {
      return "active";
    }
  }
};

/*
    Format pass seasons with a slash.
    Takes a season string like 2018-2019 and turns it into 18/19.

    @param {string} season - A season displayed like this: 2018-2019
    @return {string} niceSeason - A season now formatted like this: 18/19
 */
export const formatSeasonWithSlash = (season) => {
  if (/\d{2}\/\d{2}/.test(season)) return season; // if already formatted, escape

  let years = season.split("-");
  years[0] = years[0].replace("20", "");
  years[1] = years[1].replace("20", "");
  return years.join("/");
};

/* Gets formatted last year season */
export const getPreviousSeasonYear = () => {
  const activeSeason = getActiveSeason();
  const formatted = formatSeasonWithSlash(activeSeason).split("/");

  formatted[0] = (parseInt(formatted[0]) - 1).toString();
  formatted[1] = (parseInt(formatted[1]) - 1).toString();

  return formatted.join("/");
};

/*
    Add UAT sub-domain to account links.
    Add the UAT sub-domain for links navigating to the account site (account.ikonpass.com)
    @param {string} link - The href value of a link element in string format
    @return {string} updatedLink - The potentially updated href value with the UAT
 */

export const updateEnvSpecificAccountLink = (link) => {
  let updatedLink = link;
  const host = window.location.host;
  const env = process.env.NODE_ENV;

  if (link) {
    if (
      link.includes("account.ikonpass.com") &&
      (host === "localhost:8000" ||
        host.includes("deploy-preview") ||
        env === "development")
    ) {
      updatedLink = link.replace("account", "account.uat");
    }
  }
  return updatedLink;
};

export const getRenewalPriceForAgeCategory = (
  ageCategory,
  variants,
  removeDecimal = true
) => {
  const renewalCode = "renewal_discount"; // this will likely change seasonally

  if (!variants || !ageCategory) {
    return null;
  }
  let ageVariant = null;
  // get the correct variant by age
  for (let i = 0; i < variants.length; i++) {
    if (variants[i] && variants[i].ageCategory === ageCategory) {
      ageVariant = variants[i];
    }
  }
  let renewalPrice = null;
  // get the correct promotion by the renewal code - if it exists
  if (ageVariant && ageVariant.promotions) {
    for (let i = 0; i < ageVariant.promotions.length; i++) {
      if (ageVariant.promotions[i].code.indexOf(renewalCode) > -1) {
        renewalPrice = ageVariant.promotions[i].price;
      }
    }
  }

  if (renewalPrice && removeDecimal) {
    renewalPrice = parseInt(renewalPrice) / 100;
  }

  return renewalPrice;
};

export const getDiscountedPriceDetailsByPromoCode = (
  promoCode,
  productData,
  productId
) => {
  const ageCategory = "adult"; // for now this is set it stone
  const product = getProductFromCatalogById(productData, productId);
  const variants = product ? product.variants : null;
  if (!variants || !promoCode) {
    return null;
  }

  const passDetails = {
    title: product.title,
    price: null,
    ageRange: "",
    currency: "USD",
  };

  let ageVariant = null;
  // get the correct variant by age
  for (let i = 0; i < variants.length; i++) {
    if (variants[i].ageCategory === ageCategory) {
      ageVariant = variants[i];
      passDetails.ageRange = ageVariant.ageRange.toLowerCase();
    }
  }

  // get the correct promotion by the renewal code - if it exists
  for (let i = 0; i < ageVariant.promotions.length; i++) {
    if (ageVariant.promotions[i].code === promoCode) {
      passDetails.price = ageVariant.promotions[i].price;
    }
  }

  if (passDetails.price) {
    passDetails.price = parseInt(passDetails.price) / 100;
  } else {
    return null;
  }

  return passDetails;
};

export const convertNumberToCurrency = (number, decimals = true) => {
  let price = parseInt(number) / 100;

  if (price < 0) {
    price = price * -1;
  }

  // Hard code to USD so it doens't include the country symbol,
  if (decimals) {
    const numFormatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    });
    return numFormatter.format(price);
  } else {
    const numFormatter = new Intl.NumberFormat("en-us");
    return numFormatter.format(price);
  }
};

export const getAffirmPriceOnly = async () => {
  // Affirm API
  // VP TODO: externalize this URL in environments
  const env = process.env.REACT_APP_ENVIRONMENT;
  let url =
    env === "production"
      ? "https://www.affirm.com/api/promos/v2/W6CLXI2Y0SKW7KRX?field=ala&logo_color=blue&logo_type=logo&page_type=category&show_cta=true"
      : "https://sandbox.affirm.com/api/promos/v2/W6CLXI2Y0SKW7KRX?field=ala&logo_color=blue&logo_type=logo&page_type=category&show_cta=true";
  // let url =
  // "https://sandbox.affirm.com/api/promos/v2/W6CLXI2Y0SKW7KRX?field=ala&logo_color=blue&logo_type=logo&page_type=category&show_cta=true";

  // Iterating through each '.affirm-price-only' attribute and pulling the 'data-amount' from Alterra IKON/AMP API
  // Inject the 'data-amount' as an amount parameter into the Affirm API to send individual price-points
  let affirmElements = document.querySelectorAll(".affirm-price-only");
  for (let i = 0; i < affirmElements.length; i++) {
    let rackPrice = affirmElements[i].getAttribute("data-amount");
    let fetchUrl = url + "&amount=" + rackPrice;

    const response = await fetch(fetchUrl);
    const data = await response.json();

    // Isolating the Affirm monthly payment amount from the API repsonse of each price-point
    const div = document.createElement("DIV");
    div.innerHTML = data.promo.html_ala;
    if (!div.querySelector(".affirm-ala-price")) return; // wait for price

    let price = "";
    if (div.querySelector(".affirm-ala-price")) {
      price = div.querySelector(".affirm-ala-price").innerHTML;
    } else {
      const promoPrice = (data.promo.html_ala || "").match(/.* (\$\d*)\/mo .*/);
      if (promoPrice) {
        price = promoPrice[1] || "";
      }
    }

    affirmElements[i].innerText = price;
  }
};

export const pageToTop = () => {
  if (!window.location.hash) {
    window.onload = function () {
      window.scrollTo(0, 0);
    };
  }
};

const affirmCall = (callback) => {
  const affirmScript = document.querySelectorAll("#affirm-script");
  if (affirmScript.length) {
    // Check if affirm script is injected in the HTML
    if (window.affirm) {
      // Trigger affirm call if afirmApi is ready
      callback(window.affirm);
    } else {
      // Wait for afirmApi to be ready before triggering all calls
      // Useful when multiple affirm components are initialized before affirmAPI is ready
      const oldOnload = affirmScript[0].onload;
      affirmScript[0].onload = () => {
        oldOnload();
        callback(window.affirm);
      };
    }
  } else {
    // Otherwise inject the script
    let script = document.createElement("script");
    script.id = "affirm-script";
    script.type = "text/javascript";
    script.async = true;
    script.src = "/js/affirm-script.js";
    script.strategy = "afterInteractive";
    document.getElementsByTagName("body")[0].appendChild(script);

    script.onload = () => {
      // Trigger affirm call once afirmApi is ready
      callback(window.affirm);
    };
  }
};

var prerenderTimer = null;

export const setPrerenderReady = () => {
  if (prerenderTimer) {
    window.clearTimeout(prerenderTimer);
  }
  const prerenderEvent = new Event("prerenderReady");
  prerenderTimer = window.setTimeout(function () {
    window.prerenderReady = true;
    affirmCall((affirmApi) => {
      // affirmApi.ui.refresh();
      console.log("CC Affirm Call");
    });
    getAffirmPriceOnly();
    pageToTop();
    console.log("Prerender and Affirm ready");
    dispatchEvent(prerenderEvent);
  }, 500);
};

// Make sure all panels on the HP are ready to be loaded

let homepageTimer = null;
window.homepageReady = false;

export const setHomepageReady = () => {
  if (homepageTimer) {
    window.clearTimeout(homepageTimer);
  }
  homepageTimer = window.setTimeout(() => {
    window.homepageReady = true;
  }, 500);
};

export const buildCountryDataFromCMS = (data) => {
  let dataPoints = [];
  let countries;

  if (data) {
    countries = data;

    countries.forEach((country) => {
      // if (!country.fields) {
      //     return
      // }

      const title = country.country;
      const entryTitle = country.country;
      const regions = country.regions;

      let countryData = {
        title,
        id: entryTitle.toLowerCase().replace(/ /g, "_"),
        regions: [],
      };

      if (regions) {
        regions.forEach((region) => {
          const regionTitle = region.title;
          const entryTitle = region.title;
          const navMapImage = region.navMapImage || "";
          const points = region.mapPoints || "";
          let mapPoints = [];

          if (points) {
            points.forEach((point) => {
              mapPoints.push({
                ...point.fields,
                createdAt: point.sys.createdAt,
              });
            });
          }

          countryData.regions.push({
            regionTitle,
            navMapImage,
            id: entryTitle
              .toLowerCase()
              .replace(/ /g, "_")
              .replace("canada_", "")
              .replace("usa_", ""),
            mapPoints,
          });
        });
      }

      dataPoints.push(countryData);
    });
  }

  return dataPoints;
};

export const convertStringYYYMMDDToDate = (str) => {
  let date = null;
  if (str) {
    const strObj = str.split("-");
    date = new Date(strObj[0], strObj[1] - 1, strObj[2]);
  }
  return date;
};

export const supportsWebp = () => {
  const elem = document.createElement("canvas");

  if (!!(elem.getContext && elem.getContext("2d"))) {
    // was able or not to get WebP representation
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
};

export const buildPassBlockout = (pass) => {
  pass.blackouts = {};
  const blackoutsSorted = [];

  for (let i = 0; i < pass.access.length; i++) {
    if (pass.access[i].blackoutDates && pass.access[i].blackoutDates.length) {
      // Normalize the array
      for (let j = 0; j < pass.access[i].blackoutDates.length; j++) {
        let blackout = {
          to: pass.access[i].blackoutDates[j].to,
          from: pass.access[i].blackoutDates[j].from,
          where: pass.access[i].blackoutDates[j].hemisphere,
        };
        blackoutsSorted.push(blackout);
      }

      // Sort it by from date
      blackoutsSorted.sort((a, b) => a.from.localeCompare(b.from));

      // Do the date transformation
      blackoutsSorted.forEach((blackout) => {
        if (!pass.blackouts[blackout.where]) {
          pass.blackouts[blackout.where] = [];
        }

        const toHuman = (data) => {
          return data.toLocaleString(undefined, {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
        };

        pass.blackouts[blackout.where].push({
          from: `${toHuman(convertStringYYYMMDDToDate(blackout.from))}`,
          to: `${toHuman(convertStringYYYMMDDToDate(blackout.to))}`,
        });
      });
    }
  }

  // remove duplicates from blackouts
  for (const where in pass.blackouts) {
    var uniqueBlackouts = (function (arr) {
      var m = {},
        newarr = [];
      for (var i = 0; i < arr.length; i++) {
        var v = JSON.stringify(arr[i]);
        if (!m[v]) {
          newarr.push(arr[i]);
          m[v] = true;
        }
      }
      return newarr;
    })(pass.blackouts[where]);
    pass.blackouts[where] = uniqueBlackouts;
  }

  // If there are no blackouts, set the key to false
  if (!Object.keys(pass.blackouts).length) {
    pass.blackouts = false;
  } else {
    return pass;
  }

  return pass;
};

// Filters overides by client's adobe target activity ID. See amp.
// Returns the personalizedOveride or null
export const adobeTargetPersonalization = (
  personalizationOverides,
  activityId
) =>
  personalizationOverides &&
  personalizationOverides.filter(
    (overide) =>
      overide?.adobeTargetActivityID &&
      overide?.adobeTargetActivityID === activityId
  )[0];

// Overides componentData with personalizationData... Only works at a single level
export const personalizeDefaultComponentData = (
  componentData,
  personalizationOveride
) => {
  if (personalizationOveride) {
    const merged = merge({}, componentData, personalizationOveride, (a, b) => {
      if (b?._type == "fillerObject") {
        return a;
      } else {
        return b == null ? a : undefined;
      }
    });
    return merged;
  }
  return { ...componentData, adobeTargetActivityID: null };
};

export const lowerCaseEveryWord = (sentence, test) => {
  // console.log(sentence, test);
  if (!sentence) {
    return sentence;
  }
  return sentence
    .split(" ")
    .map((word) => word.toLowerCase())
    .join(" ");
};
