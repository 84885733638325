import {
  buildPassBlockout,
  convertStringYYYMMDDToDate,
  stripYearFromPassName,
  updateEnvSpecificAccountLink,
} from "./utils";
import { CURRENT_SEASON } from "./current-season";

// -------------------------------
// USERS Utils
// -------------------------------

export const getActiveSeason = () => {
  return `${parseInt(CURRENT_SEASON.substring(0, 2)) + 2000}-${
    parseInt(CURRENT_SEASON.substring(2)) + 2000
  }`;
};

export const getPreviousSeason = () => {
  return `${parseInt(CURRENT_SEASON.substring(0, 2)) + 1999}-${
    parseInt(CURRENT_SEASON.substring(2)) + 1999
  }`;
};

export const getUserProductBlackoutDates = (
  products,
  activeSeason,
  warningDays = null
) => {
  if (!products) {
    return null;
  }

  // for testing - comment out for prod
  // accessWithBlackouts.push({blackout_dates: [{from: '2020-06-10', to: '2021-06-20'}], resorts: [{name: 'Matts Mountain'}, {name: 'Johns Mountain'}]})
  // accessWithBlackouts.push({blackout_dates: [{from: '2020-06-14', to: '2021-06-20'}], resorts: [{name: 'Shark Mountain'}, {name: 'Big Mountain'}]})

  if (!warningDays) {
    warningDays = 5;
  }

  const today = new Date();
  let upcomingBlackouts = [];
  let activeBlackouts = [];

  // loop through all products linked to this account
  for (let i = 0; i < products.length; i++) {
    let product = products[i];
    if (product.access) {
      // loop through the access details of each account
      for (let j = 0; j < product.access.length; j++) {
        let accessPeriod = product.access[j];
        // some products have an empty array for blackout_dates
        if (
          accessPeriod.blackoutDates &&
          accessPeriod.blackoutDates.length > 0
        ) {
          // loop through each blackout period and compare dates to now
          for (let k = 0; k < accessPeriod.blackoutDates.length; k++) {
            const blackoutDate = accessPeriod.blackoutDates[k];
            const fromDate = convertStringYYYMMDDToDate(blackoutDate.from);
            let fromDateMinusWarningDays = convertStringYYYMMDDToDate(
              blackoutDate.from
            );
            fromDateMinusWarningDays.setDate(
              fromDateMinusWarningDays.getDate() - warningDays
            );
            const toDate = convertStringYYYMMDDToDate(blackoutDate.to);

            // if today is greater than the warning date && today is less then the start of the blackout period
            if (today >= fromDateMinusWarningDays && today < fromDate) {
              //console.log('Within the ' + warningDays + ' warning days')
              upcomingBlackouts.push({
                from: blackoutDate.from,
                to: blackoutDate.to,
                resorts: accessPeriod.resorts,
              });
            }
            // if today is greater than the from date and less then the closing date
            if (today > fromDate && today < toDate) {
              // console.log('There are blackouts currently active')
              activeBlackouts.push({
                from: blackoutDate.from,
                to: blackoutDate.to,
                resorts: accessPeriod.resorts,
              });
            }
          }
        }
      }
    }
  }

  // console.log('upcomingBlackouts')
  // console.log(upcomingBlackouts)
  // console.log('activeBlackouts')
  // console.log(activeBlackouts)

  return {
    warningDays: warningDays,
    upcoming: upcomingBlackouts,
    active: activeBlackouts,
  };
};

export const getUserBlackoutDates = (userData) => {
  for (
    var passIndex = 0;
    passIndex < userData.products.all.length;
    passIndex++
  ) {
    let pass = userData.products.all[passIndex];
    return buildPassBlockout(pass);

    // for (let i = 0; i < pass.access.length; i++) {
    //   if (pass.access[i].blackout_dates.length) {
    //     // Normalize the array
    //     for (let j = 0; j < pass.access[i].blackout_dates.length; j++) {
    //       let blackout = {
    //         to: pass.access[i].blackout_dates[j].to,
    //         from: pass.access[i].blackout_dates[j].from,
    //         where: pass.access[i].resorts[0].hemisphere,
    //       };
    //       blackoutsSorted.push(blackout);
    //     }

    //     // Sort it by from date
    //     blackoutsSorted.sort((a, b) => a.from.localeCompare(b.from));

    //     // Do the date transformation
    //     blackoutsSorted.map((blackout) => {
    //       if (!pass.blackouts[blackout.where]) {
    //         pass.blackouts[blackout.where] = [];
    //       }

    //       const toHuman = (data) => {
    //         return data.toLocaleString(undefined, {
    //           month: "short",
    //           day: "numeric",
    //           year: "numeric",
    //         });
    //       };

    //       pass.blackouts[blackout.where].push({
    //         from: `${toHuman(convertStringYYYMMDDToDate(blackout.from))}`,
    //         to: `${toHuman(convertStringYYYMMDDToDate(blackout.to))}`,
    //       });
    //     });
    //   }
    // }

    // // remove duplicates from blackouts
    // for (const where in pass.blackouts) {
    //   var uniqueBlackouts = (function (arr) {
    //     var m = {},
    //       newarr = [];
    //     for (var i = 0; i < arr.length; i++) {
    //       var v = JSON.stringify(arr[i]);
    //       if (!m[v]) {
    //         newarr.push(arr[i]);
    //         m[v] = true;
    //       }
    //     }
    //     return newarr;
    //   })(pass.blackouts[where]);
    //   pass.blackouts[where] = uniqueBlackouts;
    // }

    // // If there are no blackouts, set the key to false
    // if (!Object.keys(pass.blackouts).length) {
    //   pass.blackouts = false;
    // } else {
    //   return pass;
    // }
    // // blackouts ends
  }
};

export const showMountainChecklist = (userData) => {
  let shouldShowMountainChecklist = false;
  if (
    userData.mountainChecklist &&
    ((userData.mountainChecklist.uploadPhoto &&
      userData.mountainChecklist.uploadPhoto.length) ||
      (userData.mountainChecklist.signWaiver &&
        userData.mountainChecklist.signWaiver.length) ||
      (userData.mountainChecklist.signSummerWaiver &&
        userData.mountainChecklist.signSummerWaiver.length))
  ) {
    shouldShowMountainChecklist = true;
  }
  return shouldShowMountainChecklist;
};

// Used to calculate the total number of days used for the active pass - the total_days property is for multiple passes (can be)
// VP this method used to do a calculation but Trineo (AMP) now provides the calculated number in the API
export const getDaysUsedForActivePass = (redemptionProducts, activePass) => {
  if (!redemptionProducts || !activePass) {
    return 0;
  }

  // get the active pass's redemptions
  let activePassRedemptions = null;
  if (redemptionProducts.length) {
    for (let i = 0; i < redemptionProducts.length; i++) {
      if (
        redemptionProducts[i].season === activePass.season &&
        redemptionProducts[i].productName === activePass.productName
      ) {
        activePassRedemptions = redemptionProducts[i];
      }
    }
  }

  let liftRedemptions = activePassRedemptions
    ? activePassRedemptions.totalDays
    : null;
  // VP we no longer need to do this calculation because it's provided by the API
  /*if (activePassRedemptions && activePassRedemptions.lift_redemptions) {
      // loop through the active pass's lift redemptions array
      liftRedemptions = activePassRedemptions.lift_redemptions.reduce((numberOfRedemptions, liftRedemption) => {
          // if there are some redemptions
          if (liftRedemption.redemptions.length) {
              // calculate the total of redemptions within - the quantity can be more than 1 fyi
              const qty = liftRedemption.redemptions.reduce((total, redemption) => {
                  if (redemption.quantity) {
                      total += redemption.quantity
                  }
                  return total
              }, 0)
              numberOfRedemptions += qty
          }
          return numberOfRedemptions
      }, 0)
  }*/

  return liftRedemptions;
};

export const buildEmailPreferencesLink = (link, email) => {
  let emailLink = updateEnvSpecificAccountLink(link);
  if (email) {
    emailLink += `&email=${email}`;
    return emailLink;
  }
  return null;
};

export const doesUserHavePrimaryPass = (userData) => {
  return !!(
    userData?.products &&
    userData.products?.primary &&
    Object.keys(userData.products.primary).length > 0
  );
};

export const doesUserPrimaryPassBelongsToProductCode = (
  productCode,
  userProductCode
) => {
  if (productCode === userProductCode) {
    return true;
  } else if (
    /^ikon\d/.test(productCode) &&
    /^ikon\d/.test(userProductCode) &&
    userProductCode.endsWith(productCode.substring(productCode.length - 4))
  ) {
    return true;
  } else {
    return false;
  }
};

export const doesUserHaveValidPrimaryPass = (userData, displayedPasses) => {
  return (
    doesUserHavePrimaryPass(userData) &&
    displayedPasses.filter(({ productCode }) =>
      doesUserPrimaryPassBelongsToProductCode(
        productCode,
        userData.products.primary.productCode
      )
    ).length > 0
  );
};

export const getUserPrimaryPass = (userData) => {
  if (doesUserHavePrimaryPass(userData)) {
    return {
      productName: stripYearFromPassName(userData.products.primary.productName),
      productCode: userData.products.primary.productCode,
    };
  }
  return {};
};

// export const getUserPrimaryPass = (userData) => {
//   return doesUserHavePrimaryPass(userData) ? userData?.products && userData?.products?.primary;
// };

// -------------------------------
// END Users Utils
// -------------------------------
