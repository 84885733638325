import { getLocale } from "../../../utils";

const locale = getLocale();

const destinationPassDetailsProjection = `{
    _id,
    _type,
    resortCode,
    accessChanged,
    accessChangedBase,
    accessChangedSession,
    manualOrder,
    orderRank,
    fullName,
    new,
    'newToPasses': newToPasses[] -> { passId }.passId,
    'informationTooltipContent': informationTooltipContent.${locale},
    'informationTooltipContentBase': informationTooltipContentBase.${locale},
    'informationTooltipContentSession': informationTooltipContentSession.${locale},
    'informationTooltipContentPrevious': informationTooltipContentPrevious.${locale},
    'informationTooltipContentBasePrevious': informationTooltipContentBasePrevious.${locale},
    'informationTooltipContentSessionPrevious': informationTooltipContentSessionPrevious.${locale},
    url
}`;

export default destinationPassDetailsProjection;
