import { getLocale } from "../utils";
import imageProjection from "./items/image-projection";
import affirmCalloutProjection from "./modules/affirm-callout-projection";
import basicLinkProjection from "./modules/basic-link-projection";
// import buttonProjection from "./modules/button-projection";

const locale = getLocale();

const notificationProjection = `{
  'uniqueId': uniqueId,
  'notificationTitle': notificationTitle.${locale},
  'icon': icon -> {
      title
  }.title,
  'message': message.${locale},
  notificationCta ${basicLinkProjection}
}`;

const currencyProjection = `{
  _id,
  _type,
  entryTitle,
  name,
  code,
  whiteFlag ${imageProjection},
  darkFlag ${imageProjection},
  exchangeRate
}`;

const navigationProjection = `{
  _id,
  _type,
  title,
  navLinks[] -> {
    _id,
    _type,
    entryTitle,
    'title': title.${locale},
    loggedInOnly,
    primaryCta ${basicLinkProjection},
    secondaryCta[] ${basicLinkProjection},
    tertiaryCta[] ${basicLinkProjection},
    'promoArea': promoArea.${locale},
  },
  'logInPrefixText': logInPrefixText.${locale},
  'logInLinkText': logInLinkText.${locale},
  'logInSuffixText': logInSuffixText.${locale}
}`;

const currencyModalProjection = `{
  _type,
  _id,
  'title': title.${locale},
  'description': description.${locale},
  'rememberSelectionText': rememberSelectionText.${locale},
  'submitButtonText': submitButtonText.${locale},
  'currencyLabel': currencyLabel.${locale},
  'languageLabel': languageLabel.${locale},
  'currencies': *[_type == "siteCurrency"] ${currencyProjection}
}`;

const accountDropdownProjection = `{
  entryTitle,
  'loginPromptTrailText': loginPromptTrailText.${locale},
  'loginPromptLinkText': loginPromptLinkText.${locale},
  'loginPromptLeadText': loginPromptLeadText.${locale},
  'userProductsSingular': userProductsSingular.${locale},
  'userProductsPlural': userProductsPlural.${locale},
  'familyProductsSingular': familyProductsSingular.${locale},
  'familyProductsPlural': familyProductsPlural.${locale},
  'noPassMessage': noPassMessage.${locale},
  'viewMyAccountCta': viewMyAccountCta.${locale},
  'passDaysUsed': passDaysUsed.${locale},
  'friendsAndFamily': friendsAndFamily.${locale},
  'viewPassesLink': viewPassesLink.${locale},
  'headerAccountRecommendedForYou': headerAccountRecommendedForYou.${locale},
  accountHeaderCardItem -> {
      'title': headline.${locale},
      image,
      postLink
  },
  'yourDestinations': yourDestinations.${locale},
  'chooseDestinationsBlurb': chooseDestinationsBlurb.${locale},
  'editFavoriteDestinations': editFavoriteDestinations.${locale},
  shopPassesLink ${basicLinkProjection},
  'covidCreditDisclaimer': covidCreditDisclaimer.${locale},
  'logOutLinkText': logOutLinkText.${locale},
  upcomingBlackoutsNotification -> ${notificationProjection},
  userNotifications[] -> ${notificationProjection},
  mountainChecklistNotification -> ${notificationProjection},
  accountLinks[] -> ${basicLinkProjection},
  'faqGroup': navFaqGroup -> {
    'entryTitle': entryTitle.${locale},
    'title': title.${locale},
    displayType,
    'faqContent': faqContent.${locale},
    faqList[] -> {
      'title': title.${locale},
      'content': content.${locale},
      removeFromSearch,
      searchRank
    },
    faqCta ${basicLinkProjection}
  }
}`;

const miniCartProjection = `{
  'subtotalText': subtotalText.${locale},
  'disclaimerText': disclaimerText.${locale},
  'cartButtonText': cartButtonText.${locale},
  'checkoutButtonText': checkoutButtonText.${locale},
  affirmCallout -> ${affirmCalloutProjection}
}`;

const abandonedCartProjection = `{
  showModal,
  isDetailedCart,
  'header': header.${locale},
  'subHeader': subHeader.${locale},
  'cartIconText': cartIconText.${locale},
  'cartText': cartText.${locale},
  'cartItemPreText': cartItemPreText.${locale},
  'cartItemTrailText': cartItemTrailText.${locale},
  image ${imageProjection},
  'ctaText': ctaText.${locale},
}`;

const headerProjection = (coBrand) => {
  return `{
    _id,
    _type,
    'navigation': ${navigationProjection},
    'currencyModal': *[_type == "currencyModal"][0] ${currencyModalProjection},
    'accountDropdown': *[_type == "headerAccountDropdown"][0] ${accountDropdownProjection},
    'sitePreferences': *[_type == "sitePreferences"][0] {
      'accessDetailsNorthernHemisphereText': accessDetailsNorthernHemisphereText.${locale},
      'accessDetailsSouthernHemisphereText': accessDetailsSouthernHemisphereText.${locale}
    },
    'miniCart': *[_type == "miniCart"][0] ${miniCartProjection},
    'abandonedCart': *[_type == "abandonedCart"][0] ${abandonedCartProjection},
    'cartMismatchError': *[_type == "cartMismatch"][0] {
      _id,
      'errorText': errorText.${locale},
      'buttonCopy': buttonCopy.${locale}
    },
    'coBrand': *[_type == "destination" && coBrandedId == "${coBrand}" ][0] {
      coBrandedId,
      resortLogo -> {
        coBrandedCartColor,
        coBrandedLogo ${imageProjection},
        colorLogo ${imageProjection},
        darkLogo ${imageProjection},
        logo ${imageProjection},
      },
    }
  }`;
};

export default headerProjection;
