import { getLocale } from "../../utils";
import statProjection from "../modules/stat-projection";

const locale = getLocale();

const subdestinationProjection = `{
  _id,
  'description': description.${locale},
  geoData->,
  globeFileName,
  logo->,
  stats-> ${statProjection},
  title,
}
`;

export default subdestinationProjection;
