//import React from 'react'
import { InstantSearch, Index } from "react-instantsearch-dom";
import AutoComplete from "./auto-complete";
import ConfigureFaqs from "./config/configure-faqs";
import ConfigurePages from "./config/configure-pages";
import onSearchStateChange from "../on-search-state-change";
import getSearchQuickLinksPageHits from "./get-search-quick-links-page-hits";
//import {connect} from 'react-redux'
//import {createPropsSelector} from 'reselect-immutable-helpers'
//import {sitePreferences} from '../../app/selectors'
//import algoliaClient from '../../../connectors/algolia'
import algoliaClient from "../../../utils/algolia-client";
// import { useEffect, useState } from "react";
import {
  SEARCH_SETTINGS,
  useCMSContent,
} from "../../../utils/context/CMSContentContext";

const AutoCompleteSearch = ({
  lang,
  showSearch,
  searchMethod,
  toggleSearch,
  id,
}) => {
  const placeholder = lang === "en" ? "I'm searching for..." : "Je cherche...";
  const locale = lang === "en" ? "en-US" : "fr-CA";

  const [{ searchSettings }] = useCMSContent((store) => ({
    searchSettings: store[SEARCH_SETTINGS],
  }));
  // const isPreview = false;

  if (!showSearch) {
    return null;
  }

  const getSearchQuickResults = (requests, locale) => {
    const pageHits = getSearchQuickLinksPageHits(searchSettings, locale);
    return Promise.resolve({
      results: requests.map(({ indexName }) =>
        indexName === "sanityPages"
          ? { hits: pageHits, nbHits: 0, bPages: 0, processingTimeMS: 0 }
          : { hits: [], nbHits: 0, nbPages: 0, processingTimeMS: 0 }
      ),
    });
  };

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return getSearchQuickResults(requests, locale);
      }
      return algoliaClient.search(requests);
    },
  };

  return (
    <InstantSearch
      indexName="sanityPages"
      searchClient={searchClient}
      onSearchStateChange={onSearchStateChange}
    >
      <AutoComplete
        lang={lang}
        locale={locale}
        placeholder={placeholder}
        showSearch={showSearch}
        searchMethod={searchMethod}
        toggleSearch={toggleSearch}
        id={id}
      />
      <Index indexName="sanityPages">
        <ConfigurePages locale={locale} />
      </Index>
      <Index indexName="sanityFaqs">
        <ConfigureFaqs locale={locale} />
      </Index>
    </InstantSearch>
  );
};

//const mapStateToProps = createPropsSelector({sitePreferences})
//export default connect(mapStateToProps)(AutoCompleteSearch)

export default AutoCompleteSearch;
