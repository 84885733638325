import { getLocale } from "../utils";

const locale = getLocale();

const footerProjection = () => {
  return `{
    _id,
    'title': title,
    'emailHeader': emailHeader.${locale},
    'emailText': emailText.${locale},
    'emailInputPlaceholder': emailInputPlaceholder.${locale},
    'emailButtonText': emailButtonText.${locale},
    'emailFormHelpText': emailFormHelpText.${locale},
    'emailSuccessMessage': emailSuccessMessage.${locale},
    'emailFormPageId': emailFormPageId,
    'emailFormSiteId': emailFormSiteId,
    'emailFormParentPageId': emailFormParentPageId,
    'emailFormPostUrl': emailFormPostUrl,
    'emailModalHeader': emailModalHeader.${locale},
    'emailModalText': emailModalText.${locale},
    'emailModalInputPlaceholder': emailModalInputPlaceholder.${locale},
    'emailModalButtonText': emailModalButtonText.${locale},
    'emailModalFormHelpText': emailModalFormHelpText.${locale},
    'emailModalSuccessMessage': emailModalSuccessMessage.${locale},
    'emailModalFormPageId': emailModalFormPageId,
    'emailModalFormSiteId': emailModalFormSiteId,
    'emailModalFormParentPageId': emailModalFormParentPageId,
    'emailModalFormPostUrl': emailModalFormPostUrl,
    'cookieSettingsSnippet': cookieSettingsSnippet,
    'footerDisclaimerText': footerDisclaimerText.${locale},
    'footerDisclaimerRichText': footerDisclaimerRichText.${locale},
    footerLinks[] -> {
      _id,
      _type,
      _key,
      entryTitle,
      'title': coalesce(title.${locale}, title.en),
      url,
      openInNewContext
    },
    'footerSubLinks': footerSubLinks[] {
      _key,
      _type,
      entryTitle,
      'title': coalesce(title.${locale}, title.en),
      'url': coalesce(url.${locale}, url.en)
    }
  }`;
};

export default footerProjection;
