// ==========================
// Initilizing the store to fetch/save a data from the CMS
// Steps
//    1. Create an exportable constant. This is used as the key to access the content in the store.
//          e.i. export const MY_NEWLY_FETCHED_DATA = 'myNewlyFetchedData'
//    2. Inside the `useStoreData` function and during the initilization of the 'store' variable,
//       add a default value for the key.
//          e.i
//              const ...useRef({
//                  ...
//                  [MY_NEWLY_FETCHED_DATA]: null
//                  ...
//              })
//    3. Inside the `useStoreData` function, create a function that returns an
//       object containing the parameters of the `sanityRequest` function (see sanityRequest docs)
//           e.i
//               const getNewlyFetchedData = ({ ... })
//    4. Add the created function inside the `setter` object in the return value of `useStoreData`.
//           e.i
//               return {
//                  ...
//                  setters: {
//                      ...
//                      getNewlyFetchedData,
//                      ...
//                  }
//                  ...
//               }
// ==========================
// Using the context in a Functional Component:
// Steps
//    1. import `useCMSContent` in the file
//            e.i. `import { useCMSContent } from '../utils/context/CMSContentContext';`
//    2. Initialized the the state variables
//            e.i. const [subContext, getterFunctions] = useCMSContent(selectors)
//                      - subContext: the context you requested using the selector - the return value of your selector
//                      - getterFunctions: functions to fetch the data from the CMS (getThis, getThat, getThose),
//                                         it also saves the data into the store - to be accessed by values in the subContext
//                      - selectore: A function that takes a store and returns the subcontext
//            e.i. More example - grabs the pageDataBySlug content from the store and its fetcher function.
//                      const [
//                        { pageDataBySlug },
//                        { getPageDataBySlug, sanityRequest }
//                      ] = useCMSContent((store) => ({
//                        pageDataBySlug: store.pageDataBySlug
//                      }))
//            e.i. Using a Key example - Unifying the keys so you don't have to keep guesing
//                      const [
//                        { pageDataBySlug },
//                        { getPageDataBySlug, sanityRequest }
//                      ] = useCMSContent((store) => ({
//                        pageDataBySlug: store[PAGE_DATA_BY_SLUG]
//                      }))
//           e.i. Renaming the store value - changing the store key (global) in the state key (local)
//                      const [
//                        { THIS_CAN_ALSO_CHANGE },
//                        { getPageDataBySlug, sanityRequest }
//                      ] = useCMSContent((store) => ({
//                        THIS_CAN_ALSO_CHANGE: store[PAGE_DATA_BY_SLUG]
//                      }))
//    3. Fetched the data using sanityRequest and the fetcher function
//           e.i. Fetch the data inside useEffect
//                      useEffect(() => {
//                        sanityRequest(getPageDataBySlug(SOME_SLUG))
//                          .catch(console.error)
//                      }, [sanityRequest, getPageDataBySlug])
//           e.i. Fetch the data inside useEffect and redirect to 404
//                      useEffect(() => {
//                        sanityRequest(getPageDataBySlug(SOME_SLUG))
//                          .then((data) => {
//                            // NEVER USE THIS `DATA` TO POPULATE YOUR COMPONENT - USE THE ONE INITIALIZED BY STEP 2.
//                            if (some_logic_that_checks_if_404) {
//                              setNotFound(true)
//                              getPageDataBySlug('404)
//                            }
//                          })
//                          .catch(console.error)
//                      }, [sanityRequest, getPageDataBySlug, setNotFound])
//           e.i. Fetch the data inside an onclick method
//                      const someOnclick = () => {
//                          sanityRequest(getPageDataBySlug(SOME_SLUG))
//                              .catch(console.error)
//                      }
// ==========================
// Using the context in a Class Component - We're moving towards functional components... so you can just ignore this:
// Steps
//    1. Check AllContents.js if the the data is included in the selector. If so, skip Step 2
//    2. Include the data in the selector
//           e.i. ... = useCMSContent((store) => ({
//                  ...
//                  pageDataBySlug: store[PAGE_DATA_BY_SLUG]
//                  ...
//                })
//    3. Import `AllContenxt` in the file
//           e.i. `import { AllContexts } from '../utils/context/AllContexts';`
//    4. Inject AllContext to the context of the component.
//          e.i. ComponentClassName.contextType = AllContexts
//    5. Access the data and its fetcher functions using `this.context`
//           e.i. const { pageDataBySlug, sanityRequest, getPageDataBySlug } = this.context;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  // useState,
} from "react";

import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";

import { getClient, usePreviewHook, previewDocumentLimit } from "../../client";

import {
  getCookieValue,
  getLocale,
  getQueryParamByName,
  getUTMCampaignCoBranded,
  stripLocaleUrl,
} from "../utils";

import {
  getAppPageData,
  getAllEventsPageData,
  getStokeEventsPageData,
  // getGlobalMetadata,
  getCountriesData,
  getDestinationPageData,
  getDestinationPassListingPageData,
  // getDestinationsData,
  // getDestinationsLiteData,
  getDestinationsPageData,
  getFavoriteDestinationsData,
  getPassDetailsDestinationsData,
  getIkonPassesData,
  getLocalPassLandingPageData,
  getLocalPassPageData,
  getPageDataBySlug,
  getPassPageData,
  getRegionsData,
  getSearchPageData,
  getTripsLandingPageData,
  getTripPageData,
  getSiteWideComponentData,
  // getHeaderData,
  // getHeaderNavigationData,
  // getHeaderAccountDropdownData,
  getHomepageData,
  // getHomepageNextYearData,
  // getCurrencyLanguageModalData,
  // getCurrenciesData,
  // getPassCartWidgetContent,
  getSitePreferencesData,
  getFaqPageData,
  // getFooterData,
  getShopPassesLandingPageData,
  getShopPassesLandingPagePreviousData,
  getCurrencyCodesData,
  getAccessDetailsDestinationsData,
} from "../sanity";

export const PREVIEW_MODE = "previewMode";
export const ABANDON_CART_PREVIEW_MODE = "abandonCartPreviewMode";
export const AMP_PREVIEW_MODE = "ampPreviewMode";
export const OVER_PREVIEW_DOCUMENT_LIMIT = "overPreviewDocumentLimit";
export const PAGE_SEO = "pageSeo";
export const HEADER_CONTENT = "headerContent";
export const FOOTER_CONTENT = "footerContent";
export const GLOBAL_META_DATA = "globalMetaData";
export const SEARCH_SETTINGS = "searchSettings";
export const LOCAL_PASS_LANDING_PAGE = "localPassLandingPage";
export const LOCAL_PASS_PAGE = "localPassPage";
export const APP_PAGE = "appPage";
export const ALL_EVENTS_PAGE = "allEventsPage";
export const STOKE_EVENTS_PAGE = "stokeEventsPage";
export const GLOBAL_TRANSLATIONS = "globalTranslations";
export const REGIONS = "regions";
export const DESTINATION_PASS_LISTING_PAGE = "destinationPassListingPage";
export const IKON_PASSES = "ikonPasses";
export const SITE_PREFERENCES = "sitePreferences";
export const PAGE_DATA_BY_SLUG = "pageDataBySlug";
export const TRIP_PAGE = "tripPage";
export const TRIPS_LANDING_PAGE = "tripsLandingPage";
export const DESTINATIONS_LANDING_PAGE = "destinationsLandingPage";
export const DESTINATION_PAGE = "destinationPage";
export const SHOP_PASSES_LANDING_PAGE = "shopPassesLandingPage";
export const SHOP_PASSES_LANDING_PAGE_TEST = "shopPassesLandingPageTest";
export const SHOP_PASSES_LANDING_PAGE_PREVIOUS =
  "shopPassesLandingPagePrevious";
export const PASS_DETAILS_PAGE = "passDetailsPage";
export const PASS_DETAILS_DESTINATIONS = "passDetailsDestinations";
export const DESTINATIONS_LITE = "destinationsLite";
export const FAVORITE_DESTINATIONS = "favoriteDestinations";
export const HOME_PAGE = "homePage";
export const HOME_PAGE_NEXT_YEAR = "homePageNextYear";
export const FAQ_PAGE = "faqPage";
export const SEARCH_PAGE = "searchPage";
export const CURRENCY_CODES = "currencyCodes";
export const COUNTRIES = "countries";
export const ACCESS_DETAILS_DESTINATIONS = "accessDetailsDestinations";

const CMSContentContext = createContext();

const useStoreData = () => {
  const previewMode = getCookieValue("preview") ? true : false;
  const abandonCartPreviewMode = getCookieValue("abandon-cart-preview")
    ? true
    : false;
  const ampPreviewMode = getCookieValue("preview-amp") ? true : false; // no need to worry about doc #s for this

  const store = useRef({
    [PREVIEW_MODE]: previewMode,
    [ABANDON_CART_PREVIEW_MODE]: abandonCartPreviewMode,
    [AMP_PREVIEW_MODE]: ampPreviewMode,
    [OVER_PREVIEW_DOCUMENT_LIMIT]: false,
    [PAGE_SEO]: null,
    [HEADER_CONTENT]: null,
    [FOOTER_CONTENT]: null,
    [GLOBAL_META_DATA]: null,
    [SEARCH_SETTINGS]: null,
    [GLOBAL_TRANSLATIONS]: null,
    [SITE_PREFERENCES]: null,
    [IKON_PASSES]: null,
    [COUNTRIES]: null,
    [REGIONS]: null,
    [DESTINATIONS_LITE]: null,
    destinations: null,
    [FAVORITE_DESTINATIONS]: null,
    [PASS_DETAILS_DESTINATIONS]: null,
    [PASS_DETAILS_PAGE]: null,
    passCartWidget: null,
    [SHOP_PASSES_LANDING_PAGE]: null,
    [SHOP_PASSES_LANDING_PAGE_TEST]: null,
    [SHOP_PASSES_LANDING_PAGE_PREVIOUS]: null,
    [DESTINATIONS_LANDING_PAGE]: null,
    [DESTINATION_PAGE]: null,
    [DESTINATION_PASS_LISTING_PAGE]: null,
    [TRIPS_LANDING_PAGE]: null,
    [TRIP_PAGE]: null,
    [LOCAL_PASS_LANDING_PAGE]: null,
    [LOCAL_PASS_PAGE]: null,
    [APP_PAGE]: null,
    [ALL_EVENTS_PAGE]: null,
    [PAGE_DATA_BY_SLUG]: null,
    [HOME_PAGE]: false,
    [HOME_PAGE_NEXT_YEAR]: null,
    [FAQ_PAGE]: false,
    [SEARCH_PAGE]: null,
    [CURRENCY_CODES]: false,
    [ACCESS_DETAILS_DESTINATIONS]: null,
  });

  /**
   * A Function to grab a data from the store
   * Returns all the store if keyword is not provided.
   */
  const get = useCallback(
    (keyword) => (keyword ? store?.current[keyword] : store.current),
    []
  );

  /**
   * A list of callbacks to be executed when the store is updated
   */
  const subscribers = useRef(new Set());

  /**
   * A function that updates the store and executes the subscribers
   */
  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    subscribers.current.forEach((callback) => {
      return callback();
    });
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  /*
   * Sanity Request
   * This function deals with caching the data and/or fetching it in the CMS if it's not saved in the cache.
   * Param: Requestor
   *   - key: string = one of the exportable constant defined during the initialization-phase of the store (see module docs)
   *   - invalidateSavedData: (data) => boolean = a function that is used to forcefully ignore the saved value in the store
   *   - query: string = Sanity query and projection
   *   - queryParams: string = additionaly sanity query params
   *   - isCollections: boolean = is the expected sanity data a collection, used on filtering draft/published data depending on previewMode.
   *   - hasSeo: boolean = does the fetch requires to reset the saved SEO data with the SEO data returned by CMS, used primarily for pages/search
   * Return: Sanity Response
   *   - HIGHLY NOT
   */
  const sanityRequest = async (requestor) => {
    const {
      key,
      invalidateSavedData,
      query,
      queryParams,
      isCollection,
      hasSeo,
    } = requestor;
    // Grabs the data from the store using the provided key...
    //    keys are the exportable constant defined during the initialization-phase of the store (see module docs)
    const value = get(key);

    // Cache Check. if value exist or valid - return the saved data otherwise, fetch and save it to the store accordingly.
    if (
      value &&
      // True if invalidateSavedData function doesn't exist and False if it exist and returns true
      (invalidateSavedData === undefined ||
        (invalidateSavedData && !invalidateSavedData(value)))
    ) {
      return value;
    } else {
      let response = await getClient(previewMode).fetch(query, queryParams);
      if (!isCollection) {
        // Sanity returns a list an object has a draft equivalent... this filters out draft or published pages
        response = filterDataToSingleItem(response, previewMode);
      }

      let seo = {};
      // update the SEO if request requires SEO to be updated
      if (hasSeo && response) {
        seo = { [PAGE_SEO]: response.seo };
      }

      // save to the store
      set({ [key]: response, ...seo });

      // Return the response for other logic i.e. 404 checks - not primarily used to render the page
      return response;
    }
  };

  if (previewMode) {
    getClient()
      .fetch("count(*)")
      .then((numDocuments) => {
        if (numDocuments > previewDocumentLimit) {
          set({ [OVER_PREVIEW_DOCUMENT_LIMIT]: true });
        }
      });
  }

  const setPreviewMode = (value) => {
    set({ [PREVIEW_MODE]: value });
  };

  const setAbandonCartPreviewMode = (value) => {
    set({ [ABANDON_CART_PREVIEW_MODE]: value });
  };

  const setAmpPreviewMode = (value) => {
    set({ [AMP_PREVIEW_MODE]: value });
  };

  const filterDataToSingleItem = useCallback(
    (data, i) => {
      if (!Array.isArray(data)) {
        return data;
      }

      if (data.length === 1) {
        return data[0];
      }

      if (previewMode) {
        return (
          data.find((item) => item._id.startsWith(`drafts.`)) || data[i || 0]
        );
      }

      return data[0];
    },
    [previewMode]
  );

  const filterCollectionWithDraftedData = (data) => {
    if (!Array.isArray(data)) {
      return data;
    }

    if (data.length === 1) {
      return data[0];
    }

    if (previewMode) {
      // Grab the IDs of documents with drafted entry.
      const draftsIds = data
        .filter((item) => item._id.startsWith("drafts."))
        .map(({ _id }) => _id.split(".")[1]);

      // Filter out the original entry while keeping the drafted entry
      return data.filter(({ _id }) => !draftsIds.includes(_id));
    }

    return data.filter(({ _id }) => !_id.startsWith("drafts."));
  };

  useEffect(() => {
    let coBrand =
      getQueryParamByName("co-branded") ||
      getUTMCampaignCoBranded(getQueryParamByName("utm_campaign")) ||
      document.getElementById("content-block-page");

    getClient(previewMode)
      .fetch(getSiteWideComponentData(coBrand))
      .then((response) => {
        const data = Object.entries(response)
          .map(([key, entry]) => ({
            [key]: filterDataToSingleItem(entry, previewMode),
          }))
          .reduce(
            (obj, item) => ({
              ...obj,
              ...item,
            }),
            {}
          );
        set({ [HEADER_CONTENT]: data.headerNav });
        set({ [FOOTER_CONTENT]: data.footerNav });
        set({ [GLOBAL_META_DATA]: data.metadata });
        set({ [SEARCH_SETTINGS]: data.searchSettings });
      });
  }, [filterDataToSingleItem, previewMode, set]);

  // const [sitePreferences, setSitePreferences] = useState(false);

  const getSitePreferences = () => ({
    key: SITE_PREFERENCES,
    query: getSitePreferencesData(),
    previewQuery: getSitePreferencesData(),
    preview: [
      getSitePreferencesData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getIkonPasses = () => ({
    key: IKON_PASSES,
    query: getIkonPassesData(),
    isCollection: true,
    previewQuery: getIkonPassesData(),
    preview: [
      getIkonPassesData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  // const [countries, setCountries] = useState(false);
  const getCountries = () => ({
    key: COUNTRIES,
    query: getCountriesData(),
    isCollection: true,
    previewQuery: getCountriesData(),
    preview: [
      getCountriesData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getRegions = (locale) => ({
    key: REGIONS,
    query: getRegionsData(locale),
    isCollection: true,
    previewQuery: getRegionsData(),
    preview: [
      getRegionsData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  // const getDestinationsLite = () => ({
  //   key: DESTINATIONS_LITE,
  //   query: getDestinationsLiteData(),
  //   isCollection: true,
  //   preview: [
  //     getDestinationsLiteData(),
  //     {
  //       initialData: get(DESTINATIONS_LITE),
  //       enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
  //     },
  //   ],
  // });

  // const [destinations, setDestinations] = useState(false);
  // const getDestinations = () => ({
  //   query: getDestinationsData(),
  //   value: destinations,
  //   saveValue: setDestinations,
  //   isCollection: true,
  //   preview: [
  //     getDestinationsData(),
  //     {
  //       initialData: destinations,
  //       enabled: previewMode && !overPreviewDocumentLimit,
  //     },
  //   ],
  // });

  const getAccessDetailsDestinations = () => ({
    key: ACCESS_DETAILS_DESTINATIONS,
    query: getAccessDetailsDestinationsData(),
    isCollection: true,
    previewQuery: getAccessDetailsDestinationsData(),
    preview: [
      getAccessDetailsDestinationsData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getFavoriteDestinations = (destinationCodes) => ({
    key: FAVORITE_DESTINATIONS,
    query: getFavoriteDestinationsData(destinationCodes),
    isCollection: true,
    previewQuery: getFavoriteDestinationsData(destinationCodes),
    preview: [
      getFavoriteDestinationsData(destinationCodes),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getPassDetailsDestinations = () => ({
    key: PASS_DETAILS_DESTINATIONS,
    query: getPassDetailsDestinationsData(),
    isCollection: true,
    previewQuery: getPassDetailsDestinationsData(),
    preview: [
      getPassDetailsDestinationsData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getPassDetailsPage = (slug) => ({
    key: PASS_DETAILS_PAGE,
    query: getPassPageData(slug),
    invalidateSavedData: (passDetailsPage) => {
      return passDetailsPage.url.current !== stripLocaleUrl(getLocale(), slug);
    },
    hasSeo: true,
    previewQuery: getPassPageData(slug),
    preview: [
      getPassPageData(slug),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  // const [passCartWidget, setPassCartWidget] = useState(null);
  // const getPassCartWidget = () => ({
  //   query: getPassCartWidgetContent(),
  //   value: passCartWidget,
  //   saveValue: setPassCartWidget,
  //   hasSeo: false,
  // });

  const getShopPassesLandingPage = () => ({
    key: SHOP_PASSES_LANDING_PAGE,
    query: getShopPassesLandingPageData(),
    hasSeo: true,
    previewQuery: getShopPassesLandingPageData(),
    preview: [
      getShopPassesLandingPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getShopPassesLandingPagePrevious = () => ({
    key: SHOP_PASSES_LANDING_PAGE_PREVIOUS,
    query: getShopPassesLandingPagePreviousData(),
    hasSeo: true,
    previewQuery: getShopPassesLandingPagePreviousData(),
    preview: [
      getShopPassesLandingPagePreviousData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getDestinationsLandingPage = () => ({
    key: DESTINATIONS_LANDING_PAGE,
    query: getDestinationsPageData(),
    hasSeo: true,
    previewQuery: getDestinationsPageData(),
    preview: [
      getDestinationsPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getDestinationPage = (slug) => ({
    key: DESTINATION_PAGE,
    query: getDestinationPageData(slug),
    hasSeo: true,
    previewQuery: getDestinationPageData(slug),
    preview: [
      getDestinationPageData(slug),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getDestinationPassListingPage = (slug) => ({
    key: DESTINATION_PASS_LISTING_PAGE,
    query: getDestinationPassListingPageData(slug),
    hasSeo: true,
    invalidateSavedData: (passListingPage) => {
      return passListingPage.url !== `local-passes/${slug}`;
    },
    previewQuery: getDestinationPassListingPageData(slug),
    preview: [
      getDestinationPassListingPageData(slug),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getTripsLandingPage = () => ({
    key: TRIPS_LANDING_PAGE,
    query: getTripsLandingPageData(),
    hasSeo: true,
    previewQuery: getTripsLandingPageData(),
    preview: [
      getTripsLandingPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getTripPage = (slug) => ({
    key: TRIP_PAGE,
    query: getTripPageData(slug),
    invalidateSavedData: (tripPage) => {
      return tripPage.url !== stripLocaleUrl(getLocale(), `adventures/${slug}`);
    },
    hasSeo: true,
    previewQuery: getTripPageData(slug),
    preview: [
      getTripPageData(slug),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getLocalPassLandingPage = () => ({
    key: LOCAL_PASS_LANDING_PAGE,
    query: getLocalPassLandingPageData(),
    hasSeo: true,
    previewQuery: getLocalPassLandingPageData(),
    preview: [
      getLocalPassLandingPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getLocalPassPage = (destination, pass) => ({
    key: LOCAL_PASS_PAGE,
    query: getLocalPassPageData(destination, pass),
    hasSeo: true,
    previewQuery: getLocalPassPageData(destination, pass),
    preview: [
      getLocalPassPageData(destination, pass),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getAppPage = () => ({
    key: APP_PAGE,
    query: getAppPageData(),
    hasSeo: true,
    previewQuery: getAppPageData(),
    preview: [
      getAppPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });
  const getAllEventsPage = () => ({
    key: ALL_EVENTS_PAGE,
    query: getAllEventsPageData(),
    hasSeo: true,
    previewQuery: getAllEventsPageData(),
    preview: [
      getAllEventsPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });
  const getStokeEventsPage = () => ({
    key: STOKE_EVENTS_PAGE,
    query: getStokeEventsPageData(),
    hasSeo: true,
    previewQuery: getStokeEventsPageData(),
    preview: [
      getStokeEventsPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getPageBySlug = (slug) => ({
    key: PAGE_DATA_BY_SLUG,
    query: getPageDataBySlug(slug),
    hasSeo: true,
    invalidateSavedData: () => {
      // Invalidate saved pageBySlug data when rendering the 404 page
      return slug === "404";
    },
    previewQuery: getPageDataBySlug(slug),
    preview: [
      getPageDataBySlug(slug),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getSearchPage = () => ({
    key: SEARCH_PAGE,
    query: getSearchPageData(),
    hasSeo: true,
    previewQuery: getSearchPageData(),
    preview: [
      getSearchPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getFaqPage = () => ({
    key: FAQ_PAGE,
    query: getFaqPageData(),
    hasSeo: true,
    previewQuery: getFaqPageData(),
    preview: [
      getFaqPageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  const getHomePage = () => ({
    key: HOME_PAGE,
    query: getHomepageData(),
    hasSeo: true,
    previewQuery: getHomepageData(),
    preview: [
      getHomepageData(),
      {
        enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
      },
    ],
  });

  // const getHomePageNextYear = () => ({
  //   key: HOME_PAGE_NEXT_YEAR,
  //   query: getHomepageNextYearData(),
  //   hasSeo: true,
  //   preview: [
  //     getHomepageNextYearData(),
  //     {
  //       enabled: previewMode && !get(OVER_PREVIEW_DOCUMENT_LIMIT),
  //     },
  //   ],
  // });

  const getCurrencyCodes = () => ({
    key: CURRENCY_CODES,
    query: getCurrencyCodesData(),
    isCollection: true,
  });

  // ----------------------------------

  return {
    get,
    subscribe,
    setters: {
      getAppPage,
      getAllEventsPage,
      getStokeEventsPage,
      getLocalPassLandingPage,
      // getLocalPassPage,
      // getGlobalTranslations,
      getRegions,
      getIkonPasses,
      getDestinationPage,
      getDestinationsLandingPage,
      getDestinationPassListingPage,
      getSitePreferences,
      getPageBySlug,
      getTripPage,
      getTripsLandingPage,

      getShopPassesLandingPage,
      getShopPassesLandingPagePrevious,
      getPassDetailsPage,
      getPassDetailsDestinations,
      getAccessDetailsDestinations,
      getFavoriteDestinations,
      getLocalPassPage,
      getCurrencyCodes,
      getHomePage,
      // getHomePageNextYear,
      getFaqPage,
      getSearchPage,
      setAbandonCartPreviewMode,
      setPreviewMode,
      setAmpPreviewMode,
      getCountries,

      filterCollectionWithDraftedData,
      filterDataToSingleItem,
      sanityRequest,
      usePreviewHook,
    },
  };
};

function CMSContentProvider({ children }) {
  return (
    <CMSContentContext.Provider value={useStoreData()}>
      {children}
    </CMSContentContext.Provider>
  );
}

function useCMSContent(selector) {
  const store = useContext(CMSContentContext);
  if (store === undefined) {
    throw new Error("useCMSContent must be used within a DestinationsProvider");
  }

  const state = useSyncExternalStoreWithSelector(
    store.subscribe,
    store.get,
    null,
    selector,
    // Custom Equality function
    (oldState, newState) => {
      let isEqual = true;

      Object.entries(oldState).forEach(([key, entry]) => {
        if (!Object.is(entry, newState[key])) {
          isEqual = false;
        }
      });

      return isEqual;
    }
  );

  return [state, store.setters];
}

export {
  CMSContentContext, // Imported directly into class-based components
  CMSContentProvider, // Wraps components that need access to useCMSContent
  useCMSContent, // Custom hook to access context in function-based components
};
