import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useAmp, USER_DATA } from "../../utils/context/AmpContext";
import {
  HIDDEN_NAVIGATION,
  LOCALE,
  useAppContext,
} from "../../utils/context/AppContext";
import {
  HEADER_CONTENT,
  PREVIEW_MODE,
  useCMSContent,
} from "../../utils/context/CMSContentContext";

import HeaderLogo from "./components/logo";
import MenuIcon from "./components/menu-icon";
import Navigation from "./components/navigation";
import Cart from "./components/cart";
import Prompt from "./components/prompt";
import CurrencyLanguageSelector from "../currency-language-selector";
import Account from "./components/account";
import AbandonedCartModal from "./components/abandoned-cart";

import AutoCompleteSearch from "../search/auto-completion-search/auto-complete-search";

import { loggedInTracking } from "../../modules/analytics-events";

// inline styles
import "./styles.scss";
import "../user-profile-img-circle/styles.scss";
import { getHeaderData } from "../../utils/sanity";
import {
  getQueryParamByName,
  getUTMCampaignCoBranded,
} from "../../utils/utils";

const HeaderWrapper = (props) => {
  const [{ previewMode, headerContent }] = useCMSContent((store) => ({
    previewMode: store[PREVIEW_MODE],
    headerContent: store[HEADER_CONTENT],
  }));

  if (headerContent?.coBrand) {
    sessionStorage.setItem("co-branded", JSON.stringify(headerContent.coBrand));
  }

  if (previewMode) {
    return <HeaderPreview {...props} />;
  }
  return <Header {...props} headerContent={headerContent} />;
};

const HeaderPreview = (props) => {
  const [, { usePreviewHook, filterDataToSingleItem }] = useCMSContent(
    () => ({})
  );

  let coBrand =
    getQueryParamByName("co-branded") ||
    getUTMCampaignCoBranded(getQueryParamByName("utm_campaign")) ||
    document.getElementById("content-block-page");

  const headerContent = filterDataToSingleItem(
    usePreviewHook(null, getHeaderData(coBrand))
  );

  return <Header {...props} headerContent={headerContent} />;
};

const Header = ({ headerContent, showMenu, showSearch: showSearchProps }) => {
  const [{ hiddenNavigation, locale }] = useAppContext((store) => ({
    hiddenNavigation: store[HIDDEN_NAVIGATION],
    locale: store[LOCALE],
  }));
  const [{ userData }] = useAmp((store) => ({
    userData: store[USER_DATA],
  }));

  const [collapsed, setCollapsed] = useState(true);
  const [showSearch, setShowSearch] = useState(showSearchProps);
  const [onScrolled, setOnScrolled] = useState(false);
  const [isEventsPage, setIsEventsPage] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("stoke-events") || path.includes("all-events")) {
      setIsEventsPage(true);
      setOnScrolled(true);
    }
  }, [window.location.pathname]);

  const toggleNavBar = () => {
    setCollapsed(!collapsed);
    const body = document.body;
    if (body == null) {
      return;
    }
    if (body.classList.contains("panel-open")) {
      body.classList.remove("panel-open");
      body.classList.add("panel-close");
    } else {
      body.classList.add("panel-open");
      body.classList.remove("panel-close");
    }

    if (accountDropdownOpen) {
      setAccountDropdownOpen(false);
    }
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch) {
      document.body.classList.remove("panel-open");
    }
  };

  const trackScrolling = () => {
    const header = document.querySelector(".global-header");
    const sideDrawer = document.querySelector(".side-drawer");
    let offset = 70;
    if (
      (window.location.pathname === "/" && window.innerWidth < 1200) ||
      window.innerWidth < 576
    ) {
      offset = 35;
    }
    if (sideDrawer && sideDrawer.classList.contains("open")) {
      // specific case for destinations page when list drawer is visible
      offset = 15;
    }

    if (header) {
      if (window.pageYOffset < offset) {
        // this.setState({navbarOpacity: window.pageYOffset / offset})

        if (header.classList.contains("scrolled")) {
          header.classList.remove("scrolled");
          setOnScrolled(false);
        }
        // A specific class for homepage to not ruin the animation - the class only exist when they scroll back to the top of the homepage;
        const homepageHeader = document.querySelector(
          ".app-homepage .global-header"
        );
        if (homepageHeader) {
          homepageHeader.classList.add("unscrolled");
        }
      } else {
        // this.setState({navbarOpacity: 1})
        // header.classList.add("scrolled");
        if (
          !header.classList.contains("scrolled") &&
          !header.classList.contains("menu-open")
        ) {
          header.classList.add("scrolled");
          setOnScrolled(true);
        }
      }
    }
  };

  useEffect(() => {
    if (isEventsPage) {
      return;
    }
    trackScrolling();
    document.addEventListener("scroll", trackScrolling);
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, [isEventsPage]);

  useEffect(() => {
    if (userData) {
      loggedInTracking(userData);
    }
  }, [userData]);

  if (!headerContent || hiddenNavigation) {
    return null;
  }

  const isCoBranded = () => {
    if (sessionStorage.getItem("co-branded")) {
      return true;
    } else {
      return !!headerContent.coBrand;
    }
  };

  const openMenu = collapsed;

  const globalClassNames = classNames("global-header", {
    "in-checkout": !showMenu,
    "co-branded": isCoBranded(),
    "menu-open": !collapsed,
    scrolled: isEventsPage ? true : onScrolled,
    showSearch,
  });

  const cartIconColor = () => {
    let iconColor = "FFFFFF";
    if (
      isCoBranded() &&
      JSON.parse(sessionStorage.getItem("co-branded")).resortLogo
    ) {
      const { resortLogo } = JSON.parse(sessionStorage.getItem("co-branded"));
      iconColor = resortLogo.coBrandedCartColor
        ? resortLogo.coBrandedCartColor
        : "092340";
    }
    return iconColor;
  };

  // dropdownContent: headerContent.accountDropdown,
  //                 sitePreferences: headerContent.sitePreferences,
  //                 loginText: headerContent.navigation.logInLinkText,

  return (
    <div id="global-header" className={globalClassNames}>
      <div className="global-navbar" role="navigation">
        <MenuIcon collapsed={collapsed} onClick={toggleNavBar} tabIndex={0} />
        <div
          id="sideNavWrapper"
          className={classNames("side-nav-wrapper", {
            open: !collapsed,
          })}
        >
          <Navigation
            locale={locale}
            userData={userData}
            collapsed={openMenu}
            headerNavContent={headerContent.navigation}
            currencyModalContent={headerContent.currencyModal}
          />
        </div>

        <HeaderLogo
          collapsed={collapsed}
          coBrand={
            headerContent.coBrand ||
            JSON.parse(sessionStorage.getItem("co-branded"))
          }
          locale={locale}
        />

        {showSearch && (
          <div className="search-input search-input-mobile">
            <AutoCompleteSearch
              id="auto-complete-search-mobile"
              lang={locale}
              showSearch={showSearch}
              toggleSearch={toggleSearch}
            />
          </div>
        )}

        <div className="header-right-container" id="header-right-container">
          {!showSearch && (
            <div className="header-right-menu-wrap">
              <CurrencyLanguageSelector
                type="header"
                content={headerContent.currencyModal}
              />

              <Account
                userData={userData}
                isCoBranded={isCoBranded()}
                content={{
                  dropdownContent: headerContent.accountDropdown,
                  sitePreferences: headerContent.sitePreferences,
                  loginText: headerContent.navigation.logInLinkText,
                }}
              />
            </div>
          )}

          <div
            className={
              !showSearch ? "search-input" : "search-input search-input-open"
            }
          >
            <AutoCompleteSearch
              id="auto-complete-search-desktop"
              lang={locale}
              showSearch={showSearch}
              toggleSearch={toggleSearch}
            />
          </div>
          {!showSearch && (
            <button
              className="button unstyled toggle-search"
              type="button"
              aria-label="Search"
              onClick={toggleSearch}
            >
              <div className="inner">
                <i className="icon-search"></i>
              </div>
            </button>
          )}
          {showSearch && (
            <button
              className="primary-button btn btn-secondary amp-button unstyled toggle-search close-search"
              aria-label="Close Search"
              type="button"
              onClick={toggleSearch}
            >
              <div className="inner">
                <i className="icon-close-grey"></i>
              </div>
            </button>
          )}

          <div
            className={
              !showSearch
                ? "navigation-cart-wrapper"
                : "navigation-cart-wrapper hidden"
            }
            id="cart-wrap"
          >
            <Cart
              iconColor={cartIconColor()}
              content={headerContent.miniCart}
              cartMismatchError={headerContent.cartMismatchError}
            />
          </div>
        </div>

        <AbandonedCartModal content={headerContent.abandonedCart} />
      </div>

      <Prompt
        content={headerContent.accountDropdown}
        userData={userData}
        className="mobile"
      />
    </div>
  );
};

export default HeaderWrapper;
