// import blogTilesProjection from "../../modules/blog-tiles-projection";
import columnedContentProjection from "../../modules/columned-content-projection";
// import contentBlocksProjection from "../../modules/content-blocks-projection";
// import destinationOverviewProjection from "../../modules/destination-overview-projection";
// import genericHeroProjection from "../../modules/generic-hero-projection";
import imageProjection from "../image-projection";
// import seoProjection from "../../seo-projection";
// import socialFeedProjection from "../../modules/social-feed-projection";
import statProjection from "../../modules/stat-projection";

import { getLocale } from "../../../utils";
import subdestinationProjection from "../subdestination-projection";

const locale = getLocale();

// TODO - CARL - Audit to remove the AccessDetails Fields
const destinationLiteProjection = `{
  _id,
  _type,
  accessChanged,
  accessChangedBase,
  accessChangedSession,
  'description': description.${locale},
  entryTitle,
  fullName,
  globeFileName,
  hasArMap,
  'informationTooltipContent': informationTooltipContent.${locale},
  'informationTooltipContentBase': informationTooltipContentBase.${locale},
  'informationTooltipContentSession': informationTooltipContentSession.${locale},
  'informationTooltipContentPrevious': informationTooltipContentPrevious.${locale},
  'informationTooltipContentBasePrevious': informationTooltipContentBasePrevious.${locale},
  'informationTooltipContentSessionPrevious': informationTooltipContentSessionPrevious.${locale},
  latlng,
  'leadText': leadText.${locale},
  name,
  new,
  'newToPasses': newToPasses[] -> { passId }.passId,
  orderRank,
  policiesReservationsUrl,
  'region': region->name.${locale},
  'country': region->country->name.${locale},
  resortCode,
  resortLink,
  resortLogo->{
    darkLogo ${imageProjection},
    colorLogo ${imageProjection},
    logo ${imageProjection},
  },
  'resortLinkText': resortLinkText.${locale},
  'secondBody': secondBody->${columnedContentProjection},
  stats-> {
    stats[] -> ${statProjection}
  },
  subDestinations[]->${subdestinationProjection},
  'title': entryTitle,
  'url': url.current,
  'accessDetailsCurrentSeasonDescription': accessDetailsCurrentSeasonDescription.${locale},
  'accessDetailsProvisions': accessDetailsProvisions.${locale},
  'accessDetailsPreviousSeasonDescription': accessDetailsPreviousSeasonDescription.${locale},
  'ikonPassCurrentSeasonDetails': ikonPassCurrentSeasonDetails.${locale},
  'ikonPassPreviousSeasonDetails': ikonPassPreviousSeasonDetails.${locale},
  'ikonBasePassCurrentSeasonDetails': ikonBasePassCurrentSeasonDetails.${locale},
  'ikonBasePassPreviousSeasonDetails': ikonBasePassPreviousSeasonDetails.${locale},
  'ikonBasePassPlusCurrentSeasonDetails': ikonBasePassPlusCurrentSeasonDetails.${locale},
  'ikonBasePassPlusPreviousSeasonDetails': ikonBasePassPlusPreviousSeasonDetails.${locale}
}
`;

export default destinationLiteProjection;
