import env from "../modules/environments";

import {
  CURRENT_IKON_PASS_ID,
  CURRENT_IKON_BASE_PASS_ID,
  CURRENT_IKON_BASE_PLUS_PASS_ID,
  CURRENT_IKON_SESSION_FOUR_DAY_PASS_ID,
  CURRENT_IKON_SESSION_THREE_DAY_PASS_ID,
  CURRENT_IKON_SESSION_TWO_DAY_PASS_ID,
} from "../utils/current-pass-ids";

window.dataLayer = window.dataLayer || [];

// Usage from old build -> Usage from new Site
// app/src/js/global.js -> src/index.js
// app/src/containers/app.js -> src/App.js
export const virtualPageLoad = (url, ref) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "pageview",
    virtualUrl: url,
    virtualRef: ref,
    // other dataLayer elements here as needed...like ecommerce
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/global-header -> src/components/header/index.js
export const loggedInTracking = (userData) => {
  window.dataLayer = window.dataLayer || [];

  if (!userData || !userData.me || !userData.me.profile) {
    return;
  }

  const userId = userData.me.profile.id || null;

  if (userId) {
    window.dataLayer.push({
      event: "authentication",
      userId: userId,
    });
  }
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/cart-tile-block/cart-tile.jsx -> Cart Content Block Not Migrated
// app/src/components/content-blocks/local-pass-cart-widget/index.jsx -> src/components/local-pass-cart-widget/index.js
// app/src/components/content-blocks/pass-cart-widget/index.jsx -> src/components/pass-cart-widget/index.js
// app/src/containers/destination-pass-details/index.js -> Not migrated
export const addToCart = (
  productData,
  variantData,
  quantity,
  cartData = null
) => {
  // fallback to USD if need be
  let currency = "USD";
  if (cartData && cartData.payload_currency) {
    currency = cartData.payload_currency;
  }

  window.dataLayer = window.dataLayer || [];
  const price = (variantData.price / 100).toFixed(2);
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: currency,
      add: {
        // 'add' actionFieldObject measures.
        products: [
          {
            //  adding a product to a shopping cart.
            name: `${variantData.productId} ${variantData.ageCategory}`,
            id: `${variantData.productId}-${variantData.ageCategory}`,
            price: price,
            category: "pass",
            variant: variantData.ageCategory,
            quantity: quantity || 1,
          },
        ],
      },
    },
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/cart-tile-block/cart-tile.jsx -> Cart Content Block Not Migrated
// app/src/components/content-blocks/local-pass-cart-widget/index.jsx -> src/components/local-pass-cart-widget/index.js
// app/src/components/content-blocks/pass-cart-widget/index.jsx -> src/components/pass-cart-widget/index.js
// app/src/containers/destination-pass-details/index.js -> Not migrated
export const removeFromCart = (
  productData,
  variantData,
  quantity,
  cartData = null
) => {
  // fallback to USD if need be
  let currency = "USD";
  if (cartData && cartData.payload_currency) {
    currency = cartData.payload_currency;
  }

  window.dataLayer = window.dataLayer || [];
  const price = (variantData.price / 100).toFixed(2);
  window.dataLayer.push({
    event: "removeFromCart",
    ecommerce: {
      currencyCode: currency,
      remove: {
        // 'add' actionFieldObject measures.
        products: [
          {
            //  adding a product to a shopping cart.
            name: `${variantData.productId} ${variantData.ageCategory}`,
            id: `${variantData.productId}-${variantData.ageCategory}`,
            price: price,
            category: "pass",
            variant: variantData.ageCategory,
            quantity: quantity || 1,
          },
        ],
      },
    },
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/cart-tile-block/index.jsx -> Cart Content Block Not Migrated
// app/src/components/content-blocks/passes-side-by-side/index.jsx -> src/components/passes-side-by-side/index.js
// app/src/containers/destination-details/index.js -> Not Migrated
// app/src/modules/analytics-events.js -> src/modules/analytics-events.js
export const productImpression = (impressions) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productImpressions",
    ecommerce: {
      currencyCode: "USD", // Local currency is optional.
      impressions: impressions,
    },
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/cart-tile-block/index.jsx -> Cart Content Block Not Migrated
// app/src/components/content-blocks/local-pass-cart-widget/index.jsx -> src/components/local-pass-cart-widget/index.js
// app/src/components/content-blocks/pass-cart-widget/index.jsx -> src/components/pass-cart-widget/index.js
export const cartPassListAnalyticsImpressions = (data, product) => {
  if (!data || !product) {
    return;
  }

  let impressions = [];
  let count = 0;

  if (!data.passId) {
    return;
  }

  const toShow = data.passId;

  // for (let pi = 0; pi < props.productData.length; pi++) {
  // const product = props.productData[pi];

  if (product.id === toShow) {
    let price = null;

    product.variants.map((prices) => {
      if (prices.ageCategory === "adult") {
        price = prices.price / 100;
      }
    });

    // in case the price can't be set using the adult category, use the first variants price
    if (!price) {
      price = product.variants[0].price / 100;
    }

    let listTitle = document.title;
    if (!listTitle) {
      listTitle = "Pass cart widget: " + window.location.pathname;
    }
    impressions.push({
      name: `${product.id} adult`, // Name or ID is required.
      id: `${product.id}-adult`,
      price: price.toFixed(2),
      category: "pass",
      variant: "adult",
      list: listTitle,
      position: count + 1,
    });

    for (let pvi = 0; pvi < product.variants.length; pvi++) {
      const variant = product.variants[pvi];
      price = variant.price / 100;

      count = count + 1;

      if (variant.ageCategory === "adult") {
        continue;
      }

      impressions.push({
        name: `${variant.productId} ${variant.ageCategory}`, // Name or ID is required.
        id: `${variant.productId}-${variant.ageCategory}`,
        price: price.toFixed(2),
        category: "pass",
        variant: variant.ageCategory,
        list: listTitle,
        position: count,
      });
    }
  }
  // }

  // console.log(impressions)
  productImpression(impressions);
};

// Usage from old build -> Usage from new Site
// app/src/containers/destination-pass-details/index.js -> Not Migrated
export const productDetails = (productData, price) => {
  window.dataLayer = window.dataLayer || [];
  if (productData && productData.id && price) {
    window.dataLayer.push({
      event: "productDetail",
      ecommerce: {
        detail: {
          products: [
            {
              name: `${productData.id} adult`, // Name or ID is required.
              id: `${productData.id}-adult`,
              price: price.toFixed(2),
              category: "pass",
              variant: "adult",
            },
          ],
        },
      },
    });
  }
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/destination-pass-tile/index.jsx -> src/components/destination-pass-tile/index.js
// app/src/components/content-blocks/ikon-pass-tile/index.jsx -> src/components/ikon-pass-tile/index.js
// app/src/components/content-blocks/passes-side-by-side/partials/pass-block.jsx -> src/components/passes-side-by-side/partials/pass-block.js
export const productClick = (productData) => {
  window.dataLayer = window.dataLayer || [];
  if (productData) {
    window.dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          products: productData,
        },
      },
    });
  }
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/access-details/partials/details-content.jsx -> src/components/access-details/content.js
export const productCompare = (productIds) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Product Compare",
    products_selected: productIds,
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/passes-side-by-side/partials/pass-group.jsx -> src/components/passes-side-by-side/partials/pass-group.js
export const productTabClick = (productId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Product Tab Click",
    tab_name: productId,
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/pass-cart-widget-group/index.jsx -> src/components/pass-cart-widget-group/index.js
// app/src/components/content-blocks/passes-side-by-side/index.jsx -> src/components/passes-side-by-side/index.js
export const priceDrawerClick = (productId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Price Drawer Click",
    price_drawer: productId,
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/pass-cart-widget-group/index.jsx -> src/components/pass-cart-widget-group/index.js
// app/src/components/content-blocks/passes-side-by-side/index.jsx -> src/components/passes-side-by-side/index.js
export const priceDrawerView = (productId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "Price Drawer View",
    price_drawer: productId,
  });
};

// Usage from old build -> Usage from new Site
// app/src/components/cart-message.js -> missing functionality
// app/src/components/cart-modal.js -> src/components/header/components/abandonned-cart/cart-modal/index.js
// app/src/components/mini-cart.js -> src/components/header/components/cart/mini-cart/index.js
// app/src/containers/app.js -> src/components/header/components/cart/index.js
// app/src/containers/destination-pass-details.js -> Not Migrated
export const toAmpCheckout = (cartData, productData) => {
  window.dataLayer = window.dataLayer || [];

  let products = new Array();
  const cartItems = cartData.profiles;

  if (!cartItems) return;

  if (cartItems.length) {
    for (let i = 0; i < cartItems.length; i++) {
      const item = cartItems[i].items[0];
      const price = (item.paid_price / 100).toFixed(2);
      let productName = "";

      for (let pi = 0; pi < productData.length; pi++) {
        const product = productData[pi];

        if (product.id === item.type) {
          productName = product.title;
          break;
        }
      }

      products.push({
        //  adding a product to a shopping cart.
        name: `${item.type} ${item.variant}`,
        id: `${item.type}-${item.variant}`,
        price: price,
        category: item.product,
        variant: item.variant,
        quantity: 1,
      });
    }
  }

  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: 1 },
        products: products,
      },
    },
  });
};

// Usage from old build -> Usage from new Site
// app/src/containers/adventure-page/index.js -> ??
// app/src/containers/content-block-template/index.js -> src/pages/page.js
// app/src/containers/destination-details/index.js -> Not Migrated
// app/src/containers/destination-landing-page/index.js -> Not Migrated
// app/src/containers/destination-page/index.js -> src/pages/destination-page.js
// app/src/containers/destination-pass-details/index.js -> Not Migrated
// app/src/containers/parallax-page/index.js -> src/pages/home-page.js
// app/src/containers/parallax-page-ie/index.js -> src/pages/home-page.js
// app/src/containers/search/instant-search-page.js -> src/pages/instant-search-page.js
export const renderCompleteEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: "rendercomplete" });
};

// Usage from old build -> Usage from new Site
// app/src/components/content-blocks/adventure-wishlist/actions.js -> ??
// app/src/components/content-blocks/destination-overview/actions.js -> ??
// app/src/components/content-blocks/faq-item/index.jsx -> src/components/content-blocks/faq-item/index.jsx
// app/src/components/content-blocks/faq-parent-container/top-titles.jsx -> ??
// app/src/components/content-blocks/passes-side-by-side/partials/pass-block.jsx -> src/components/passes-side-by-side/partials/pass-block.js
// app/src/connectors/trineo/index.js -> src/utils/amp-client.js
// app/src/containers/app/index.js -> src/App.js
// app/src/containers/error-404/index.js  -> ??
export const generalTracking = (action, category, label, value = "") => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "customEvent",
    customEventAction: action,
    customEventCategory: category,
    customEventLabel: label,
    customEventValue: value,
  });
};

export const homePageTracking = (id, text, destination = null) => {
  datalayerTracking({
    category: id,
    name: text,
    destination: destination || text,
  });
};

export const benefitTracking = (name, destination, position) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: "click",
    eventInfo: {
      type: "other",
      category: "Benefits Card CTA",
      name: name,
      destination: destination,
      position: `Position ${position}`,
    },
  });
};

export const shopPassesExpandTracking = () => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: "click",
    eventInfo: {
      type: "other",
      category: "Shop Passes Plus Accordion",
      name: "Opened",
    },
  });
};

export const shopPassesDiscountChangeTracking = (name) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: "click",
    eventInfo: {
      type: "other",
      category: "Canadian Discount Pricing",
      name: name,
    },
  });
};

export const comparePassesSelectedPassesTracking = (
  selectedPassesNames,
  toggledPassName,
  state
) => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: "click",
    eventInfo: {
      type: "other",
      category: "Compare Passes",
      name: selectedPassesNames,
      destination: `${toggledPassName} - ${state}`,
    },
  });
};

export const datalayerTracking = (eventInfo) => {
  if (!env.isProduction) {
    console.log(eventInfo);
  }

  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: "click",
    eventInfo: {
      type: "other",
      ...eventInfo,
    },
  });
};

export const comparePassesSortByTracking = (sort) => {
  const sortParse = {
    alphabetical: "Alphabetical",
    "lift-reservations-required": "Reservation Required",
    "unlimited-destinations": "Unlimited",
  }[sort];

  datalayerTracking({
    category: "Compare Passes - Sort By Drop Down Menu",
    name: `Sort By ${sortParse}`,
    destination: `Sort By ${sortParse}`,
  });
};

export const shopPassesViewPricesCTATracking = (passID) => {
  const passName = {
    [CURRENT_IKON_PASS_ID]: "Ikon Pass",
    [CURRENT_IKON_BASE_PASS_ID]: "Base Pass",
    [CURRENT_IKON_SESSION_FOUR_DAY_PASS_ID]: "Session Pass",
  }[passID];

  datalayerTracking({
    category: "Shop Passes - View Prices CTA",
    name: `${passName} - VIEW PRICES`,
    destination: `${passName} - VIEW PRICES`,
  });
};

export const shopPassesTabsTraking = (passGroupID, passID) => {
  const passName = {
    [CURRENT_IKON_BASE_PASS_ID]: "IKON BASE PASS",
    [CURRENT_IKON_BASE_PLUS_PASS_ID]: "IKON BASE PLUS PASS",
    [CURRENT_IKON_SESSION_FOUR_DAY_PASS_ID]: "4-DAY",
    [CURRENT_IKON_SESSION_THREE_DAY_PASS_ID]: "3-DAY",
    [CURRENT_IKON_SESSION_TWO_DAY_PASS_ID]: "2-DAY",
  }[passID];

  const groupName = {
    1: "Base Pass",
    2: "Session Pass",
  }[passGroupID];

  datalayerTracking({
    category: `Shop Passes - ${groupName} Tabs`,
    name: passName,
    destination: passName,
  });
};
