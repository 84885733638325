import { getLocale } from "../../utils";

const locale = getLocale();

const accessDetailsProjection = `{
  _id,
  _type,
  entryTitle,
  'year': year.${locale},
  'title': title.${locale},
  'description': description.${locale},
  'previousYearLinkText': previousYearLinkText.${locale},
  'upsellDescription': upsellDescription.${locale},
  'personalizedDescription': personalizedDescription.${locale},
  optionalResortCode,
  usePreviousSeasonDetails,
  passes[] -> {
    passId,
    entryTitle,
    'accessDetailsDescription': accessDetails.${locale},
    'accessDetailsButtonText': accessDetailsButtonText.${locale},
    urlSlug,
    'personalizedInformationTileText': accessDetailsPersonalizedInformationTileText.${locale},
    'personalizedComparisonTileText': accessDetailsPersonalizedComparisonTileText.${locale},
    'upsellInformationText': accessDetailsUpsellInformationTileText.${locale},
    'upsellComparisonTileText': accessDetailsUpsellComparisonTileText.${locale},
    'upgradeButtonText': accessDetailsUpgradeButtonText.${locale},
    'upgradeButtonAllTranslations': accessDetailsUpgradeButtonText,
    'upgradeButtonURL': accessDetailsUpgradeButtonURL,
    passOnSale,
    showReservationsRequired,
    'blackoutDatesInformation': blackoutDatesInformation.${locale}
  },
  defaultCheckedPasses[] -> {
    passId 
  }
}`;

export default accessDetailsProjection;
