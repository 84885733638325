import { getLocale } from "../../utils";

const locale = getLocale();

const imageGalleryProjection = `{
  _id,
  asset ->,
}
`;

export default imageGalleryProjection;
