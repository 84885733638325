import { getLocale } from "../../utils";

const locale = getLocale();

const searchQuickLinkProjection = `{
  _id,
  entryTitle,
  'linkTitle': linkTitle.${locale},
  'description': description.${locale},
  'urlSlug': urlSlug
}`;

export default searchQuickLinkProjection;
