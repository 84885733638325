import benefitTileProjection from "./benefit-tile-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const benefitsGroupProjection = `{
  _id,
  _type,
  entryTitle,
  'benefitsText': benefitsText.${locale},
  benefits[] -> ${benefitTileProjection}
}`;

export default benefitsGroupProjection;
