import accessDetailsProjection from "./access-details-projection";
import accordionGroupProjection from "./accordion-group-projection";
import adventureItineraryItemDestination from "./adventure-itinerary-item-destination-projection";
import benefitsGroupProjection from "./benefits-group-projection";
import columnedContentProjection from "./columned-content-projection";
import disruptionBannerProjection from "./disruption-banner-projection";
import faqGroupProjection from "./faq-group-projection";
import flexGroupProjection from "./flex-group-projection";
import { followUsProjection } from "./follow-us-projection";
import headingOverrideProjection from "./heading-override-projection";
import heroProjection from "./hero-projection";
import passWithContentProjection from "./pass-with-content-projection";
import tabsProjection from "./tabs-projection";
import vectorMountainsProjection from "./vector-mountains-projection";
import videoEmbedProjection from "./video-embed-projection";

const contentBlockProjections = `{
  _id,
  _type,
  _type == 'accordionGroup' => ${accordionGroupProjection},
  _type == 'adventureItineraryItemDestination' => ${adventureItineraryItemDestination},
  _type == 'benefitsGroup' => ${benefitsGroupProjection},
  _type == 'faqGroup' => ${faqGroupProjection},
  _type == 'flexGroup' => ${flexGroupProjection},
  _type == 'followUs' => ${followUsProjection},
  _type == 'flexibleHeading' => ${headingOverrideProjection},
  _type == 'moduleHero' => ${heroProjection},
  _type == 'columnedContent' => ${columnedContentProjection},
  _type == 'disruptionBanner' => ${disruptionBannerProjection},
  _type == 'passWithContent' => ${passWithContentProjection},
  _type == 'tabs' => ${tabsProjection},
  _type == 'vectorMountains' => ${vectorMountainsProjection},
  _type == 'accessDetails' => ${accessDetailsProjection},
  _type == 'videoEmbed' => ${videoEmbedProjection}
}`;

export default contentBlockProjections;
