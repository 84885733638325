import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import Markdown from "../markdown";

class SpanTooltip extends Component {
  constructor(props) {
    super(props);
    // const rootElement = document.getElementById('body');
    // const width = rootElement.getBoundingClientRect().width
    const width = window.innerWidth;
    this.state = {
      isOpen: false,
      width: width,
      placement: width >= 576 ? "right" : "bottom",
    };
    this.handleResize = this.handleResize.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize() {
    // const rootElement = document.getElementById('root-app');
    const newWidth = window.innerWidth;
    if (
      (this.state.width >= 576 && newWidth < 576) ||
      (this.state.width < 576 && newWidth >= 576)
    ) {
      const placement = newWidth >= 576 ? "right" : "bottom";
      this.setState({ width: newWidth, placement });
    }
  }
  toggle() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }
  render() {
    const {
      id,
      content,
      container,
      children,
      theme = "dark",
      className = "",
      innerClassName = "",
      addWhiteSpace = false,
    } = this.props;

    if (!content) {
      return null;
    }

    if (container) {
      return (
        <span className="span-tooltip">
          {addWhiteSpace && <span>&nbsp;</span>}
          <span className={className} id={id}>
            {children}
          </span>
          <Tooltip
            container={container}
            placementPrefix={`${theme} ikon-tooltip bs-tooltip`}
            placement={this.state.placement}
            isOpen={this.state.isOpen}
            toggle={this.toggle}
            target={id}
            innerClassName={innerClassName}
          >
            <Markdown>{content}</Markdown>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <span className="span-tooltip">
          {addWhiteSpace && <span>&nbsp;</span>}
          <span className={className} id={id}>
            {children}
          </span>
          <Tooltip
            placementPrefix={`${theme} ikon-tooltip bs-tooltip`}
            placement={"bottom"}
            isOpen={this.state.isOpen}
            toggle={this.toggle}
            target={id}
            innerClassName={innerClassName}
          >
            <Markdown>{content}</Markdown>
          </Tooltip>
        </span>
      );
    }
  }
}

export default SpanTooltip;
