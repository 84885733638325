import ReactModal from "react-modal";
import React, { useState, useEffect } from "react";

// inline styles
import "./styles.scss";

const Modal = ({
  isOpen = false,
  children,
  contentLabel,
  hideClose,
  launcher,
  onRequestClose,
  onClickHandler,
  className = "",
  overlayClassName = "",
  portalClassName = "",
}) => {
  ReactModal.setAppElement(document.querySelector("#root"));
  const [open, setOpen] = useState(isOpen);
  const defaultCloseHandler = () => setOpen(false);
  const defaultClickHandler = () => setOpen(true);
  const onRequestCloseHandler = onRequestClose
    ? onRequestClose
    : defaultCloseHandler;
  const onLauncherClick = onClickHandler ? onClickHandler : defaultClickHandler;

  // This is required so that state can be controlled externally via isOpen prop
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <ClickWrapper clickHandler={onLauncherClick}>{launcher}</ClickWrapper>
      <ReactModal
        isOpen={open}
        portalClassName={`modal ${portalClassName}`}
        overlayClassName={`modal-backdrop ${overlayClassName}`}
        className={`modal-dialog ${className}`}
        contentLabel={contentLabel}
        onRequestClose={onRequestCloseHandler}
      >
        <div className="modal-content">
          {!hideClose && (
            <button
              aria-label="Close"
              className="modal-close-button"
              onClick={onRequestCloseHandler}
            >
              <span aria-hidden="true">×</span>
            </button>
          )}
          {children}
        </div>
      </ReactModal>
    </>
  );
};

// Stole this technique for wrapping unknown elements with a click
// handler from:
// https://stackoverflow.com/questions/54521354/react-create-onclick-hoc-without-using-an-additional-div
const ClickWrapper = ({ children, clickHandler }) => (
  <React.Fragment>
    {React.Children.map(children, (child) =>
      React.cloneElement(child, { onClick: clickHandler })
    )}
  </React.Fragment>
);

export default Modal;
