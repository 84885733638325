import React from "react";
import { connectHighlight } from "react-instantsearch-dom";

const CustomMatchedArraySnippet = ({
  highlight,
  attribute,
  matchedContent,
}) => {
  if (!matchedContent) {
    return null;
  }

  const parsedHit = highlight({
    highlightProperty: "_snippetResult",
    attribute,
    hit: { _snippetResult: { [attribute]: matchedContent } },
  });

  return (
    <span>
      {parsedHit.map((part, index) => {
        if (part.value === "[...]") {
          return (
            <span key={index} className="ellipsis-text">
              {part.value}
            </span>
          );
        } else if (part.isHighlighted) {
          return <mark key={index}>{part.value}</mark>;
        } else {
          return <span key={index}>{part.value}</span>;
        }
      })}
    </span>
  );
};

export default connectHighlight(CustomMatchedArraySnippet);
