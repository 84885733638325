import classNames from "classnames";
import { buildEmailPreferencesLink } from "../../../../../utils/users";
import {
  addLocaleUrl,
  isLinkActive,
  updateEnvSpecificAccountLink,
} from "../../../../../utils/utils";
import Button from "../../../../button";

const MenuLinks = ({ canRenew, content, closeDropdown, userData, locale }) => {
  return (
    <div className="account-dropdown-menu-links">
      {content.accountLinks &&
        !canRenew &&
        content.accountLinks.map((link, i) => {
          // const targetProps = link.openInNewContext ? { target: "_blank" } : {};
          let linkUrl = addLocaleUrl(locale, link.url); // ensure locale is set
          // if the link is email related, add the users email as a parameter
          if (link && link.url && link.url.includes("spMailingID")) {
            linkUrl = buildEmailPreferencesLink(
              linkUrl,
              userData.me.profile.email
            );
            if (!linkUrl) {
              return null;
            }
          }
          // hide wishlist
          if (linkUrl.includes("wishlist")) {
            return null;
          }
          // currently, hide the favorites link
          if (linkUrl.includes("favorite")) {
            return null;
          }

          // hide show passes link for renewal if user in not eligible for renewal
          if (linkUrl.includes("shop-passes")) {
            return null;
          }

          // hide my account home
          if (linkUrl.includes("myaccount")) {
            return null;
          }

          // hide pass holder welcome
          if (linkUrl.includes("pass-holder-welcome")) {
            return null;
          }

          return (
            <div
              className={classNames("account-dropdown-menu-link", {
                "subscription-center": linkUrl.includes("subscriptionCenter"),
              })}
              key={i}
            >
              <Button
                disabled={isLinkActive(linkUrl)}
                onClick={closeDropdown}
                type="plain"
                url={updateEnvSpecificAccountLink(linkUrl)}
                text={link.title}
              />
            </div>
          );
        })}

      {/* to only show shop pass link in renewal flyout if user is eligible to renew */}
      {content.accountLinks &&
        canRenew &&
        content.accountLinks.map((link, i) => {
          // const targetProps = link.openInNewContext ? { target: "_blank" } : {};
          let linkUrl = addLocaleUrl(locale, link.url); // ensure locale is set

          // only show shop passes link
          if (linkUrl.includes("subscriptionCenter")) {
            return null;
          }

          if (linkUrl.includes("account.ikonpass.com")) {
            return null;
          }

          if (linkUrl.includes("favorite")) {
            return null;
          }

          if (linkUrl.includes("/pass-holder-welcome")) {
            return null;
          }

          if (linkUrl.includes("wishlist")) {
            return null;
          }

          return (
            <div className="header-account-link" key={i}>
              <Button
                disabled={isLinkActive(linkUrl)}
                onClick={closeDropdown}
                type="plain"
                url={updateEnvSpecificAccountLink(linkUrl)}
                text={link.title}
              />
            </div>
          );
        })}
    </div>
  );
};

export default MenuLinks;
