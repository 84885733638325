import buttonProjection from "./button-projection";
import iconProjection from "./icon-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const featureTileProjection = `{
  'cta': cta ${buttonProjection},
  ctaText,
  ctaUrl,
  'description': description.${locale},
  'headline': headline.${locale},
  icon->${iconProjection},
  image,
  linkColor,
  textColor
}
`;

export default featureTileProjection;
