import createClient from "@sanity/client";
import { definePreview } from "next-sanity/preview"; // used to subscribe to content-store

const clientConfig = {
  projectId: "bjsgnxuy", // find this at manage.sanity.io or in your sanity.json
  // projectId: "vanw8d6x",
  apiVersion: "2022-05-12", // use a UTC date string
  dataset: process.env.REACT_APP_DATASET || "production",
  // token: process.env.REACT_APP_TOKEN,
  useCdn: true,
};

const previewDocumentLimit = 10000;

export const previewClientConfig = {
  projectId: "bjsgnxuy", // find this at manage.sanity.io or in your sanity.json
  apiVersion: "2022-05-12", // use a UTC date string
  dataset: process.env.REACT_APP_DATASET || "production",
  // token: process.env.REACT_APP_SANITY_PREVIEW_TOKEN,
  withCredentials: true,
  useCdn: false,
  documentLimit: previewDocumentLimit,
  subscriptionThrottleMs: 30000,
};

const sanityClient = createClient(clientConfig);
const sanityPreviewClient = createClient(previewClientConfig);

const usePreviewHook = definePreview(previewClientConfig);

const getClient = (previewMode = false) => {
  return previewMode ? sanityPreviewClient : sanityClient;
};

export {
  getClient,
  sanityClient,
  sanityPreviewClient,
  usePreviewHook,
  previewDocumentLimit,
};
