import currencyProjection from "./currency-projection";
import iconProjection from "./icon-projection";
import { getLocale } from "../../utils";

const locale = getLocale();

const customPromotionsProjection = `{
  'ageRange': ageRange.${locale},
  apiCode,
  availableCurrencies[]->${currencyProjection},
  'description': description.${locale},
  discountedPassesToShow,
  enableUnavailableState,
  entryTitle,
  icon->${iconProjection},
  price,
  priceCad,
  'title': title.${locale},
  _id,
  _type
}
`;

export default customPromotionsProjection;
