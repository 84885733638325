import { getLocale } from "../../utils";

const locale = getLocale();

const automatedPromotionEligibilityProjection = `{
  'title': title.${locale},
  'detailsBoxTitle': detailsBoxTitle.${locale},
  'subtitle': subTitle.${locale},
  'descriptionCAD': descriptionCAD.${locale},
  'descriptionUSD': descriptionUSD.${locale},
  apiCode
}`;

export default automatedPromotionEligibilityProjection;
