// import { Link } from "react-router-dom";

import blueAccountIcon from "../../../../static/images/svg/account.svg";
import whiteAccountIcon from "../../../../static/images/svg/account-white.svg";
import { useState, useEffect } from "react";
import classNames from "classnames";
import UserProfileImageCircle from "../../../user-profile-img-circle";

import Dropdown from "./dropdown/index-fn";
import Prompt from "../prompt";
import { getLocale, setQueryParamByName } from "../../../../utils/utils";
import {
  getUserProductBlackoutDates,
  showMountainChecklist,
} from "../../../../utils/users";
import environments from "../../../../modules/environments";
import { useAmp } from "../../../../utils/context/AmpContext";
// import { useCMSContent } from "../../../../utils/context/CMSContentContext";

// inline styles for the account nav
import "./styles.scss";
import "./dropdown/styles.scss";
import "./faq-group/styles.scss";
import "./flyout/styles.scss";
import "./notification/styles.scss";
import "./menu-links/styles.scss";
import "./favorites/styles.scss";
import "./recommendations/styles.scss";
import "./stats/styles.scss";

const Account = ({
  isCoBranded,
  userData,
  content: { dropdownContent, sitePreferences, loginText },
}) => {
  const [, { logoutUser }] = useAmp(() => ({}));

  const [isOpen, setIsOpen] = useState(false);

  const locale = getLocale();

  const handleLogoutUser = () => {
    logoutUser();
  };

  const getReadNotificationCookies = () => {
    return document.cookie
      .split("; ")
      .filter((cookie) => cookie.startsWith("readNotification_"))
      .map((cookie) => cookie.split("=")[0]);
  };

  const numberOfNotifications = () => {
    if (!userData || !dropdownContent) {
      return 0;
    }

    let readNotifications = getReadNotificationCookies();

    let blackouts = null;
    let userHasProducts = false;
    if (
      userData.products &&
      userData.products.primary &&
      userData.products.primary.access
    ) {
      userHasProducts = true;
      blackouts = getUserProductBlackoutDates(
        userData.products.all,
        userData.products.primary.season,
        dropdownContent.upcomingBlackoutsDaysOfWarning
      );
    }

    let numberOfNotifications = 0;
    if (
      dropdownContent.upcomingBlackoutsNotification &&
      blackouts &&
      blackouts.upcoming &&
      blackouts.upcoming.length
    ) {
      numberOfNotifications = 1;
    }

    if (
      dropdownContent.userNotifications &&
      dropdownContent.userNotifications.length > 0
    ) {
      numberOfNotifications += dropdownContent.userNotifications.length;
    }

    if (showMountainChecklist(userData) && userHasProducts) {
      numberOfNotifications += 1;
    }

    numberOfNotifications -= readNotifications.length;

    return Math.max(numberOfNotifications, 0);
  };

  const [notificationCount, setNotificationCount] = useState(
    numberOfNotifications()
  );

  useEffect(() => {
    setNotificationCount(numberOfNotifications());
  }, [userData, dropdownContent]);

  const AccountButton = () => {
    if (!dropdownContent || !userData || !userData.me) {
      return null;
    }

    const { welcomeMessage } = dropdownContent;

    const { cloudinaryPhoto } = userData.me.profile;
    return (
      <button
        className={classNames("account-toggle account-button", {
          open: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <UserProfileImageCircle
          notificationNumber={notificationCount}
          bordered={true}
          profileImg={
            cloudinaryPhoto &&
            cloudinaryPhoto.version &&
            cloudinaryPhoto.publicId
              ? `https://res.cloudinary.com/intrawest/image/upload/g_face,c_thumb,w_100,h_100/v${cloudinaryPhoto.version}/${cloudinaryPhoto.publicId}`
              : blueAccountIcon
          }
          variant="small"
        />
        <span className="account-button-welcome">
          {welcomeMessage}
          {userData.me.profile.firstName}
        </span>
        <i
          className={`account-button-chevron icon-chevron-${
            isOpen ? "up" : "down"
          }`}
        />
      </button>
    );
  };

  const LoginButton = () => {
    if (!loginText) {
      return null;
    }

    // redirect from AMP login back to page on website after login

    let redirectLocation;

    if (environments.isProduction) {
      redirectLocation = "https://ikonpass.com";
    } else if (environments.isStaging) {
      redirectLocation = "https://staging.ikonpass.com";
    } else if (environments.isLocalhost) {
      redirectLocation = "http://localhost:8000";
    } else {
      redirectLocation = "https://ikonpass.com";
    }

    let loginUrl = environments.amp.cartUrl + "/" + locale + "/login";
    loginUrl = setQueryParamByName(
      loginUrl,
      "redirect_uri",
      redirectLocation + window.location.pathname
    );

    const accountIcon = isCoBranded ? blueAccountIcon : whiteAccountIcon;

    return (
      <a className="account-toggle login-button" href={loginUrl}>
        <img src={accountIcon} alt="Account Icon" className="login-icon" />
        {loginText}
      </a>
    );
  };

  return userData ? (
    <div className="account-button-container">
      <AccountButton />
      <Dropdown
        locale={locale}
        userData={userData}
        // destinationList={destinationList}
        content={dropdownContent}
        logoutUser={handleLogoutUser}
        closeDropdown={() => setIsOpen(!isOpen)}
        parentClassName="header-account-dropdown"
        sitePreferences={sitePreferences}
        accountDropdownOpen={isOpen}
        numberOfNotifications={notificationCount}
        updateNumberOfNotifications={setNotificationCount}
      />
    </div>
  ) : (
    <div className="account-button-container">
      <LoginButton />
      <Prompt content={dropdownContent} userData={userData} />
    </div>
  );
};
export default Account;
