import { getLocale } from "../../utils";

const locale = getLocale();

const headingOverrideProjection = `{
  _id,
  'headingTitle': headline.${locale},
  headingLevel,
  headingClassOverride
}`;

export default headingOverrideProjection;
