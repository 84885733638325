import React, { Component } from "react";
import { addLocaleUrl } from "../../../../utils/utils";
import Button from "../../../button";

export default class NavigationPrimaryCTA extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { link, locale } = this.props;
    if (!link) {
      return null;
    }

    return (
      <div className="side-nav-content__cta-wrapper side-nav-content__cta-wrapper--primary">
        <Button
          url={addLocaleUrl(locale, link.url)}
          text={link.title}
          type="blue-creek"
        />
      </div>
    );
  }
}
