import genericHeroProjection from "../modules/generic-hero-projection";
import seoProjection from "../seo-projection";
import buttonProjection from "../modules/button-projection";

import { getLocale } from "../../utils";
import imageGalleryProjection from "../items/image-gallery-projection";
import videoEmbedProjection from "../modules/video-embed-projection";
import disruptionBannerProjection from "../modules/disruption-banner-projection";
const locale = getLocale();

const stokeEventsPageProjection = `{
  _id,
  seo ${seoProjection},
  hero -> ${genericHeroProjection},
  stokeInfo {
    'headline': headline.${locale},
    'leadText': leadText.${locale},
    cta->${buttonProjection},
    featuredVideo
  },
  eventListDetails {
    'headline': headline.${locale},
    'leadText': leadText.${locale},
  },
  disruptionBanner -> ${disruptionBannerProjection},
}`;

export default stokeEventsPageProjection;
