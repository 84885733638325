import affirmCalloutProjection from "../modules/affirm-callout-projection";
import contentBlockProjections from "../modules/content-blocks-projection";
import genericHeroProjection from "../modules/generic-hero-projection";
import headingOverrideProjection from "../modules/heading-override-projection";
import imageProjection from "../items/image-projection";
import seoProjection from "../seo-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

const destinationPassListingPageDataProjection = `{
  _id,
  _type,
  'contentBlocks': contentBlocks[] -> ${contentBlockProjections},
  'description': description.${locale},
  excludePasses,
  hero -> ${genericHeroProjection},
  resortLogo ->  {
    coBrandedCartColor,
    coBrandedLogo ${imageProjection},
    colorLogo ${imageProjection},
    darkLogo ${imageProjection},
    logo ${imageProjection},
  },
  passesIncluded[] -> {
    _id,
    affirmCallout -> ${affirmCalloutProjection},
    'benefitCheckMarks': benefitCheckMarks.${locale},
    'benefits' : benefits.${locale},
    'buttonText': buttonText.${locale},
    destinationReference -> {
      'url': url.current
    },
    headlineOverride ${headingOverrideProjection},
    hideSeason,
    passId,
    passOnSale,
    'priceLabel': priceLabel.${locale},
    'promoText': promoText.${locale},
    'shortDescription': shortDescription.${locale},
    'title': title.${locale},
    urlSlug,
    passSoldExternally,
    seasonTag,
    priceUSD,
    priceCAD,
    buttonLink
  },
  placeIkonPassesBelowLocal,
  seo ${seoProjection},
  'url': url.current
}`;

export default destinationPassListingPageDataProjection;
