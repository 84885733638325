import { getLocale } from "../../utils";

const locale = getLocale();

const basicLinkProjection = `{
  _id,
  _type,
  entryTitle,
  'title': title.${locale},
  'description': description.${locale},
  url,
  openInNewContext
}`;

export default basicLinkProjection;
