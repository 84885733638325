import { getLocale } from "../../utils";
import headingOverrideProjection from "./heading-override-projection";

const locale = getLocale();

const regionProjection = `
{
  _type,
  _id,
  'country': country -> name.${locale},
  headlineOverride ${headingOverrideProjection},
  'title': name.${locale},
}`;

export default regionProjection;
