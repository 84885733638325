import React from "react";
// import { cartData, locale, stickyCartBarContent } from '../../containers/app/selectors'
// import { getStickyCartBarContent, getShowStickyCartBar } from '../../containers/app/actions'
import env from "../../modules/environments";
import { AllContexts } from "../../utils/context/AllContexts";

const getStickyCartBarContent = () => {
  // console.log('replace me')
};

class StickyCartBar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.closeStickyCartBar = this.closeStickyCartBar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.isInViewport = this.isInViewport.bind(this);
  }

  componentDidMount() {
    if (!this.props.stickyCartBarContent) {
      getStickyCartBarContent();
    }

    const self = this;
    window.setTimeout(() => {
      self.handleScroll(); // initial load
    }, 250);
    window.addEventListener("scroll", function () {
      self.handleScroll();
    });
  }

  // Hoping we don't need this
  //
  // componentWillReceiveProps(nextProps) {
  //     // if the subtotal is different, show the bar again
  //     if(nextProps.cartData.subtotal !== this.props.cartData.subtotal) {
  //         this.setState({
  //             showStickyBar: true
  //         })
  //         this.handleScroll()
  //     }
  // }

  handleScroll() {
    const footer = document.getElementById("amp-global-footer");
    const stickyCartBar = document.getElementById("sticky-cart-bar");
    //const docHeight = (document && document.documentElement) ? document.documentElement.offsetHeight : 0 // full document height
    //const heightToStopAt = (footer) ? docHeight - footer.offsetHeight : 0 // full doc height minus the height of the footer is where we want to stop
    //const positionDifference = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight - heightToStopAt // doc scroll position + viewport height - height to stop at

    if (stickyCartBar) {
      if (this.isInViewport(footer, stickyCartBar.offsetHeight)) {
        stickyCartBar.classList.remove("sticky");
      } else {
        stickyCartBar.classList.add("sticky");
      }
    }
    /*
        if(stickyCartBar) {
            if(this.isInViewport(footer)) {
                stickyCartBar.style.bottom = 'auto';
                //stickyCartBar.style.position = 'absolute';
                //stickyCartBar.style.top = (0 - stickyCartBar.offsetHeight) + 'px';
                stickyCartBar.style.position = 'relative';
            }
            else {
                stickyCartBar.style.bottom = 0;
                stickyCartBar.style.position = 'fixed';
                stickyCartBar.style.top = 'auto';
            }
        }*/
  }

  isInViewport(ele, topOffset = 0) {
    if (!ele) return;
    const { top, bottom } = ele.getBoundingClientRect();
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    return (top + topOffset > 0 || bottom > 0) && top + topOffset < vHeight;
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  closeStickyCartBar() {
    const { setShowStickyCartBar } = this.context;
    setShowStickyCartBar(false);
  }

  getQuantityText() {
    const { cartData, locale } = this.context;
    const totalQuantity = cartData.profiles?.filter(
      (item) => item.items[0]
    ).length;
    if (locale?.toLowerCase() === "en") {
      if (totalQuantity > 1 || totalQuantity === 0) {
        return `${totalQuantity} Items in Cart`;
      }
      return `${totalQuantity} Item in Cart`;
    }
    if (totalQuantity > 1 || totalQuantity === 0) {
      return `${totalQuantity} articles dans le panier`;
    }
    return `${totalQuantity} article dans le panier`;
  }

  getButtonText() {
    const { locale } = this.context;
    if (locale?.toLowerCase() === "en") {
      return "View Cart";
    } else {
      return "Voir le Panier";
    }
  }

  render() {
    const { stickyCartBarContent } = this.props;
    const { cartData, locale } = this.context;
    if (!cartData || cartData.total <= 0 || !stickyCartBarContent) {
      return null;
    }
    let total = 0;
    let subtotal = 0;
    let availableCurrencies = [];
    let otherPassCount = 0;

    // count other passes
    if (cartData && cartData.profiles && cartData.profiles.length >= 1) {
      cartData.profiles.forEach((item) => {
        if (
          item.items[0] &&
          item.items[0].type !== "ikonpass" &&
          item.items[0].type !== "ikonbase"
        ) {
          otherPassCount++;
        }
      });
    }

    // allow CAD if no other passes
    if (otherPassCount === 0) {
      availableCurrencies = ["USD", "CAD"];
    } else {
      availableCurrencies.push(cartData.payload_currency);
    }

    subtotal = cartData.subtotal / 100;
    total = cartData.total / 100;

    if (Number.isNaN(subtotal)) {
      subtotal = 0;
    }
    if (Number.isNaN(total)) {
      total = 0;
    }

    // parse totals to make cents small
    // let parseTotal = 0;
    // let cents = "";

    // if (total % 1 !== 0) {
    //   let totalArray = total.toFixed(2).toString().split(".");
    //   parseTotal = totalArray[0];
    //   cents = `.${totalArray[1]}`;
    // } else {
    //   parseTotal = total;
    // }

    return (
      <div
        className="sticky-cart-bar sticky"
        id="sticky-cart-bar"
        role="complementary"
      >
        <div className="sticky-cart-bar-inner">
          <div className="sticky-cart-bar-left">
            <div className="quantity">
              <h2 data-testid="sticky-cart-bar-quantity">
                {this.getQuantityText()}
              </h2>
            </div>
          </div>
          <div className="sticky-cart-bar-right">
            <a
              className="button primary sticky-cart-cart-link"
              href={`${env.amp.cartUrl}/${locale ? locale : "en"}/cart`}
              disabled={total === 0}
              onClick={() => this.closeStickyCartBar()}
            >
              <span className="inner">
                {stickyCartBarContent.buttonText
                  ? stickyCartBarContent.buttonText
                  : this.getButtonText()}
              </span>
            </a>

            {false && (
              <button
                type="button"
                className="sticky-cart-close button plain"
                onClick={() => this.closeStickyCartBar()}
              >
                <span className="inner">
                  {stickyCartBarContent.closeButtonText
                    ? stickyCartBarContent.closeButtonText
                    : ""}
                  <i className="icon-close-grey" />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
StickyCartBar.contextType = AllContexts;

export default StickyCartBar;
