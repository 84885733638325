import { getLocale } from "../../utils";

const locale = getLocale();

const cardGroupProjection = `
destinationStories->{
  _id,
  _type,
  'headline': headline.${locale},
  'leadText': leadText.${locale},
  'viewMoreText': viewMoreText.${locale}
}
`;

export default cardGroupProjection;
