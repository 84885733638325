import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";
import itineraryContentBlockProjection from "../items/itinerary-content-block-projection";

const locale = getLocale();

const adventureItineraryItemProjection = `{
  _id,
  _type,
  accordionState,
  entryTitle,
  'heading': heading.${locale},
  itineraryBackgroundImage ${imageProjection},
  itineraryContentBlock[] -> ${itineraryContentBlockProjection},
  'itineraryDistance': itineraryDistance.${locale},
  endingLocation,
  startingLocation
}`;

export default adventureItineraryItemProjection;
