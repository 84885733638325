import buttonProjection from "./button-projection";
import { getLocale } from "../../utils";
import iconProjection from "./icon-projection";
import headingOverrideProjection from "./heading-override-projection";

const locale = getLocale();

const flexItemProjection = `{
  _id,
  'alert': alert.${locale},
  'icon': icon->${iconProjection},
  logo,
  showQRCode,
  'headline': headline.${locale},
  headlineOverride ${headingOverrideProjection},
  'leadText': leadText.${locale},
  cta ${buttonProjection},
  showAppStoreButtons,
  'modalCta': modalCta.${locale},
  modalImage,
  'modalLeadText': modalLeadText.${locale},
  'disclaimer': disclaimer.${locale},
  categories[] -> {
    entryTitle,
    'title': title.${locale},
    orderRank, 
  }
}`;

export default flexItemProjection;
