import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import "./styles.scss";

const CustomDropdown = ({ type, label, options, defaultOption, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(
    defaultOption ? defaultOption : options[0]
  );
  const [isOpen, setIsOpen] = useState();

  const inputRef = useRef();
  const dropdownRef = useRef();

  const onChangeFunction = onChange ? onChange : () => {};

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && dropdownRef.current) {
        const target = event.target;
        if (
          !inputRef.current.contains(target) &&
          !dropdownRef.current.contains(target)
        ) {
          setIsOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, dropdownRef, setIsOpen]);

  return (
    <div className={classNames("custom-dropdown", { [type]: type })}>
      {label && <h5 className="custom-dropdown-label">{label}</h5>}
      <div
        ref={inputRef}
        onClick={() => setIsOpen(!isOpen)}
        className={classNames("custom-dropdown-input")}
      >
        <div>{selectedOption.content}</div>
        <i className={`icon-chevron-${isOpen ? "up" : "down"}`} />
      </div>
      <div
        ref={dropdownRef}
        className={classNames("custom-dropdown-options", { open: isOpen })}
      >
        {options.map((option, index) => {
          return (
            <div
              key={index}
              className="custom-dropdown-option"
              onClick={() => {
                onChangeFunction(option);
                setSelectedOption(option);
                setIsOpen(false);
              }}
            >
              {option.content}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomDropdown;
