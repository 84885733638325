import React, { useEffect, lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import CheckLocale from "./components/check-locale";
import classNames from "classnames";
import ContentPreview from "./components/content-preview";
import { CMSContentProvider } from "./utils/context/CMSContentContext";
import { AmpContextProvider } from "./utils/context/AmpContext";
import { AppContextProvider } from "./utils/context/AppContext";
import { AllContextsProvider } from "./utils/context/AllContexts";
import Header from "./components/header";
import Footer from "./components/footer";
// import ImportContentful from "./components/import-contentful";
import ScrollToTop from "./components/scroll-to-top";
import StickyCartBar from "./components/sticky-cart-bar";
import { targetView } from "./modules/at-events";
import { getLocale } from "./utils/utils";
import { generalTracking, virtualPageLoad } from "./modules/analytics-events";

const AppPage = lazy(() => import("./pages/app-page"));
// const HomePage = lazy(() => import("./pages/home-page-wrapper"));
const HomePageWrapper = lazy(() => import("./pages/home-page-wrapper"));
const DestinationsPage = lazy(() => import("./pages/destinations-page"));
const DestinationPage = lazy(() => import("./pages/destination-page"));
const DestinationPassListingPage = lazy(() =>
  import("./pages/destination-pass-listing-page")
);
const DestinationPassPage = lazy(() => import("./pages/destination-pass-page"));
const LocalPassesLandingPage = lazy(() =>
  import("./pages/local-passes-landing-page")
);
const Page = lazy(() => import("./pages/page"));
const PassDetailsPage = lazy(() => import("./pages/pass-details-page"));
const InstantSearchPage = lazy(() => import("./pages/instant-search-page"));
const ShopPassesPage = lazy(() => import("./pages/shop-passes-page"));
const AllEventsPage = lazy(() => import("./pages/all-events-page"));
const StokeEventsPage = lazy(() => import("./pages/stoke-events-page"));
const ShopPassesPagePrevious = lazy(() =>
  import("./pages/shop-passes-page-previous")
);
const TripsLandingPage = lazy(() => import("./pages/trips-landing-page"));
const TripPage = lazy(() => import("./pages/trip-page"));
const FaqPage = lazy(() => import("./pages/faq-page"));

const renderLoader = () => {
  return <div style={{ height: "100vh" }}></div>;
};

const App = () => {
  const location = useLocation();
  const locale = getLocale();

  useEffect(() => {
    generalTracking("appLoad", "Login State", "loginState", "logged-out");
    virtualPageLoad(window.location.pathname, document.referrer);
  }, []);

  useEffect(() => {
    // this will fire consistently, while the above is inconsistent
    //if (lastPath !== location.pathname) {
    //setLastPath(location.pathname)
    doneLoading();
    //}
  }, [location]);

  const doneLoading = () => {
    if (document.readyState === "complete") {
      // no readystatechange but it is loaded
      targetView(window.location);
    }
    /*document.addEventListener('readystatechange', e => {
      if (document.readyState === 'complete') {
        targetView(window.location)
      }
    })*/
  };

  const isHomepage = () => {
    const path = window.location.pathname;
    if (
      path === "/" ||
      path === "/en" ||
      path === "/fr" ||
      path === "/en/" ||
      path === "/fr/"
    ) {
      return true;
    }
    return false;
  };

  const appClasses = classNames(locale, { "app-homepage": isHomepage() });

  const showStickyCartBar =
    window.location.pathname.indexOf("shop-passes") > -1;

  if (!locale) return null;

  return (
    <CMSContentProvider>
      <a
        href="#mainContent"
        title="Skip to main content link"
        id="skipToMainContent"
        tabIndex="0"
      >
        Skip to main content
      </a>
      <AppContextProvider>
        <AmpContextProvider>
          <AllContextsProvider>
            <CheckLocale />
            <ContentPreview />
            <div
              id="app-wrapper"
              data-page={location.pathname}
              className={appClasses}
            >
              <Suspense fallback={() => <div></div>}>
                <Header />
              </Suspense>
              <main id="mainContent">
                <ScrollToTop>
                  <Suspense fallback={renderLoader}>
                    <Switch>
                      {/* Remove trailing slashes and redirect to the same url without the trailing slash*/}
                      {window &&
                        window.location.pathname !== "/" &&
                        window.location.pathname.slice(-1) === "/" && (
                          <Route
                            path="*"
                            render={(match) => {
                              const pathname = window.location.pathname.slice(
                                0,
                                -1
                              );
                              const search = match.location.search; // in case there are search params
                              return (
                                <Redirect
                                  to={{ pathname: `${pathname}${search}` }}
                                />
                              );
                            }}
                          />
                        )}

                      {/* Getting multiple bots hitting the site searching for undefined pages.  Go straight to
                        the page not found.
                      */}
                      {window &&
                        window.location.pathname &&
                        window.location.pathname.indexOf("undefined") > -1 && (
                          <Route>
                            <Page />
                          </Route>
                        )}
                      <Route path="/:lang(en|fr)?" exact>
                        <HomePageWrapper nextYear={false} />
                      </Route>
                      {/* <Route path="/:lang(en|fr)/next-year" exact>
                        <HomePageWrapper nextYear={true} />
                      </Route> */}
                      <Route path="/:lang(en|fr)/events" exact>
                        <AllEventsPage />
                      </Route>
                      <Route path="/:lang(en|fr)/stoke-events" exact>
                        <StokeEventsPage />
                      </Route>
                      <Route path="/:lang(en|fr)/app" exact>
                        <AppPage />
                      </Route>
                      <Route path="/:lang(en|fr)/destinations" exact>
                        <DestinationsPage />
                      </Route>
                      <Route
                        path="/:lang(en|fr)/destinations/:destinationName"
                        exact
                      >
                        <DestinationPage />
                      </Route>
                      <Route path="/:lang(en|fr)/local-passes" exact>
                        <LocalPassesLandingPage />
                      </Route>
                      <Route
                        path="/:lang(en|fr)/local-passes/:destinationName"
                        exact
                      >
                        <DestinationPassListingPage />
                      </Route>
                      <Route
                        path="/:lang(en|fr)/local-passes/:destinationName/:passName"
                        exact
                      >
                        <DestinationPassPage />
                      </Route>
                      <Route path="/:lang(en|fr)/shop-passes" exact>
                        <ShopPassesPage />
                      </Route>
                      <Route path="/:lang(en|fr)/shop-passes-2023-2024" exact>
                        <ShopPassesPagePrevious />
                      </Route>
                      <Route path="/:lang(en|fr)/shop-passes/:passName" exact>
                        <PassDetailsPage />
                      </Route>
                      <Route path="/:lang(en|fr)/adventures" exact>
                        <TripsLandingPage />
                      </Route>
                      <Route
                        path="/:lang(en|fr)/adventures/:destinationName"
                        exact
                      >
                        <TripPage />
                      </Route>
                      <Route path="/:lang(en|fr)/search" exact>
                        <InstantSearchPage />
                      </Route>
                      <Route path="/:lang(en|fr)/faq" exact>
                        <FaqPage />
                      </Route>
                      <Route path="/:lang(en|fr)/:slug" component={Page} />

                      {/*
                        Route complete and tested. This route will match anything without a langKey,
                        set above by "/:lang(en|fr)", and redirect it to the same URL with a langkey
                    */}
                      <Route
                        path="*"
                        render={(match) => {
                          // const langKey = "fr";
                          const pathName = window.location.pathname;
                          const prefixTest = /(^\/fr)|(^\/en)/i;
                          if (!prefixTest.test(pathName)) {
                            return (
                              <Redirect
                                to={{
                                  pathname: `/${locale}${pathName}`,
                                  search: `${match.location.search}`,
                                  // push: true,
                                  hash: `${match.location.hash}`,
                                  from: match.location,
                                }}
                              />
                            );
                          }
                        }}
                      />
                    </Switch>
                  </Suspense>
                </ScrollToTop>
              </main>
            </div>

            <Footer>
              {showStickyCartBar && (
                <StickyCartBar
                  stickyCartBarContent={{
                    buttonText: "",
                    cartSubtotalText: "Cart Subtotal",
                    closeButtonText: "Close",
                    currencyTooltipText: "Approx.",
                    entryTitle: "Sticky Cart Bar",
                  }}
                />
              )}
            </Footer>
          </AllContextsProvider>
        </AmpContextProvider>
      </AppContextProvider>
    </CMSContentProvider>
  );
};

export default App;
