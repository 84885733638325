import affirmCalloutProjection from "./affirm-callout-projection";
import buttonProjection from "./button-projection";
import countdownTimerProjection from "./countdown-timer-projection";
import featureTileProjection from "./feature-tile-projection";
import disruptionBannerProjection from "../modules/disruption-banner-projection";
import gradientProjection from "./gradient-projection";
import iconsListProjection from "../lists/icons-list-projection";
import imageProjection from "../items/image-projection";
import statProjection from "./stat-projection";
import videoProjection from "../items/video-projection";
import { getLocale } from "../../utils";
import diagonalTextProjection from "./diagonal-text-projection";
import imageGalleryProjection from "../items/image-gallery-projection";

const locale = getLocale();

const genericHeroProjection = `{
  _id,
  _type,
  'affirmCalloutContent': affirmCallout->${affirmCalloutProjection},
  'accessDetailsOverride': accessDetailsOverride.${locale},
  'backgroundColor': backgroundColor.title,
  'heroTextColor': heroTextColor.title,
  backgroundImage ${imageProjection},
  backgroundImageSecondary ${imageProjection},
  backgroundVideo ${videoProjection},
  ${countdownTimerProjection},
  countUpStatGroup -> {
    _id,
    stats[] -> ${statProjection}
  },
  cta->${buttonProjection},
  diagonalText -> ${diagonalTextProjection},
  secondaryCta->${buttonProjection},
  enableAppStoreBadges,
  hasDuotoneOverlay,
  featureTile->${featureTileProjection},
  disruptionBanner -> ${disruptionBannerProjection},
  'gallery': gallery[]${imageProjection},
  gradient->${gradientProjection},
  'headline': headline.${locale},
  'leadText': leadText.${locale},
  passYear,
  ${iconsListProjection},
  variant,
  headlineAlignment,
  'renewalPriceLabel': renewalPriceLabel.${locale},
  adobeTargetActivityID
}
`;

export default genericHeroProjection;
