import adventureIntroProjection from "../modules/adventure-intro-projection";
import adventureItineraryItemProjection from "../modules/adventure-itinerary-item-projection";
import columnedContentProjection from "../modules/columned-content-projection";
import disruptionBannerProjection from "../modules/disruption-banner-projection";
import { getLocale } from "../../utils";
import imageProjection from "../items/image-projection";
import seoProjection from "../seo-projection";

const locale = getLocale();

const adventurePageProjection = `{
  _id,
  _type,
  adventureId,
  contentBlocks[] -> {
    _id,
    _type,
    _type == 'adventureItineraryItem' => ${adventureItineraryItemProjection},
    _type == 'disruptionBanner' => ${disruptionBannerProjection},
    _type == 'columnedContent' => ${columnedContentProjection},
  },
  'adventureBodyText': adventureBodyText.${locale},
  'adventureRegion': adventureRegion.${locale},
  'bookingLinkText': bookingLinkText.${locale},
  bookingLinkUrl,
  intro -> ${adventureIntroProjection},
  seo ${seoProjection},
  'tripTypeLabel': tripTypeLabel -> {
    'tagText': tagText.${locale}
  },
  listingDisplayImage ${imageProjection},
  'title': title.${locale},
  'url': url.current,
}`;

export default adventurePageProjection;
