import blueAccountIcon from "../../static/images/svg/account.svg";
import whiteAccountIcon from "../../static/images/svg/account-white.svg";
import classNames from "classnames";

const UserProfileImageCircle = ({
  profileImg,
  notificationNumber,
  variant,
  color,
  bordered,
}) => {
  const circleClasses = classNames(
    "user-profile-img-circle",
    variant ? `user-profile-img-circle__${variant}` : null,
    bordered ? `user-profile-img-circle__border` : null
  );
  const hasProfileImg = !!(profileImg && profileImg.includes("cloudinary"));
  let placeholderIcon = blueAccountIcon; // default is blue version
  if (color && color === "white") {
    placeholderIcon = whiteAccountIcon;
  }

  return (
    <div className={circleClasses}>
      {hasProfileImg ? (
        <img src={profileImg} className="user-profile-img" alt="User profile" />
      ) : (
        <img
          src={placeholderIcon}
          className="user-fallback-img"
          alt="User fallback"
        />
      )}
      {notificationNumber && notificationNumber > 0 ? (
        <div className="user-notification-badge">
          <div className="user-notification-badge-number">
            {notificationNumber}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserProfileImageCircle;
