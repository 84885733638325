// ==========================
// Initilizing the store to fetch/save a data from the CMS
// Steps
//    1. Create an exportable constant. This is used as the key to access the content in the store.
//          e.i. export const MY_DATA = 'myData'
//    2. Inside the `useStoreData` function and during the initilization of the 'store' variable,
//       add a default value for the newly fetched data.
//          e.i
//              const ...useRef({
//                  ...
//                  [MY_DATA]: null
//                  ...
//              })
//    3. Inside the `useStoreData` function, create/modify someFunction to include a `set` call that updates the value of the key
//           e.i
//               const someFunction = (data) => {
//                   ...
//                   set({ [MY_DATA]: data });
//                   ...
//               }
//    4. Add the created function inside the `setter` object in the return value of `useStoreData`.
//           e.i
//               return {
//                  ...
//                  setters: {
//                      ...,
//                      getNewlyFetchedData,
//                      ...
//                  },
//                  ...
//               }
// ==========================
// Using the context in a Functional Component:
// Steps
//    1. import `useCMSContent` in the file
//            e.i. `import { useAmp } from '../utils/context/AmpContext';`
//    2. Initialized the the state variables
//            e.i. const [subContext, getterFunctions] = useAmp(selectors)
//                      - subContext: the context you requested using the selector - the return value of your selector
//                      - getterFunctions: functions to fetch the data from the CMS (getThis, getThat, getThose),
//                                         it also saves the data into the store - to be accessed by values in the subContext
//                      - selectore: A function that takes a store and returns the subcontext
//            e.i. More example - grabs the accessDetailsApiData from the store and its fetcher function.
//                      const [
//                        { accessDetailsApiData },
//                        { fetchAccessDetailsApiData }
//                      ] = useAmp((store) => ({
//                        accessDetailsApiData: store.accessDetailsApiData
//                      }))
//            e.i. Using a Key example - Unifying the keys so you don't have to keep guesing
//                      const [
//                        { accessDetailsApiData },
//                        { fetchAccessDetailsApiData }
//                      ] = useAmp((store) => ({
//                        accessDetailsApiData: store[ACCESS_DETAILS_API_DATA]
//                      }))
//           e.i. Renaming the store value - changing the store key (global) in the state key (local)
//                      const [
//                        { THIS_CAN_ALSO_CHANGE },
//                        { fetchAccessDetailsApiData }
//                      ] = useAmp((store) => ({
//                        THIS_CAN_ALSO_CHANGE: store[ACCESS_DETAILS_API_DATA]
//                      }))
//    3. Fetched the data using the fetcher function
//           e.i. Fetch the data inside a useEffect
//                      useEffect(() => {
//                        fetchAccessDetailsApiData()
//                          .catch(console.error)
//                      }, [fetchAccessDetailsApiData])
//           e.i. Fetch the data inside an onclick method
//                      const someOnclick = () => {
//                          fetchAccessDetailsApiData()
//                            .catch(console.error)
//                      }
// ==========================
// Using the context in a Class Component - We're moving towards functional components... so you can just ignore this:
// Steps
//    1. Check AllContents.js if the the data is included in the selector. If so, skip Step 2
//    2. Include the data in the selector
//           e.i. ... = useAmp((store) => ({
//                  ...
//                  accessDetailsApiData: store[ACCESS_DETAILS_API_DATA]
//                  ...
//                })
//    3. Import `AllContenxt` in the file
//           e.i. `import { AllContexts } from '../utils/context/AllContexts';`
//    4. Inject AllContext to the context of the component.
//          e.i. ComponentClassName.contextType = AllContexts
//    5. Access the data and its fetcher functions using `this.context`
//           e.i. const { accessDetailsApiData, fetchAccessDetailsApiData } = this.context;

import React, {
  createContext,
  useContext,
  // useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { camelizeKeys } from "humps";
import { generalTracking } from "../../modules/analytics-events";

import {
  getProductData2,
  getCartData2,
  sendCartData2,
  getAccessDetailsApiData2,
  isUserLoggedIn2,
  logoutUser2,
} from "../amp-client";
import { writeCookie } from "../utils";

import { AppContext } from "./AppContext";
import env from "../../modules/environments";

import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";

export const ACCESS_DETAILS_API_DATA = "accessDetailsApiData";
export const USER_DATA = "userData";
export const API_OUTAGE = "apiOutage";
export const PRODUCT_DATA = "productData";
export const CART_DATA = "cartData";

const AmpContext = createContext();

let isFetchingAccessDetailsApiData = false;
let isFetchingProductData = false;

const useStoreData = () => {
  const store = useRef({
    [ACCESS_DETAILS_API_DATA]: [],
    [USER_DATA]: null,
    [API_OUTAGE]: false,
    [PRODUCT_DATA]: null,
    [CART_DATA]: {},
  });

  const get = useCallback(
    (keyword) => (keyword ? store?.current[keyword] : store.current),
    []
  );

  const subscribers = useRef(new Set());

  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    subscribers.current.forEach((callback) => {
      return callback();
    });
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  // Initial load for userData
  useEffect(() => {
    isUserLoggedIn2()
      .then((newUserData) => {
        generalTracking(
          "loginConfirmed",
          "Login State",
          "loginState",
          "logged-in"
        );
        // setUserData(newUserData)
        set({ [USER_DATA]: newUserData });
      })
      .catch(console.error);
  }, [set]);

  const fetchAccessDetailsApiData = async () => {
    if (
      !isFetchingAccessDetailsApiData &&
      (!get("accessDetailsApiData") || !get("accessDetailsApiData").length)
    ) {
      isFetchingAccessDetailsApiData = true;
      fetch(env.cachedAccessDetailsUrl, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!env.isProduction()) {
            throw new Error("Get LIVE access details instead of cached");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Using cached access details");
          set({ [ACCESS_DETAILS_API_DATA]: camelizeKeys(data.data) });
          // setAccessDetailsApiData(camelizeKeys(data.data))
        })
        .catch((e) => {
          console.log("fetching access details");
          getAccessDetailsApiData2().then((newAccessDetails) => {
            set({ [ACCESS_DETAILS_API_DATA]: newAccessDetails });
          });
        });
    }
  };

  const {
    get: appContextGet,
    setters: { setCurrency },
  } = useContext(AppContext);
  const currency = appContextGet("currency");
  const locale = appContextGet("locale");

  // Retrieves and saves the product data for the current currency and locale
  const fetchProductData = async () => {
    if (
      !isFetchingProductData &&
      (!get("productData") || !get("productData").length)
    ) {
      isFetchingProductData = true;

      if (!currency || !locale) {
        return;
      }

      getProductData2(currency, locale).then((data) => {
        set({ [PRODUCT_DATA]: data });
        if (data === null) {
          // setAPIOutage(true)
          set({ [API_OUTAGE]: true });
        }
      });
    }
  };

  useEffect(() => {
    getCartData2().then((newCartData) => {
      set({ cartData: newCartData });
      if (newCartData?.payload_currency) {
        writeCookie(
          "currentCurrency",
          newCartData.payload_currency,
          5,
          null,
          null,
          null
        );
        if (currency !== newCartData.payload_currency) {
          setCurrency(newCartData.payload_currency);
          // MQ: we weren't updating the new currency in the product data fetch
          getProductData2(newCartData.payload_currency, locale).then((data) => {
            set({ [PRODUCT_DATA]: data });
          });
        }
      }
    });
  }, [currency, set, setCurrency]);

  const sendCartData = async (variant, type, count, cartData = null) => {
    const newCartData = await sendCartData2(variant, type, count, cartData);
    set({ [CART_DATA]: newCartData });
  };

  const logoutUser = () => {
    return logoutUser2()
      .then(() => {
        set({ [USER_DATA]: null });
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        window.location.reload();
      });
  };

  return {
    get,
    subscribe,
    setters: {
      fetchAccessDetailsApiData,
      fetchProductData,
      sendCartData,
      logoutUser,
    },
  };
};

function AmpContextProvider({ children }) {
  // const [accessDetailsApiData, setAccessDetailsApiData] = useState([]);

  // const fetchAccessDetailsApiData = async() => {
  //   if(!isFetchingAccessDetailsApiData && (!accessDetailsApiData || !accessDetailsApiData.length)) {
  //     isFetchingAccessDetailsApiData = true;
  //     fetch(env.cachedAccessDetailsUrl, {headers : {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json'
  //     }}).then(response => {
  //         return response.json()
  //     }).then(data => {
  //       console.log('Using cached access details')
  //       setAccessDetailsApiData(camelizeKeys(data.data))
  //     }).catch(e => {
  //       console.log('fetching access details')
  //       getAccessDetailsApiData2().then(
  //         (newAccessDetails) => {
  //           setAccessDetailsApiData(newAccessDetails)
  //         });
  //     })
  //   }
  // }

  // // Initial load for userData
  // useEffect(() => {
  //   isUserLoggedIn2()
  //     .then(
  //       (newUserData) => {
  //         generalTracking('loginConfirmed', 'Login State', 'loginState', 'logged-in');
  //         setUserData(newUserData)
  //       }
  //     ).catch(console.error);
  // }, []);

  // // const getAccessDetailsApiData = async () => {
  // //   if (Object.keys(accessDetailsApiData).length === 0) {
  // //   const accessDetailsPromise = getAccessDetailsApiData2();
  // //   setAccessDetailsApiData(accessDetailsPromise);
  // //   return accessDetailsPromise;
  // //   } else {
  // //     return accessDetailsApiData;
  // //   }
  // // };

  // const {get: appContextGet, setters: { setCurrency }} = useContext(AppContext);
  // const currency = appContextGet('currency');
  // const locale = appContextGet('locale')

  // const [userData, setUserData] = useState(null);

  // const [userLoggedInState, setUserLoggedInState] = useState(false);

  // const [apiOutage, setAPIOutage] = useState(false)

  // const [productData, setProductData] = useState(null);

  // // const { currency, setCurrency, locale } = appContext;

  // // Retrieves and saves the product data for the current currency and locale
  // const fetchProductData = async () => {
  //   if(!isFetchingProductData && (!productData || !productData.length)) {
  //     isFetchingProductData = true
  //     console.log(currency, locale);
  //     getProductData2(currency, locale).then((data) => {
  //       setProductData(data)
  //       if (data === null) {
  //         setAPIOutage(true)
  //       }
  //     });
  //   }
  // };

  // // Initial load of productData
  // /*useEffect(() => {
  //   getProductData();
  // }, [currency, locale]);*/

  // const [cartData, setCartData] = useState({});

  // // Inital load of cartData
  // useEffect(() => {
  //   getCartData2().then((newCartData) => {
  //     setCartData(newCartData)
  //     if(newCartData?.payload_currency) {
  //       writeCookie('currentCurrency', newCartData.payload_currency, 5, null, null, null);
  //       setCurrency(newCartData.payload_currency)
  //     }
  //   });
  // }, []);

  // const sendCartData = async (variant, type, count, cartData = null) => {
  //   const newCartData = await sendCartData2(variant, type, count, cartData);
  //   setCartData(newCartData);
  // };

  // const logoutUser = () => {
  //   return logoutUser2()
  //     .then(() => {
  //       setUserData(null);
  //     })
  //     .catch(console.error);
  // }

  // const context = {
  //   accessDetailsApiData,
  //   fetchAccessDetailsApiData,
  //   apiOutage,
  //   fetchProductData,
  //   productData,
  //   cartData,
  //   setCartData,
  //   sendCartData,
  //   userData,
  //   logoutUser
  // };

  return (
    <AmpContext.Provider value={useStoreData()}>{children}</AmpContext.Provider>
  );
}

function useAmp(selector) {
  const store = useContext(AmpContext);
  if (store === undefined) {
    throw new Error("useAmp must be used within a DestinationsProvider");
  }

  const state = useSyncExternalStoreWithSelector(
    store.subscribe,
    store.get,
    null,
    selector,
    // Custom Equality function to
    (oldState, newState) => {
      let isEqual = true;

      Object.entries(oldState).forEach(([key, entry]) => {
        if (!Object.is(entry, newState[key])) {
          isEqual = false;
        }
      });

      return isEqual;
    }
  );

  return [state, store.setters];
}

function AmpContextConsumer({ children }) {
  return (
    <AmpContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            "AmpContextConsumer must be used within a AmpContextProvider"
          );
        }
        return children(context);
      }}
    </AmpContext.Consumer>
  );
}

export {
  AmpContext, // Imported directly into class-based components
  AmpContextProvider, // Wraps components that need access to useAmp
  useAmp, // Custom hook to access context in function-based components
  AmpContextConsumer, // Can be used to consume context in a class-based component, maybe unnecessary
};
