import imageProjection from "../items/image-projection";
import buttonProjection from "../modules/button-projection";

import { getLocale } from "../../utils";

const locale = getLocale();

// this is for the instance where an adventure is used as a blog tile
const linkCategory = locale?.toLowerCase() === "en" ? "Trip" : "Voyage";

const storyProjection = `{
  _id,
  _type,
  _type == 'cardItem' => {
    'headline': headline.${locale},
    image ${imageProjection},
    'imageAlt': image.alt.${locale},
    'category': postCategory[0]->title.${locale},
    cta->${buttonProjection},
    'link': postLink,
    linkText
  },
  _type == 'adventurePage' => {
    _type,
    _id,
    entryTitle,
    'headline': title.${locale},
    'image': listingDisplayImage ${imageProjection},
    'category': '${linkCategory}',
    'cta': {
      'url': url.current,
      'text': listingLinkText.${locale},
      'style': 'text-with-caret'
    },
    'link': url.current,
    'linkText': listingLinkText.${locale}
  }
}
`;

export default storyProjection;
