import { getLocale } from "../../utils";
import buttonProjection from "./button-projection";
import passCartWidgetProjection from "./pass-cart-widget-projection";

const locale = getLocale();

const passCartWidgetGroupProjection = `{
  entryTitle,
  'groupTitle': groupTitle.${locale},
  'toggleButtonText': toggleButtonText.${locale},
  passCartAutomatedLearnMore -> ${buttonProjection},
  passCartWidgets[] -> ${passCartWidgetProjection},
  adobeTargetActivityID
}
`;

export default passCartWidgetGroupProjection;
